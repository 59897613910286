import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sucstu-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit {

  @Input() planId;
  @Input() queryParams;
  @Input() popular;
  @Input() badge;

  constructor() {
  }

  ngOnInit() {
  }

}
