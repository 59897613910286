import { NgModule } from '@angular/core';
import { NgMasonryGridModule } from 'ng-masonry-grid';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Third party modules.
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// modules
import { SharedModule } from '@app/shared/shared.module';
import { PagesRoutingModule } from '@app/pages/pages.routing';
import { SwiperModule } from 'ngx-swiper-wrapper';

// Containers
import * as fromContainers from './containers';

// components
import { FaqQuestionComponent } from './containers/faq/components/faq-question/faq-question.component';
import { SummerBoxItemComponent } from './containers/summer/components/box-item/box-item.component';

// services
import * as fromServices from './services';
import { ShipmentTrackingComponent } from './containers/shipment-tracking/shipment-tracking.component';
import { WinterComponent } from './containers/winter/winter.component';

@NgModule({
  imports: [
    SharedModule,
    PagesRoutingModule,
    NgMasonryGridModule,
    InfiniteScrollModule,
    SwiperModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [],
  declarations: [
    ...fromContainers.containers,
    FaqQuestionComponent,
    SummerBoxItemComponent,
    ShipmentTrackingComponent,
    WinterComponent,
  ],
  providers: [
    ...fromServices.services
  ],
})
export class PagesModule { }
