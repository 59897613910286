import { Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';
import { paramsMap } from '../mappers/params-map.mapper';

// models
import { Coupon } from './../models/coupon.model';

// services
import { NotificationBarService } from './notification-bar.service';

// actions
import * as couponsActions from '@app/store/actions/coupon.actions';

// selectors
import * as fromRoot from '@app/store/selectors';
import * as fromCoupons from '@app/store/selectors/coupons.selector';
import * as fromOrderActions from '@app/latest-checkout/store/actions/order.actions';

@Injectable()
export class CouponsService {

  coupon$: Observable<Coupon>;
  firstLoad = true;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private store: Store<fromRoot.State>,
    private localStorageService: LocalStorageService,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private sessionStorageService: SessionStorageService,
    private notificationBarService: NotificationBarService,
  ) {
  }

  init() {
    this.coupon$ = this.store.pipe(select(fromCoupons.getCouponData));

    this.coupon$
      .pipe(filter(coupon => !!coupon))
      .subscribe((coupon: Coupon) => {
        this.sessionStorageService.store('coupon', JSON.stringify(coupon));
        const showCouponNotification = this.localStorageService.retrieve('showCouponNotification');
        if (this.firstLoad || showCouponNotification) {
          this.notificationBarService.open({
            mode: 'success',
            type: 'coupon',
            title: coupon.bannerTitle || 'Succulent Savings!',
            message: coupon.bannerMessage,
          });
          this.localStorageService.store('showCouponNotification', true);
        }

        this.firstLoad = false;
      });

    this.router.routerState.root.queryParams
      .pipe(
        map(paramsMap),
        map(queryParams => queryParams.ccode),
        filter(value => value
          && !this.router.routerState.root.snapshot.queryParams.claimCode
          && this.router.routerState.root.snapshot.queryParams.bogo !== 'true'
          && !this.router.routerState.root.snapshot.queryParams.bradsdeals
          && !this.router.routerState.root.snapshot.queryParams.lf,
        )
      )
      .subscribe(coupon => {
        this.store.dispatch(new couponsActions.VerifyCouponAction(coupon));
      });
    this.router.routerState.root.queryParams
      .pipe(
        map(paramsMap),
        map(queryParams => queryParams.claimCode),
        filter(Boolean),
      )
      .subscribe(claimCode => {
        const coupon = this.router.routerState.root.snapshot.queryParams.ccode;
        this.store.dispatch(new couponsActions.VerifyClaimCouponAction({coupon, claimCode}));
        this.store.dispatch(new fromOrderActions.ApplyClaimCode(claimCode));
      });

    this.notificationBarService.close$.subscribe(data => {
      if (data.type === 'coupon') {
        this.localStorageService.store('showCouponNotification', false);
      }
    });
  }

  verifyCoupon(code: string, planId?: string, claimCode?: string): Observable<Coupon> {
    const params = new HttpParams({
      fromObject: {
        couponCode: code,
        planId,
        claimCode
      }
    });
    return this.httpClient
      .get(`${ this.app_config.api_uri }/users/subscriptions/verify_coupon`, {params})
      .pipe(map((result: any) => result.coupon as Coupon));
  }

  resetToFirstLoad() {
    this.firstLoad = true;
  }
}
