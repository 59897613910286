import * as tslib_1 from "tslib";
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromBoxesSelectors from '@app/store/selectors/boxes.selector';
var WinterComponent = /** @class */ (function () {
    function WinterComponent(store) {
        var _this = this;
        this.store = store;
        this.boxes$ = of([
            '63c05cbacdf02a80591c8ed9',
            '63c05cbacdf02a80591c8eda',
            '63c05cbacdf02a80591c8edb',
            '63c05cbacdf02a80591c8edc',
            '63c05cbacdf02a80591c8edd',
            '63c05cbacdf02a80591c8ede',
        ])
            .pipe(switchMap(function (ids) { return combineLatest(ids.map(function (id) {
            return _this.store.pipe(select(fromBoxesSelectors.getBoxById(id)));
        })); }));
        this.cart$ = new BehaviorSubject({
            '63c05cbacdf02a80591c8ed9': 0,
            '63c05cbacdf02a80591c8eda': 0,
            '63c05cbacdf02a80591c8edb': 0,
            '63c05cbacdf02a80591c8edc': 0,
            '63c05cbacdf02a80591c8edd': 0,
            '63c05cbacdf02a80591c8ede': 0,
        });
        this.queryParams$ = this.cart$
            .pipe(map(function (cartState) {
            return {
                boxId: Object.keys(cartState),
                boxQty: Object.values(cartState)
            };
        }));
    }
    WinterComponent.prototype.quantityChange = function (quantity, box) {
        var _a;
        var currentState = this.cart$.getValue();
        this.cart$.next(tslib_1.__assign({}, currentState, (_a = {}, _a[box._id] = quantity, _a)));
    };
    WinterComponent.prototype.preventRedirect = function () {
        var currentState = this.cart$.getValue();
        var hasBox = Object.keys(currentState).find(function (key) { return currentState[key] > 0; });
        return !hasBox;
    };
    return WinterComponent;
}());
export { WinterComponent };
