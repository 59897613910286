import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import * as boxActions from '@app/store/actions/box.action';
;
export var adapter = createEntityAdapter({
    selectId: function (box) { return box._id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    errors: null
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case boxActions.LOAD_BOX:
        case boxActions.LOAD_BOXES: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false });
        }
        case boxActions.LOAD_BOXES_COMPLETE: {
            return adapter.addAll(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false, errors: null }));
        }
        case boxActions.LOAD_BOX_COMPLETE: {
            return adapter.addOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false, errors: null }));
        }
        case boxActions.LOAD_BOX_FAIL:
        case boxActions.LOAD_BOXES_FAIL: {
            return tslib_1.__assign({}, state, { errors: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getBoxes = function (state) { return state.entities; };
export var getBoxesLoaded = function (state) { return state.loaded; };
export var getBoxesLoading = function (state) { return state.loading; };
export var getBoxesError = function (state) { return state.errors; };
