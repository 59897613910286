import { Component } from '@angular/core';

@Component({
  selector: 'sucstu-app-terms',
  styleUrls: ['./terms.component.scss'],
  templateUrl: './terms.component.html'
})
export class TermsComponent {
  constructor() { }
}
