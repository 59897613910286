export { State } from '@app/store/reducers';

// reducers
import * as Root from './../reducers';

// selectors
export const getRootState = (state: Root.State) => state;
export const getUserState = (state: Root.State) => state.user;
export const getBoxesState = (state: Root.State) => state.boxes;
export const getPlansState = (state: Root.State) => state.plans;
export const getCouponState = (state: Root.State) => state.coupon;
export const getAddressState = (state: Root.State) => state.addresses;
export const getShipmentsState = (state: Root.State) => state.shipments;
export const getSearchEmailState = (state: Root.State) => state.searchEmail;
export const getSubscriptionsState = (state: Root.State) => state.subscriptions;
export const getPaymentMethodsState = (state: Root.State) => state.paymentMethods;
export const getNotificationBarState = (state: Root.State) => state.notificationBar;
