import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { timer } from 'rxjs';
var VideoTimedLoopDirective = /** @class */ (function () {
    function VideoTimedLoopDirective(el) {
        this.el = el;
        this.secs = 2;
        this.started = false;
    }
    VideoTimedLoopDirective.prototype.loopVideoAfter2secs = function () {
        var _this = this;
        // console.time('appVideoTimedLoop[ended]');
        var loopInterval = timer(this.secs * 1000);
        loopInterval.subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.el.nativeElement.currentTime = 0;
                        return [4 /*yield*/, this.el.nativeElement.play()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return VideoTimedLoopDirective;
}());
export { VideoTimedLoopDirective };
