import { createSelector } from '@ngrx/store';

import * as fromRoot from '@app/store/selectors';
import * as fromSubscriptions from '@app/store/reducers/subscription.reducer';
import * as fromPlans from '@app/store/selectors/plans.selector';
import { Subscription } from '@app/core/models/subscription.model';
import { Plan } from '@app/core/models/plan.model';
import { Dictionary } from '@ngrx/entity/src/models';

export { State, Entities } from '@app/store/reducers/subscription.reducer'

export const {
  selectIds: getSubscriptionsIds,
  selectEntities: getSubscriptionEntities,
  selectTotal: getTotalSubscriptions
 } = fromSubscriptions.adapter.getSelectors(fromRoot.getSubscriptionsState);

export const getAllSubscriptions = createSelector(
  fromSubscriptions.adapter.getSelectors(fromRoot.getSubscriptionsState).selectAll,
  fromPlans.getPlansEntities,
  (subscriptions: Subscription[], plans: { [id: string]: Plan }) => {
    return subscriptions.map(subscription => {
      const plan = plans[subscription.plan as string] ;
      return {...subscription, plan };
    });
  }
);

export const getSubscriptionsLoaded = createSelector(
  fromRoot.getSubscriptionsState,
  (state: fromSubscriptions.State) => state.loaded
);

export const getSubscriptionsLoading = createSelector(
  fromRoot.getSubscriptionsState,
  (state: fromSubscriptions.State) => state.loading
);

export const getSubscriptionSelected = createSelector(
  fromRoot.getSubscriptionsState,
  (state: fromSubscriptions.State) => state.selected
);

export const getSubscriptionById = id => createSelector(
  getSubscriptionEntities,
  ((entities: Dictionary<Subscription>) => entities[id])
);

export const getSubscriptionError = createSelector(
  fromRoot.getSubscriptionsState,
  (state: fromSubscriptions.State) => state.error
);

export const getSelectedSubscriptionEntity = createSelector(
  getSubscriptionEntities,
  getSubscriptionSelected,
  fromPlans.getPlansEntities,
  (subscriptions: fromSubscriptions.Entities, selectedId: number, plans: any) => {
    const subscription = subscriptions[selectedId];
    if (subscription) {
      const plan = plans[subscription.plan as string];
      return { ...subscription, plan };
    }
    return;
  }
);
