import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sucstu-backgrounds',
  templateUrl: './backgrounds.component.html',
  styleUrls: ['./backgrounds.component.scss']
})
export class BackgroundsPageComponent implements OnInit {

  backgroundsList = [
    'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/background-images/background-0.png',
    'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/background-images/background-2.jpeg',
    'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/background-images/background-3.jpeg',
    'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/background-images/background-1.png',
  ];

  constructor() { }

  ngOnInit(): void { }
}
