import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
  ) {}

  dataLayerPush(data: any) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
      (window as any).dataLayer.push(data);
    }
  }

}
