// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';

import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
// Angulartics
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
// RxJS
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
// services
import { AuthService } from '@app/auth/services/auth.service';
import { AppService, ConfigService, GoogleOptimizeService, LeadsService } from '@app/core/services';
import { ErrorService } from './error/services';
import { Experiment } from './core/services/google-optimize.service';
// actions
import * as userActions from '@app/store/actions/user.actions';
import * as notificationBarActions from '@app/store/actions/notification-bar.actions';
// selectors
import * as fromRoot from '@app/store/selectors';
import * as fromUser from '@app/store/selectors/user.selector';


// components
import { MothersBannerComponent } from '@app/shared/components/mothers-banner/mothers-banner.component';
import { paramsMap } from '@app/core/mappers/params-map.mapper';
import { isPlatformBrowser, Location } from '@angular/common';
import {MarketingService} from '@app/core/services/marketing.service';
import { GoogleTagManagerService } from './core/services/google-tag-manager.service';
import {AnglerAiService} from '@app/core/services/angler-ai/angler-ai.service';

@Component({
  selector: 'sucstu-root',
  template: `
    <div class="app-loading" *ngIf="(appLoading$ | async) || !isBrowser">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    <div
      class="content">
      <!-- [@appState]="state" -->
      <sucstu-notification-bar></sucstu-notification-bar>
      <div class="main">
        <sucstu-header>
        </sucstu-header>

        <div class="app-container">
          <router-outlet (deactivate)="onDeactivate()"></router-outlet>
        </div>

      </div>
      <sucstu-footer class="footer-container"></sucstu-footer>
    </div>
    <app-console-viewer></app-console-viewer>
    <app-gmail-modal></app-gmail-modal>
  `,
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   trigger('appState', [
  //     state('inactive', style({ opacity: '0' })),
  //     state('active', style({ opacity: '1' })),
  //     transition('inactive <=> active', animate('500ms ease-out'))
  //   ])
  // ]
})
export class AppComponent implements OnInit, AfterViewInit {
  isBrowser = isPlatformBrowser(this.platformId);

  @ViewChild(MothersBannerComponent, { read: ElementRef }) mothersBannerComponent: ElementRef;

  user$: Observable<fromUser.State>;
  notificationBar$: Observable<any>;
  appLoading$: Observable<boolean> = this.appService.appLoading$.asObservable();
  state = 'inactive';

  notificationBarHeight = 0;
  displayMotherBanner = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private authService: AuthService,
    private angulartics: Angulartics2GoogleTagManager,
    private leadService: LeadsService,
    private errorService: ErrorService,
    private store: Store<fromRoot.State>,
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private googleOptimizeService: GoogleOptimizeService,
    private marketingService: MarketingService,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
    private location: Location,
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly anglerAiService: AnglerAiService,
  ) {
    this.angulartics.startTracking();
    router.events
      .pipe(withLatestFrom(this.store))
      .subscribe(([event, state]) => {
        this.navigationInterceptor(event as RouterEvent);
      });
  }

  ngOnInit() {
    this.user$ = this.store.pipe(select(fromRoot.getUserState));
    this.notificationBar$ = this.store.pipe(select(fromRoot.getNotificationBarState));

    this.configService.getConfig().subscribe((data) => {
      this.sessionStorage.store('config', data.config);
    });

    this.appService.init();

    // Clear LS props when needed.
    const currentAppVersion = this.localStorage.retrieve('appVersion');
    if (!currentAppVersion || currentAppVersion !== environment.app_version) {
      this.localStorage.store('appVersion', environment.app_version);
      Object.keys(environment.propsToClearOnLS)
        .filter(key => environment.propsToClearOnLS[key])
        .forEach(key => this.localStorage.clear(key));
    }

    if (isPlatformBrowser(this.platformId)) {
      window['setGOExperiments'] = {
        zone: this.zone,
        componentFn: (experiment: Experiment) => {
          this.googleOptimizeService.setGoogleOptimizeAsInitialzed();
          this.googleOptimizeService.setExperiment(experiment);
          // RUN THIS FUNCTION ON OPTIMIZE OR IN YOUR CONSOLE TO ACTIVE the METHOD ABOVE
          /*
              window['setGOExperiments'].zone.run(() => {
                  window['setGOExperiments'].componentFn({ name: 'ccr_version', value: true, version: 1, reload: true })
              });
          */
        },
        component: this,
      };
    }

    // Set UUID in order to be capable
    // of recognize the client when the lead
    // data is saved/updated
    const availableAffiliateRefs = ['clickref', 'clickRef'];
    const availableLeadsProps = [
      'utm_medium',
      'utm_term',
      'utm_campaign',
      'utm_source',
      'utm_content',
      'http_referrer_url',
      'source'
    ];

    const queryParams$ = this.route.queryParams
    .pipe(
      filter((data: any) => !!Object.keys(data).length),
      map(paramsMap),
    );

    const utmData$ = queryParams$.pipe(
      filter((data: any) => {
        const value = Object.keys(data)
          .findIndex(key => availableLeadsProps.includes(key));
        return value !== -1;
      }),
    );

    const affiliateRef$ = queryParams$.pipe(
      map((data: any) => {
        const key = Object.keys(data).find(item => availableAffiliateRefs.includes(item));
        return data[key] || null;
      }),
      filter(data => !!data)
    ).subscribe(data => {
      this.sessionStorage.store('affiliateRef', data);
    });

    const storedUUID = this.localStorage.retrieve('uuid');
    const getUUID$ = storedUUID ? of(storedUUID) :
      this.appService.getUUID().pipe(tap((uuid) => this.localStorage.store('uuid', uuid)));

    getUUID$
      .pipe(
        take(1),
        switchMap(uuid => {
          return utmData$.pipe(map(utmData => ({ uuid, params: utmData })));
        }),
        switchMap(({ uuid, params }) => {
          console.log('Here is', uuid, params);
          this.sessionStorage.store('utmData', {
            ...params,
            utm_source: params.utm_source || params.source,
          });
          return this.leadService.create({ uuid, utmData: params });
        }),
      ).subscribe(
      (data) => console.log(data),
      (error) => {
        const message = `Error trying to create UUID/Lead.`;
        this.errorService.client.notify(error, {
          beforeSend: report => {
            report.severity = 'warning';
            report.updateMetaData('extras', {
              http: true,
              client: true,
              message,
              error,
            });
          }
        });
      }
    );

    combineLatest([
      this.user$,
      this.route.queryParams.pipe(
        map(paramsMap),
        filter(queryParams => Object.keys(queryParams).length > 0 === this.location.path().includes('?')),
        map(queryParams => queryParams.token),
      )
    ])
      .pipe(
        switchMap(([user, token]) => {
          if (token && token.length && this.authService.token !== token) {
            this.authService.setJWTToken(token);
            return this.authService.checkToken(token).pipe(
              switchMap(() => of([user, token])),
              catchError(error => {
                this.authService.unsetJWTToken();
                this.router.navigate(['/login']);
                return of([user, null]);
              })
            );
          }
          return of([user, token]);
        })
      )
      .subscribe(([user, token]) => {
        if (
          token && token.length && this.authService.token !== token
          || !user.isAuthenticated && this.authService.token
        ) {
          this.store.dispatch(new userActions.LoadAction());
        }
      });

    this.localStorage
      .observe('authToken')
      .subscribe(token => {
        if (!token) {
          this.store.dispatch(new userActions.LogoutCompleteAction());
        }
      });

    this.displayMotherBanner = this.router.url === '/';
  }

  ngAfterViewInit() {

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if ((window as any).dataLayer && typeof (window as any).dataLayer.push === 'function') {
          (window as any).dataLayer.push({
            event: 'optimize.activate',
            eventCallback: (containerId, ...args) => {
              console.log('Container ID: ' + containerId, args);
            }
          });
        }
      }, 0);
    }
  }


  onCloseNotificationBar() {
    this.store.dispatch(new notificationBarActions.CloseNotificationBarAction());
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      if (isPlatformBrowser(this.platformId)) {
        window.scroll(0, 0);
      }
      // this.state = 'inactive';
      this.appService.setAppLoading(true);
    }

    if (event instanceof NavigationEnd) {
      // this.state = 'active';
      // this.appService.setAppLoading(false)
      if (isPlatformBrowser(this.platformId)) {
        this.marketingService.reportPageView().subscribe(response => {
          this.googleTagManagerService.dataLayerPush({
            event: 'pageView',
            eventID: response.eventId,
          });
        });

        this.user$
          .pipe(take(1))
          .subscribe(user => {
            this.anglerAiService.notifyPageView({
              customer: user.data
            })
              .then()
              .catch(e => {
                console.error(e);
              });
          });
      }

      if (isPlatformBrowser(this.platformId) && (window as any).Intercom) {
        (window as any).Intercom('update');
      }
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError) {
      this.appService.setAppLoading(false);
    }
  }

  onDeactivate() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
    // this.showCouponBanner = false;
  }

}
