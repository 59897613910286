<section class="flex flex-row flex-wrap">
  <div class="w-full lg:w-6/12 md:rounded-lg hero-image flex justify-content-center items-center px-3 text-bold mb-8">
    <h1 class="m-0 p-0 hero-text">ON <br> OUR <br> WAY!</h1>
  </div>

  <div class="w-full lg:w-4/12 md:mx-auto flex flex-row flex-wrap py-8">
    <!--<div class="w-full px-3 mb-6">-->
    <!--  <h2 class="m-0 p-0 status-text">-->
    <!--    Arrives-->
    <!--    <br>-->
    <!--    Wed May 23-->
    <!--  </h2>-->
    <!--</div>-->

    <div *ngIf="(trackingEvents$ | async) as trackingEvents" class="w-full px-3 mb-5">
      <div class="w-full status-bar mb-5">
        <div *ngIf="trackingEvents.lastStatusCode === 'pre_transit'" class="status-bar-inside"></div>
        <div *ngIf="trackingEvents.lastStatusCode === 'in_transit'" class="w-4/12 status-bar-inside"></div>
        <div *ngIf="trackingEvents.lastStatusCode === 'out_for_delivery'" class="w-8/12 status-bar-inside"></div>
        <div *ngIf="trackingEvents.lastStatusCode === 'delivered'" class="w-full status-bar-inside"></div>
      </div>

      <div class="w-full flex justify-between">
        <div [class.step-text--active]="trackingEvents.lastStatusCode === 'pre_transit'"
             class="step-text w-auto text-left">
          Packed
        </div>
        <div [class.step-text--active]="trackingEvents.lastStatusCode === 'in_transit'"
             class="step-text w-auto text-center">
          Shipped
        </div>
        <div [class.step-text--active]="trackingEvents.lastStatusCode === 'out_for_delivery'"
             class="step-text w-auto text-center">
          Out for
          <br>
          delivery
        </div>
        <div [class.step-text--active]="trackingEvents.lastStatusCode === 'delivered'"
             class="step-text w-auto text-right">
          Delivered
        </div>
      </div>
    </div>

    <div class="w-full px-3 mb-16">
      <div class="w-full status-box px-5 pb-8 pt-5">
        <ul>
          <li *ngFor="let event of (trackingEvents$ | async)?.events" class="w-full flex">
            <div class="w-auto mr-4 relative">
              <div style="background: #52C4A2; left: 50%; width: 1px" class="absolute t-0 h-full transform -translate-x-1/2 z-0"></div>
              <img class="w-5 h-5" src="/assets/images/check.svg" alt="check" class="relative">
            </div>
            <div class="w-auto w-full pb-4">
              <div class="w-full">
                <p class="historical-title m-0 p-0">{{ event.status }}</p>
              </div>
              <div class="w-full">
                <span class="historical-text mr-3">{{ event.date | date: 'MMM dd | hh:mm a' }}</span>
                <span class="historical-text">{{ event.location }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
