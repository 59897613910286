
    <div class="login-container container">

      <h2 class="login-container__title">Forgot Password</h2>

      <form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <div class="form-group form-group__flex-start">
          <input #emailInput [ngClass]="{ 'input-error': isFieldValid('email', 'invalidEmail') }" class="form-group__input" type="email"
            placeholder="Email" formControlName="email" name="email" autocomplete="email" (change)="clearErrors()">
          <span class="error-text" *ngIf="isFieldValid('email', 'invalidEmail')">Please use a valid email</span>
          <span class="error-text" *ngIf="error">{{ error }}</span>
        </div>

        <div class="form-button-container">
          <button
            class="btn btn-primary btn-primary--with-hover"
            type="submit">
            Send
          </button>
        </div>

      </form>

    </div>
  