import { AfterViewInit, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';
import { combineLatest, ReplaySubject } from 'rxjs';
// actions
import * as user from '@app/store/actions/user.actions';
// selectors
import * as fromUser from '@app/store/selectors/user.selector';
import { takeUntil } from 'rxjs/operators';
import { NotificationBarService } from '@app/core/services';
// models
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(store, router, renderer, locationService, notificationBarService) {
        this.store = store;
        this.router = router;
        this.renderer = renderer;
        this.locationService = locationService;
        this.notificationBarService = notificationBarService;
        this.destroyed$ = new ReplaySubject(1);
        this.isAuthenticated$ = this.store.pipe(select(fromUser.getUserIsAuthenticated));
    }
    Object.defineProperty(HeaderComponent.prototype, "isInspiration", {
        get: function () {
            return this.locationService.path() === '/inspiration';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "isSales", {
        get: function () {
            return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "showPrideLogo", {
        get: function () {
            // Always show the pride logo
            return true;
            // return this.locationService.path() === '' ||
            //   this.locationService.path() === '/gift' ||
            //   this.locationService.path() === '/gift/pride' ||
            //   this.locationService.path() === '/lp/farm2';
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.pipe(select(fromUser.getUserData))
            .subscribe(function (userState) { return _this.userRole = userState.role; });
    };
    HeaderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        combineLatest([
            this.notificationBarService.data$,
            this.notificationBarService.dimensions$,
        ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (_a) {
            var data = _a[0], dimensions = _a[1];
            if (!_this.isSales) {
                if (dimensions.height > 0 && data.open) {
                    var navRowElement = _this.navRow.nativeElement;
                    var headerContainerPaddingTop = navRowElement.clientHeight + dimensions.height;
                    _this.renderer.setStyle(_this.navRow.nativeElement, 'top', dimensions.height + "px");
                    _this.renderer.setStyle(_this.headerContainer.nativeElement, 'padding-top', headerContainerPaddingTop + "px");
                }
                else {
                    _this.renderer.setStyle(_this.navRow.nativeElement, 'top', "");
                    _this.renderer.setStyle(_this.headerContainer.nativeElement, 'padding-top', "");
                }
            }
        });
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    };
    HeaderComponent.prototype.openNav = function () {
        // this.isNavOpened = !this.isNavOpened;
    };
    HeaderComponent.prototype.logout = function (e) {
        var _this = this;
        this.router
            .navigate(['/'])
            .then(function (n) { return _this.store.dispatch(new user.LogoutAction()); });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
