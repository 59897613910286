import { Component, OnDestroy, AfterViewInit, ElementRef, ViewChild, Renderer2, OnInit, AfterViewChecked } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationBarService } from '@app/core/services';
import { State as NotificationBarState } from '@app/store/reducers/notification-bar.reducer';

@Component({
  selector: 'sucstu-notification-bar',
  styleUrls: ['./notification-bar.component.scss'],
  templateUrl: './notification-bar.component.html',
})
export class NotificationBarComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  @ViewChild('notificationBar') notificationBar: ElementRef;

  data$: Observable<NotificationBarState>;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private renderer: Renderer2,
    private notificationBarService: NotificationBarService,
  ) {  }

  ngOnInit() {
    this.data$ = this.notificationBarService.data$.pipe(takeUntil(this.destroyed$));
  }

  ngAfterViewInit() {
    const notificationBarElement = this.notificationBar.nativeElement as HTMLElement;
    const initialNotificationBarHeight = notificationBarElement.offsetHeight;
    this.renderer.setStyle(notificationBarElement, 'top', `-${initialNotificationBarHeight}px`);

    this.data$.subscribe(data => {
      const notificationBarHeight = notificationBarElement.offsetHeight;
      if (data.open) {
        this.renderer.setStyle(notificationBarElement, 'top', `0px`);
      } else {
        this.renderer.setStyle(notificationBarElement, 'top', `-${notificationBarHeight}px`);
      }
    });
  }

  ngAfterViewChecked() {
    const notificationBarElement = this.notificationBar.nativeElement as HTMLElement;
    const { width, height } = this.notificationBarService.getDimensions();
    if ((notificationBarElement.offsetWidth !== width) || (notificationBarElement.offsetHeight !== height)) {
      this.notificationBarService.setDimensions({
        width: notificationBarElement.offsetWidth,
        height: notificationBarElement.offsetHeight,
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  close() {
    this.notificationBarService.close();
  }

}

export interface NotificationBarData {
  title: string,
  message: string,
  type: string,
  open: boolean
};
