import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

// RxJS
import { Observable } from 'rxjs';

// selectors
import * as fromRoot from './../../../store/selectors';

import * as notificationBarActions from '@app/store/actions/notification-bar.actions';

// services
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ClaimService } from './../../services';
import { map, tap } from 'rxjs/operators';
import * as fromOrderActions from '@app/latest-checkout/store/actions/order.actions';

@Component({
  selector: 'sucstu-claim',
  styleUrls: ['./claim.component.scss'],
  templateUrl: 'claim.component.html'
})
export class ClaimComponent implements OnInit {

  user$: Observable<any>;

  form = this.fb.group({
    email: '',
    claimCode: ''
  });

  userExist = false;
  isAuthenticated = false;

  requesting = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private store: Store<any>,
    private claimService: ClaimService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
  ) { }

  ngOnInit() {
    this.user$ = this.store.pipe(select(fromRoot.getUserState));
    this.user$
      .subscribe(value => {
        this.isAuthenticated = value.isAuthenticated;
        this.form.get('email').setValue(value.data.email);
      });
  }

  claimSubscription() {
    this.requesting = true;
    this.claimService.checkClaimCode(this.form.value)
      .pipe(
        tap((response) => {
          this.sessionStorage.store('claimCode', this.form.value.claimCode);
          this.localStorage.store('customerEmail', this.form.value.email);
          if (response.recipient && response.address) {
            this.localStorage.store('customerAddress', JSON.stringify(response.address));
            this.localStorage.store('customerRecipient', JSON.stringify(response.recipient));
          }
          this.store.dispatch(new fromOrderActions.ApplyClaimCode(this.form.value.claimCode));
        }),
        map((response) => this.checkUserExist(response)),
      )
      .subscribe(
        () => {
        },
        response => {
          this.requesting = false;
          this.store.dispatch(new notificationBarActions.OpenNotificationBarAction({
            title: '',
            message: response.error.message,
            mode: 'error',
            type: 'notification',
          }))
        }
      )
  }

  checkUserExist({userExist}) {
    this.requesting = false;
    if (userExist && !this.isAuthenticated) {
      this.userExist = true;
      return;
    }

    this.router.navigate(['/checkout'], {
      queryParams: {
        planId: '613fa5c6e0e8171ffb36b7f6',
        isPrepay: true,
        ccode: '3MFREESHIP',
        claimCode: this.sessionStorage.retrieve('claimCode')
      }
    });
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
