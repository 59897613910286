<div class="page-container">
  <section class="privacy-container container">
    <h1 class="section__title privacy__title center-text">Privacy Policy</h1>
    <div class="privacy__content">
      <div class="privacy__section">
        <p class="privacy__section__text">
          Succulent Studios, INC.. (“Succulent Studios,” “we,” “us” or “our”) values your privacy and is committed to protecting your
          personal information. We are an online service that delivers high- quality succulents to our members monthly, as
          well as high quality “one-time” products for members.</p>
        <p class="privacy__section__text">In this Privacy Policy (“Policy”), we describe the information that we collect from users of our Site. This Policy
          applies to our website, www.succulent.studio and any subdomains of www.succulent.studio (the “Site”) and the memberships
          that you can purchase on the Site to receive products that we offer or make available through the Site (“Membership(s)”).
          By using the Site and purchasing a Membership, you agree that your personal information will be handled as described
          in this Policy, which is incorporated by reference into the Succulent Studios Terms of Service, available at
          <a routerLink="/terms">www.succulent.studio/terms</a>
        </p>
      </div>
      <div class="privacy__section">
        <h4 class="privacy__section__title">What Information Do We Collect About You and Why?</h4>
        <p class="privacy__section__text">The information we collect from you mainly enables us to fulfill your request for our products. The information we
          collect is also used to send you administrative or account communication or content in which are essential to your
          account. These communications are essential to the successful and effective functioning of your Membership. If
          you no longer wish to receive any communications from us, you may simply cancel your account. You may cancel your
          account by emailing a request to hello@succulent.studio. We also use this information to personalize and
          continually
          improve our Site.</p>
      </div>
      <div class="privacy__section">
        <h4 class="privacy__section__title">Third party offers and communications</h4>
        <p class="privacy__section__text">We will NOT sell your personal contact information to third parties without your consent. We have amazing partners
          who offer special discounts to our members. We provide our members with the opportunity to opt-in to receive these
          offers. If you believe you did not opt-in to this list, or no longer want to receive occasional special offers,
          simply click the unsubscribe button at the bottom of these emails, or email us directly and you will be removed
          immediately at hello@succulent.studio
        </p>
      </div>
    </div>
  </section>
</div>
