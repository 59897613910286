import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import * as boxActions from '@app/store/actions/box.action';

// services
import { BoxService } from '@app/core/services/box.service';

@Injectable()
export class BoxEffects {
  @Effect({ dispatch: false }) loadAll$ = this.actions$.pipe(
    ofType(boxActions.LOAD_BOXES),
    switchMap(() => {
      return this.boxService.getBoxes().pipe(
        map(boxes =>
          this.store.dispatch(new boxActions.LoadBoxesCompleteAction(boxes))
        ),
        catchError(reason => {
          this.store.dispatch(
            new boxActions.LoadBoxesFailAction(reason.errors)
          );
          return of(new boxActions.LoadBoxesFailAction(reason.errors));
        })
      );
    })
  );

  @Effect({ dispatch: false }) load$ = this.actions$.pipe(
    ofType(boxActions.LOAD_BOX),
    map((action: boxActions.LoadBoxAction) => action.payload),
    switchMap(({ id }) => {
      return this.boxService.getBox(id).pipe(
        map(box =>
          this.store.dispatch(new boxActions.LoadBoxCompleteAction(box))
        ),
        catchError(reason => {
          this.store.dispatch(new boxActions.LoadBoxFailAction(reason.error));
          return of(new boxActions.LoadBoxFailAction(reason.error));
        })
      );
    })
  );

  constructor(
    private boxService: BoxService,
    private actions$: Actions,
    private store: Store<any>
  ) {}
}
