import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromCouponsActions from './../../../store/actions/coupon.actions';

@Component({
  selector: 'sucstu-pages-gift-done',
  styleUrls: ['./gift-done.component.scss'],
  templateUrl: 'gift-done.template.html'
})
export class GiftDoneComponent implements OnInit {
  term;

  constructor(private store: Store<any>,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.term = this.route.snapshot.queryParamMap.get('term');
  }

  goToCheckoutWithCoupon() {
    this.store.dispatch(new fromCouponsActions.VerifyCouponAction('fivedollars'));
    this.router.navigate(['/checkout'], { queryParams: { planId: '5997c3ac8852a761249342e9' } });
  }
}
