import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// components
import { HomeComponent } from '@app/pages/containers';
import { ApartmentTherapyComponent } from '@app/pages/containers/apartment-therapy/apartment-therapy.component';
// guards
// import { RouterTransitionGuard } from './core/guards/router-transition.guard';
// resolvers
// shared components
import { MonthlyThanksComponent, ThanksComponent } from '@app/shared/components';
import { PreloadByRoutingService } from './core/preload-strategies/preload-by-routing.service';
import {LandingPagesGuard} from '@app/core/guards/landing-pages/landing-pages.guard';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [
      LandingPagesGuard,
    ]
  },
  {
    path: '',
    loadChildren: 'app/gifts/gifts.module#GiftsModule',
    data: {
      preload: true
    }
  },
  {
    path: 'apartment-therapy',
    component: ApartmentTherapyComponent,
  },
  // {
  //   path: 'checkout',
  //   loadChildren: 'app/checkout/checkout.module#CheckoutModule',
  //   data: {
  //     preload: true
  //   }
  // },
  {
    path: 'checkout',
    loadChildren: 'app/latest-checkout/latest-checkout.module#LatestCheckoutModule',
    data: {
      preload: true
    },
  },
  {
    path: 'profile',
    loadChildren: 'app/my-account/my-account.module#MyAccountModule',
    data: {
      preload: true
    }
  },
  { path: 'blog', loadChildren: 'app/blog/blog.module#BlogModule' },
  { path: 'boxes', loadChildren: 'app/boxes/boxes.module#BoxesModule' },
  { path: 'reviews', loadChildren: 'app/reviews/reviews.module#ReviewsModule' },
  {
    path: 'thanks',
    component: ThanksComponent,
  },
  {
    path: 'monthly-thanks',
    component: MonthlyThanksComponent,
  },
  {
    path: 'lp',
    loadChildren: './landing-pages/landing-pages.module#LandingPagesModule'
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadByRoutingService
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
