<div class="thanks-container">

  <div class="thanks-title-container">
    <h1 class="thanks-title">Thank you so much for subscribing!</h1>

    <p class="thanks-title-message">
      We’re a small team here at Succulent Studios, and we‘re honored
      you chose to join our succulent club.
    </p>

    <div class="thanks-title-divider">
      <img src="/assets/images/icon-transparent@3x.png" alt="Succulent Studio Logo"/>
      <span class="gray-line"></span>
    </div>

    <p class="term-message">
      A welcome email should arrive in your inbox any moment with next steps.
    </p>

    <p class="term-message">
      In the meantime, check out our video on welcoming your new succulents home!
    </p>
  </div>

  <video class="video-container" controls preload="auto"
         poster="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/placeholder/unboxing.jpg">
    <source src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/unboxing-vid.mp4" type="video/mp4">
    <source src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/unboxing-vid.webm" type="video/webm">
    Your browser does not support the video tag.
  </video>
</div>
