import { User } from '@app/core/models/user.model';
import * as user from '@app/store/actions/user.actions';

export interface State {
  data: User,
  loaded: boolean,
  loading: boolean
  isAuthenticated: boolean,
  error: {
    message: string,
    status: number
  }
}

export const initialState: State = {
  data: {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    role: '',
    referralCode: null,
    referrals: [],
    createdAt: null,
    updatedAt: null,
    selectedMonthBoxes: []
  },
  loaded: true,
  loading: false,
  isAuthenticated: false,
  error: null
};

export function reducer(
  state: State = initialState,
  action: user.Actions
): State {
  switch (action.type) {

    case user.LOGIN_COMPLETE:
    case user.CREATE_COMPLETE:
    case user.UPDATE_COMPLETE:
    case user.LOAD_COMPLETE: {
      const data = { ...state.data, ...action.payload };
      return { ...state, isAuthenticated: true, data, loaded: true, loading: false, error: null };
    }

    case user.LOGOUT_COMPLETE: {
      return { ...initialState, error: null };
    }

    case user.LOGIN_FAIL:
    case user.CREATE_FAIL:
    case user.LOAD_FAIL: {
      return { ...state, error: action.payload };
    }

    default: {
      return state
    }
  }
}

export const getUserData = (state: State) => state.data;
export const getUserLoaded = (state: State) => state.loaded;
export const getUserLoading = (state: State) => state.loading;
export const getUserIsAuthenticated = (state: State) => state.isAuthenticated;
export const getUserError = (state: State) => state.error;
