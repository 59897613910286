import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sucstu-monthly-thanks',
  templateUrl: './monthly-thanks.component.html',
  styleUrls: ['./monthly-thanks.component.scss']
})
export class MonthlyThanksComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
