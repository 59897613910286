import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// service
import { AuthService } from '@app/auth/services/auth.service';
import { NotificationBarService } from '@app/core/services/notification-bar.service';
var RecoveryPasswordComponent = /** @class */ (function () {
    function RecoveryPasswordComponent(router, fb, authService, route, notificationBarService) {
        this.router = router;
        this.fb = fb;
        this.authService = authService;
        this.route = route;
        this.notificationBarService = notificationBarService;
        this.displayErrors = false;
        this.form = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
        });
    }
    Object.defineProperty(RecoveryPasswordComponent.prototype, "arePasswordsEquals", {
        get: function () {
            var password = this.form.get('password').value;
            var confirmPassword = this.form.get('confirmPassword').value;
            return password === confirmPassword;
        },
        enumerable: true,
        configurable: true
    });
    RecoveryPasswordComponent.prototype.ngOnInit = function () {
        this.token = this.route.snapshot.queryParams.recoveryToken;
    };
    RecoveryPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        var password = this.form.get('password').value;
        var confirmPassword = this.form.get('confirmPassword').value;
        if (this.form.invalid && !this.arePasswordsEquals) {
            this.displayErrors = true;
            return;
        }
        this.authService
            .recoveryPassword(this.token, password, confirmPassword)
            .subscribe(function (_a) {
            var message = _a.message;
            _this.notificationBarService.open({ title: '', message: message, type: 'notification', mode: 'success' });
            _this.router.navigate(['/login']);
        }, function (error) { return _this.error = error.error.message; });
    };
    RecoveryPasswordComponent.prototype.isFieldValid = function (field, validation) {
        var control = this.form.get(field);
        return control.hasError(validation) && this.displayErrors;
    };
    return RecoveryPasswordComponent;
}());
export { RecoveryPasswordComponent };
