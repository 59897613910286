import { Action } from '@ngrx/store';

export const OPEN_NOTIFICATION_BAR = 'Open Notification Bar';
export const CLOSE_NOTIFICATION_BAR = 'Close Notification Bar';

export class OpenNotificationBarAction implements Action {
  readonly type = OPEN_NOTIFICATION_BAR;
  constructor(public payload: { title: string, message: string, type: string, mode: string }) { }
};

export class CloseNotificationBarAction implements Action {
  readonly type = CLOSE_NOTIFICATION_BAR;
  constructor() { }
};

export type Actions =
  | OpenNotificationBarAction
  | CloseNotificationBarAction;
