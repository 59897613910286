import { SelectBoxGuard } from './select-box.guard';
import { BoxPurchaseGuard } from './box-purchase.guard';
import { BoxConfirmationGuard } from './box-confirmation.guard';
import {AllBoxesGuard} from '@app/boxes/guards/all-boxes.guard';

export const guards = [
  SelectBoxGuard,
  BoxPurchaseGuard,
  BoxConfirmationGuard,
  AllBoxesGuard
];

export { SelectBoxGuard } from './select-box.guard';
export { BoxPurchaseGuard } from './box-purchase.guard';
export { BoxConfirmationGuard } from './box-confirmation.guard';
export { AllBoxesGuard } from './all-boxes.guard';
