import { LeadFormBaseComponent } from './lead-form-base/lead-form-base.component';
import { FiveOffLeadFormComponent } from './five-off-lead-form/five-off-lead-form.component';
import { FreeShippingLeadFormComponent } from './free-shipping-lead-form/free-shipping-lead-form.component';
import { WinItWednesdayLeadFormComponent } from './win-it-wednesday-lead-form/win-it-wednesday-lead-form.component';
import {SixOffLeadFormComponent} from '@app/shared/components/lead-forms/six-off-lead-form/six-off-lead-form.component';
import { FiftyOffLeadFormComponent } from '@app/shared/components/lead-forms/fifty-off-lead-form/fifty-off-lead-form.component';
import {OnlyShippingComponent} from '@app/shared/components/lead-forms/only-shipping/only-shipping.component';
import {LeadformRareCollectibleComponent} from '@app/shared/components/lead-forms/leadform-rare-collectible/leadform-rare-collectible.component';
import {LeadformColorfulClassicComponent} from '@app/shared/components/lead-forms/leadform-colorful-classic/leadform-colorful-classic.component';
import {LeadformThreeforthirtyComponent} from '@app/shared/components/lead-forms/leadform-threeforthirty/leadform-threeforthirty.component';
import {RaceFansLeadFormComponent} from '@app/shared/components/lead-forms/race-fans-lead-form/race-fans-lead-form.component';

export const leadForms = [
  LeadFormBaseComponent,
  FiveOffLeadFormComponent,
  FreeShippingLeadFormComponent,
  WinItWednesdayLeadFormComponent,
  SixOffLeadFormComponent,
  FiftyOffLeadFormComponent,
  OnlyShippingComponent,
  LeadformRareCollectibleComponent,
  LeadformColorfulClassicComponent,
  LeadformThreeforthirtyComponent,
  RaceFansLeadFormComponent,
];

export { LeadFormBaseComponent } from './lead-form-base/lead-form-base.component';
export { FiveOffLeadFormComponent } from './five-off-lead-form/five-off-lead-form.component';
export { FreeShippingLeadFormComponent } from './free-shipping-lead-form/free-shipping-lead-form.component';
export { WinItWednesdayLeadFormComponent } from './win-it-wednesday-lead-form/win-it-wednesday-lead-form.component';
export { SixOffLeadFormComponent } from './six-off-lead-form/six-off-lead-form.component';
export { FiftyOffLeadFormComponent } from '@app/shared/components/lead-forms/fifty-off-lead-form/fifty-off-lead-form.component';
export { OnlyShippingComponent } from '@app/shared/components/lead-forms/only-shipping/only-shipping.component';
export { LeadformRareCollectibleComponent } from './leadform-rare-collectible/leadform-rare-collectible.component';
export { LeadformColorfulClassicComponent } from './leadform-colorful-classic/leadform-colorful-classic.component';
export { LeadformThreeforthirtyComponent } from './leadform-threeforthirty/leadform-threeforthirty.component';
export { RaceFansLeadFormComponent } from './race-fans-lead-form/race-fans-lead-form.component';
