import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as jsonData from './jobs.json';

export interface JobListItem{
  id: string | number;
  roleTitle: string;
  roleLocation: string;
  skills?: string[];
  responsibilities?: string[];
}

@Component({
  selector: 'sucstu-pages-jobs',
  styleUrls: ['./jobs.component.scss'],
  templateUrl: './jobs.template.html'
})

export class JobsComponent implements OnInit {

  jobOpenings;

  selectedJob = null;

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.jobOpenings = jsonData['jobList'];
    this.metaService.updateTag({
      name: 'description',
      content: 'Our team has a deeply rooted passion (pun intended) for integrating the outdoors into our homes. And we want you to help.'
    });

    this.titleService.setTitle('Succulent Studios: Careers');

    const jobId = this.route.snapshot.params.jobId;
    if (jobId) {
      this.selectedJob = this.jobOpenings.find(o => o.id === jobId);
    } else {
      this.selectedJob = null;
    }
  }
}
