<div class="page-container">
  <div class="about-container container">
    <h1 class="section__title about__title text-medium">About</h1>
    <div class="about__container">

      <div class="about__item about__item--1">
        <div class="about__item-picture-container">
          <img src="/assets/images/colored-box.jpg" alt="Colored Box">
        </div>
        <div class="about__item-text-container">
          <h3 class="about__item-title">What’s in the Box</h3>
          <p class="about__item-text">
            Each succulent comes potted in a 2" pot that is carefully secured in its box.
          </p>
          <div class="about__item-button">
            <a routerLink="/checkout" class="btn btn-primary btn-primary--with-hover">Subscribe</a>
          </div>
        </div>
      </div>

      <span class="about__divider"></span>

      <!--<div class="about__item about__item&#45;&#45;2">
        <div class="about__item-picture-container">
          <img src="/assets/images/truck.png" alt="Colored Box">
        </div>
        <div class="about__item-text-container">
          <h3 class="about__item-title">Shipping Information</h3>
          <p class="about__item-subtitle">Succulents are shipped anywhere in the U.S.</p>
          <p class="about__item-text">Once an order is placed, we will verify your order details, select a pair of
            healthy plants from our nursery, trim
            them, and carefully pot them and package them safely in their box. This process takes about 2-3 business
            days,
            then they'll be on their way! *NOTE:* We are committed to providing you the very best plants, and we
            sometimes
            need an extra day or two for your plants to be in peak condition for shipping. Please be patient if it takes
            a little longer for your box to ship! Once shipped, you will receive an email with tracking information and
            an
            estimated delivery day.
          </p>
        </div>
      </div>

      <span class="about__divider"></span>-->

      <div class="about__item about__item--3">
        <div class="about__item-picture-container">
          <img src="/assets/images/2x-plan.jpg" alt="Colored Box">
        </div>
        <div class="about__item-text-container">
          <h3 class="about__item-title">The Company</h3>
          <p class="about__item-subtitle">Born in California, raised wherever you are.</p>
          <br>
          <p class="about__item-text">
            With its second-generation farm in Fallbrook and fulfillment center in Long Beach,
            California, Succulent Studios grows and ships hundreds of succulent and cacti species. Our team has a deeply
            rooted passion (pun intended) for integrating the outdoors into our homes, and we love sharing our
            succulents with folks in all corners of the country!
          </p>
          <br>
<!--          <p class="about__item-subtitle">Organic. Plastic-free. Earth-conscious.</p>-->
<!--          <br>-->
<!--          <p class="about__item-text">-->
<!--            Succulent Studios takes pride in growing these hardy little plants using only <strong>organic media and-->
<!--            fertilizers</strong>. We believe it's long overdue and vitally important for businesses to operate with our-->
<!--            earth and future generations in mind, which is why we use <strong>biodegradable pots and 100% plastic-free-->
<!--            packaging</strong>. We've made it our mission to walk this earth more gently and do our part for a brighter,-->
<!--            greener future.-->
<!--          </p>-->
<!--          <br>-->
          <p class="about__item-subtitle">You can reach us at:</p>
          <br>
          <p class="about__item-text">
            Succulent Studios <br>
            11120 Scott Ave <br>
            South Gate, CA 90280 <br>

            <br>
            hello@succulent.studio
          </p>


        </div>
      </div>

    </div>
  </div>
</div>
