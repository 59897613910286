import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({ selector: '[click-outside]' })
export class ClickOutsideDirective {

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const element = this.elementRef.nativeElement as HTMLElement;
    const target = event.target as HTMLElement;
  }

  constructor(
    private elementRef: ElementRef
  ) { }
}
