import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy, AfterViewInit, PLATFORM_ID, HostListener } from '@angular/core';
import { MasonryOptions } from 'ng-masonry-grid';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Subject } from 'rxjs';
import { debounceTime, map, filter } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@app/app.config';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

export interface Image {
  url: string,
  widthSpan: number,
  heightSpan: number,
  loaded: boolean
}

@Component({
  selector: 'sucstu-inspiration',
  styleUrls: ['./inspiration.component.scss'],
  templateUrl: 'inspiration.template.html'
})
export class InspirationComponent implements OnInit, AfterViewInit, OnDestroy {
  isBrowser: boolean;
  @ViewChild('inspirationsContainer') inspirationsContainer: ElementRef;
  showmsnry = true;
  end = false;
  images: Image[] = [];
  page = 0;
  perPage = 50;

  masonryOpts: MasonryOptions = {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    gutter: 14,
    fitWidth: true
  };
  private apiUrl = this.app_config.api_uri;
  private scroll$: Subject<number> = new Subject<number>();

  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private metaService: Meta,
    private titleService: Title,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.metaService.updateTag({
      name: 'description',
      content: 'Need a little inspiration when it comes to your succulents? Check out our favorite ideas from our customers!'
    });

    this.titleService.setTitle('Succulent Ideas & Inspiration | Succulent Studios');
    this.scroll$
      .pipe(debounceTime(1000), filter(_ => isPlatformBrowser(this.platformId)))
      .subscribe(() => {
        if (!this.end) {
          this.fetchOurSucculentsUrls(this.page, this.perPage);
        }
      });
  }

  ngAfterViewInit(): void {
    this.scroll$.next(0);
  }

  // TODO: Find a better way to handle these two functions without issues with SSR
  // @HostListener('window:scroll', ['$event.path'])
  //   windowObject(path: any []) {
  //     if (typeof window === 'undefined') {
  //       this.onScroll(0);
  //     } else {
  //       this.onScroll(window.scrollY);
  //     }
  //   }

  // onScroll(windowOffset): void {
  //   const elementHeight = this.inspirationsContainer.nativeElement.offsetHeight;
  //   const elementOffset = {
  //     top: this.inspirationsContainer.nativeElement.getBoundingClientRect().top + this.document.body.scrollTop,
  //     left: this.inspirationsContainer.nativeElement.getBoundingClientRect().left + this.document.body.scrollLeft
  //   };

  //   if (elementHeight + elementOffset.top - windowOffset <= 1200 && !this.end) {
  //     this.scroll$.next(windowOffset);
  //   }
  // }

  randomSizeSpan(): number {
    return Math.random() < 0.6 ? 1 : 2;
  }

  fetchOurSucculentsUrls(page: number, limit: number): void {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('page', page.toString());
    httpParams = httpParams.append('limit', limit.toString());

    this.http.get(`${this.apiUrl}/public/images/our-succulents/`, { params: httpParams })
      .pipe(map((response: any): string[] => response.data))
      .subscribe(urls => {
        this.end = !!(urls.length === 0);
        const newImages: Image[] = urls.map((url: string) => ({
          url: url,
          widthSpan: this.randomSizeSpan(),
          heightSpan: this.randomSizeSpan(),
          loaded: false
        }));

        newImages.forEach(newImage => {
          if (this.images.findIndex(image => image.url === newImage.url) === -1) {
            this.images.push(newImage);
          }
        });

        this.page = this.page + 1;
      }, e => console.error(e));
  }

  onImageLoaded(index: number) {
    if (typeof this.images[index] !== 'undefined') {
      this.images[index].loaded = true;
    }
  }

  ngOnDestroy(): void {
    this.showmsnry = false;
    this.scroll$.unsubscribe();
  }
}
