import { HttpClient, HttpHeaders } from '@angular/common/http';
// config
import { AppConfig } from '@app/app.config';
import { MarketingService } from '@app/core/services/marketing.service';
import { AnglerAiService, CustomEventName } from '@app/core/services/angler-ai/angler-ai.service';
var LeadsService = /** @class */ (function () {
    function LeadsService(httpClient, app_config, marketingService, anglerAiService) {
        this.httpClient = httpClient;
        this.app_config = app_config;
        this.marketingService = marketingService;
        this.anglerAiService = anglerAiService;
    }
    LeadsService.prototype.create = function (_a) {
        var uuid = _a.uuid, email = _a.email, phone = _a.phone, utmData = _a.utmData, leadForm = _a.leadForm;
        var headers = new HttpHeaders({ retry: 'yes' });
        if (email) {
            this.anglerAiService.notifyLeadCompleted({
                customer: { email: email },
                customEventName: CustomEventName.LEAD_SAVED,
            })
                .catch(console.error);
        }
        return this.httpClient
            .post(this.app_config.api_uri + "/leads", { uuid: uuid, email: email, phone: phone, utmData: utmData, leadForm: leadForm }, { headers: headers });
    };
    LeadsService.prototype.leadCheckout = function (data) {
        return this.httpClient
            .post(this.app_config.api_uri + "/leads/checkout", data);
    };
    LeadsService.prototype.createNewWinItWednesdayEntry = function (data) {
        return this.httpClient
            .post(this.app_config.api_uri + "/win-it-wednesday", data);
    };
    return LeadsService;
}());
export { LeadsService };
