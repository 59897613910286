<div
  *ngIf="(data$ | async) as data"
  #notificationBar
  class="notification-bar"
  [ngClass]="{
    'notification-bar--active': data.open,
    'notification-bar--disabled': !data.open,
    'notification-bar--success': data.mode === 'success',
    'notification-bar--error': data.mode === 'error'
  }"
>
  <span class="notification-bar-title">{{ data.title }}</span>
  <p class="notification-bar-message">{{ data.message }}</p>
  <span class="close-icon" (click)="close()">X</span>
</div>
