import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
// models
import { Subscription } from '@app/core/models/subscription.model';
import { Gift } from '@app/core/models/gift.model';
import { Address } from '@app/core/models/address.model';
import { Shipment } from '@app/core/models/shipment.model';

export const LOAD_SUBSCRIPTIONS = '[Subscription] Load Subscriptions';
export const LOAD_SUBSCRIPTIONS_COMPLETE = '[Subscription] Load Subscriptions Complete';
export const LOAD_SUBSCRIPTIONS_FAIL = '[Subscription] Load Subscriptions Fail';

export const LOAD_SUBSCRIPTION = '[Subscription] Load Subscription';
export const LOAD_SUBSCRIPTION_COMPLETE = '[Subscription] Load Subscription Complete';
export const LOAD_SUBSCRIPTION_FAIL = '[Subscription] Load Subscription Fail';

export const ADD_SUBSCRIPTION = '[Subscription] Add Subscription';
export const ADD_SUBSCRIPTION_COMPLETE = '[Subscription] Add Subscription Complete';
export const ADD_SUBSCRIPTION_FAIL = '[Subscription] Add Subscription Fail';

export const UPDATE_SUBSCRIPTION = '[Subscription] Update Subscription';
export const UPDATE_SUBSCRIPTION_COMPLETE = '[Subscription] Update Subscription Complete';
export const UPDATE_SUBSCRIPTION_FAIL = '[Subscription] Update Subscription Fail';

export const UPDATE_SUBSCRIPTIONS = '[Subscription] Update Subscriptions';
export const UPDATE_SUBSCRIPTIONS_COMPLETE = '[Subscription] Update Subscriptions Complete';
export const UPDATE_SUBSCRIPTIONS_FAIL = '[Subscription] Update Subscriptions Fail';

export const UPDATE_SUBSCRIPTION_ADDRESS = '[Subscription] Update Subscription Address';
export const UPDATE_SUBSCRIPTION_ADDRESS_COMPLETE = '[Subscription] Update Subscription Address Complete';
export const UPDATE_SUBSCRIPTION_ADDRESS_FAIL = '[Subscription] Update Subscription Address Fail';

export const SELECT_SUBSCRIPTION = '[Subscription] Select Subscription';
export const DESELECT_SUBSCRIPTION = '[Subscription] Deselect Subscription';

export const SKIP_IT_SUBSCRIPTION = '[Subscription] Skip It Subscription';
export const UN_SKIP_SUBSCRIPTION = '[Subscription] Un-Skip Subscription';
export const RE_ACTIVATE_SUBSCRIPTION = '[Subscription] Re-Activate Subscription';
export const GIFT_IT_SUBSCRIPTION = '[Subscription] Gift It Subscription';
export const CANCEL_GIFT_IT = '[Subscription] Cancel Gift It';
export const CANCEL_GIFT_IT_EXTRA_BOX = '[Subscription] Cancel Gift It Extra Box';
export const CANCEL_SUBSCRIPTION = '[Subscription] Cancel Subscription';

export const UPDATE_SUBSCRIPTION_NBD = '[Subscription] Update Subscription NBD';
export const UPDATE_SUBSCRIPTION_NBD_COMPLETE = '[Subscription] Update Subscription NBD Complete';
export const UPDATE_SUBSCRIPTION_NBD_FAIL = '[Subscription] Update Subscription NBD Fail';

export const TURN_OFF_SUBSCRIPTION = '[Subscription] Turn Off Subscription';
export const TURN_OFF_SUBSCRIPTION_COMPLETE = '[Subscription] Turn Off Subscription Complete';
export const TURN_OFF_SUBSCRIPTION_FAIL = '[Subscription] Turn Off Subscription Fail';

export const TURN_ON_SUBSCRIPTION = '[Subscription] Turn On Subscription';
export const TURN_ON_SUBSCRIPTION_COMPLETE = '[Subscription] Turn On Subscription Complete';
export const TURN_ON_SUBSCRIPTION_FAIL = '[Subscription] Turn On Subscription Fail';

export const UNSELECT_BOX = '[Subscription] Unselect Box';
export const UNSELECT_BOX_COMPLETE = '[Subscription] Unselect Box Complete';
export const UNSELECT_BOX_FAIL = '[Subscription] Unselect Box Fail';

export const REACTIVATE_SUBSCRIPTION_FREE_SHIPMENT = '[Subscription] Reactivate With Free Shipment';

export class LoadSubscriptionsAction implements Action {
  readonly type = LOAD_SUBSCRIPTIONS;

  constructor() { }
}

export class LoadSubscriptionsCompleteAction implements Action {
  readonly type = LOAD_SUBSCRIPTIONS_COMPLETE;

  constructor(public payload: Subscription[]) { }
}

export class LoadSubscriptionsFailAction implements Action {
  readonly type = LOAD_SUBSCRIPTIONS_FAIL;

  constructor(public payload: any[]) { }
}

export class LoadSubscriptionAction implements Action {
  readonly type = LOAD_SUBSCRIPTION;

  constructor(public payload: { id: number }) { }
}

export class LoadSubscriptionCompleteAction implements Action {
  readonly type = LOAD_SUBSCRIPTION_COMPLETE;

  constructor(public payload: Subscription) { }
}

export class LoadSubscriptionFailAction implements Action {
  readonly type = LOAD_SUBSCRIPTION_FAIL;

  constructor(public payload: any) { }
}

export class AddSubscriptionAction implements Action {
  readonly type = ADD_SUBSCRIPTION;

  constructor() { }
}

export class AddSubscriptionCompleteAction implements Action {
  readonly type = ADD_SUBSCRIPTION_COMPLETE;

  constructor(public payload: Subscription) { }
}

export class AddSubscriptionFailAction implements Action {
  readonly type = ADD_SUBSCRIPTION_FAIL;

  constructor(public payload: any[]) { }
}

export class UpdateSubscriptionAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION;

  constructor(public payload: { subscriptionId: number, recipient?: any, paymentMethod?: any }) { }
}

export class UpdateSubscriptionCompleteAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_COMPLETE;

  constructor(public payload: Update<Subscription>) { }
}

export class UpdateSubscriptionFailAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_FAIL;

  constructor(public payload: any[]) { }
}

export class UpdateSubscriptionAddressAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_ADDRESS;

  constructor(public payload: { subscriptionId: number, address: Address }) { }
}

export class UpdateSubscriptionAddressCompleteAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_ADDRESS_COMPLETE;

  constructor(public payload: Update<Subscription>) { }
}

export class UpdateSubscriptionAddressFailAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_ADDRESS_FAIL;

  constructor(public payload: any[]) { }
}

export class UpdateSubscriptionsAction implements Action {
  readonly type = UPDATE_SUBSCRIPTIONS;

  constructor(public payload: any) { }
}

export class UpdateSubscriptionsCompleteAction implements Action {
  readonly type = UPDATE_SUBSCRIPTIONS_COMPLETE;

  constructor(public payload: Update<Subscription>[]) { }
}

export class UpdateSubscriptionsFailAction implements Action {
  readonly type = UPDATE_SUBSCRIPTIONS_FAIL;

  constructor(public payload: any[]) { }
}

export class SelectSubscriptionAction implements Action {
  readonly type = SELECT_SUBSCRIPTION;

  constructor(public payload: number) { }
}

export class DeselectSubscriptionAction implements Action {
  readonly type = DESELECT_SUBSCRIPTION;

  constructor() { }
}

export class SkipItSubscriptionAction implements Action {
  readonly type = SKIP_IT_SUBSCRIPTION;

  constructor(public payload: { subscriptionId: number, skipValue: string }) { }
}

export class UnSkipItSubscriptionAction implements Action {
  readonly type = UN_SKIP_SUBSCRIPTION;

  constructor(public payload: { subscriptionId: number }) { }
}

export class ReActivateSubscriptionAction implements Action {
  readonly type = RE_ACTIVATE_SUBSCRIPTION;

  constructor(public payload: { nextBillingDate: string, subscriptionId: number }) { }
}

export class GiftItSubscriptionAction implements Action {
  readonly type = GIFT_IT_SUBSCRIPTION;

  constructor(public payload: { subscriptionId: number, gift: Gift }) { }
}

export class CancelGiftItSubscriptionAction implements Action {
  readonly type = CANCEL_GIFT_IT;

  constructor(public payload: { giftId: string, subscriptionId: number }) { }
}

export class CancelGiftItExtraBoxSubscriptionAction implements Action {
  readonly type = CANCEL_GIFT_IT_EXTRA_BOX;

  constructor(public payload: { giftId: string, subscriptionId: number }) { }
}

export class CancelSubscriptionAction implements Action {
  readonly type = CANCEL_SUBSCRIPTION;

  constructor(public payload: { subscriptionId: number, reasons: any }) { }
}

export class UpdateSubscriptionNBDAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_NBD;

  constructor(public payload: { subscriptionId: number, newBillingDate: any }) { }
}

export class UpdateSubscriptionNBDCompleteAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_NBD_COMPLETE;

  constructor(public payload: Update<Subscription>) { }
}

export class UpdateSubscriptionNBDFailAction implements Action {
  readonly type = UPDATE_SUBSCRIPTION_NBD_FAIL;

  constructor(public payload: any[]) { }
}

export class TurnOffSubscriptionAction implements Action {
  readonly type = TURN_OFF_SUBSCRIPTION;

  constructor(public payload: {
                subscriptionId: number,
                turnedOffReasons?: any[],
                refundShipment?: boolean
              }
  ) { }
}

export class TurnOffSubscriptionCompleteAction implements Action {
  readonly type = TURN_OFF_SUBSCRIPTION_COMPLETE;

  constructor(public payload: { subscription: Subscription, shipment: Shipment }) { }
}

export class TurnOffSubscriptionFailAction implements Action {
  readonly type = TURN_OFF_SUBSCRIPTION_FAIL;

  constructor(public payload: any) { }
}

export class TurnOnSubscriptionAction implements Action {
  readonly type = TURN_ON_SUBSCRIPTION;

  constructor(public payload: {
    nextBillingDate: string,
    subscriptionId: number,
  }) { }
}

export class TurnOnSubscriptionCompleteAction implements Action {
  readonly type = TURN_ON_SUBSCRIPTION_COMPLETE;

  constructor(public payload: { subscription: Subscription }) { }
}

export class TurnOnSubscriptionFailAction implements Action {
  readonly type = TURN_ON_SUBSCRIPTION_FAIL;

  constructor(public payload: any) { }
}

export class ReactivateSubscriptionWithFreeShipment implements Action {
  readonly type = REACTIVATE_SUBSCRIPTION_FREE_SHIPMENT;

  constructor(public payload: { subscriptionId: number }) { }
}

export class UnselectBoxAction implements Action {
  readonly type = UNSELECT_BOX;

  constructor(public payload: { subscriptionId: string, boxId: string }) { }
}

export class UnselectBoxCompleteAction implements Action {
  readonly type = UNSELECT_BOX_COMPLETE;

  constructor(public payload: { subscription: Subscription }) { }
}

export class UnselectBoxFailAction implements Action {
  readonly type = UNSELECT_BOX_FAIL;

  constructor(public payload: any) { }
}

export type Actions =
  | LoadSubscriptionsAction
  | LoadSubscriptionsCompleteAction
  | LoadSubscriptionsFailAction
  | LoadSubscriptionAction
  | LoadSubscriptionCompleteAction
  | LoadSubscriptionFailAction
  | AddSubscriptionAction
  | AddSubscriptionCompleteAction
  | AddSubscriptionFailAction
  | UpdateSubscriptionAction
  | UpdateSubscriptionCompleteAction
  | UpdateSubscriptionFailAction
  | UpdateSubscriptionAddressAction
  | UpdateSubscriptionAddressCompleteAction
  | UpdateSubscriptionAddressFailAction
  | UpdateSubscriptionsAction
  | UpdateSubscriptionsCompleteAction
  | UpdateSubscriptionsFailAction
  | SelectSubscriptionAction
  | DeselectSubscriptionAction
  | SkipItSubscriptionAction
  | UnSkipItSubscriptionAction
  | ReActivateSubscriptionAction
  | GiftItSubscriptionAction
  | CancelSubscriptionAction
  | CancelGiftItSubscriptionAction
  | CancelGiftItExtraBoxSubscriptionAction
  | UpdateSubscriptionNBDAction
  | UpdateSubscriptionNBDCompleteAction
  | UpdateSubscriptionNBDFailAction
  | TurnOffSubscriptionAction
  | TurnOffSubscriptionCompleteAction
  | TurnOffSubscriptionFailAction
  | TurnOffSubscriptionFailAction
  | TurnOnSubscriptionAction
  | TurnOnSubscriptionCompleteAction
  | TurnOnSubscriptionFailAction
  | ReactivateSubscriptionWithFreeShipment
  | UnselectBoxAction
  | UnselectBoxCompleteAction
  | UnselectBoxFailAction;
