import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {pluck} from 'rxjs/operators';
import {Shipment} from '@app/core/models/shipment.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentTrackingService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  getShipmentTrackingEvents(trackingCode: string) {
    return this.http.get<{ events: Shipment['trackingEvents'], tracker: Shipment['tracker'] }>(
      `${environment.api_uri_v2}/shipments/tracking-events?trackingCode=${trackingCode}`
    );
  }


}
