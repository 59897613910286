import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Plan } from '@app/core/models/plan.model';

import { APP_CONFIG, AppConfig } from '@app/app.config';
import { paramsMap } from '@app/core/mappers/params-map.mapper';
import * as plansActions from '@app/store/actions/plans.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/store/selectors';

@Injectable()
export class PlansService {

  REGULAR_PLAN = '5997c3ac8852a761249342e9';
  GIFT_PLAN = '5a10dbfaf8ceb4830ff186a9';

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<fromRoot.State>,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) {
  }

  init() {
    this.router.routerState.root.queryParams
      .pipe(
        map(paramsMap),
        map(queryParams => queryParams.planId),
        filter(Boolean)
      )
      .subscribe(planId => {
        this.store.dispatch(new plansActions.SelectPlanAction(planId));
      });
  }

  getAvailablePlans(): Observable<Plan[]> {
    const headers = new HttpHeaders({retry: 'yes'});
    return this.http.get(`${ this.app_config.api_uri }/products/plans`, {headers})
      .pipe(map((result: any) => result.plans as Plan[]));
  }

  getPlan(id: string): Observable<{ plan: Plan, rate: any }> {
    const headers = new HttpHeaders({retry: 'yes'});
    return this.http.get<any>(`${ this.app_config.api_uri }/products/plans/${ id }`, {headers});
  }
}
