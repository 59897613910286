import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'sucstu-bogo-component',
  templateUrl: 'bogo.component.html',
  styleUrls: ['./bogo.component.scss']
})
export class BogoComponent implements OnInit {

  PLAN_3M_GIFT = '5a10dbfaf8ceb4830ff186a9';
  PLAN_6M_GIFT = '5abd46654d4f52a1ea64fa91';
  PLAN_12M_GIFT = '5b33d996641451a36467b89d';

  constructor(
    private metaService: Meta,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.setupMetaTags();
  }

  setupMetaTags() {
    this.metaService.updateTag({
      name: 'description',
      content: 'Our organically-grown succulents make a unique, memorable gift that lasts all year round. Set the ship date, write a note, and we\'ll take care of the rest!'
    });
    this.titleService.setTitle('Succulent Gift Box | Succulent Studios');
  }
}
