import * as notificationBar from '@app/store/actions/notification-bar.actions';

export interface State {
  open: boolean,
  title: string,
  message: string,
  type: string,
  mode: string,
};

export const initialState: State = {
  open: false,
  title: null,
  message: null,
  type: null,
  mode: 'notification',
};

export function reducer (
  state: State = initialState,
  action: notificationBar.Actions
): State {
  switch (action.type) {

    case notificationBar.OPEN_NOTIFICATION_BAR: {
      return { ...state, ...action.payload, open: true };
    }

    case notificationBar.CLOSE_NOTIFICATION_BAR: {
      return { ...state, open: false };
    }

    default: {
      return state
    }
  }
}

export const getNotificationBar = (state: State) => state;
