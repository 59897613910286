<div class="wrapper">
  <div class="numberOfSales">
    {{numberOfSales}}
  </div>
  <div class="orders">
    <div class=text>
    <p *ngIf="orderShown">{{orderShown.createdAt | date:'HH:mm:ss'}}</p>
    <p *ngIf="orderShown && orderShown.user" class="customerName">{{orderShown.user.firstName}} {{orderShown.user.lastName}}</p>
    <p *ngIf="orderShown && orderShown.address">{{orderShown.address.city}}, {{orderShown.address.stateCode}}</p>
    <p class="plan" *ngIf="plan!=='' && plan==='Monthly subscription'">{{plan}}</p> 
    <p class="plan" *ngIf="plan!=='' && plan!=='Monthly subscription'">{{plan}} to {{orderShown.recipient.firstName}} {{orderShown.recipient.lastName}}</p>
    <p class="giftMessage" *ngIf="orderShown && orderShown.giftMessage">{{orderShown.giftMessage}}</p>
    </div>
    <div class="container-plants" *ngIf="sales.length>0"> 
      <div class="items" *ngFor="let order of sales; let j=index;">
        <img [src]="i == j? '../../assets/images/logos/logo-transparent.png' : '../../assets/images/logos/logo-grey.png'"/>
      </div>
    </div>
  </div>  
</div>

