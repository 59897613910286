import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

// selectors
import * as fromRoot from '@app/store/selectors';

// actions
import * as fromNotificationBar from '@app/store/actions/notification-bar.actions';

import { State as NotificationBarState } from '@app/store/reducers/notification-bar.reducer';
import { take } from 'rxjs/operators';

@Injectable()
export class NotificationBarService {

  private _dimensions$ = new BehaviorSubject<{ width: number, height: number }>({ width: 0, height: 0 });
  dimensions$ = this._dimensions$.asObservable();

  private _close$ = new Subject<NotificationBarState>();
  close$ = this._close$.asObservable();
  data$: Observable<NotificationBarState>;

  constructor(
    private store: Store<fromRoot.State>,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.data$ = store.pipe(select(fromRoot.getNotificationBarState));
  }

  open({ title, message, type = 'notification', mode = 'success' }) {
    if (isPlatformBrowser(this.platformId)) {
      this.store.dispatch(new fromNotificationBar.OpenNotificationBarAction({ title, message, type, mode }));
    }
  }

  close() {
    this.data$
      .pipe(take(1))
      .subscribe(data => this._close$.next(data));
    this.store.dispatch(new fromNotificationBar.CloseNotificationBarAction());
  }

  getDimensions() {
    return this._dimensions$.value;
  }

  setDimensions({ width, height }: { width: number, height: number }) {
    this._dimensions$.next({ width, height });
  }
}
