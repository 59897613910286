import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskCard'
})
export class CreditCardMask implements PipeTransform {
  transform(value, extension: boolean = false) {
    if (!extension) {
      return value.replace(/\d(?=\d{4})/g, "*");
    } else {
      return value.replace(/\d(?=\d{4})/g, "");
    }
  }
}
