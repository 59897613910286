import * as tslib_1 from "tslib";
import { PlatformRef } from '@angular/core';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import * as i0 from "@angular/core";
export var AnglerAiEvent;
(function (AnglerAiEvent) {
    AnglerAiEvent["page_viewed"] = "page_viewed";
    AnglerAiEvent["checkout_started"] = "checkout_started";
    AnglerAiEvent["checkout_completed"] = "checkout_completed";
    AnglerAiEvent["custom_event"] = "custom_event";
})(AnglerAiEvent || (AnglerAiEvent = {}));
export var CustomEventName;
(function (CustomEventName) {
    CustomEventName["LEAD_SAVED"] = "lead_saved";
})(CustomEventName || (CustomEventName = {}));
var AnglerAiService = /** @class */ (function () {
    function AnglerAiService(platformRef) {
        var _this = this;
        this.platformRef = platformRef;
        this.scriptLoaded$ = new ReplaySubject();
        if (isPlatformBrowser(platformRef)) {
            this.loadScript()
                .then(function () { return _this.startAnglerAi(); });
        }
    }
    AnglerAiService.prototype.startAnglerAi = function () {
        anglerai.init({ workspaceId: environment.anglerAiWorkspaceId, token: environment.anglerAiToken });
    };
    AnglerAiService.prototype.notifyPageView = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.scriptLoaded$.pipe(take(1)).toPromise()];
                    case 1:
                        _a.sent();
                        anglerai.sendEvent({ eventName: AnglerAiEvent.page_viewed, data: data });
                        return [2 /*return*/];
                }
            });
        });
    };
    AnglerAiService.prototype.notifyCheckoutStarted = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.scriptLoaded$.pipe(take(1)).toPromise()];
                    case 1:
                        _a.sent();
                        anglerai.sendEvent({ eventName: AnglerAiEvent.checkout_started, data: data });
                        return [2 /*return*/];
                }
            });
        });
    };
    AnglerAiService.prototype.notifyCheckoutCompleted = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.scriptLoaded$.pipe(take(1)).toPromise()];
                    case 1:
                        _a.sent();
                        anglerai.sendEvent({ eventName: AnglerAiEvent.checkout_completed, data: data });
                        return [2 /*return*/];
                }
            });
        });
    };
    AnglerAiService.prototype.notifyLeadCompleted = function (_a) {
        var customEventName = _a.customEventName, data = tslib_1.__rest(_a, ["customEventName"]);
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.scriptLoaded$.pipe(take(1)).toPromise()];
                    case 1:
                        _b.sent();
                        anglerai.sendEvent({ eventName: AnglerAiEvent.custom_event, customEventName: customEventName, data: data });
                        return [2 /*return*/];
                }
            });
        });
    };
    AnglerAiService.prototype.loadScript = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var script;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                script = document.createElement('script');
                script.src = 'https://static.getangler.ai/dist/analytics/_latest.min.js';
                script.type = 'text/javascript';
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        script.onload = function () {
                            _this.scriptLoaded$.next();
                            resolve();
                        };
                        script.onerror = function (error) {
                            reject(error);
                        };
                        document.getElementsByTagName('body')[0].appendChild(script);
                    })];
            });
        });
    };
    AnglerAiService.ngInjectableDef = i0.defineInjectable({ factory: function AnglerAiService_Factory() { return new AnglerAiService(i0.inject(i0.PLATFORM_ID)); }, token: AnglerAiService, providedIn: "root" });
    return AnglerAiService;
}());
export { AnglerAiService };
