import { Injectable } from '@angular/core';
import * as find from 'lodash/find';
import * as without from 'lodash/without';
// modal component
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ModalService {

  private modals: ModalComponent[] = [];
  private activeModal: ModalComponent;

  getModal(id: string): ModalComponent {
    const modal = find(this.modals, { id: id });
    return modal;
  }

  add(modal: any) {
    this.modals.push(modal);
  }

  remove(id: string) {
    const modalToRemove = find(this.modals, { id: id });
    this.modals = without(this.modals, modalToRemove);
  }

  async open(id: string): Promise<any> {
    const modal = find(this.modals, { id: id }) as ModalComponent;
    if (typeof modal === 'undefined') {
      return Promise.resolve();
    }

    this.modals.forEach((item: ModalComponent) => {
      if (item.isOpen) {
        item.close();
      }
    });
    await modal.open();
    return Promise.resolve();
  }

  close(id: string) {
    const modal = find(this.modals, { id: id });
    modal.close();
  }
}
