import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';

// RxJS
import { Observable, of, Observer } from 'rxjs';
import { tap, filter, take, switchMap, catchError } from 'rxjs/operators';

// actions
import * as boxActions from '@app/store/actions/box.action';

// selectos
import * as fromBoxes from '@app/store/selectors/boxes.selector';

// models
import { Box } from './../../models/box.model';

@Injectable()
export class BoxGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>
  ) { }

  checkStoreForBox(id: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.store.select(fromBoxes.getBoxesErrors)
        .pipe(
          filter((error: any) => !!error),
          take(1),
        )
        .subscribe((error) => {
          observer.error(error);
        });

      this.store
        .select(fromBoxes.getBoxById(id))
        .pipe(
          tap(box => {
            if (!box) {
              this.store.dispatch(new boxActions.LoadBoxAction({ id }));
            }
          }),
          filter((box: Box) => !!box),
          take(1)
        )
        .subscribe(() => {
          observer.next(true);
        });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const id = route.queryParams.boxId;

    if (!id) {
      this.router.navigate(['/']);
      return false;
    }

    return this.checkStoreForBox(id)
      .pipe(
        switchMap(() => of(true)),
        catchError((error) => {
          this.router.navigate(['/']);
          return of(false);
        })
      );
  }
}
