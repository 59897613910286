import { Directive, HostBinding, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, Subscription, timer } from 'rxjs';

@Directive({
  selector: '[appNonAutocomplete]'
})
export class NonAutocompleteDirective implements OnDestroy, OnInit {
  @HostBinding('name')
  @HostBinding('autocomplete')
  inputName;

  timer$: Observable<number>;
  timerSubscription$: Subscription;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.inputName = new Date().toISOString();
      this.timer$ = timer(0, 1000);
      this.timerSubscription$ = this.timer$.subscribe(this.onfocus.bind(this));
    }
  }

  @HostListener('focus')
  onfocus() {
    this.inputName = new Date().toISOString();
  }

  ngOnDestroy(): void {
    if (this.timerSubscription$) {
      this.timerSubscription$.unsubscribe();
    }
  }
}
