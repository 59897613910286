import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ClaimGiftGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (!!route.queryParams.subscriptionId) {
      return true
    } else {
      return this.router.navigate(['/'])
        .then(() => false);
    }
  }
}
