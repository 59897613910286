import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable, ReplaySubject } from 'rxjs';
// actions
import * as user from '@app/store/actions/user.actions';
// selectors
import * as fromUser from '@app/store/selectors/user.selector';
import { takeUntil } from 'rxjs/operators';
import { NotificationBarService } from '@app/core/services';

// models

@Component({
  selector: 'sucstu-header',
  styleUrls: ['./header.component.scss'],
  template: `
    <header class="header">
      <!-- Mobile Header -->
      <div class="header-container" #headerContainer>
        <div
          #navRow
          class="nav-row"
          *ngIf="!isSales"
        >
          <a class="logo-container" routerLink="/">
            <img class="logo-container-img" src="/assets/images/icon-transparent@2x.png" alt="Succulent Studio logo">
<!--            <img *ngIf="showPrideLogo" class="logo-container-img" src="/assets/images/logos/pride-logo.png">-->
          </a>
          <a class="logo-container" routerLink="/profile">
            <!-- <img class="logo-container-img" src="/assets/images/give-succs.gif"> -->
          </a>
          <h1 class="header-title header-title--desk">
            <a routerLink="/"><span class="header-title-bolder">Succulent</span> Studios</a>
          </h1>
          <div class="nav-container" [ngClass]="{ 'isInspiration': isInspiration }" *ngIf="isInspiration">
            <h5 class="guarantee-text">100% satisfaction guarantee</h5>
            <div class="nav-action">
              <a routerLink="/checkout">Subscribe</a>
            </div>
          </div>
          <div class="nav-container" *ngIf="!isInspiration">
            <h5 class="guarantee-text" *ngIf="!(isAuthenticated$ | async)">100% satisfaction guarantee</h5>
            <div
              class="nav-action"
              [ngClass]="{ 'logged-in': isAuth }" *ngIf="(isAuthenticated$ | async) as isAuth; else loggedOut">
              <a #loginButton routerLink="/profile">Account</a>
              <a [attr.test-id]="'header__logout-button'" routerLink="/" (click)="logout($event)">Log Out</a>
              <a *ngIf="userRole === 'admin'" routerLink="/control">Control center</a>
            </div>
            <ng-template #loggedOut>
              <div class="nav-action">
                <a [attr.test-id]="'header__login-button'" #loginButton routerLink="/login">Log In</a>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="title-row" *ngIf="!isSales">
          <h1 class="header-title header-title--mobile">
            <a routerLink="/"><span class="header-title-bolder">Succulent</span> Studios</a>
          </h1>
        </div>
      </div>

    </header>
  `
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('headerContainer') headerContainer: ElementRef;
  @ViewChild('navRow') navRow: ElementRef;
  isAuthenticated$: Observable<boolean>;
  userRole: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private store: Store<any>,
    private router: Router,
    private renderer: Renderer2,
    private locationService: Location,
    private notificationBarService: NotificationBarService,
  ) {
    this.isAuthenticated$ = this.store.pipe(select(fromUser.getUserIsAuthenticated));
  }

  get isInspiration() {
    return this.locationService.path() === '/inspiration';
  }

  get isSales() {
    return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
  }

  get showPrideLogo() {
    // Always show the pride logo
    return true;
    // return this.locationService.path() === '' ||
    //   this.locationService.path() === '/gift' ||
    //   this.locationService.path() === '/gift/pride' ||
    //   this.locationService.path() === '/lp/farm2';
  }

  ngOnInit() {
    this.store.pipe(select(fromUser.getUserData))
      .subscribe(userState => this.userRole = userState.role);
  }

  ngAfterViewInit() {
    combineLatest([
      this.notificationBarService.data$,
      this.notificationBarService.dimensions$,
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([data, dimensions]) => {
        if (!this.isSales) {
          if (dimensions.height > 0 && data.open) {
            const navRowElement = this.navRow.nativeElement as HTMLElement;
            const headerContainerPaddingTop = navRowElement.clientHeight + dimensions.height;
            this.renderer.setStyle(this.navRow.nativeElement, 'top', `${ dimensions.height }px`);
            this.renderer.setStyle(this.headerContainer.nativeElement, 'padding-top', `${ headerContainerPaddingTop }px`);
          } else {
            this.renderer.setStyle(this.navRow.nativeElement, 'top', ``);
            this.renderer.setStyle(this.headerContainer.nativeElement, 'padding-top', ``);
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  openNav() {
    // this.isNavOpened = !this.isNavOpened;
  }

  logout(e: any) {
    this.router
      .navigate(['/'])
      .then(n => this.store.dispatch(new user.LogoutAction()));
  }

}
