
    <div class="app-loading" *ngIf="(appLoading$ | async) || !isBrowser">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    <div
      class="content">
      <!-- [@appState]="state" -->
      <sucstu-notification-bar></sucstu-notification-bar>
      <div class="main">
        <sucstu-header>
        </sucstu-header>

        <div class="app-container">
          <router-outlet (deactivate)="onDeactivate()"></router-outlet>
        </div>

      </div>
      <sucstu-footer class="footer-container"></sucstu-footer>
    </div>
    <app-console-viewer></app-console-viewer>
    <app-gmail-modal></app-gmail-modal>
  