import { createSelector } from '@ngrx/store';

import * as fromRoot from '@app/store/selectors';
import * as fromAddress from '@app/store/reducers/address.reducer';
import * as fromSubscription from '@app/store/selectors/subscriptions.selector';
import { Address } from '@app/core/models/address.model';
import { Subscription } from '@app/core/models/subscription.model';

export { State, Entities } from '@app/store/reducers/address.reducer'

export const {
  selectIds: getAddressesIds,
  selectEntities: getAddressEntities,
  selectTotal: getTotalAddresses,
  selectAll: getAllAddresses,
} = fromAddress.adapter.getSelectors(fromRoot.getAddressState);

export const getAddressById = (id: string) => createSelector(
  fromRoot.getAddressState,
  (state: fromAddress.State) => state.entities[id]
);

export const getSubscriptionAddress = id => createSelector(
  fromSubscription.getSubscriptionEntities,
  getAddressEntities,
  (subscriptions: { [id: string]: Subscription }, addresses: { [id: string]: Address }) => {
    return addresses[subscriptions[id].address];
  }
);

export const getAddressesLoaded = createSelector(
  fromRoot.getAddressState,
  (state: fromAddress.State) => state.loaded
);

export const getAddressesLoading = createSelector(
  fromRoot.getAddressState,
  (state: fromAddress.State) => state.loading
);

export const getAddressesError = createSelector(
  fromRoot.getAddressState,
  (state: fromAddress.State) => state.error,
);
