/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./notification-bar.component";
import * as i4 from "../../../core/services/notification-bar.service";
var styles_NotificationBarComponent = [i0.styles];
var RenderType_NotificationBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationBarComponent, data: {} });
export { RenderType_NotificationBarComponent as RenderType_NotificationBarComponent };
function View_NotificationBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["notificationBar", 1]], null, 8, "div", [["class", "notification-bar"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "notification-bar--active": 0, "notification-bar--disabled": 1, "notification-bar--success": 2, "notification-bar--error": 3 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "notification-bar-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "notification-bar-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["X"]))], function (_ck, _v) { var currVal_0 = "notification-bar"; var currVal_1 = _ck(_v, 2, 0, _v.context.ngIf.open, !_v.context.ngIf.open, (_v.context.ngIf.mode === "success"), (_v.context.ngIf.mode === "error")); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.ngIf.title; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.ngIf.message; _ck(_v, 6, 0, currVal_3); }); }
export function View_NotificationBarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { notificationBar: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotificationBarComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.data$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotificationBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-notification-bar", [], null, null, null, View_NotificationBarComponent_0, RenderType_NotificationBarComponent)), i1.ɵdid(1, 12828672, null, 0, i3.NotificationBarComponent, [i1.Renderer2, i4.NotificationBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationBarComponentNgFactory = i1.ɵccf("sucstu-notification-bar", i3.NotificationBarComponent, View_NotificationBarComponent_Host_0, {}, {}, []);
export { NotificationBarComponentNgFactory as NotificationBarComponentNgFactory };
