import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sucstu-page-privacy-policy',
  styleUrls: ['./privacy-policy.component.scss'],
  templateUrl: 'privacy-policy.template.html'
})

export class PrivacyPolicyComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
