import {Component, OnDestroy} from '@angular/core';
import {ShipmentTrackingService} from '@app/pages/services/shipment-tracking.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Shipment} from '@app/core/models/shipment.model';
import {Observable, Subject, Subscription} from 'rxjs';
import {map, pluck, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss']
})
export class ShipmentTrackingComponent implements OnDestroy {
  componentDestroyed$: Subject<void> = new Subject<void>();
  trackingCode$: Observable<string> = this.route.queryParams
    .pipe(pluck('trackingCode'));

  trackingEvents$: Subject<Shipment['trackingEvents']> = new Subject<Shipment['trackingEvents']>();
  trackerObject$: Subject<Shipment['tracker']> = new Subject<Shipment['tracker']>();

  constructor(
    private readonly shipmentTrackingService: ShipmentTrackingService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  getTrackingEventsSubscription: Subscription = this.trackingCode$
    .pipe(map(trackingCode => {
      if (!trackingCode) {
        throw new Error('Tracking code is not defined');
      }

      return trackingCode;
    }))
    .pipe(switchMap(trackingCode =>
      this.shipmentTrackingService.getShipmentTrackingEvents(trackingCode)
    ))
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(({ events, tracker }) => {
      this.trackingEvents$.next(events);
      this.trackerObject$.next(tracker);
    }, error => {
      console.error(error);
      this.router.navigate(['/']);
    });


  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

}
