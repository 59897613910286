import { Action } from '@ngrx/store';

// models
import { Shipment } from '@app/core/models/shipment.model';

export const LOAD_SHIPMENTS = '[Shipment] Load Shipments';
export const LOAD_SHIPMENTS_COMPLETE = '[Shipment] Load Shipments Complete';
export const LOAD_SHIPMENTS_FAIL = '[Shipment] Load Shipments Fail';

export const LOAD_SHIPMENT = '[Shipment] Load Shipment';
export const LOAD_SHIPMENT_COMPLETE  = '[Shipment] Load Shipment Complete';
export const LOAD_SHIPMENT_FAIL  = '[Shipment] Load Shipment Fail';

export const UPDATE_SHIPMENT = '[Shipment] Update Shipment';
export const UPDATE_SHIPMENT_COMPLETE = '[Shipment] Update Shipment Complete';
export const UPDATE_SHIPMENT_FAIL = '[Shipment] Update Shipment Fail';

export class LoadShipmentsAction implements Action {
  readonly type = LOAD_SHIPMENTS;
  constructor() { }
};

export class LoadShipmentsCompleteAction implements Action {
  readonly type = LOAD_SHIPMENTS_COMPLETE;
  constructor(public payload: Shipment[]) { }
};

export class LoadShipmentsFailAction implements Action {
  readonly type = LOAD_SHIPMENTS_FAIL;
  constructor(public payload: any[]) { }
};

export class LoadShipmentAction implements Action {
  readonly type = LOAD_SHIPMENT;
  constructor(public payload: string) { }
};

export class LoadShipmentCompleteAction implements Action {
  readonly type = LOAD_SHIPMENT_COMPLETE;
  constructor(public payload: Shipment) { }
};

export class LoadShipmentFailAction implements Action {
  readonly type = LOAD_SHIPMENT_FAIL;
  constructor(public payload: any[]) { }
};

export class UpdateShipmentAction implements Action {
  readonly type = UPDATE_SHIPMENT;
  constructor() { }
};

export class UpdateShipmentCompleteAction implements Action {
  readonly type = UPDATE_SHIPMENT_COMPLETE;
  constructor(public payload: Shipment) { }
};

export class UpdateShipmentFailAction implements Action {
  readonly type = UPDATE_SHIPMENT_FAIL;
  constructor(public payload: any[]) { }
};

export type Actions =
  | LoadShipmentsAction
  | LoadShipmentsCompleteAction
  | LoadShipmentsFailAction
  | LoadShipmentAction
  | LoadShipmentCompleteAction
  | LoadShipmentFailAction
  | UpdateShipmentAction
  | UpdateShipmentCompleteAction
  | UpdateShipmentFailAction;
