import { HttpClient } from '@angular/common/http';
// rxjs
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
// config
import { AppConfig } from '@app/app.config';
// services
import { AuthService } from '@app/auth/services/auth.service';
var AddressService = /** @class */ (function () {
    function AddressService(httpClient, authService, app_config, platformId) {
        this.httpClient = httpClient;
        this.authService = authService;
        this.app_config = app_config;
        this.platformId = platformId;
    }
    AddressService.prototype.getAddresses = function () {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .get(this.app_config.api_uri + "/users/" + userId + "/address")
            .pipe(map(function (result) { return result.addresses; }));
    };
    AddressService.prototype.getAddress = function (addressId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .get(this.app_config.api_uri + "/users/" + userId + "/address/" + addressId)
            .pipe(map(function (result) { return result.address; }));
    };
    AddressService.prototype.updateAddress = function (_a) {
        var _id = _a._id, newData = _a.newData;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/address/" + _id, newData)
            .pipe(map(function (result) { return result.address; }));
    };
    AddressService.prototype.verifyAddress = function (_a) {
        var address = _a.address;
        // We are disabling the validation because some users hasn't been able to checkout
        return of(address);
        // return this.httpClient
        //   .post(`${this.app_config.api_uri}/public/address/validation`, { address })
        //   .pipe(catchError(e => {
        //     if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
        //       (window as any).dataLayer.push({
        //         'event': 'manuallyAddressFail',
        //         'manuallyAddressFail': true,
        //       });
        //     }
        //     return throwError(e);
        //   }))
        //   .pipe(map((result: any) => result.address as Address));
    };
    return AddressService;
}());
export { AddressService };
