import { createSelector } from '@ngrx/store';

import * as fromRoot from '@app/store/selectors';
import * as fromCoupon from '@app/store/reducers/coupon.reducer';

export { State } from '@app/store/reducers/coupon.reducer';

export const getCouponData = createSelector(
  fromRoot.getCouponState,
  (state: fromCoupon.State) => state.data
);

export const getCouponErrors = createSelector(
  fromRoot.getCouponState,
  (state: fromCoupon.State) => state.errors
);

export const getCouponSearched = createSelector(
  fromRoot.getCouponState,
  (state: fromCoupon.State) => state.searched
);

export const getCouponSearching = createSelector(
  fromRoot.getCouponState,
  (state: fromCoupon.State) => state.searching
);

export const getCouponValid = createSelector(
  fromRoot.getCouponState,
  (state: fromCoupon.State) => state.valid
);

export const getCouponInvalid = createSelector(
  fromRoot.getCouponState,
  (state: fromCoupon.State) => state.invalid
);
