<div class="faq-question-container" [ngClass]="{ 'show-cursor': !open }">
  <div class="header" (click)="onToggle()">
    <div class="green-dot"></div>
    <ng-content select=".question__title"></ng-content>
    <img src="/assets/images/triangle.png" [ngClass]="{ 'flip-arrow': open }" class="arrow" />
  </div>
  <div class="body" [style.display]="open ? 'block' : 'none'">
    <ng-content select=".question__answer"></ng-content>
  </div>
</div>
