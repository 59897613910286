<div class="page-container container">

  <div class="row">
    <div class="inspiration-page" #inspirationsContainer>
      <div class="inspiration-title">
        <h1 class="text-medium">Ideas + Inspiration</h1>
        <div class="text-content">
          <p>Huddle 'em together as a centerpiece, line 'em up along your windowsills, or cover your entire desk with
            'em.
            Your
            succulent subscription box is an excuse to exercise your green thumb AND your interior design skills.</p>
          <p>
            <span class="text-bolder">Need a little inspiration?</span> Check out some ideas from our succulent
            subscribers!</p>
          <p>Ready to join our succulent-loving club?
            <a routerLink="/checkout">
              <span class="text-bolder">Subscribe today!</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="showmsnry && isBrowser">
    <ng-masonry-grid infiniteScroll [infiniteScrollDistance]="100" [infiniteScrollThrottle]="500"
                     [masonryOptions]="masonryOpts"
                     [useImagesLoaded]="true" class="inspirations-pictures-container">
      <ng-masonry-grid-item class="grid-sizer"></ng-masonry-grid-item>
      <ng-masonry-grid-item class="grid-item" *ngFor="let image of images; let i = index"
                            [ngClass]="{ 'grid-item--width-2': image.widthSpan === 2 }">
        <img [src]="image?.url" (load)="onImageLoaded(i)">
      </ng-masonry-grid-item>
    </ng-masonry-grid>
  </div>
</div>
