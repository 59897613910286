import { enableProdMode } from '@angular/core';
import 'hammerjs';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
// Add GTM script to <head>
var gtmHeadScript = document.createElement('script');
var googleOptimizeAntiFlickerSnippet = document.createElement('script');
var gtmBodyScript = document.createElement('noscript');
var gtmBodyIframe = document.createElement('iframe');
gtmBodyIframe.src = "https://www.googletagmanager.com/ns.html?id=" + environment.gtmContainerId;
gtmBodyIframe.height = '0';
gtmBodyIframe.width = '0';
gtmBodyIframe.style.display = 'none';
gtmBodyIframe.style.visibility = 'hidden';
gtmBodyScript.appendChild(gtmBodyIframe);
gtmHeadScript.onerror = function (e) {
    console.error(e);
};
gtmHeadScript.innerHTML = "(function (w, d, s, l, i) {\n  try {\n    w[l] = w[l] || []; w[l].push({\n      'gtm.start':\n        new Date().getTime(), event: 'gtm.js'\n    }); var f = d.getElementsByTagName(s)[0],\n      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.onerror = function(e) { console.error(e) }; j.src =\n        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);\n  } catch(e) {\n    console.error(e)\n  }\n})(window, document, 'script', 'dataLayer', '" + environment.gtmContainerId + "');";
googleOptimizeAntiFlickerSnippet.innerHTML = "\n(function (a, s, y, n, c, h, i, d, e) {\n  try {\n    s.className += ' ' + y;\n    h.start = 1 * new Date;\n    h.end = i = function () {\n      s.className = s.className.replace(RegExp(' ?' + y), '')\n    };\n    (a[n] = a[n] || []).hide = h;\n    setTimeout(function () {\n      i();\n      h.end = null\n    }, c);\n    h.timeout = c;\n  } catch(e) {\n    console.error(e)\n  }\n})(window, document.documentElement, 'async-hide', 'dataLayer', 4000, { '" + environment.gtmContainerId + "': true });\n";
document.head.appendChild(gtmHeadScript);
document.head.appendChild(googleOptimizeAntiFlickerSnippet);
document.body.appendChild(gtmBodyScript);
var addAssuranceSite = function () {
    try {
        var script_1 = document.createElement('script');
        script_1.src = 'https://assurance.mimtid.com/adstart/t.js?c=4EF238BDE8-site';
        script_1.type = 'text/javascript';
        new Promise(function (resolve, reject) {
            script_1.onload = function () {
                resolve();
            };
            script_1.onerror = function (error) {
                reject(error);
            };
            document.getElementsByTagName('body')[0].appendChild(script_1);
        });
    }
    catch (error) {
        console.log('Error: ', error);
    }
};
var ɵ0 = addAssuranceSite;
if (environment.production) {
    enableProdMode();
    addAssuranceSite();
}
document.addEventListener('DOMContentLoaded', function () {
    __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
});
export { ɵ0 };
