import { AbstractControl } from '@angular/forms';
import * as isEmail from 'validator/lib/isEmail';

export class EmailValidator {
  static checkEmail(control: AbstractControl) {
    const email = control.value;

    if (!email || !email.length) {
      return;
    }

    const isCON = /\.con$/.test(email);

    if (!email) {
      return { invalidEmail: true };
    }

    return isEmail(email) && !isCON ? null : { invalidEmail: true };
  }
}
