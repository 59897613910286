<div class="thanks-container">

  <div class="thanks-title-container">
    <h1 class="thanks-title">Thank you so much for gifting succulents!</h1>
    <p class="thanks-title-message">
      You'll receive an email confirmation of your order, as well as email updates upon shipment and delivery of your
      gifted succulents.
    </p>

    <div class="thanks-title-divider">
      <img src="/assets/images/icon-transparent@3x.png" alt="Succulent Studio Logo"/>
      <span class="gray-line"></span>
    </div>

    <p class="term-message" *ngIf="term">
      Subscription automatically ends after the {{ term | ordinal }} month, so no need
      to cancel!
    </p>
  </div>
  <video class="video-container" controls preload="auto"
         poster="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/placeholder/unboxing.jpg">
    <source src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/unboxing-vid.mp4" type="video/mp4">
    <source src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/unboxing-vid.webm" type="video/webm">
    Your browser does not support the video tag.
  </video>
  <div class="discount-btn-section">
    <p>Succulents brighten even the succ-iest of days! Want your own subscription?</p>
    <a class="btn discount-btn" (click)="goToCheckoutWithCoupon()">GET $5 OFF YOUR FIRST BOX</a>
  </div>
</div>
