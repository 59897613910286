import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';

// services
import { AuthService } from '@app/auth/services/auth.service';

// actions
import * as user from '@app/store/actions/user.actions';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private store: Store<any>
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = route.queryParams.token;
    if (token) {
      this.authService.setJWTToken(token);
      this.store.dispatch(new user.LoadAction());
    }

    if (!this.authService.tokenPayload) {
      this.router.navigate(['/login']);
    }

    // Access to administrative module
    if (state.url.slice(0, 9).includes('/control/') && this.authService.tokenPayload) {
      if (this.authService.tokenPayload.role === 'admin') {
        return true;
      } else {
        this.router.navigate(['/control/access-denied']);
      }
    }
    return !!this.authService.tokenPayload;
  }
}
