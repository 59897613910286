import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import * as boxActions from '@app/store/actions/box.action';
import * as fromBoxes from '@app/store/selectors/boxes.selector';
import {catchError, filter, switchMap, take, timeout} from 'rxjs/operators';
import {Store} from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AllBoxesGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>
  ) { }

  // TODO: develop a specific endpoint to get specific boxes from API
  getBoxesFromStoreOrApi(): Observable<any> {
    this.store.dispatch(new boxActions.LoadBoxesAction());
    return this.store
      .select(fromBoxes.getBoxesLoaded)
      .pipe(
        filter(loaded => loaded),
        take(1),
        timeout(5000),
        catchError((e) => {
          return of(false);
        })
      );
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getBoxesFromStoreOrApi().pipe(
      switchMap((data) => of(true)),
      catchError((e) => this.router.navigate(['/'])),
    );
  }
}
