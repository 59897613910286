import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {sift4} from '@app/shared/algorithms/sift4.algorithm';
import {BehaviorSubject, Subject} from 'rxjs';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {EmailValidator} from '@app/shared/validators/email.validator';

@Component({
  selector: 'sucstu-lead-form-base',
  template: `NO UI TO BE FOUND HERE!`,
})
export class LeadFormBaseComponent implements OnInit {
  destroyComponent$ = new Subject<void>();
  form: FormGroup
  emailFormField: string;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  suggestedEmail$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  showSuggestedEmail$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  commonDomains: string[] = ['gmail.com', 'yahoo.com', 'aol.com', 'hotmail.com', 'outlook.com', 'yahoo.net'];

  closeLeadForm() {
    this.close.emit();
  }

  constructor() {}

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', EmailValidator.checkEmail)
    });
    this.emailFormField = 'email';
  }

  testSuggestedEmails(email: string): void {
    let tempSuggestedEmail = { email: '', count: 3 };
    for (let i = 0; i < this.commonDomains.length; i++) {
      const domain = this.commonDomains[i];
      const algorithmResult = sift4(email.split('@')[1], domain, 5);
      if (tempSuggestedEmail.count > algorithmResult) {
        tempSuggestedEmail = {
          email: `${email.split('@')[0]}@${domain}`,
          count: algorithmResult
        };
      }
    }
    if (tempSuggestedEmail.count < 3 && tempSuggestedEmail.count !== 0) {
      this.suggestedEmail$.next(tempSuggestedEmail.email);
      this.showSuggestedEmail$.next(true)
    } else {
      this.showSuggestedEmail$.next(false)
    }
  }

  saveSuggestedEmail(): void {
    const suggestedEmail = this.suggestedEmail$.getValue();
    this.form.get(this.emailFormField).setValue(suggestedEmail);
    this.showSuggestedEmail$.next(false);
  }

}
