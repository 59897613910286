import * as tslib_1 from "tslib";
import { AfterContentInit, AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomersService } from '@app/core/services/customers/customers.service';
var AddPaymentFormComponent = /** @class */ (function () {
    function AddPaymentFormComponent(fb, _renderer, platformId, app_config, customersService) {
        this.fb = fb;
        this._renderer = _renderer;
        this.platformId = platformId;
        this.app_config = app_config;
        this.customersService = customersService;
        this.componentDestroyed$ = new Subject();
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.showLoader = new EventEmitter();
        this.hideLoader = new EventEmitter();
        this.isLoading = false;
        this.isModal = false;
        this.canSubmitPayment = true;
        this.isFocus = false;
        this.isFormValid = false;
        this.hideNewPaymentMethod = false;
        this.showErrorMessages = false;
        this.showTokenizeError = false;
        this.isNumberTouched = false;
        this.isCvvTouched = false;
        this.isExpDateTouched = false;
        this.form = this.fb.group({
            cardholderName: ['', Validators.required],
            nonce: ['', Validators.required],
            lastTwo: ['', Validators.required],
            cardType: ['', Validators.required],
            type: ['', Validators.required],
            description: ['', Validators.required],
            maskedNumber: [''],
            expirationDate: [''],
            zip: new FormControl('', [Validators.required]),
        });
        this.cardForm = new FormGroup({
            number: new FormControl('', [Validators.required]),
            exp: new FormControl('', [Validators.required]),
            cvv: new FormControl('', [Validators.required]),
            zip: new FormControl('', [Validators.required]),
        });
        this.payment_processor = this.app_config.payment_processor;
    }
    AddPaymentFormComponent.prototype.ngOnInit = function () {
        this.ccId = 'ccn';
        this.ccExpDateId = 'exp';
        this.cvvId = 'cod';
    };
    AddPaymentFormComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadEdgepayScript()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AddPaymentFormComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        fromEvent(this.saveButton.nativeElement, 'click')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(function () { return _this.onSave(); });
        fromEvent(this.cancelButton.nativeElement, 'click')
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(function (e) {
            e.stopPropagation();
            _this.onCancel();
        });
    };
    AddPaymentFormComponent.prototype.onSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.form.get('cardholderName').value.length || this.cardForm.invalid) {
                            this.showErrorMessages = true;
                            return [2 /*return*/];
                        }
                        this.isLoading = true;
                        this.showLoader.emit();
                        return [4 /*yield*/, this.initEdgepayScript()];
                    case 1:
                        _a.sent();
                        window.document.querySelector('#edgepayButton').click();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddPaymentFormComponent.prototype.onCancel = function () {
        this.hideLoader.emit();
        this.cancel.emit();
    };
    AddPaymentFormComponent.prototype.loadEdgepayScript = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var script;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                script = document.createElement('script');
                script.src = this.app_config.edgepay_pivot;
                script.type = 'text/javascript';
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        script.onload = function () {
                            _this.isLoading = false;
                            resolve();
                        };
                        script.onerror = function (error) {
                            _this.isLoading = false;
                            reject(error);
                        };
                        document.getElementsByTagName('body')[0].appendChild(script);
                    })];
            });
        });
    };
    AddPaymentFormComponent.prototype.initEdgepayScript = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var authKey;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.customersService.getPaymentTokenizationAuthToken().toPromise()];
                    case 1:
                        authKey = _a.sent();
                        EdgePay.init({ pivotAuthKey: authKey });
                        EdgePay.getToken({
                            formFieldIdSelector: {
                                submitButton: 'edgepayButton',
                                cardNumber: this.ccId,
                                cardExpirationDate: this.ccExpDateId,
                                cvv2: this.cvvId // id of input field that will contain the cvv
                            },
                            onSuccess: function (success) {
                                _this.form.patchValue({
                                    nonce: success.body.tokenID,
                                    lastTwo: _this.cardForm.value.number.slice(-2),
                                    cardType: 'Card',
                                    type: 'CreditCard',
                                    description: "Ending in " + _this.cardForm.value.number.slice(-2),
                                    maskedNumber: "**** **** **** " + _this.cardForm.value.number.slice(-4),
                                    expirationDate: success.body.cardExpirationDate.slice(0, 2) + "/" + success.body.cardExpirationDate.slice(-2),
                                    zip: _this.cardForm.value.zip
                                });
                                _this.save.emit(_this.form.value);
                                _this.showErrorMessages = false;
                                _this.showTokenizeError = false;
                            },
                            onError: function (error) {
                                console.error({ error: error });
                                alert("We couldn't validate your card, make sure it is correct.");
                                _this.isLoading = false;
                                _this.hideLoader.emit();
                                _this.showErrorMessages = true;
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AddPaymentFormComponent.prototype.ngOnDestroy = function () {
        // this.hostedFieldsInstance.teardown();
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    };
    return AddPaymentFormComponent;
}());
export { AddPaymentFormComponent };
