/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./console-viewer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./console-viewer.component";
import * as i4 from "@angular/router";
var styles_ConsoleViewerComponent = [i0.styles];
var RenderType_ConsoleViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsoleViewerComponent, data: {} });
export { RenderType_ConsoleViewerComponent as RenderType_ConsoleViewerComponent };
function View_ConsoleViewerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "w-vw log-", _v.context.$implicit.name, " text-sm py-2"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.text; _ck(_v, 1, 0, currVal_1); }); }
function View_ConsoleViewerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["style", "z-index: 10000;"]], [[8, "className", 0]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "text-sm border text-white px-2 py-1 absolute bg-black top-1 left-1"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleExpand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Expand"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "ul", [["class", "overflow-y-scroll h-full"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ConsoleViewerComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.logs$)); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "w-full ", (i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.expand$)) ? "h-full" : "h-32"), " fixed bottom-0 left-0 bg-black p-2"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ConsoleViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ConsoleViewerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.showConsole$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConsoleViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-console-viewer", [], null, null, null, View_ConsoleViewerComponent_0, RenderType_ConsoleViewerComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConsoleViewerComponent, [i1.PLATFORM_ID, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsoleViewerComponentNgFactory = i1.ɵccf("app-console-viewer", i3.ConsoleViewerComponent, View_ConsoleViewerComponent_Host_0, {}, {}, []);
export { ConsoleViewerComponentNgFactory as ConsoleViewerComponentNgFactory };
