import { Component, Inject } from '@angular/core';

import { ModalInfoData } from './../services/modal-info/modal-info.service';
import { ModalInfoRef } from './../services/modal-info/modal-info-ref';
import { MODAL_INFO_DATA,  } from './../services/modal-info/modal-info.tokens';

@Component({
  selector: 'sucstu-modal-info',
  template: `
    <div class="overlay-container">
      <h1 class="modal-title">{{ data.title }}</h1>
      <p class="modal-message">{{ data.message }}</p>
      <button class="modal-close-button btn btn-primary btn-primary--with-hover" (click)="closeModal()">Close</button>
    </div>
  `,
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent {

  constructor(
    public dialogRef: ModalInfoRef,
    @Inject(MODAL_INFO_DATA) public data: ModalInfoData
  ) { }

  closeModal() {
    this.dialogRef.close();
  }

}

