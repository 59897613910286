/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./lead-form-base.component";
var styles_LeadFormBaseComponent = [];
var RenderType_LeadFormBaseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeadFormBaseComponent, data: {} });
export { RenderType_LeadFormBaseComponent as RenderType_LeadFormBaseComponent };
export function View_LeadFormBaseComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["NO UI TO BE FOUND HERE!"]))], null, null); }
export function View_LeadFormBaseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sucstu-lead-form-base", [], null, null, null, View_LeadFormBaseComponent_0, RenderType_LeadFormBaseComponent)), i0.ɵdid(1, 114688, null, 0, i1.LeadFormBaseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeadFormBaseComponentNgFactory = i0.ɵccf("sucstu-lead-form-base", i1.LeadFormBaseComponent, View_LeadFormBaseComponent_Host_0, {}, { close: "close", submit: "submit" }, []);
export { LeadFormBaseComponentNgFactory as LeadFormBaseComponentNgFactory };
