import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {SessionStorageService} from 'ngx-webstorage';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LandingPagesGuard implements CanActivate {
  constructor(
    private readonly sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID)
    private readonly platformId: any,
    private readonly router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (isPlatformServer(this.platformId)) {
      return true;
    }

    console.log(this.sessionStorageService.retrieve('testlp'))

    switch (this.sessionStorageService.retrieve('testlp')) {
      case 'farm-house':
        return this.router.navigate(['/', 'lp', 'farm'])
          .then(() => false);

      case 'farm-house-2':
        return this.router.navigate(['/', 'lp', 'farm2'])
          .then(() => false);

      case 'glow-up':
        return this.router.navigate(['/', 'lp', 'glow-up'])
          .then(() => false);

      case 'glow2':
        return this.router.navigate(['/', 'lp', 'glow2'])
          .then(() => false);


      default:
        return true;
    }
  }
}
