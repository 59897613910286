import { Directive, ElementRef, Renderer, OnChanges, Input } from '@angular/core';

@Directive({ selector: '[focus]' })
export class FocusDirective implements OnChanges {

  @Input() focus: boolean;

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer
  ) { }

  ngOnChanges() {
    if (this.focus) {
      setTimeout(() => this.hostElement.nativeElement.focus(), 0);
    }
  }
}
