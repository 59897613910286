import * as tslib_1 from "tslib";
import * as coupon from '@app/store/actions/coupon.actions';
;
export var initialState = {
    data: null,
    searching: false,
    searched: true,
    valid: false,
    invalid: false,
    errors: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case coupon.VERIFY_COUPON: {
            return tslib_1.__assign({}, state, { searching: true, searched: false, invalid: false, valid: false, errors: [] });
        }
        case coupon.VERIFY_CLAIM_COUPON: {
            return tslib_1.__assign({}, state, { searching: true, searched: false, invalid: false, valid: false, errors: [] });
        }
        case coupon.VERIFY_COUPON_COMPLETE: {
            return tslib_1.__assign({}, state, { data: action.payload, searching: false, searched: true, invalid: false, valid: true });
        }
        case coupon.VERIFY_COUPON_FAIL: {
            return tslib_1.__assign({}, state, { data: null, searching: false, searched: true, invalid: true, valid: false, errors: action.payload });
        }
        case coupon.CLEAR_COUPON: {
            return tslib_1.__assign({}, state, { data: null, searching: false, searched: true, invalid: false, valid: false, errors: [] });
        }
        default: {
            return state;
        }
    }
}
export var getCouponData = function (state) { return state.data; };
export var getSearchingCoupon = function (state) { return state.searching; };
export var getSearchedCoupon = function (state) { return state.searched; };
export var getValidCoupon = function (state) { return state.valid; };
export var getInvalidCoupon = function (state) { return state.invalid; };
export var getErrorsCoupon = function (state) { return state.errors; };
