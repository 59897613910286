import { Injectable, Injector } from '@angular/core';
import { Event, NavigationError, Router } from '@angular/router';
import bugsnag from '@bugsnag/js';
import { Bugsnag } from '@bugsnag/node';
import { environment } from '@env/environment';

const bugsnagClient = bugsnag({
  apiKey: environment.bugSnagKey,
  releaseStage: 'succulent-studio' + '-' + environment.name,
});

@Injectable()
export class ErrorService {

  private router: Router;

  client: Bugsnag.Client;

  constructor(
    private injector: Injector,
  ) {
    this.client = bugsnagClient;
  }

  initialize() {
    this.router = this.injector.get(Router);
    this.router
      .events
      .subscribe((event: Event) => {
        // console.log('Navigation Event: ', event);
        if (event instanceof NavigationError) {
          console.log('Navigation Error!', event);
          // if (event.error.message && event.error.message.includes('Loading chunk')) {
          //   alert('There is a new version of the app, we will reload the page');
          //   this.router.navigate([event.url]);
          // }
        }
      });
  }

  setClientReporterUser(id: string, email: string, name: string) {
    this.client.user = {
      id, email, name
    };
  }

  unsetClientReporterUser() {
    this.client.user = {};
  }
}
