/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./backgrounds.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./backgrounds.component";
var styles_BackgroundsPageComponent = [i0.styles];
var RenderType_BackgroundsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackgroundsPageComponent, data: {} });
export { RenderType_BackgroundsPageComponent as RenderType_BackgroundsPageComponent };
function View_BackgroundsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "background-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "background image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "download-link"], ["target", "a_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
export function View_BackgroundsPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "page-container container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "backgrounds-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "backgrounds-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Desert Dream Digital Wallpapers"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download Succulent Studios' exclusive collection of Desert Dream Digital Wallpapers featuring succulents and cacti, oh my! Perfect for smartphones, Apple watches, and tablets. Show off your dreamy new background with a screenshot, and tag us @sucstu."])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "ul", [["class", "backgrounds-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundsPageComponent_1)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundsList; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_BackgroundsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-backgrounds", [], null, null, null, View_BackgroundsPageComponent_0, RenderType_BackgroundsPageComponent)), i1.ɵdid(1, 114688, null, 0, i3.BackgroundsPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundsPageComponentNgFactory = i1.ɵccf("sucstu-backgrounds", i3.BackgroundsPageComponent, View_BackgroundsPageComponent_Host_0, {}, {}, []);
export { BackgroundsPageComponentNgFactory as BackgroundsPageComponentNgFactory };
