import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
// config
import {APP_CONFIG, AppConfig} from '@app/app.config';
import {Address} from '@app/core/models/address.model';
import {MarketingService} from '@app/core/services/marketing.service';
import {AnglerAiService, CustomEventName} from '@app/core/services/angler-ai/angler-ai.service';

export interface LeadParams {
  uuid: string,
  email?: string,
  utmData?: any,
  leadForm?: string;
  phone?: string;
}

export interface WinItWednesdayParams extends LeadParams {
  social?: {
    instagram?: string;
  }
}

export interface LeadCheckoutParams {
  uuid: string,
  email: string,
  utmData?: any,
  plan: string,
  firstName: string,
  lastName: string,
  recipient: {
    firstName: string,
    lastName: string,
    email: string,
  },
  phone: string,
  address: Address,
  timeInSite: number,
  userIp: string,
  recaptchav3Token: string,
}

@Injectable()
export class LeadsService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private readonly marketingService: MarketingService,
    private readonly anglerAiService: AnglerAiService,
  ) { }

  create({ uuid, email, phone, utmData, leadForm }: LeadParams) {
    const headers = new HttpHeaders({ retry: 'yes' });

    if (email) {
      this.anglerAiService.notifyLeadCompleted({
        customer: { email },
        customEventName: CustomEventName.LEAD_SAVED,
      })
        .catch(console.error);
    }

    return this.httpClient
      .post(`${this.app_config.api_uri}/leads`, { uuid, email, phone, utmData, leadForm }, { headers });
  }

  leadCheckout(data: LeadCheckoutParams) {
    return this.httpClient
      .post(`${this.app_config.api_uri}/leads/checkout`, data);
  }

  createNewWinItWednesdayEntry(data: WinItWednesdayParams) {
    return this.httpClient
      .post(`${this.app_config.api_uri}/win-it-wednesday`, data);
  }
}
