
import {filter} from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';

// rxjs
import { Observable, Subscription } from 'rxjs';

// models
import { User } from '@app/core/models/user.model';

// actions
import * as user from '@app/store/actions/user.actions';

// selectors
import * as fromUser from '@app/store/selectors/user.selector';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'sucstu-login',
  styleUrls: ['./login.component.scss'],
  template: `
    <div class="login-container container">

      <h2 class="login-container__title">Log In</h2>

      <sucstu-auth-form
        (submitted)="onSubmit($event)">

        <p class="error-text" *ngIf="(error$ | async) as error;">
          {{ error.message }}
        </p>
      </sucstu-auth-form>

    </div>
  `
})
export class LoginComponent implements OnInit, OnDestroy {

  user$: Observable<User>;
  error$: Observable<any>;
  isAuthenticated$: Observable<boolean>;
  subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.user$ = this.store.pipe(select(fromUser.getUserData));
    this.error$ = this.store.pipe(select(fromUser.getUserError));
    this.isAuthenticated$ = this.store.pipe(select(fromUser.getUserIsAuthenticated));
    const redirectRoute = this.route.snapshot.queryParams.redirect ?
      this.route.snapshot.queryParams.redirect : '/profile';

    this.subscription = this.isAuthenticated$
      .pipe(filter(Boolean))
      .subscribe(isAuthenticated => {
        this.router.navigate([redirectRoute], { queryParamsHandling: 'preserve' });
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSubmit(event: FormGroup) {
    this.store.dispatch(new user.LoginAction(event.value));
  }

}
