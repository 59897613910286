import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';

// RxJS
import { Observable, of } from 'rxjs';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

// actions
import * as planActions from '@app/store/actions/plans.actions';

// selectos
import * as fromPlans from '@app/store/selectors/plans.selector';

// models
import { Plan } from './../../models/plan.model';

@Injectable()
export class PlanGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>
  ) { }

  checkPlan(id: string): Observable<boolean> {
    return this.store
      .select(fromPlans.getPlansEntities)
      .pipe(
        map((entities: { [key: number]: Plan }) => !!entities[id]),
        take(1)
      );
  }

  checkStore(): Observable<any> {
    return this.store
      .select(fromPlans.getPlansLoaded)
      .pipe(
        tap(loaded => {
          if (!loaded) {
            this.store.dispatch(new planActions.LoadPlansAction());
          }
        }),
        filter((loaded: boolean) => loaded),
        take(1)
      );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkStore()
      .pipe(
        switchMap(() => {
          const id = route.params.planId;
          return this.checkPlan(id);
        }),
        catchError(() => of(false))
      );
  }
}
