<div class="page-container">
  <section class="page-body">
    <div class="claim-form-container container">
      <h2 class="claim-form-title">Review and claim your gift!</h2>
      <div class="claim-form-row">
        <form [formGroup]="sigupForm" class="claim-form" (ngSubmit)="onClaimSubscription()">
          <div class="form-group form-group__flex-start">
            <input disabled class="form-group__input claim-code-input" type="text" placeholder="E-mail" formControlName="email" name="claim-code">
          </div>
          <div class="form-group form-group__flex-start">
            <input class="form-group__input claim-code-input" type="password" placeholder="Password" formControlName="password" name="claim-code">
          </div>
          <div class="form-group form-group__flex-start">
            <input class="form-group__input email-input" type="text" placeholder="First Name" formControlName="firstName" name="firstName" autocomplete="First Name">
          </div>
          <div class="form-group form-group__flex-start">
            <input class="form-group__input email-input" type="email" placeholder="Last Name" formControlName="lastName" name="lastName" autocomplete="Last Name">
          </div>
          <div class="form-group form-group__flex-start">
            <input class="form-group__input email-input" type="string" placeholder="Phone" formControlName="phone" name="phone" autocomplete="Phone">
          </div>

          <a class="claim-button" (click)="onClaimSubscription()">Claim</a>
          <span class="claim-terms-text" style="color: red">{{ responseError$ | async }}</span>
        </form>
      </div>
    </div>

    <div class="aside-section">
      <div class="gift-with-calendar-picture-container">
        <img class="gift-with-calendar-mobile" src="/assets/images/gift-with-calendar-mobile.jpg" alt="">
        <img class="gift-with-calendar-desk" src="/assets/images/gift-with-calendar-desk.png" alt="">
      </div>
      <div class="aside-text-container">
        <h4 class="aside-title">Subscribers get monthly SKIP + GIFT options</h4>
        <p class="aside-text"><span>SKIP</span> any month at no cost. </p>
        <p class="aside-text"><span>GIFT </span> any month to a friend.</p>
      </div>
    </div>

    <div class="attributes-section">

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/jar.png" alt="Jar icon" />
        </div>
        <h3 class="attribute__title">Drought-tolerant</h3>
        <p class="attribute__text">Conserve water! Your succulents only need a drink every 7-10 days.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/sun.png" alt="Sun icon" />
        </div>
        <h3 class="attribute__title">Easy Care</h3>
        <p class="attribute__text">Set in a spot that gets about 6+ hours of natural light a day.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/oxygen.png" alt="Oxygen icon" />
        </div>
        <h3 class="attribute__title">Oxygen-boosters</h3>
        <p class="attribute__text">Succulents release oxygen all night long while your other plants release carbon dioxide.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/leaf.png" alt="Leaf icon" />
        </div>
        <h3 class="attribute__title">Low Pollen Production</h3>
        <p class="attribute__text">Most succulents and all cacti are a 1 on the Ogren Plant Allergy Scale (OPALS), so they make perfect houseplants
          for those who suffer from allergies.</p>
      </div>

    </div>

  </section>
</div>
