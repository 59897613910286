import { CreditCardMask } from './credit-card-mask.pipe';
import { FormatPhoneNumberPipe } from './phone-number-format.pipe';
import { OrdinalPipe } from '@app/shared/pipes/ordinal.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';

export const pipes = [CreditCardMask, FormatPhoneNumberPipe, OrdinalPipe, SafeHtmlPipe];

export * from './credit-card-mask.pipe';
export * from './phone-number-format.pipe';
export * from './ordinal.pipe';
export { SafeHtmlPipe } from './safe-html.pipe';
