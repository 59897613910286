import * as tslib_1 from "tslib";
import * as searchEmail from '@app/store/actions/search-email.actions';
import * as user from '@app/store/actions/user.actions';
;
export var initialState = {
    searching: false,
    searched: true,
    found: false,
    errors: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case searchEmail.SEARCH_EMAIL: {
            return tslib_1.__assign({}, state, { searching: true, searched: false });
        }
        case searchEmail.SEARCH_EMAIL_COMPLETE: {
            return tslib_1.__assign({}, state, { searching: false, searched: true, found: true });
        }
        case user.LOGOUT_COMPLETE: {
            return tslib_1.__assign({}, state, { searching: false, found: false, searched: true });
        }
        case searchEmail.SEARCH_EMAIL_FAIL: {
            return tslib_1.__assign({}, state, { searching: false, found: false, searched: true, errors: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getSearchingValue = function (state) { return state.searching; };
export var getSearchedValue = function (state) { return state.searched; };
export var getFoundValue = function (state) { return state.found; };
export var getErrors = function (state) { return state.errors; };
