import * as tslib_1 from "tslib";
import * as notificationBar from '@app/store/actions/notification-bar.actions';
;
export var initialState = {
    open: false,
    title: null,
    message: null,
    type: null,
    mode: 'notification',
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case notificationBar.OPEN_NOTIFICATION_BAR: {
            return tslib_1.__assign({}, state, action.payload, { open: true });
        }
        case notificationBar.CLOSE_NOTIFICATION_BAR: {
            return tslib_1.__assign({}, state, { open: false });
        }
        default: {
            return state;
        }
    }
}
export var getNotificationBar = function (state) { return state; };
