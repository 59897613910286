import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

// models
import { PaymentMethod } from '@app/core/models/payment-method';

import {fromEvent, Subject} from 'rxjs';
import { APP_CONFIG } from '@app/app.config';
import { environment } from '@env/environment';
import {takeUntil} from 'rxjs/operators';
import {CustomersService} from '@app/core/services/customers/customers.service';

declare var EdgePay: any;
declare var window: Window;

@Component({
  selector: 'sucstu-add-payment-form',
  styleUrls: ['./add-payment-form.component.scss'],
  templateUrl: 'add-payment-form.component.html'
})
export class AddPaymentFormComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  componentDestroyed$: Subject<void> = new Subject<void>();

  @ContentChild('saveButton') saveButton: ElementRef;
  @ContentChild('cancelButton') cancelButton: ElementRef;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() showLoader: EventEmitter<any> = new EventEmitter<any>();
  @Output() hideLoader: EventEmitter<any> = new EventEmitter<any>();

  @Input() isLoading = false;
  @Input() isModal = false;

  canSubmitPayment = true;
  isFocus = false;

  isFormValid = false;
  hideNewPaymentMethod = false;
  selectedPaymentMethod: PaymentMethod;

  isNumberValid: boolean;
  isCvvValid: boolean;
  isExpDateValid: boolean;

  showErrorMessages = false;
  showTokenizeError = false;
  isNumberTouched = false;
  isCvvTouched = false;
  isExpDateTouched = false;

  ccId: string;
  ccExpDateId: string;
  cvvId: string;

  form = this.fb.group({
    cardholderName: ['', Validators.required],
    nonce: ['', Validators.required],
    lastTwo: ['', Validators.required],
    cardType: ['', Validators.required],
    type: ['', Validators.required],
    description: ['', Validators.required],
    maskedNumber: [''],
    expirationDate: [''],
    zip: new FormControl('', [Validators.required]),
  });

  cardForm: FormGroup = new FormGroup({
    number: new FormControl('', [Validators.required]),
    exp: new FormControl('', [Validators.required]),
    cvv: new FormControl('', [Validators.required]),
    zip: new FormControl('', [Validators.required]),
  });

  payment_processor = this.app_config.payment_processor;

  constructor(
    private fb: FormBuilder,
    private _renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_CONFIG) private app_config: typeof environment,
    private readonly customersService: CustomersService,
  ) { }

  ngOnInit() {
    this.ccId = 'ccn';
    this.ccExpDateId = 'exp';
    this.cvvId = 'cod';
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      await this.loadEdgepayScript();
    })
  }

  ngAfterContentInit() {
    fromEvent(this.saveButton.nativeElement, 'click')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(() => this.onSave());
    fromEvent(this.cancelButton.nativeElement, 'click')
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((e: MouseEvent) => {
        e.stopPropagation();
        this.onCancel();
      });
  }

  async onSave() {
    if (!this.form.get('cardholderName').value.length || this.cardForm.invalid) {
      this.showErrorMessages = true;
      return;
    }

    this.isLoading = true;
    this.showLoader.emit();
    await this.initEdgepayScript();
    (window.document.querySelector('#edgepayButton') as HTMLButtonElement).click();
  }

  onCancel() {
    this.hideLoader.emit();
    this.cancel.emit();
  }

  private async loadEdgepayScript(): Promise<void> {
    this.isLoading = true;
    const script = document.createElement('script');
    script.src = this.app_config.edgepay_pivot;
    script.type = 'text/javascript';

    return new Promise((resolve, reject) => {
      script.onload = () => {
        this.isLoading = false;
        resolve();
      };
      script.onerror = (error: any) => {
        this.isLoading = false;
        reject(error);
      };
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }

  private async initEdgepayScript(): Promise<void> {
    const authKey: string = await this.customersService.getPaymentTokenizationAuthToken().toPromise();
    EdgePay.init({ pivotAuthKey: authKey });
    EdgePay.getToken({
      formFieldIdSelector: {
        submitButton: 'edgepayButton', // id of button that will trigger the credit card tokenization
        cardNumber: this.ccId, // id of input field that will contain the credit card number
        cardExpirationDate: this.ccExpDateId, // id of input field that will contain the expiration date in MMYY format
        cvv2: this.cvvId // id of input field that will contain the cvv
      },
      onSuccess: (success) => { // send the token to your server //success.body.tokenID
        this.form.patchValue({
          nonce: success.body.tokenID,
          lastTwo: this.cardForm.value.number.slice(-2),
          cardType: 'Card',
          type: 'CreditCard',
          description: `Ending in ${this.cardForm.value.number.slice(-2)}`,
          maskedNumber: `**** **** **** ${this.cardForm.value.number.slice(-4)}`,
          expirationDate: `${success.body.cardExpirationDate.slice(0, 2)}/${success.body.cardExpirationDate.slice(-2)}`,
          zip: this.cardForm.value.zip
        });

        this.save.emit(this.form.value);
        this.showErrorMessages = false;
        this.showTokenizeError = false;
      },
      onError: (error) => { // Handle Errors
        console.error({error});
        alert(`We couldn't validate your card, make sure it is correct.`);
        this.isLoading = false;
        this.hideLoader.emit();
        this.showErrorMessages = true;
      }
    });
  }

  ngOnDestroy() {
    // this.hostedFieldsInstance.teardown();
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
