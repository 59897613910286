import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

// RxJS
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { PlansService } from './../services/plans.service';
import { Plan } from './../models/plan.model';

@Injectable()
export class PlanResolver implements Resolve<Plan> {

  constructor(
    private planService: PlansService,
    private sessionStorage: SessionStorageService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    const REGULAR_PLAN_ID = '5997c3ac8852a761249342e9';
    const GIFT_PLAN_ID = '5a10dbfaf8ceb4830ff186a9';
    let planId = this.sessionStorage.retrieve('selectedPlanId');
    const isAGift = route.queryParams.isAGift;

    if (!planId) {
      planId = route.queryParams.planId || REGULAR_PLAN_ID;
    }

    if (isAGift) {
      planId = GIFT_PLAN_ID;
    } else {
      planId = REGULAR_PLAN_ID;
    }

    return this.planService.getPlan(planId)
      .pipe(catchError(e => {
        this.router.navigate(['/']);
        return of(null);
      }));
  }
}
