import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export interface Entites<T> {
  [id: string]: T
}

export interface Variant {
  id: string,
  name: string,
}

export interface Experiment {
  id: string,
  name: string,
  variants: Entites<Variant>,
}

@Injectable()
export class GoogleOptimizeService {

  private _isGoogleOptimizeInitialized$ = new BehaviorSubject(false);
  isGoogleOptimizeInitialized$ = this._isGoogleOptimizeInitialized$.asObservable().pipe(distinctUntilChanged());

  private _experiments$ = new BehaviorSubject<Entites<Experiment>>({});
  experiments$ = this._experiments$.asObservable().pipe(distinctUntilChanged());


  constructor() { }

  setGoogleOptimizeAsInitialzed() {
    this._isGoogleOptimizeInitialized$.next(true);
  }

  setExperiment(experiment: Experiment) {
    const value = this._experiments$.getValue();
    this._experiments$.next({ ...value, [experiment.id]: experiment });
  }

}
