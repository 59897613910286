import {Inject, Injectable, PLATFORM_ID, PlatformRef} from '@angular/core';
import {environment} from '@env/environment';
import {isPlatformBrowser} from '@angular/common';
import {ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';

declare let anglerai: {
  init(params: { workspaceId: string; token: string }): void;
  sendEvent(
    params:
      | { eventName: AnglerAiEvent; data: EventsParams }
      | { eventName: AnglerAiEvent.custom_event; data: EventsParams; customEventName: CustomEventName }
  ): void;
};

export enum AnglerAiEvent {
  page_viewed = 'page_viewed',
  checkout_started = 'checkout_started',
  checkout_completed = 'checkout_completed',
  custom_event = 'custom_event',
}

export enum CustomEventName {
  LEAD_SAVED = 'lead_saved',
}

export interface EventsParams {
  customer?: {
    email?: string;
  };

  checkout?: {
    attributes?: Array<{
      key: string;
      value: string;
    }>;
    billingAddress?: {
      address1: string;
      address2: string;
      city: string;
      country: string;
      countryCode: string;
      firstName: string;
      lastName: string;
      phone: string;
      province: string;
      provinceCode: string;
      zip: string;
    },
    currencyCode?: string,
    discountApplications?: Array<{
      allocationMethod: string,
      targetSelection: string,
      targetType: string,
      title: string,
      type: string,
      value: {
        amount: number,
        currencyCode: string
      }
    }>,
    email?: string,
    lineItems?: Array<{
      discountAllocations: Array<{
        amount: {
          amount: number,
          currencyCode: string
        },
        discountApplication: {
          allocationMethod: string,
          targetSelection: string,
          targetType: string,
          title: string,
          type: string,
          value: {
            amount: number,
            currencyCode: string
          }
        }
      }>,
      id: string,
      quantity: number,
      title: string,
      variant: {
        id: string,
        image: {
          src: string
        },
        price: {
          amount: number,
          currencyCode: string
        },
        product: {
          id: string,
          title: string,
          untranslatedTitle: string,
          vendor: string,
          type: string,
          url: string
        },
        sku: string,
        title: string,
        untranslatedTitle: string
      }
    }>,
    order?: {
      id: string
    },
    phone?: string,
    shippingAddress?: {
      address1: string,
      address2: string,
      city: string,
      country: string,
      countryCode: string,
      firstName: string,
      lastName: string,
      phone: string,
      province: string,
      provinceCode: string,
      zip: string
    },
    shippingLine?: {
      price: {
        amount: number,
        currencyCode: string
      }
    },
    subtotalPrice?: {
      amount: number,
      currencyCode: string
    },
    token?: string,
    totalPrice?: {
      amount: number,
      currencyCode: string
    },
    totalTax?: {
      amount: number,
      currencyCode: string
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnglerAiService {
  scriptLoaded$: ReplaySubject<void> = new ReplaySubject<void>();

  constructor(
    @Inject(PLATFORM_ID)
    private readonly platformRef: PlatformRef,
  ) {
    if (isPlatformBrowser(platformRef)) {
      this.loadScript()
        .then(() => this.startAnglerAi());
    }
  }

  private startAnglerAi() {
    anglerai.init({ workspaceId: environment.anglerAiWorkspaceId, token: environment.anglerAiToken });
  }

  async notifyPageView(data: Pick<EventsParams, 'customer'>) {
    await this.scriptLoaded$.pipe(take(1)).toPromise();
    anglerai.sendEvent({ eventName: AnglerAiEvent.page_viewed, data });
  }

  async notifyCheckoutStarted(data: EventsParams) {
    await this.scriptLoaded$.pipe(take(1)).toPromise();
    anglerai.sendEvent({ eventName: AnglerAiEvent.checkout_started, data });
  }

  async notifyCheckoutCompleted(data: EventsParams) {
    await this.scriptLoaded$.pipe(take(1)).toPromise();
    anglerai.sendEvent({ eventName: AnglerAiEvent.checkout_completed, data });
  }

  async notifyLeadCompleted({
    customEventName,
    ...data
  }: EventsParams & { customEventName: CustomEventName }): Promise<void> {
    await this.scriptLoaded$.pipe(take(1)).toPromise();
    anglerai.sendEvent({ eventName: AnglerAiEvent.custom_event, customEventName, data });
  }

  private async loadScript(): Promise<void> {
    const script: HTMLScriptElement = document.createElement('script');
    script.src = 'https://static.getangler.ai/dist/analytics/_latest.min.js';
    script.type = 'text/javascript';

    return new Promise((resolve, reject) => {
      script.onload = () => {
        this.scriptLoaded$.next();
        resolve();
      };
      script.onerror = (error: any) => {
        reject(error);
      };
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }
}
