import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
var GoogleOptimizeService = /** @class */ (function () {
    function GoogleOptimizeService() {
        this._isGoogleOptimizeInitialized$ = new BehaviorSubject(false);
        this.isGoogleOptimizeInitialized$ = this._isGoogleOptimizeInitialized$.asObservable().pipe(distinctUntilChanged());
        this._experiments$ = new BehaviorSubject({});
        this.experiments$ = this._experiments$.asObservable().pipe(distinctUntilChanged());
    }
    GoogleOptimizeService.prototype.setGoogleOptimizeAsInitialzed = function () {
        this._isGoogleOptimizeInitialized$.next(true);
    };
    GoogleOptimizeService.prototype.setExperiment = function (experiment) {
        var _a;
        var value = this._experiments$.getValue();
        this._experiments$.next(tslib_1.__assign({}, value, (_a = {}, _a[experiment.id] = experiment, _a)));
    };
    return GoogleOptimizeService;
}());
export { GoogleOptimizeService };
