import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as plansActions from '@app/store/actions/plans.actions';

// services
import * as fromServices from './../../core/services';
import { SessionStorageService } from 'ngx-webstorage';

// models
import { Plan } from '@app/core/models/plan.model';

@Injectable()
export class PlansEffects {

  @Effect({ dispatch: false }) load$ = this.actions$
    .pipe(
      ofType(plansActions.LOAD_PLANS),
      switchMap(data =>
        this.plansService.getAvailablePlans()
          .pipe(
            map((plans: Plan[]) => {
              this.store.dispatch(new plansActions.LoadPlansCompleteAction(plans));
            }),
            catchError(reason => {
              this.store.dispatch(new plansActions.LoadPlansFailAction({ error: reason }));
              return of(null);
            })
          )
      ));

  @Effect({ dispatch: false }) loadById$ = this.actions$
    .pipe(
      ofType(plansActions.LOAD_PLAN),
      switchMap((action: plansActions.LoadPlanAction) =>
        this.plansService.getPlan(action.payload)
          .pipe(
            map(({ plan }) => {
              this.store.dispatch(new plansActions.LoadPlanCompleteAction(plan));
            }),
            catchError(reason => {
              this.store.dispatch(new plansActions.LoadPlanFailAction({ error: reason }));
              return of(null);
            })
          )
      ));

  @Effect({ dispatch: false }) select$ = this.actions$
    .pipe(
      ofType(plansActions.SELECT_PLAN),
      map((action: plansActions.SelectPlanAction) => action.payload),
      tap((planId: string) => this.sessionStorage.store('selectedPlanId', planId))
    );

  constructor(
    private plansService: fromServices.PlansService,
    private sessionStorage: SessionStorageService,
    private actions$: Actions,
    private store: Store<any>
  ) {
  }

}
