import { Router } from '@angular/router';
// services
import { ModalInfoService, SubscriptionService } from '@app/core/services';
var SelectBoxGuard = /** @class */ (function () {
    function SelectBoxGuard(router, modalInfoService, subscriptionService) {
        this.router = router;
        this.modalInfoService = modalInfoService;
        this.subscriptionService = subscriptionService;
    }
    SelectBoxGuard.prototype.selectBox = function (subscriptionId, boxId) {
        return this.subscriptionService
            .selectSubscriptionBox(subscriptionId, boxId)
            .toPromise();
    };
    SelectBoxGuard.prototype.canActivate = function (route) {
        var _this = this;
        var _a = route.queryParams, subscriptionId = _a.subscriptionId, boxId = _a.boxId;
        return this.selectBox(subscriptionId, boxId)
            .then(function () {
            _this.router.navigate(['/boxes/box-confirmation'], {
                queryParams: { boxId: boxId, subscriptionId: subscriptionId }
            });
            return true;
        })
            .catch(function (_a) {
            var error = _a.error;
            if (error.code && error.code === 1) {
                _this.router.navigate(['/profile'], {
                    queryParams: {
                        reason: 'box-selection-payment-failed',
                        subscriptionId: subscriptionId,
                        boxId: boxId,
                    }
                });
            }
            else {
                _this.router.navigate(['/profile']).then(function () {
                    _this.modalInfoService.open({
                        data: {
                            title: 'Something went wrong',
                            message: error.message
                        }
                    });
                });
            }
            return false;
        });
    };
    return SelectBoxGuard;
}());
export { SelectBoxGuard };
