import { HttpClient } from '@angular/common/http';
// config
import { AppConfig } from './../../app.config';
var ClaimService = /** @class */ (function () {
    function ClaimService(httpClient, app_config) {
        this.httpClient = httpClient;
        this.app_config = app_config;
    }
    ClaimService.prototype.checkClaimCode = function (_a) {
        var email = _a.email, claimCode = _a.claimCode;
        return this.httpClient.post(this.app_config.api_uri + "/users/subscriptions/check-claim-code", { email: email, claimCode: claimCode ? claimCode.toUpperCase() : '' });
    };
    return ClaimService;
}());
export { ClaimService };
