import { Component } from '@angular/core';

@Component({
  selector: 'sucstu-faq-question',
  styleUrls: ['./faq-question.component.scss'],
  templateUrl: 'faq-question.component.html'
})
export class FaqQuestionComponent {
  open = false;
  onToggle() {
    this.open = !this.open;
  }
}
