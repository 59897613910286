import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, pluck, skip, take } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
var ConsoleViewerComponent = /** @class */ (function () {
    function ConsoleViewerComponent(platform, route) {
        this.platform = platform;
        this.route = route;
        this.showConsole$ = new BehaviorSubject(false);
        this.logs$ = new BehaviorSubject([]);
        this.expand$ = new BehaviorSubject(false);
    }
    ConsoleViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams
            .pipe(skip(1))
            .pipe(take(1))
            .pipe(pluck(('stcos')))
            .pipe(filter(function (value) { return value === 'yes'; }))
            .pipe(filter(function () { return isPlatformBrowser(_this.platform); }))
            .subscribe(function () {
            _this.updateConsoleMethods();
            _this.showConsole$.next(true);
        });
    };
    ConsoleViewerComponent.prototype.updateConsoleMethods = function () {
        try {
            this.consoleMethod('log');
            this.consoleMethod('error');
            var that_1 = this;
            window.onerror = function (event, source, fileno, columnNumber, error) {
                that_1.logs$.next(that_1.logs$.getValue().concat([
                    { name: name, text: typeof error === 'object' ? JSON.stringify(error) : error }
                ]));
            };
        }
        catch (e) {
        }
    };
    ConsoleViewerComponent.prototype.consoleMethod = function (name) {
        var that = this;
        console[name] = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            that.logs$.next(that.logs$.getValue().concat(args.map(function (arg) { return ({ name: name, text: typeof arg === 'object' ? JSON.stringify(arg) : arg }); })));
        };
    };
    ConsoleViewerComponent.prototype.toggleExpand = function () {
        this.expand$.next(!this.expand$.getValue());
    };
    return ConsoleViewerComponent;
}());
export { ConsoleViewerComponent };
