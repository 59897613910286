<div class="page-container container">

  <h1 class="page__title">Content Submission</h1>
  <p class="page__desc">Send us your photos, videos, and GIFs, and we'll share'em on social!</p>

  <div class="form-loader" *ngIf="submitting">
    <img src="/assets/images/loader.svg" alt="">
  </div>

  <form [formGroup]="form" class="form-container" [ngClass]="{ 'form-loader-opacity': submitting }">
    <div class="form-group form-group__flex-start">
      <input
        class="form-group__input"
        type="text"
        placeholder="First Name"
        formControlName="firstName"
        name="firstName"
        autocomplete="given-name" />
      <span class="error-text" *ngIf="isFieldValid('firstName', 'required')">
        You have to provide your first name.
      </span>
    </div>
    <div class="form-group form-group__flex-start form-group__social-media-user">
      <input
        class="form-group__input"
        type="text"
        placeholder="@social"
        formControlName="socialMediaUser"
        name="socialMediaUser" />
      <span class="error-text" *ngIf="isFieldValid('socialMediaUser', 'required')">
        You have to provide your social media identifier.
      </span>
    </div>

    <div class="actions">
      <div class="file-uploader-container">
        <label class="fileInput__label" for="fileInput">Select Files</label>
        <input
          #fileInput
          id="fileInput"
          class="fileInput"
          type="file"
          (change)="onFileChange($event)"
          multiple />
        <!-- <span class="error-text" *ngIf="isFieldValid('files', 'required')">
          You have to select at least one file.
        </span> -->
      </div>
      <div class="submit-btn">
        <div class="form-button-container">
          <button
            (click)="submitForm()"
            class="btn-submit btn btn-primary btn-desk btn-primary--with-hover"
            type="button">
            Submit
          </button>
        </div>
      </div>
    </div>
    <p class="file-error-text" *ngFor="let error of (filesErrors$ | async)">{{ error }}</p>
    <ul class="file__items">
      <li class="file__item" *ngFor="let file of form.get('files').value"> {{ file.name }}</li>
    </ul>
    <a
      class="clear-btn"
      *ngIf="form.get('files').value.length"
      (click)="onClearFiles()">
      Clear Files
    </a>
  </form>
</div>
