import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
// actions
import * as fromPaymentMethodActions from './../actions/payment-methods.actions';
// models
import { PaymentMethod } from '@app/core/models/payment-method';
// services
import { UserService } from './../../core/services/user.service';
import { NotificationBarService } from './../../core/services/notification-bar.service';
import { Subscription } from '@app/core/models/subscription.model';
import * as subscriptionActions from '@app/store/actions/subscription.action';

@Injectable()
export class PaymentMethodEffects {
  @Effect({dispatch: false}) update$ = this.actions$
    .pipe(
      ofType(fromPaymentMethodActions.UPDATE_PAYMENT_METHOD),
      map((action: fromPaymentMethodActions.UpdatePaymentMethodAction) => action.payload),
      switchMap(({paymentMethodId, nonce, cardholderName, zip}) => {
        return this.userService.updatePaymentMethod({paymentMethodId, nonce, cardholderName, zip})
          .pipe(
            map(({paymentMethod}: { paymentMethod: PaymentMethod }) => {
              this.notificationService.open({
                title: 'Payment method updated!',
                message: 'Your payment profile has been updated successfully.',
                type: 'success'
              });

              // We only use one payment method for user now
              this.store.dispatch(new fromPaymentMethodActions.LoadPaymentMethodsCompleteAction([paymentMethod]));
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodCompleteAction({
                id: paymentMethod._id,
                changes: paymentMethod
              }));
            }),
            catchError(reason => {
              this.store.dispatch(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
              this.notificationService.open({
                title: 'Oh oh!',
                message: reason.error.message,
                type: 'error',
              });
              return of(new fromPaymentMethodActions.UpdatePaymentMethodFailAction(reason.error));
            })
          );
      })
    );

  constructor(
    private userService: UserService,
    private notificationService: NotificationBarService,
    private actions$: Actions,
    private store: Store<any>
  ) {
  }

}

