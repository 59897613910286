import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';


// RxJS
import { Observable, throwError, iif, of } from 'rxjs';
import { tap, retryWhen, delay, concatMap } from 'rxjs/operators';

// Services
import { ErrorService } from '@app/error/services/error.service';

@Injectable()
export class HTTPErrorInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const errorService = this.injector.get(ErrorService);
    const retryPipeline =  retryWhen(errors => errors.pipe(
      concatMap((e, i) =>
        iif(
          () => i > 2,
          throwError(e),
          of(e).pipe(delay(2000)),
        )
      )
    ));

    const shouldRetry = req.headers.get('retry') === 'yes';
    const newReq = req.clone({
      headers: req.headers.delete('retry'),
    });

    if (shouldRetry) {
      return next.handle(newReq).pipe(
        retryPipeline,
        tap(
          (event: HttpEvent<any>) => {},
          (error) => {
            if (error instanceof HttpErrorResponse) {
              console.error('HttpErrorResponse Error after retrying 3 times.');
            }
          }
        ),
      );
    }

    return next.handle(newReq);
  }

}
