/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./care-cards.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./care-cards.component";
import * as i4 from "@angular/platform-browser";
var styles_CareCardsComponent = [i0.styles];
var RenderType_CareCardsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CareCardsComponent, data: {} });
export { RenderType_CareCardsComponent as RenderType_CareCardsComponent };
function View_CareCardsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "instruction-item"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_CareCardsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "care-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "plant-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "plant-image"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "care-card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "plant-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h5", [["class", "plant-scientistic-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "month-donwload"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download care card"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ul", [["class", "instruction-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CareCardsComponent_3)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_5 = _v.context.$implicit.instructions; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.image; var currVal_1 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.scientisticName; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.pdf; _ck(_v, 8, 0, currVal_4); }); }
function View_CareCardsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "month-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "month-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "month-header-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "care-cards"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CareCardsComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.plants; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_0); }); }
export function View_CareCardsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "section", [["class", "container care-cards-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "section__title care-cards__title text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Succulent Care Cards"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "lead-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Get the names, care instructions, and descriptions of your succulents! "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["href", "/assets/docs/care-guide.pdf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download our Care Guide for instructions on unboxing, repotting, and healing broken succulents"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "care-cards-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CareCardsComponent_1)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.careCardsList; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_CareCardsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-care-component", [], null, null, null, View_CareCardsComponent_0, RenderType_CareCardsComponent)), i1.ɵdid(1, 114688, null, 0, i3.CareCardsComponent, [i4.Meta, i4.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CareCardsComponentNgFactory = i1.ɵccf("sucstu-care-component", i3.CareCardsComponent, View_CareCardsComponent_Host_0, {}, {}, []);
export { CareCardsComponentNgFactory as CareCardsComponentNgFactory };
