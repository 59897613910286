import * as tslib_1 from "tslib";
import { createEntityAdapter } from '@ngrx/entity';
import * as addressActions from '@app/store/actions/address.actions';
;
export var adapter = createEntityAdapter({
    selectId: function (address) { return address._id; },
    sortComparer: false
});
export var initialState = adapter.getInitialState({
    loading: false,
    loaded: false,
    error: null
});
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case addressActions.LOAD_ADDRESSES:
        case addressActions.LOAD_ADDRESS:
        case addressActions.UPDATE_ADDRESS: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false });
        }
        case addressActions.LOAD_ADDRESSES_COMPLETE: {
            return adapter.addAll(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case addressActions.LOAD_ADDRESS_COMPLETE: {
            return adapter.addOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false, error: null }));
        }
        case addressActions.UPDATE_ADDRESS_COMPLETE: {
            return adapter.updateOne(action.payload, tslib_1.__assign({}, state, { loaded: true, loading: false }));
        }
        case addressActions.LOAD_ADDRESSES_FAIL:
        case addressActions.LOAD_ADDRESSES_COMPLETE:
        case addressActions.UPDATE_ADDRESS_FAIL: {
            return tslib_1.__assign({}, state, { error: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getAddresses = function (state) { return state.entities; };
export var getAddressesLoaded = function (state) { return state.loaded; };
export var getAddressesLoading = function (state) { return state.loading; };
export var getAddressesError = function (state) { return state.error; };
