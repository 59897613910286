<div class="page-container">
  <div class="jobs-container container">
    <div class="jobs__container">
      <div [ngClass]="{'jobs__main-image': true, 'mobile-job-details-noimg': !!selectedJob}">
        <img class="desktop-img" src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/jobs/jobs-desktop.png">
        <img class="mobile-img" src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/jobs/jobs-mobile.png">
      </div>
      <div class="jobs__job-list" *ngIf="!selectedJob">
        <h1 class="job-list__title">Openings.</h1>
        <ul class="jobs__job-list-container">
          <li class="jobs__job-list__item" *ngFor="let item of jobOpenings">
            <div class="job-list__item__details">
              <div class="job__role-title">{{item.roleTitle}}</div>
              <div class="job__role-location">
                <img src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/jobs/location.svg">
                {{item.roleLocation}}
              </div>
            </div>
            <div class="job-list__item__action">
              <a class="btn btn-primary btn-primary--with-hover" href [routerLink]="['/jobs', item.id]">
                Learn more
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div *ngIf="selectedJob" class="jobs__job-details">
        <a class="job-details__back-link" [routerLink]="['/jobs']" href="/jobs">
          <ng-container *ngTemplateOutlet="arrowLeftSvg"></ng-container>
          Back to openings
        </a>
        <div class="job-details__header">
          <div class="job-details__role-title">{{selectedJob.roleTitle}}</div>
          <div class="job-details__role-location">
            <img src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/jobs/location.svg">
            {{selectedJob.roleLocation}}
          </div>
        </div>
        <div class="job-details__action-area">
          <a href="mailto:jobs@sucstu.com" class="apply-btn btn">
            Apply now
          </a>
          Send us an email w/ resume + cover letter / whatever to <a class="email-link" href="mailto:jobs@sucstu.com">jobs@sucstu.com</a><br>
          <strong>Let's talk! :)</strong>
        </div>
        <div *ngIf="selectedJob.skills" class="job-details__skills">
          <div class="job-details__skills-title">Required skills</div>
          <ul class="skills-list">
            <ng-container *ngFor="let skill of selectedJob.skills">
              <li class="skills-list__item">{{skill}}</li>
            </ng-container>
          </ul>
        </div>
        <div *ngIf="selectedJob.responsibilities" class="job-details__responsibilities">
          <div class="job-details__responsibilities-title">Responsibilities</div>
          <ul class="responsibilities-list">
            <ng-container *ngFor="let resp of selectedJob.responsibilities">
              <li class="responsibilities-list__item">{{resp}}</li>
            </ng-container>
          </ul>
        </div>
        <div class="mobile-job-details-footer">
          <a class="btn btn-primary btn-primary--with-hover apply-now-mobile">
            Apply now
          </a>
          <a class="job-details__back-link" [routerLink]="['/jobs']" href="/jobs">
            <ng-container *ngTemplateOutlet="arrowLeftSvg"></ng-container>
            Back to openings
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #arrowLeftSvg>
  <svg
    width="20"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z"
      fill="currentColor"
    />
  </svg>
</ng-template>

