<!--<div class="lead-banner flex flex-col items-center justify-center md:flex-row md:flex-wrap">-->
<!--  <div class="w-full h-full absolute top-0 left-0 lead-darker"></div>-->

<!--  <div class="w-full md:w-4/12 join-the-club md:flex md:items-center relative">-->
<!--    <h1 class="join-the-club__text">-->
<!--      JOIN <br> THE CLUB-->
<!--    </h1>-->
<!--  </div>-->

<!--  <section class="w-full md:w-7/12 md:ml-auto md:flex md:flex-col md:justify-center lg:w-6/12 relative">-->
<!--    <div class="w-full weve-got">-->
<!--      <p class="m-0 text-white text-center weve-got__text">-->
<!--      <span class="font-semibold">-->
<!--        We've got some plan(t)s in the making ... <br> wanna come?-->
<!--      </span>-->
<!--        <br>-->
<!--        <span>-->
<!--        You should come. Drop your info below to get insider tips,-->
<!--      </span>-->
<!--      </p>-->
<!--    </div>-->

<!--    <div class="w-full off-number text-center">-->
<!--      <h2 class="off-number__6-off-title">$6 OFF</h2>-->
<!--      <p class="off-number__6-off-text text-white">and be on the road to plant happiness.</p>-->
<!--    </div>-->

<!--    <div class="w-full dont-worry text-center">-->
<!--      <p class="m-0 p-0 text-white dont-worry__text">-->
<!--        Don't worry, we won't ping you in the middle of the night when your plants are sleeping.-->
<!--        <br class="md:hidden">-->
<!--        Use <b>HELP</b> for help or <b>STOP</b> to cancel.-->
<!--      </p>-->
<!--    </div>-->

<!--    <span *ngIf="(showSuggestedEmail$ | async)" class="user-suggested-email text-center text-white mb-2">-->
<!--    Do you mean <strong (click)="saveSuggestedEmail()">{{ suggestedEmail$ | async }}</strong>?-->
<!--  </span>-->

<!--    <form class="form flex flex-col md:flex-row md:flex-wrap md:justify-center" [formGroup]="form">-->
<!--      <div class="flex w-full md:w-auto md:flex-1 md:mr-2 bg-white form__input">-->
<!--        <input formControlName="email" class="w-full" id="six-off-email" type="text" placeholder="Your email"/>-->
<!--      </div>-->

<!--      <button (click)="submitLeadForm()"-->
<!--              [disabled]="form.invalid || (form.value.email === '' && form.value.phone === '') || savingLead"-->
<!--              class="w-full love-succulents">-->
<!--        I Love Succulents-->
<!--      </button>-->
<!--    </form>-->

<!--    <div class="cursor-pointer w-full no-thanks text-center text-white">-->
<!--      <p (click)="closeLeadForm()" class="m-0 p-0 no-thanks__text">No thanks, I don't want to save $6.</p>-->
<!--    </div>-->
<!--  </section>-->
<!--</div>-->


<section class="w-full">
  <div class="bg-white bg-no-repeat flex flex-col"
       style="
          height: 32.5rem;
          width: 20.935rem;
          background-image: url(/assets/images/lead-form/race-fans/bg.png);
          background-size: 100%;
       ">

    <div class="" style="height: 60%"></div>

    <div class="text-center" style="margin-bottom: 30px">
      <h1 class="m-0"
          style="
            color: #1D2939;
            line-height: 100%;
            font-size: 78px;
            font-family: 'Colfax';
            font-weight: 800;
          ">
        $8 OFF
      </h1>
    </div>

    <div class="h-full flex flex-col">
      <p style="
          color: #171616;
          font-family: 'Colfax';
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          margin-bottom: 40px;
         "
         class="text-center">
        Welcome race fans, here’s a lil something for you :)
      </p>

      <form class="form flex flex-col mb-auto" [formGroup]="form">
        <input
          style="
            border: 0.6px solid #95A3B5;
            border-radius: 6px;
          "
          formControlName="email"
          class="w-full p-3 mb-4 text-sm"
          id="race-fans"
          type="text"
          placeholder="Your email"/>

        <button (click)="submitLeadForm()"
                [disabled]="form.invalid || (form.value.email === '' && form.value.phone === '') || savingLead"
                style="
                  border-radius: 6px;
                  background: #1D2939;
                "
                class="w-full text-white py-3 px-4 font-medium">
          Get $8 OFF
        </button>
      </form>

      <div class="cursor-pointer w-full text-center text-white" style="margin-bottom: 12px">
        <p (click)="closeLeadForm()"
           style="color: #96A0B9"
           class="m-0 p-0 text-xs">
          No thanks, I don’t like discounts.
        </p>
      </div>
    </div>
  </div>
</section>