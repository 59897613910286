import { Action } from '@ngrx/store';

import { AccountInfo, Address, Order, OrderAmounts, PaymentInfo, Recipient } from '../../models/order.model';

export enum OrderActionTypes {
  ToggleTerms = '[Checkout/Order] Toggle Terms',
  SetRecipients = '[Checkout/Order] Set Recipients',
  SetPrepayFlag = '[Checkout/Order] Set Prepay Flag',
  SetAccountData = '[Checkout/Order] Set Account Data',
  ApplyClaimCode = '[Checkout/Order] Apply Claim Code',
  SetInitialDate = '[Checkout/Order] Set Initial Date',
  SetMainRecipient = '[Checkout/Order] Set Main Recipient',
  SetBillingAddress = '[Checkout/Order] Set Billing Address',
  SetPaymentData = '[Checkout/Order] Save Payment Info Data',
  SetSecondaryRecipient = '[Checkout/Order] Set Secondary Recipient',
  UpdateOrderAmounts = '[Checkout/Order] Update Order Amounts',
  SaveCardInfo = '[Checkout/Order] Save Card Info',
  SaveAccountInfo = '[Checkout/Order] Save Account Info',
  SaveRecipientsInfo = '[Checkout/Order] Save Recipients Info',
}


export class SaveAccountInfo implements Action {
  readonly type = OrderActionTypes.SaveAccountInfo;
  constructor(public payload: AccountInfo) { }
}

export class SetAccountData implements Action {
  readonly type = OrderActionTypes.SetAccountData;
  constructor(public payload: Partial<AccountInfo>) { }
}

export class SetBillingAddress implements Action {
  readonly type = OrderActionTypes.SetBillingAddress;
  constructor(public payload: Address) { }
}

export class SaveCardInfo implements Action {
  readonly type = OrderActionTypes.SaveCardInfo;
  constructor(public payload: PaymentInfo) { }
}

export class SetPaymentData implements Action {
  readonly type = OrderActionTypes.SetPaymentData;
  constructor(public payload: PaymentInfo) { }
}

export class ApplyClaimCode implements Action {
  readonly type = OrderActionTypes.ApplyClaimCode;
  constructor(public payload: string) { }
}

export class ToggleTerms implements Action {
  readonly type = OrderActionTypes.ToggleTerms;
  constructor() { }
}

export class SetInitialDate implements Action {
  readonly type = OrderActionTypes.SetInitialDate;
  constructor(public payload: Date) { }
}

export class SetMainRecipient implements Action {
  readonly type = OrderActionTypes.SetMainRecipient;
  constructor(public payload: Partial<Recipient>) { }
}

export class SetSecondaryRecipient implements Action {
  readonly type = OrderActionTypes.SetSecondaryRecipient;
  constructor(public payload: Partial<Recipient>) { }
}

export class SetPrepayFlag implements Action {
  readonly type = OrderActionTypes.SetPrepayFlag;
  constructor(public payload: boolean) { }
}

export class SetRecipients implements Action {
  readonly type = OrderActionTypes.SetRecipients;
  constructor(public payload: {
    main: Recipient,
    secondary: Recipient,
  }) { }
}

export class SaveRecipientsInfo implements Action {
  readonly type = OrderActionTypes.SaveRecipientsInfo;
  constructor(public payload: {
    main: Recipient,
    secondary?: Recipient,
  }) { }
}

export class UpdateOrderAmounts implements Action {
  readonly type = OrderActionTypes.UpdateOrderAmounts;
  constructor(public payload: Partial<OrderAmounts>) { }
}

export type OrderActionsUnion =
  | SetAccountData
  | SetBillingAddress
  | SetPaymentData
  | ApplyClaimCode
  | ToggleTerms
  | SetInitialDate
  | SetMainRecipient
  | SetSecondaryRecipient
  | SetRecipients
  | SetPrepayFlag
  | SaveAccountInfo
  | SaveCardInfo
  | SaveRecipientsInfo
  | UpdateOrderAmounts;
