import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Add GTM script to <head>
const gtmHeadScript = document.createElement('script');
const googleOptimizeAntiFlickerSnippet = document.createElement('script');
const gtmBodyScript = document.createElement('noscript');

const gtmBodyIframe = document.createElement('iframe');
gtmBodyIframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtmContainerId}`;
gtmBodyIframe.height = '0';
gtmBodyIframe.width = '0';
gtmBodyIframe.style.display = 'none';
gtmBodyIframe.style.visibility = 'hidden';

gtmBodyScript.appendChild(gtmBodyIframe);

gtmHeadScript.onerror = function (e) {
  console.error(e);
};
gtmHeadScript.innerHTML = `(function (w, d, s, l, i) {
  try {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.onerror = function(e) { console.error(e) }; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  } catch(e) {
    console.error(e)
  }
})(window, document, 'script', 'dataLayer', '${environment.gtmContainerId}');`;


googleOptimizeAntiFlickerSnippet.innerHTML = `
(function (a, s, y, n, c, h, i, d, e) {
  try {
    s.className += ' ' + y;
    h.start = 1 * new Date;
    h.end = i = function () {
      s.className = s.className.replace(RegExp(' ?' + y), '')
    };
    (a[n] = a[n] || []).hide = h;
    setTimeout(function () {
      i();
      h.end = null
    }, c);
    h.timeout = c;
  } catch(e) {
    console.error(e)
  }
})(window, document.documentElement, 'async-hide', 'dataLayer', 4000, { '${environment.gtmContainerId}': true });
`;

document.head.appendChild(gtmHeadScript);
document.head.appendChild(googleOptimizeAntiFlickerSnippet);
document.body.appendChild(gtmBodyScript);

const addAssuranceSite = () => {
  try {
    const script = document.createElement('script');
    script.src = 'https://assurance.mimtid.com/adstart/t.js?c=4EF238BDE8-site';
    script.type = 'text/javascript';

    new Promise<void>((resolve, reject) => {
      script.onload = () => {
        resolve();
      };
      script.onerror = (error: any) => {
        reject(error);
      };
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  } catch (error) {
    console.log('Error: ', error);
  }
}

if (environment.production) {
  enableProdMode();
  addAssuranceSite();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
