import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EmailValidator } from './../../../shared/validators/email.validator';

import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'sucstu-lead-banner',
  styleUrls: ['./lead-banner.component.scss'],
  templateUrl: './lead-banner.component.html'
})
export class LeadBannerComponent implements AfterViewInit {

  @ViewChild('banner') banner: ElementRef;
  @ViewChild('bannerContainer') bannerContainer: ElementRef;

  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<string> = new EventEmitter<string>();

  form = this.fb.group({
    email: ['', EmailValidator.checkEmail]
  });

  constructor(
    private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) { }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(document, 'click')
        .pipe(
          map((e: MouseEvent) => `.${(e as any).target.classList[0]}`),
          filter((className: string) => !this.bannerContainer.nativeElement.querySelector(className)),
        )
        .subscribe((className: string) => {
          this.closeBanner();
        });
    }
  }

  closeBanner() {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(this.document.body, 'fixed-body');
    }
    this.close.emit();
  }

  submitEmail() {
    const email = this.form.get('email').value;
    if (email.length && !this.isFieldInvalid('email', 'invalidEmail')) {
      this.submit.emit(email);
      this.close.emit();
    }
  }

  isFieldInvalid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }
}
