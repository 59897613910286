import { Component, OnInit } from '@angular/core';
import {ModalService} from '@app/core/services';
import {GmailModalService} from '@app/core/services/gmail-modal.service';

@Component({
  selector: 'app-gmail-modal',
  templateUrl: './gmail-modal.component.html',
  styleUrls: ['./gmail-modal.component.scss']
})
export class GmailModalComponent implements OnInit {
  showGmailModal$ = this.gmailModalService.showGmailModal$;

  constructor(
    private readonly gmailModalService: GmailModalService
  ) { }

  ngOnInit() {
  }

  okClose() {
    this.gmailModalService.showGmailModal$.next(false)
  }

}
