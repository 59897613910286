import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import * as subscriptionActions from '@app/store/actions/subscription.action';

// models
import { Subscription } from '@app/core/models/subscription.model';

export interface Entities {
  [id: string]: Subscription
}

export interface State extends EntityState<Subscription> {
  loading: boolean,
  loaded: boolean,
  error: any,
  selected: number | null
}

export const adapter: EntityAdapter<Subscription> = createEntityAdapter<Subscription>({
  selectId: (susbcription: Subscription) => susbcription._id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  selected: null
});

export function reducer(
  state: State = initialState,
  action: subscriptionActions.Actions): State {
  switch (action.type) {

    // show is loading when:
    case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS:
    case subscriptionActions.CANCEL_SUBSCRIPTION:
    case subscriptionActions.CANCEL_GIFT_IT:
    case subscriptionActions.GIFT_IT_SUBSCRIPTION:
    case subscriptionActions.TURN_OFF_SUBSCRIPTION:
    case subscriptionActions.TURN_ON_SUBSCRIPTION:
    case subscriptionActions.RE_ACTIVATE_SUBSCRIPTION:
    case subscriptionActions.SKIP_IT_SUBSCRIPTION:
    case subscriptionActions.UN_SKIP_SUBSCRIPTION:
    case subscriptionActions.UNSELECT_BOX:
    case subscriptionActions.UPDATE_SUBSCRIPTION: {
      const id = action.payload.subscriptionId;
      const changes = <Update<Subscription>>{
        id,
        changes: { isLoading: true }
      }
      return adapter.updateOne(changes, {
        ...state,
        loaded: true,
        loading: false
      });
    }

    case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS:
    case subscriptionActions.UPDATE_SUBSCRIPTION:
    case subscriptionActions.UPDATE_SUBSCRIPTION_NBD:
    case subscriptionActions.LOAD_SUBSCRIPTION:
    case subscriptionActions.LOAD_SUBSCRIPTIONS:
    case subscriptionActions.ADD_SUBSCRIPTION: {
      return { ...state, loading: true, loaded: false };
    }

    case subscriptionActions.LOAD_SUBSCRIPTIONS_COMPLETE: {
      return adapter.addAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        error: null
      });
    }

    case subscriptionActions.LOAD_SUBSCRIPTION_COMPLETE:
    case subscriptionActions.ADD_SUBSCRIPTION_COMPLETE: {
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false
      });
    }

    case subscriptionActions.UPDATE_SUBSCRIPTION_NBD_COMPLETE:
    case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS_COMPLETE:
    case subscriptionActions.UPDATE_SUBSCRIPTION_COMPLETE: {
      return adapter.updateOne(action.payload, {
        ...state,
        loaded: true,
        loading: false
      });
    }

    case subscriptionActions.UPDATE_SUBSCRIPTIONS_COMPLETE: {
      return adapter.updateMany(action.payload, {
        ...state,
        loaded: true,
        loading: false
      });
    }

    case subscriptionActions.UPDATE_SUBSCRIPTION_NBD_FAIL:
    case subscriptionActions.LOAD_SUBSCRIPTIONS_FAIL:
    case subscriptionActions.LOAD_SUBSCRIPTION_FAIL:
    case subscriptionActions.ADD_SUBSCRIPTION_FAIL:
    case subscriptionActions.UNSELECT_BOX_FAIL:
    case subscriptionActions.UPDATE_SUBSCRIPTION_ADDRESS_FAIL:
    case subscriptionActions.UPDATE_SUBSCRIPTION_FAIL: {
      return { ...state, error: action.payload };
    }

    case subscriptionActions.SELECT_SUBSCRIPTION: {
      return { ...state, selected: action.payload };
    }

    case subscriptionActions.DESELECT_SUBSCRIPTION: {
      return { ...state, selected: null };
    }

    default: {
      return state;
    }
  }
}

export const getSubscriptions = (state: State) => state.entities;
export const getSubscriptionLoaded = (state: State) => state.loaded;
export const getSubscriptionLoading = (state: State) => state.loading;
export const getSubscriptionError = (state: State) => state.error;
export const getSubscriptionSelected = (state: State) => state.selected;
