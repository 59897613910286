import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var GmailModalService = /** @class */ (function () {
    function GmailModalService() {
        this.showGmailModal$ = new BehaviorSubject(false);
    }
    GmailModalService.ngInjectableDef = i0.defineInjectable({ factory: function GmailModalService_Factory() { return new GmailModalService(); }, token: GmailModalService, providedIn: "root" });
    return GmailModalService;
}());
export { GmailModalService };
