import { isPlatformBrowser } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
// selectors
import * as fromRoot from '@app/store/selectors';
// actions
import * as fromNotificationBar from '@app/store/actions/notification-bar.actions';
import { take } from 'rxjs/operators';
var NotificationBarService = /** @class */ (function () {
    function NotificationBarService(store, platformId) {
        this.store = store;
        this.platformId = platformId;
        this._dimensions$ = new BehaviorSubject({ width: 0, height: 0 });
        this.dimensions$ = this._dimensions$.asObservable();
        this._close$ = new Subject();
        this.close$ = this._close$.asObservable();
        this.data$ = store.pipe(select(fromRoot.getNotificationBarState));
    }
    NotificationBarService.prototype.open = function (_a) {
        var title = _a.title, message = _a.message, _b = _a.type, type = _b === void 0 ? 'notification' : _b, _c = _a.mode, mode = _c === void 0 ? 'success' : _c;
        if (isPlatformBrowser(this.platformId)) {
            this.store.dispatch(new fromNotificationBar.OpenNotificationBarAction({ title: title, message: message, type: type, mode: mode }));
        }
    };
    NotificationBarService.prototype.close = function () {
        var _this = this;
        this.data$
            .pipe(take(1))
            .subscribe(function (data) { return _this._close$.next(data); });
        this.store.dispatch(new fromNotificationBar.CloseNotificationBarAction());
    };
    NotificationBarService.prototype.getDimensions = function () {
        return this._dimensions$.value;
    };
    NotificationBarService.prototype.setDimensions = function (_a) {
        var width = _a.width, height = _a.height;
        this._dimensions$.next({ width: width, height: height });
    };
    return NotificationBarService;
}());
export { NotificationBarService };
