/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inspiration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng-masonry-grid";
import * as i4 from "../../../../../node_modules/ng-masonry-grid/ng-masonry-grid.ngfactory";
import * as i5 from "ngx-infinite-scroll";
import * as i6 from "@angular/router";
import * as i7 from "./inspiration.component";
import * as i8 from "../../../app.config";
import * as i9 from "@angular/platform-browser";
import * as i10 from "@angular/common/http";
var styles_InspirationComponent = [i0.styles];
var RenderType_InspirationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InspirationComponent, data: {} });
export { RenderType_InspirationComponent as RenderType_InspirationComponent };
function View_InspirationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ng-masonry-grid-item", [["class", "grid-item"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "grid-item--width-2": 0 }), i1.ɵdid(3, 4407296, null, 0, i3.NgMasonryGridDirective, [i1.ElementRef, i3.NgMasonryGridComponent, i1.PLATFORM_ID, i3.NgMasonryGridService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onImageLoaded(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_0 = "grid-item"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit.widthSpan === 2)); _ck(_v, 1, 0, currVal_0, currVal_1); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.url); _ck(_v, 4, 0, currVal_2); }); }
function View_InspirationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "ng-masonry-grid", [["class", "inspirations-pictures-container"], ["infiniteScroll", ""]], null, null, null, i4.View_NgMasonryGridComponent_0, i4.RenderType_NgMasonryGridComponent)), i1.ɵdid(2, 1294336, null, 0, i3.NgMasonryGridComponent, [i1.PLATFORM_ID, i1.ElementRef, i3.NgMasonryGridService], { masonryOptions: [0, "masonryOptions"], useImagesLoaded: [1, "useImagesLoaded"] }, null), i1.ɵdid(3, 4866048, null, 0, i5.InfiniteScrollDirective, [i1.ElementRef, i1.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], infiniteScrollThrottle: [1, "infiniteScrollThrottle"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "ng-masonry-grid-item", [["class", "grid-sizer"]], null, null, null, null, null)), i1.ɵdid(5, 4407296, null, 0, i3.NgMasonryGridDirective, [i1.ElementRef, i3.NgMasonryGridComponent, i1.PLATFORM_ID, i3.NgMasonryGridService], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InspirationComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.masonryOpts; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = 100; var currVal_3 = 500; _ck(_v, 3, 0, currVal_2, currVal_3); _ck(_v, 5, 0); var currVal_4 = _co.images; _ck(_v, 7, 0, currVal_4); }, null); }
export function View_InspirationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { inspirationsContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "page-container container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["inspirationsContainer", 1]], null, 16, "div", [["class", "inspiration-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 15, "div", [["class", "inspiration-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ideas + Inspiration"])), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "text-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Huddle 'em together as a centerpiece, line 'em up along your windowsills, or cover your entire desk with 'em. Your succulent subscription box is an excuse to exercise your green thumb AND your interior design skills."])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "text-bolder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Need a little inspiration?"])), (_l()(), i1.ɵted(-1, null, [" Check out some ideas from our succulent subscribers!"])), (_l()(), i1.ɵeld(14, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ready to join our succulent-loving club? "])), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [["routerLink", "/checkout"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "text-bolder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Subscribe today!"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InspirationComponent_1)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/checkout"; _ck(_v, 17, 0, currVal_2); var currVal_3 = (_co.showmsnry && _co.isBrowser); _ck(_v, 21, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 17).target; var currVal_1 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_0, currVal_1); }); }
export function View_InspirationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-inspiration", [], null, null, null, View_InspirationComponent_0, RenderType_InspirationComponent)), i1.ɵdid(1, 4440064, null, 0, i7.InspirationComponent, [i8.APP_CONFIG, i9.Meta, i9.Title, i10.HttpClient, i1.PLATFORM_ID, i2.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InspirationComponentNgFactory = i1.ɵccf("sucstu-inspiration", i7.InspirationComponent, View_InspirationComponent_Host_0, {}, {}, []);
export { InspirationComponentNgFactory as InspirationComponentNgFactory };
