import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'sucstu-care-component',
  styleUrls: ['./care-cards.component.scss'],
  templateUrl: 'care-cards.component.html'
})
export class CareCardsComponent implements OnInit {
  /* tslint:disable */
  careCardsList = [
    {
      name: 'August',
      plants: [
        {
          name: `JELLY BEAN PLANT`,
          scientisticName: `SEDUM RUBROTINCTUM`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/JELLY_BEAN_PLANT.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/JELLY_BEAN_PLANT.pdf',
          instructions: [
            `Shrub succulent that gets its name from its cute, jelly bean-shaped leaves that are bright green and can blush red`,
            `Grows small yellow flowers in summertime`,
            `Appreciates infrequent watering; every 2 to 3 weeks is sufficient`,
            `Thrives in partial to full sun`,
            `Propagates easily by leaves or cuttings`,
            `Not Pet Safe`,
          ]
        },
        {
          name: `OCTOPUS AGAVE`,
          scientisticName: `AGAVE VILMORINIANA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/OCTOPUS_AGAVE.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/OCTOPUS_AGAVE.pdf',
          instructions: [
            `A stunning succulent with tentacle-like leaves, perfect for adding a unique flair to your garden`,
            `Water sparingly; allow the soil to dry out completely between waterings`,
            `Thrives in full sun but can tolerate partial shade`,
            `Best propagated by removing and planting offsets (pups) that form around the base of the plant`,
            `Mildly toxic to humans and pets; sap may cause skin irritation`
          ]
        },
      ]
    },
    {
      name: 'June',
      plants: [
        {
          name: `SPRINGTIME`,
          scientisticName: `CRASSULA SPRINGTIME`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/springtime.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/springtime.pdf',
          instructions: [
            `A slow growing succulent with thick, grey-green triangular leaves that are densely packed around a thin stem`,
            `Thoroughly water only when the soil is completely dry. Overwatering can predispose the plant to rot and other fungal diseases`,
            `Prefers plenty of bright, filtered light`,
            `Easily propagates from stem cuttings, or twisting leaves from main stem`,
            `Can be mildly toxic`,
          ]
        },
        {
          name: `VARIEGATED RAINBOW BUSH`,
          scientisticName: `PORTULACARIA AFRA F. VERIEGATA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/variegated.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/variegated.pdf',
          instructions: [
            `A slow-growing succulent shrub with many small, round variegated leaves, which are a pretty pale green, white, and pink in color`,
            `Water throughly every 2 weeks`,
            `Crave bright, indirect sunlight`,
            `Stems propagate easily as cuttings`,
            `Typically not toxic for pets`
          ]
        },
      ]
    },
    {
      name: 'May',
      plants: [
        {
          name: `Donkey's Tail`,
          scientisticName: `Sedum Burrito`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/JAN_DonkeyTail.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/JAN_DonkeyTail.pdf',
          instructions: [
            `Cute, pendant succulent with tightly stacked, icy green leaves that can have pointed or round tips`,
            `Water once every 2-3 weeks`,
            `Grows best in bright, indirect sunlight`,
            `Easily propagates by cuttings or leaves`,
            `Pet safe`,
          ]
        },
        {
          name: `MOONSTONES`,
          scientisticName: `PACHYPHYTUM OVIFERUM`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/MOONSTONES.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/MOONSTONES.pdf',
          instructions: [
            `Adorably plump succulent with smooth, round leaves that rang in color from opalescent blue to pale pink`,
            `Needs a good soak every couple weeks in winter and once a month in the hotter months`,
            `Appreciates bright, indirect sunlight`,
            `Leaves are fragile and can fall off when touched, but they'll propagate easily`,
            `Pet safe`
          ]
        },
      ]
    },
    {
      name: 'April',
      plants: [
        {
          name: `ICE PLANT`,
          scientisticName: `CORPUSCULARIA LEHMANNII`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/ICE_PLANT.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/ICE_PLANT.pdf',
          instructions: [
            `Renowned for its distinctive ice-like appearance due to its densely packed, glistening, translucent leaves that resemble tiny ice crystals`,
            `Water sparingly, allowing the soil to dry out completely between waterings. Overwatering can lead to root rot`,
            `Thrives in bright sunlight`,
            `Easily propagated from stem or lead cuttings`,
            `Typically non-toxic to humans and pets`,
          ]
        },
        {
          name: `Roger`,
          scientisticName: `Crassula Rogersii`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/ROGER.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/ROGER.pdf',
          instructions: [
            `A small succulent bush with unusual, velvety green leaves that grow pale yellow flowers in clusters at the end of its long stems`,
            `Only water when soil it completely dry`,
            `Prefers direct sunlight in order to keep its leaf tips a reddish hue`,
            `Propagates easily by offshoots and leave`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'March',
      plants: [
        {
          name: `String Of Pearls`,
          scientisticName: `Curio Rowleyanus`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/string-1.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/string-1.pdf',
          instructions: [
            `Popular string succulent that grows small, bright green 'pearls' along cascading stems`,
            `Blooms small white flowers in summer`,
            `Only needs water once or twice a month`,
            `Appreciates bright, partial to full sunlight`,
            `Easily propagated by cuttings, just prune off a stem and nestle it in moist cactus oil, spraying once every couple days`,
            `Can be toxic - keep away from curios pets and kiddos`
          ]
        },
        {
          name: `Blowsfish Barbara`,
          scientisticName: `Mammillaria Spinosissima F. Rubrispinaurrito`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/blowsfish_front.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/blowsfish.pdf',
          instructions: [
            `Cute, cylindrical cactus with spines that range from orange-red to white`,
            `Needs water once every couple weeks in summer months, and little to no water in winter`,
            `Craves bright, full sun`,
            `Mature plants will bloom bright purple flowers in late winter and early spring`,
            `Wear gloves or use tongs when handling to avoid getting poked`
          ]
        },
      ]
    },
    {
      name: 'February',
      plants: [
        {
          name: `ECHEVERIA ROSY`,
          scientisticName: `ECHEVERIA PERLE VON NUREMBERG`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/echeveria_rosy.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/echeveria_rosy.pdf',
          instructions: [
            `A lovely small, shrubby succulent with thick stems, tipped with small rosettes of green spathulate, fuzzy leaves`,
            `Water once a month, or whenever soil is completely dry`,
            `Prefers partial to full sunlight`,
            `Propagates easily from leaf cutting`,
            `Typically not toxic for pets`,
          ]
        },
        {
          name: `CRINKLE-LEAF PLANT`,
          scientisticName: ``,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/CRINKLE_LEAF_PLANT.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/CRINKLE_LEAF_PLANT.pdf',
          instructions: [
            `A rare and unusual succulent with distinctive, chunky, wedge-shaped leaves with wavy edges. A light coating of short hairs on the foliage gives a silvery sheen to the blue-green leaves`,
            `Thrives in full sun to partial shade`,
            `Water every 7-14 days, when the soil is totally dry`,
            `Easily propagated from its leaves by gently twisting from the stem`,
            `Typically not toxic`
          ]
        },
      ]
    },
    {
      name: 'January',
      plants: [
        {
          name: `Star Cactus`,
          scientisticName: `Haworthia retusa`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/star_cactus.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/star_cactus.pdf',
          instructions: [
            `A cute, slow-growing succulent with thick, pointed leaves that grow small white flowers in late spring and early summer`,
            `Water when soil is completely dry`,
            `Prefers bright, indirect light. Can sunburn easily so avoid direct light`,
            `Propagates easily from its many offshoots`,
            `Typically not toxic`
          ]
        },
        {
          name: `Tiger's Jaw`,
          scientisticName: `Faucaria tigrina`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/tigers_jaw.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/tigers_jaw.pdf',
          instructions: [
            `A small clump-forming succulent, with soft, white "teeth" along the edges`,
            `Water only every 3 weeks, when leaves start to feel rubbery`,
            `Craves bright sunlight, though partial shade is recommenced in extreme heat`,
            `Propagate by removing a rosette, allowing it to callous for a day and then replanting it`,
            `Typically not toxic to pets`
          ]
        },
      ]
    },
    {
      name: 'December',
      plants: [
        {
          name: `LAVENDER SCALLOPS`,
          scientisticName: `KALANCHOE FEDTSCHENKOI`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/LAVENDER_SCALLOPS.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/LAVENDER_SCALLOPS.pdf',
          instructions: [
            `Native to Madagascar, she is a flowering succulent with flat, pale green leaves that have scalloped pink edges`,
            `Requires water when soil is completely dry; typically every 2-3 weeks`,
            `Loves direct, bright sunshine, but also thrives under a grow lamp`,
            `Can be easily propagated from her leaves, as well as baby plantlets that form on the leaf's edges`,
            `Can be toxic to pets`,
          ]
        },
        {
          name: 'GEORGE SEDUM',
          scientisticName: `SEDUM CLAVATUM`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/GEORGE_SEDUM.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/GEORGE_SEDUM.pdf',
          instructions: [
            `Grows beautiful blue green rosettes up to 2 inches wide, and I'll bloom white, star-shaped flowers in late spring / early summer`,
            `Water once every other week when soil totally is dry`,
            `Enjoys lots of direct sunlight, though will also thrive in partial sun`,
            `Leaves propagate easily into bb pups`,
            `Not typically toxic to pets`,
          ]
        },
      ]
    },
    {
      name: 'November',
      plants: [
        {
          name: `MINI DESERT ROSE`,
          scientisticName: `TRICHODIADEMA DENSUM`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/MINI_DESERT_ROSE.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/MINI_DESERT_ROSE.pdf',
          instructions: [
            `This cactus-like succulent has stiff hairs that grow on the leaves while the stem is woody.  She flowers vivid pink flowers in late winter`,
            `Regular watering but lighter than other plants during the winter and spring - reduce watering in summer`,
            `Thrives best in full sun`,
            `Propagates from stem cuttings`,
            `Typically not toxic`,
          ]
        },
        {
          name: 'OLIVIA',
          scientisticName: `GRAPTOVERIA OLIVIA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/OLIVIA.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/OLIVIA.pdf',
          instructions: [
            `Gorgeous olive green rosette succulent with a pink flush to its leaf margins and a bronze sheen`,
            `Water deeply enough for water to run out the drainage hole, then wait for the soil to fully dry before watering again`,
            `Prefers full sun, bright indoor light`,
            `Propagates easily by single leafs from the mother plant`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'October',
      plants: [
        {
          name: `CHOCOLATE PANDA`,
          scientisticName: `KALANCHOE TOMENTOSA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/chocolate-panda-front.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/chocolate-panda.pdf',
          instructions: [
            `Dark green, velvety succulent with long leaves that have brown spots on the edges and tips`,
            `Water once every 2 weeks in winter and once a month in summer`,
            `Loves full sun but can tolerate partial shade`,
            `Propagates easily by leaf cuttings`,
            `Can be toxic`,
          ]
        },
        {
          name: 'BEAR\'S PAW',
          scientisticName: `COTYLEDON TOMENTOSA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/bear-front.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/bears_paw.pdf',
          instructions: [
            `Rare shrub succulent with fuzzy green leaves that are tipped with tiny "claws"`,
            `Water every couple weeks in summer, and only about once a month in winter`,
            `Loves bright, indirect sunlight`,
            `Leaves are sensitive, so this succulent will propagate best by cuttings`,
            `Pet safe`,
          ]
        },
      ]
    },
    {
      name: 'September',
      plants: [
        {
          name: `CAMPFIRE CRASSULA`,
          scientisticName: `CRASSULA CAPITELLA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/campfire_crassula.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/campfire_crassula.pdf',
          instructions: [
            `Unique, branching succulent with green leaves that are tipped in bright red`,
            `Grows clusters of white flowers in summer`,
            `Water once every 7-10 days`,
            `Thrives in partial to full sunlight`,
            `Propagates best by offshoots`,
            `Not pet safe`,
          ]
        },
        {
          name: 'LILY PAD',
          scientisticName: `AEONIUM HYBRID`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/lily-pad.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/lily-pad.pdf',
          instructions: [
            `A perennial succulent that forms flat rosettes of thick, waxy, bright green leaves, and grows offsets easily around its base`,
            `Water sparingly to prevent root rot, using well-draining soil`,
            `Prefers bright, direct sunlight`,
            `Propagates easily from offsets growing around the main plant`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'August',
      plants: [
        {
          name: `SNOW PEAS`,
          scientisticName: `SENECIO CRASSISSIMUS HUMBERT`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/snow-peas.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/snow-peas.pdf',
          instructions: [
            `A striking succulent known for its eye-catching, upright growth habit and thick, triangular leaves that have a silvery-gray hue, and are covered in fine, white hairs`,
            `Allow the soil to dry out partially between watering, making sure the excess water drains from the pot to prevent root rot`,
            `Thrives in bright, indirect sunlight`,
            `Propagate through stem cuttings`,
            `Can be mildly toxic`,
          ]
        },
        {
          name: 'TOM\'STHUMB',
          scientisticName: `CRASSULA TOM'S THUMB`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/toms-thumb.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/toms-thumb.pdf',
          instructions: [
            `Known for its compact size, unique rosette shape, and attractive plump leaves that are often have a gray-green hue`,
            `Provide bright,indirect sunlight to maintain its vibrant appearance. Avoid exposing it to direct sunlight for extended periods, as this can lead to sunburn and damage the leaves`,
            `Water sparingly, allowing the soil to dry out completely between watering to avoid root rot`,
            `Propagate with single leaves or stem cuttings`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'July',
      plants: [
        {
          name: `Kalanchoe 'FANG'`,
          scientisticName: `Kalanchoe Beharensis`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/fang-A.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/fang-A.pdf',
          instructions: [
            `A tall, fuzzy succulent with velvety silver leaves, with the undersides covered in small tubercles, or "fangs". Goes dormant in the summer months`,
            `Water every 7-10 days as needed when soil is dry`,
            `Prefers full sun to partial shade`,
            `Leaves or stem cuttings can be easily propagated`,
            `Can be toxic`,
          ]
        },
        {
          name: 'Little Jewel',
          scientisticName: `Pachyphyntum Compactum`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/Little-J.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/LITTLE-JEWEL-A.pdf',
          instructions: [
            `Resembles a cut gemstone with angular facets left by the pressure of neighboring leaves that are a matte blue-green but cas show tinges of violet when gently stressed by full sun`,
            `Water every 2 weeks as needed when soil is dry`,
            `Thrives in bright indoor light filtered / partial sun`,
            `Propagates easily from stem cuttings and leaf propagation from any fallen leaves in shipping`,
            `Typically not toxic for pets`,
          ]
        },
      ]
    },
    {
      name: 'June',
      plants: [
        {
          name: `SMALL RED CARPET`,
          scientisticName: `CRASSULA PUBESCENS SSP. RADICANS`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/SMALL-RED-CARPET.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/SMALL-RED-CARPET.pdf',
          instructions: [
            `A bushy, ground cover stonecrop with oval leaves that transition from green to red when exposed to bright sun, and bloom in late winter or spring with clusters of tiny white blossoms`,
            `Water deeply enough for water to run out of the drainage hole, then wait for the soil to fully dry before watering again`,
            `Prefers bright, indirect sunlight`,
            `Propagates best by cuttings - will grow small offshoots from the stem`,
            `Parts of plant can be toxic`,
          ]
        },
        {
          name: `DWARF ELEPHANT BUSH`,
          scientisticName: `PORTULACARIA AFRA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/DWARF-ELEPHANT-BUSH.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/DWARF-ELEPHANT-BUSH.pdf',
          instructions: [
            `A large, bushing succulent with woody stems, perfect for an indoor or outdoor garden. It can also be used in hanging baskets to add "spiller'`,
            `Water throughly every 2 weeks`,
            `Crave bright, indirect sunlight`,
            `Stems propagate easily as cuttings`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'May',
      plants: [
        {
          name: `SUNBURST`,
          scientisticName: `AEONIUM SUNBURST`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/SUNBURST_PLANT.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/SUNBURST_PLANT.pdf',
          instructions: [
            `An award-winning Aeonium with creamy yellow and green striped leaves, whose tips turn coppery-red when exposed to sun`,
            `Regular watering whenever soil is`,
            `Thrives in partial sun, and is sensitive to extreme heat`,
            `Can be propagate by offshoots and cut leaves`,
            `Typically not toxic`,
          ]
        },
        {
          name: `PINK ICEPLANT`,
          scientisticName: `OSCULARIA DETOIDES`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/ICE_PLANT.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/ICE_PLANT.pdf',
          instructions: [
            `A low-growing, versatile succulent with gorgeous dark pink stems and angled green leaves that have little teeth on them, and bloom large purple flowers. Great for hanging baskets!`,
            `Water when the soil is completely dry`,
            `Tolerant of full sun and high temps`,
            `Propagates from stem cuttings of any length`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'April',
      plants: [
        {
          name: `WATCH CHAIN`,
          scientisticName: `CRASSULA MUSCOSA`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/WATCH.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/WATCH.pdf',
          instructions: [
            `Super unique succulent with tiny green leaves that are tightly stacked around long stems, giving the nickname "Watch Chain"`,
            `Water once very 2 weeks`,
            `Grows best in partial shade`,
            `Can easily be cut back and propagated`,
            `Can be toxic to animals`,
          ]
        },
        {
          name: `DRUNKARD'S DREAM`,
          scientisticName: `HATIORA SALICORNIOIDES`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/DRUNK.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/DRUNK.pdf',
          instructions: [
            `A bushy cactus with long, segmented stalks that can grow up to two feet tall, and produce beautiful yellow flowers at the top`,
            `Prefers to be kept evenly moist but not soggy. Water them when the top inch of soil is dry. Avoid letting them sit in standing water as this can lead to root rot`,
            `Best thrives in bright, indirect light`,
            `Can be propagated through stem cuttings, orby twisting a "bottle cap" off from a stem`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'March',
      plants: [
        {
          name: `Gold Moss`,
          scientisticName: `Sedum Acre`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/GOLD_MOSS.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/GOLD_MOSS.pdf',
          instructions: [
            `A low-maintenance succulent known for its bright green, star-shaped foliage and its ability to thrive in a range of light conditions`,
            `Water sparingly, allowing the soil to dry out completely before watering again. Over-watering can lead to root rot`,
            `Thrives in bright, indirect light. It can also tolerate some shade`,
            `Propagate by dividing the plant or by taking stem cuttings`,
            `Typically not toxic to humans or pets`,
          ]
        },
        {
          name: `Coppertone Hybrid`,
          scientisticName: `Sedum Nussbaumerianum`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/COPPERTONE_HYBRID.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/COPPERTONE_HYBRID.pdf',
          instructions: [
            `Low-growing rosette succulent with slender, pointed leaves that will turn from green to fiery red-orange in bright light or cool temperatures`,
            `Needs a good drench once every 2-3 weeks`,
            `Blooms bunches of tiny white flowers in late winter to early spring`,
            `Thrives in partial to full sun`,
            `Propagates easily by leaves or cuttings`,
            `Pet safe`,
          ]
        },
      ]
    },
    {
      name: 'February',
      plants: [
        {
          name: `Bruce ‘Blue’ Spruce`,
          scientisticName: `Sedum Rupestre`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/BRUCE_SPRUCE.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/BRUCE_SPRUCE.pdf',
          instructions: [
            `A ground-covering succulent that gets its name from its beautiful, needle-like leaves`,
            `Water sparingly, allowing the soil to dry out slightly between watering. Overwatering can cause the roots to rot`,
            `Prefers full sun to partial shade`,
            `Can be propagated from stem cuttings or by dividing the rosettes`,
            `Typically not toxic to pets`,
          ]
        },
        {
          name: `String of Bananas`,
          scientisticName: `Senecio Radicans`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/BANANA_FRONT.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/BANANA_FRONT.pdf',
          instructions: [
            `Beautiful vine succulent with bright green leaves that are shaped like little bananas`,
            `Water thoroughly every couple weeks`,
            `Loves bright, indirect sunlight`,
            `Best propagated by cuttings, just prune off a stem and nestle it in moist cactus soil, spraying once every couple davs`,
            `Pet safe`,
          ]
        },
      ]
    },
    {
      name: 'January',
      plants: [
        {
          name: `Variegated Rainbow Bush`,
          scientisticName: `Portulacaria Afra F. Variegata`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/rainbow+bush.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/rainbow+bush.pdf',
          instructions: [
            `A slow-growing succulent shrub with many small, round variegated leaves, which are a pretty pale green, white, and pink in color`,
            `Water thoroughly every 2 weeks`,
            `Crave bright, indirect sunlight`,
            `Stems propagate easily as cuttings`,
            `Typically not toxic to pets`,
          ]
        },
        {
          name: `Roger`,
          scientisticName: `Crassula Rogersii`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/ROGER.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/ROGER.pdf',
          instructions: [
            `A small succulent bush with unusual, velvety green leaves that grow pale yellow flowers in clusters at the end of its long stems`,
            `Only water when soil it completely dry`,
            `Prefers direct sunlight in order to keep its leaf tips a reddish hue`,
            `Propagates easily by offshoots and leave`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'December',
      plants: [
        {
          name: `Panda Plant`,
          scientisticName: `Kalanchoe Tomentosa`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/panda_plant.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/panda_plant.pdf',
          instructions: [
            `Gorgeous velvety succulent with long, pale green leaves that have brown spots on the edges and tips`,
            `Unsafe for animals - keep away from pets`,
            `Water once every 2 weeks in winter and once a month in summer`,
            `Loves full sun but can tolerate partial shade`,
            `Propagates easily by leaves`,
          ]
        },
        {
          name: `Buddha's Temple`,
          scientisticName: `Crassula`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/buddha_temple.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/buddha_temple.pdf',
          instructions: [
            `An extremely rare and slow growing succulent with a mesmerizing form of stacked leaves that somewhat resembling a pagoda`,
            `Water sparingly, when soil is completely`,
            `Thrives in bright, indoor light`,
            `Can be propagated by offshoots or cuttings`,
            `Can be toxic`,
          ]
        },
      ]
    },
    {
      name: 'November',
      plants: [
        {
          name: `Lawyer's Tongue`,
          scientisticName: `Gasteria Bicolor`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/LAWYERS_WEB.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/LAWYERS_WEB.pdf',
          instructions: [
            `A relatively rare, aloe type plant, with long tongue-like leaves that are spotted with white dots. Leaves can change color hues depending on environment, season and sunlight`,
            `Water when soil is completely dried out, and very sparingly in the winter months`,
            `Thrives in semi-shade avoiding direct sun exposure`,
            `Can propagate by separation of suckers (offshoots)`,
            `Can be toxic`,
          ]
        },
        {
          name: `Fuzzy Crassula`,
          scientisticName: `Crassula Mesembryanthemoides`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/FUZZY_WEB.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/FUZZY_WEB.pdf',
          instructions: [
            `A green succulent with teardrop shaped leaves that are covered in fine "hairs" that offer protection from the sun. It grows on a woody stem, with leaves growing in pairs`,
            `Water when the soil is totally dry`,
            `Prefers full sun to partial shade`,
            `Propagates easily by offshoots and/or leaves`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'October',
      plants: [
        {
          name: `Crinkle-Leaf Plant`,
          scientisticName: `Adromischus Cristatus`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/CRINK-FRONT.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/CRINK.pdf',
          instructions: [
            `A rare and unusual succulent with distinctive, chunky, wedge-shaped leaves with wavy edges. A light coating of short hairs on the foliage gives a silvery sheen to the blue-green leaves`,
            `Thrives in full sun to partial shade`,
            `Water every 7-14 days, when the soil is totally dry`,
            `Easily propagated from its leaves by gently twisting from the stem`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Cobweb Houseleek`,
          scientisticName: `Cobweb Houseleek`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/COBWEB-FRONT.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/COBWEB.pdf',
          instructions: [
            `A low-growing and clump-forming succulent with green rosettes covered in a white, hairy webbing, that grows pink flowers once a year`,
            `Prefers bright light to thrive`,
            `Water when soil is totally dry, typically every 10-14 days depending on the season`,
            `Propagate by separating the pups and removing the offsets from the mother plant`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'September',
      plants: [
        {
          name: `Blue Rose`,
          scientisticName: `Echeveria Hybrid Imbricata`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/blue%2Brose.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/blue%2Brose.pdf',
          instructions: [
            `One of the oldest Echeveria hybrids, it is a rosette succulent with flat, powdery blue-green leaves tipped in pink`,
            `Water when the soil is completely dry`,
            `Prefers partial sun to partial shade`,
            `Propagates by leaves and offshoots`,
            `Not typically toxic to pets`,
          ]
        },
        {
          name: `Hens + Chicks`,
          scientisticName: `Sempervivum Hybrid`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/hens%2Bchicks.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/hens%2Bchicks.pdf',
          instructions: [
            `Bright green, low-maintenance succulent with flat, pointed leaves`,
            `Once mature, blooms light pink flowers in the summer`,
            `Water when soil is completely dry, about once every 10-14 days, by drenching thoroughly and pouring off excess water`,
            `Can tolerate full to partial sunlight`,
            `Propagates best by cuttings - will grow small offshoots from the stem`,
          ]
        },
      ]
    },
    {
      name: 'August',
      plants: [
        {
          name: `Love Plant`,
          scientisticName: `Anacampseros Rufescens`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/love-plant.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/chrome.pdf',
          instructions: [
            `This slow-growing succulent spreads out in clumping mats. The tops of its leaves are dark green, while the underside is a deep purple color. She grows fine, fuzzy white filaments from its center that help it survive droughts by trapping water vapor from the air`,
            `Thrives in full to partial sun`,
            `Water every few weeks, once the soil is dry`,
            `Propagate by stem cuttings or seeds`,
            `Can be toxic`,
          ]
        },
        {
          name: `Chroma`,
          scientisticName: `Echeveria 'Chroma'`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/chroma.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/love-plant.pdf',
          instructions: [
            `A sturdy rosette with show-stopping, psychedelic hues of blue-green, purple, and peach all swirled together. The center of the rosette will look like a sunset during the right seasonal variegation`,
            `Full sun to partial shade`,
            `Water when soil is totally dry`,
            `Propagates easily from leaves and offshoots`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'July',
      plants: [
        {
          name: `Jewel Of The Desert`,
          scientisticName: `Delosperma`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/jewel_of_desert.jpeg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/jewel_of_desert.pdf',
          instructions: [
            `This almost ever-blooming Ice plant is a vigorous, mat-forming, evergreen succulent with a profusion of brightly colored daisy-like flowers that bloom from late Spring to the first frost`,
            `Water only when soil is completely dry`,
            `Thrives in full sun, but can tolerate filtered light`,
            `Propagates easily from leaves, either from cuttings or dropped in shipping`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Ruby Necklace`,
          scientisticName: `Othonna Capensis`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/ruby_necklace.jpeg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/ruby_necklace.pdf',
          instructions: [
            `This plant is narrow and attractive due to its brightly colored stems and beautiful flowers. Its stems are thin and red or purple. She is one of the rarest succulent plants on the planet`,
            `Let soil dry completely between waterings before watering again (days between vary based on climate and environment)`,
            `Prefers direct sunlight (6 hours a day is ideal)`,
            `Easily propagates by stem cuttings and nodes that fall from their stem`,
            `Can be toxic`,
          ]
        },
      ]
    },
    {
      name: 'June',
      plants: [
        {
          name: `Pulido's Echeveria`,
          scientisticName: `Echeveria Pulidonis`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/pulidonis-echeveria.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/pulidonis-echeveria.pdf',
          instructions: [
            `A slow-grower with blue-green, oblong leaves that are slightly cupped to form a tidy rosette.
            When grown in bright sunlight, the leaf edges have a fine, crimson outline that contrasts well with its soft blue tones`,
            `Water deeply enough for water to run out the drainage hole, then wait for the soil to fully dry before watering again`,
            `Prefers bright sunlight to maintain colors and compact rosette form`,
            `Propagates from stem cuttings or mature leaves, as well as offsets`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Mount Everest`,
          scientisticName: `Curio (Senecio) Ficoides`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/mount-everest.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/mount-everest.pdf',
          instructions: [
            `An attractive succulent with upright stems that bear thick fleshy blue-green covered with a waxy coating`,
            `Water when soil is dry, or when leaves start to look wrinkled`,
            `Prefers full sun to partial shade`,
            `Propagates very easily by leaf cuttings or offshoots`,
            `Can be toxic`,
          ]
        },
      ]
    },
    {
      name: 'May',
      plants: [
        {
          name: `Black Rose`,
          scientisticName: `Aeonium Arboreum 'Zwartkop'`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/Blackrose-A.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/Blackrose-A.pdf',
          instructions: [
            `An especially unique succulent with glossy, dark purple leaves that can range from green in the center to black on the edges`,
            `Water every 7-10 days or when soil is dry`,
            `Prefers bright light to keep dark purple hue`,
            `Can be propagated from offshoot cuttings`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Mixed Sparklers`,
          scientisticName: `Sedum Sunsparklers`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/sparklers-A.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/sparklers-A.pdf',
          instructions: [
            `Mixed with different varieties / colors of the SunSparkler succulent, this hardy ground-cover succulent has round leaves and can grow up to 5'' tall and spread over a few feet`,
            `Needs water once every 2 to 3 weeks; make sure soil is completely dried first`,
            `Appreciates full, bright sunlight`,
            `Propagates easily by cuttings`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'April',
      plants: [
        {
          name: `Gollum Jade`,
          scientisticName: `Crassula Ovata`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/Gollum+Jade.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/Gollum+Jade.pdf',
          instructions: [
            `Unique, bright green shrub succulent! with cylindrical, waxy leaves tipped in red`,
            `Appreciates filtered water once every 2 weeks. 
            Very sensitive to over-watering, and shriveled leaves are a sign it needs more water`,
            `Blooms small, pink-white flowers in late fall and early winter`,
            `Thrives in full, indirect sunlight`,
            `Propagates best by cuttings`,
            `Not pet safe`
          ]
        },
        {
          name: `Graptosedum Bronze`,
          scientisticName: `Graptosedum 'Bronze'`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/Graptosedum+Bronze.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/Graptosedum+Bronze.pdf',
          instructions: [
            `A perennial succulent that forms flat rosettes of thick, waxy, bright green leaves, and grows offsets easily around its base`,
            `Water sparingly to prevent root rot, using well-draining soil`,
            `Prefers bright, direct sunlight`,
            `Propagates easily from offsets growing around the main plant`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'March',
      plants: [
        {
          name: `String Of Pearls`,
          scientisticName: `Curio Rowleyanus`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/string-1.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/string-1.pdf',
          instructions: [
            `Popular string succulent that grows small, bright green 'pearls' along cascading stems`,
            `Blooms small white flowers in summer`,
            `Only needs water once or twice a month`,
            `Appreciates bright, partial to full sunlight`,
            `Easily propagated by cuttings, just prune off a stem and nestle it in moist cactus oil, spraying once every couple days`,
            `Can be toxic - keep away from curios pets and kiddos`
          ]
        },
        {
          name: `Lily Pad`,
          scientisticName: `Aeonium Hybrid`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/lily-1.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/lily-1.pdf',
          instructions: [
            `A perennial succulent that forms flat rosettes of thick, waxy, bright green leaves, and grows offsets easily around its base`,
            `Water sparingly to prevent root rot, using well-draining soil`,
            `Prefers bright, direct sunlight`,
            `Propagates easily from offsets growing around the main plant`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'February',
      plants: [
        {
          name: `Aeonium Kiwi`,
          scientisticName: `Aeonium Haworthii`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/aeonium-kiwi.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/aeonium-kiwi.pdf',
          instructions: [
            `A brightly colored shrub succulent with pointed, yellow-green leaves edged in hot pink that blooms clusters of little yellow flowers`,
            `Regular watering whenever soil is fry`,
            `Thrives in partial sun, and is sensitive to extreme heat`,
            `can be propagate by offshoots and cut leaves`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Shattering Echeveria`,
          scientisticName: `Echeveria Diffractens`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/shattering-echeveria.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/shattering-echeveria.pdf',
          instructions: [
            `A succulent with flattened rosettes of many short, broad, pale pinkish to gray-violet leaves with a thing cream outline`,
            `Watering: the soak and dry method is preferred (water when totally dry, then soak with water, not letting any excess water sit under the plant). Over watering can kill her`,
            `Full sun is recommended`,
            `Easily propagates from leaf cuttings`,
            `Typically not toxic to animals`,
          ]
        },
      ]
    },
    {
      name: 'January',
      plants: [
        {
          name: `Topsy Turvy`,
          scientisticName: `Echeveria Runyonii`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/topsy_turvy.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/topsy_turvy.pdf',
          instructions: [
            `A silvery-green succulent with rosettes than can grow up to 10 inches in diameter, and orange blooms that emerge on tall stems in late summer and fall`,
            `Only water when soil is 100% dry, while also not allowing the plant to sit in water (which can attract mealybug)`,
            `Prefers indirect sunlight`,
            `Can be propagated by leaf cuttings, on soil or by propagation`,
            `Typically not toxic to pets`,
          ]
        },
        {
          name: `Tiger Tooth Aloe`,
          scientisticName: `Aloe Juvenna`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/tiger_tooth_aloe.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/tiger_tooth_aloe.pdf',
          instructions: [
            `An unusual succulent with thick stems reaching up to 2 feet long! His stems are covered in bright green leaves that are flecked with white and turn red-brown in full sun`,
            `Partially drought-tolerant, and doesn't need frequent watering`,
            `Grow best in full sun or partial shade`,
            `Propagates best from offsets growing from main plant`,
            `Tipically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'December',
      plants: [
        {
          name: `Black Prince`,
          scientisticName: `Echeveria 'Black Prince'`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/black-prince1.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/black-prince1.pdf',
          instructions: [
            `A gorgeous Echeveria with dark green, purplish and almost black leaves that intensify and darken with more sun exposure. They can turn more green when kept in the shade or a greenhouse`,
            `Water every 7-12 days when soil is completely dry`,
            `Prefers 5-6 hours of bright light per day from the South or West facing window`,
            `Propagates easily from leaf cuttings (or leaves that drop in shipping!)`,
            `Typically not toxic`,
          ]
        },
      ]
    },
    {
      name: 'November',
      plants: [
        {
          name: `Hens + Chicks 'Kalinda'`,
          scientisticName: `Sempervivum`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/kalinda1.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/kalinda1.pdf',
          instructions: [
            `Bright green, low-maintenance succulent with flat, pointed leaves that flower in pink once mature`,
            `Water when soil is completely dry, about once every 10-14 days, by drenching thoroughly and pouring off excess water`,
            `Can tolerate full to partial sunlight`,
            `Propagates best by cuttings - will grow small offshoots from the stem`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Small Red Carpet`,
          scientisticName: `Crassula Pubescens Ssp. Radicans`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/red-carpet.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/red-carpet.pdf',
          instructions: [
            `A bushy, ground cover stonecrop with oval leaves that transition from green to red when exposed to bright sun, and bloom in late winter or spring with clusters of tiny white blossoms`,
            `Water deeply enough for water to run out of the drainage hole, then wait for the soil to fully dry before watering again`,
            `Prefers bright, indirect sunlight`,
            `Propagates best by cuttings - will grow small offshoots from the stem`,
            `Parts of the plant can be toxic`
          ]
        },
      ]
    },
    {
      name: 'October',
      plants: [
        {
          name: `Kalanchoe 'FANG'`,
          scientisticName: `Kalanchoe Beharensis`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/fang-A.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/fang-A.pdf',
          instructions: [
            `A tall, fuzzy succulent with velvety silver leaves, with the undersides covered in small tubercles, or "fangs". Goes dormant in the summer months`,
            `Water every 7-10 days as needed when soil is dry`,
            `Prefers full sun to partial shade`,
            `Leaves or stem cuttings can be easily propagated`,
            `Can be toxic`,
          ]
        },
        {
          name: `Peperomia Axillaris`,
          scientisticName: `Peperomia`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/peperomiaA.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/PEPEROMIA-A.pdf',
          instructions: [
            `An exotic plant with chartreuse foliage that grows in tight clumps and is shaped like peapods with a green stripe on top`,
            `Keep the soil moist, not letting it dry completely out as typical for most other succulents`,
            `Grows best in bright to moderate light, or a grow lamp`,
            `Fallen leaves can be propagated to form new baby peperomias, as leaves fall off easily (do not be alarmed by this)`,
            `Not typically toxic`
          ]
        },
      ]
    },
    {
      name: 'September',
      plants: [
        {
          name: 'Little Jewel',
          scientisticName: `Pachyphyntum Compactum`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/Little-J.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/LITTLE-JEWEL-A.pdf',
          instructions: [
            `Resembles a cut gemstone with angular facets left by the pressure of neighboring leaves that are a matte blue-green but cas show tinges of violet when gently stressed by full sun`,
            `Water every 2 weeks as needed when soil is dry`,
            `Thrives in bright indoor light filtered / partial sun`,
            `Propagates easily from stem cuttings and leaf propagation from any fallen leaves in shipping`,
            `Typically not toxic for pets`,
          ]
        },
        {
          name: `Rhoeo 'Tricolor'`,
          scientisticName: `Tradescantia Spathaea`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/Rhoeo.png',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/docs/RHOEO-A.pdf',
          instructions: [
            `Attractive tricolored foliage with white and green striping, pink highlights on the leaves and purple undersides`,
            `Water when soil is completely dry, and until liquid flow through the drainage hole`,
            `Prefer bright, indirect light. Insufficient light may cause the foliage to fade`,
            `The easiest way to propagate is by cutting stems or dividing the roots`,
            `Can be toxic`,
          ]
        },
      ]
    },
    {
      name: 'August',
      plants: [
        {
          name: 'Prolifica',
          scientisticName: `Echeveria Prolific`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/august-2021/prolifica1.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/august-2021/prolifica1.pdf',
          instructions: [
            `A small succulent that forms rosettes of silvery-green leaves, with an abundance of small offsets. In early Spring, small bell-shapped flowers are known to appear`,
            `Water every 1-2 weeks as needed, not letting water sit in the rosettes, which can cause rot and/or fungus`,
            `Bright light to slight shade`,
            `Can be easily propagated from leaf cuttings`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Copper Spoons`,
          scientisticName: `Kalanchoe Orgyalis`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/august-2021/copper1.jpg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/august-2021/copper1.pdf',
          instructions: [
            `A much-branched shrubby succulent, with spatulate-ovate leaves that fold upwards. Leaves vary from bronze to gray, with the top side feeling felted`,
            `Water every 2-3 weeks in Fall/Winter, and less in the hotter months while dormant`,
            `Prefer a bright, sunny location`,
            `Propagate by carefully ﻿twisting a leaf from the stem`,
            `Can be toxic`,
          ]
        },
      ]
    },
    {
      name: 'July',
      plants: [
        {
          name: 'Fred Ives',
          scientisticName: `Graptoveria Fred Ives`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/july-2021/fred1.jpeg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/july-2021/fred1.pdf',
          instructions: [
            `A pointy rosette succulent with foliage that can change color from a glowing pinkish-purple, to almost any color of the rainbow, depending on the amount of water and sun they get`,
            `Water every 7 - 10 days`,
            `Full sun to partial shade`,
            `Easy propagation by leaves, cuttings, and offsets`,
            `Typically not toxic`,
          ]
        },
        {
          name: `Hesperaloe`,
          scientisticName: `Red Yucca, Hesperaloe parviflora`,
          image: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/july-2021/hesperaloe1.jpeg',
          pdf: 'https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/july-2021/hesperaloe1.pdf',
          instructions: [
            `Strong vertical shape and spectacular flower spikes makes this clumping, evergreen perennial an ideal accent plant`,
            `Water every 1-2 weeks`,
            `Prefer a bright, sunny location`,
            `Typically spreads on its own through self-seeding, or is grown from seeds or propagated by clump division.`,
            `Can be toxic to dogs`,
          ]
        },
      ]
    },
    {
      name: 'June',
      plants: [
        {
          name: 'Tiger Jade',
          scientisticName: `Crassula Exilis SSP. Cooperi`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/tiger-jade.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/tiger-jade.pdf',
          instructions: [
            `A gorgeous, compact succulent with dark green leaves and black spots that remains small as it spreads`,
            `Water thoroughly when soil is dry to the touch, then let drain completely`,
            `Full sun to partial sun`,
            `Easy propagation by division, offsets or leaf cuttings`,
            `Can be toxic to pets`,
          ]
        },
        {
          name: `Variegated Rainbow Bush`,
          scientisticName: `Portulacaria Afra F. Veriegata`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/rainbow-bush.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/rainbow-bush.pdf',
          instructions: [
            `A slow-growing succulent shrub with many small, round variegated leaves, which are a pretty pale green, white and pink in color`,
            `Water throughly every 2 weeks`,
            `Crave bright, indirect sunlight`,
            `Stems propagate easily as cuttings`,
            `Typically not toxic to pets`,
          ]
        },
      ]
    },
    {
      name: 'May',
      plants: [
        {
          name: 'Darley Sunshine',
          scientisticName: `Graptosedum Francesco Baldi`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/darley_front.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/darley_back.pdf',
          instructions: [
            `Blue-gray to pinkish-gray succulent that forms rosettes that spread up to 6 inches`,
            `Requires a lot of sunlight to look her best`,
            `Water every 2 weeks`,
            `Any leaf that drops off will root and produce a new rosette quickly`,
            `Can be toxic to animals`,
          ]
        },
        {
          name: `Zebra Plant Hybrid`,
          scientisticName: `Haworthia`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/zebra_front.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/zebra_back.pdf',
          instructions: [
            `Dark green leaves with small white lines / bumps on the underside`,
            `Thrives in semi-shade, but can tolerate full sun`,
            `Water using the "soak and dry" method, meaning allow the soil to completely dry out before watering again`,
            `Propagate by removing offsets from the main plant`,
            `Typically not toxic to animals`,
          ]
        },
      ]
    },
    {
      name: 'April',
      plants: [
        {
          name: 'Paddle Plant',
          scientisticName: `Kalanchoe Thyrsiflora`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/paddle_plant.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/paddle_plant.pdf',
          instructions: [
            `Hardy succulent whose leaves will flush red when given lots of sunlight`,
            `Water every 2 weeks`,
            `Thrives in direct, bright sunlight`,
            `Repot every year; clay pots are preferred for this variety`,
            `Can be toxic to animals`,
          ]
        },
        {
          name: `Scallop Hybrid`,
          scientisticName: `Kalanchoe Marnieriana`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/scallop_hybrid.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/scallop_hybrid.pdf',
          instructions: [
            `Native to Madagascar, she is a flowering succulent with flat, pale green leaves that have scalloped pink edges`,
            `Required water when soil is completely dry; typically every 2-3 weeks`,
            `Loves direct, bright sunshine, but also thrives under a grow lamp`,
            `Flowers from leaves will propagate into a new plant quickly and easily`,
            `Can be harmful to pets`,
          ]
        },
        {
          name: `Flower Dust Plant`,
          scientisticName: `kalanchoe pumila`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/flower_dust.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/flower_dust.pdf',
          instructions: [
            `A dwarf succulent shrub that can grow up to 12 inches tall, and covered in soft, waxy hairs which give it its frosted look`,
            `Water moderately in the summer months, and reduce watering in winter. Be sure her pot drains well to keep away from over-moisture`,
            `Grows best in partial sun to light shade. Intense light can burn her tips`,
            `Flowers from leaves will propagate into a new plant quickly and easily`,
            `Can be harmful to pets`,
          ]
        },
      ]
    },
    {
      name: 'March',
      plants: [
        {
          name: 'Watch Chain',
          scientisticName: `Crassula Muscosa`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/watch_plant.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/watch_plant.pdf',
          instructions: [
            `Super unique succulent with tiny green leaves that are tightly stacked around long stems, giving the nickname "Watch Chain"`,
            `Water once every 2 weeks`,
            `Grows best in partial shade`,
            `Can easily be cut back and propagated`,
            `Can be toxic to animals`,
          ]
        },
        {
          name: `Bear's Paw`,
          scientisticName: `Cotyledon Tomentosa`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/bears_paw.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/bears_paw.pdf',
          instructions: [
            `Rare shrub succulent with fuzzy green leaves that are tipped with tiny "claws"`,
            `Water every couple weeks in summer, and only about once a month in winter`,
            `Loves bright, indirect sunlight`,
            `Leaves are sensitive, so this succulent will propagate best by cuttings`,
            `Pet safe`,
          ]
        },
      ]
    },
    {
      name: 'February',
      plants: [
        {
          name: 'Echeveria Arrow',
          scientisticName: `Echeveria 'Arrow Setosa'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/echeveria_arrow.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/echeveria_arrow.pdf',
          instructions: [
            `Showy succulent with bright green, fuzzy leaves that have striking burgundy tips`,
            `Craves bright, indirect sunlight to maintain their color`,
            `Water deeply every 2-3 weeks`,
            `Will bloom stalks with yellow and red flowers in spring`,
            `Propagates easily by cuttings or mature leaves`,
            `Pet safe`,
          ]
        },
        {
          name: `Propeller Plant`,
          scientisticName: `Crassula falcata`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/propeller_plant.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/propeller_plant.pdf',
          instructions: [
            `Beautiful, winter-growing succulent named for its unique, propeller-shaped leaves`,
            `Craves bright, indirect sunlight`,
            `Water deeply every 2-3 weeks`,
            `Will bloom showy bunches of small red flowers once mature`,
            `Propagates easily by leaves or cuttings`,
            `Pet safe`,
          ]
        },
        {
          name: `Panda Plant`,
          scientisticName: `Kalanchoe Tomentosa`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/panda_plant.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/panda_plant.pdf',
          instructions: [
            `Gorgeous velvety succulent with long, pale green leaves that have brown spots on the edges and tips`,
            `Water once every 2 weeks in winter and once a month in summer`,
            `Full sun but can tolerate partial shade`,
            `Propagates easily by leaves`,
            `Not pet safe`,
          ]
        },
      ]
    },
    {
      name: 'January',
      plants: [
        {
          name: 'Belle Blue',
          scientisticName: `Pachyveria 'Belle Blue'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/JAN_belle_blue.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/JAN_belle_blue.pdf',
          instructions: [
            `Gorgeous, powdery blue succulent with thick leaves that grow in rosettes`,
            `Grows pretty, pink-red flowers in spring`,
            `Water once every 2-3 weeks`,
            `Thrives best in bright, indirect sunlight`,
            `Easily propagated by cuttings or leaves`,
            `Pet Safe`,
          ]
        },
        {
          name: `Donkey's Tail`,
          scientisticName: `Sedum Burrito`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/JAN_DonkeyTail.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/JAN_DonkeyTail.pdf',
          instructions: [
            `Cute, pendant succulent with tightly stacked, icy green leaves that can have pointed or round tips`,
            `Water once every 2-3 weeks`,
            `Grows best in bright, indirect sunlight`,
            `Easily propagates by cuttings or leaves`,
            `Pet safe`,
          ]
        },
      ]
    },
    {
      name: 'December',
      plants: [
        {
          name: 'Lime Sparkler',
          scientisticName: `Sedum Lime Sparkler`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/lime_sparkler.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/lime_sparkler.pdf',
          instructions: [
            `This hardy ground-cover succulent has round, yellow-green leaves and can grow up to 5" tall and spread over a few feet`,
            `Blooms small pink flowers in late summer and early fall`,
            `Needs water once every 2 to 3 weeks; make sure soil is completely dried first`,
            `Appreciates full, bright sunlight`,
            `Propagates easily by cuttings`,
            `Pet Safe`,
          ]
        },
        {
          name: 'Five Stamen Graptopetalum',
          scientisticName: `Graptopetalum Pentandrum`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/five_stamen_grapto.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/five_stamen_grapto.pdf',
          instructions: [
            `Sweet rosette succulent with flat, pointed, gray-purple leaves`,
            `Grows unique, colorful flowers in late winter and early spring`,
            `Appreciates full, indirect sunlight`,
            `Water thoroughly once every 10-14 days, or whenever the soil is completely dry`,
            `Propagates easily by cuttings and leaves`,
            `Pet safe`,
          ]
        },
        {
          name: 'Painted Echeveria',
          scientisticName: `Echeveria Nodulosa`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/painted_echeveria.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/painted_echeveria.pdf',
          instructions: [
            `Bicolor succulent with mint-green leaves striped and outlined in a gorgeous red-purple`,
            `Blooms red, bell-shaped flowers in summertime`,
            `Craves bright, indirect sunlight`,
            `Needs a good soak every 2-3 weeks`,
            `Propagates easily by cuttings or leaves`,
            `Pet safe`,
          ]
        },
        {
          name: 'Superbum',
          scientisticName: `Graptopetalum superbum`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/superbum.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/superbum.pdf',
          instructions: [
            `Sweet rosette succulent with flat, pointed, gray-purple leaves`,
            `Grows unique, colorful flowers in late winter and early spring`,
            `Appreciates full, indirect sunlight`,
            `Water thoroughly once every 10-14 days, or whenever the soil is completely dry`,
            `Propagates easily by cuttings and leaves`,
            `Pet safe`,
          ]
        }
      ]
    },
    {
      name: 'November',
      plants: [
        {
          name: 'Jelly Bean Plant',
          scientisticName: `Sedum rubrotinctum`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/jellybean_CC-15.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/jellybean.pdf',
          instructions: [
            `Shrub succulent that gets its name from its cute, jelly bean-shaped leaves that are a bright green and can blush red`,
            `Grows small yellow flowers in summertime`,
            `Loves full, direct sunlight`,
            `Appreciates infrequent watering; every 2 to 3 weeks is sufficient`,
            `Propagates easily by leaves or cuttings`,
            `Not Pet Safe`,
          ]
        },
        {
          name: 'String of Dolphins',
          scientisticName: `Senecio peregrinus`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/stringo_dolphins.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/stringofdolphins.pdf',
          instructions: [
            `Very rare string succulent that grows dolphin-shaped leaves along cascading stems`,
            `Blooms small white flowers in summer`,
            `Loves indirect sunlight, or bright shade if outdoors`,
            `Needs a good soak every 10 days, or whenever the soil is completely dry`,
            `Easily propagated by cuttings, just prune off a stem and nestle it in moist cactus soil, spraying once every couple days`,
            `Can be toxic - keep away from curious pets and kiddos`,
          ]
        }
      ]
    },
    {
      name: 'October',
      plants: [
        {
          name: 'Ghost Plant',
          scientisticName: `Graptopetalum paraguayense`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/ghost_plant.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/ghost_plant.pdf',
          instructions: [
            `Beautiful gray-blue succulent with flat, pointed leaves that grow in rosettes`,
            `Grows white, star-shaped flowers in spring`,
            `Water once every 10-14 days, giving it a good soak when the soil is completely dry`,
            `Appreciates full, bright sunlight`,
            `Propagates easily by leaves or cuttings`,
            `Pet safe`,
          ]
        },
        {
          name: 'Campfire Crassula',
          scientisticName: `Crassula capitella`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/crassula_capitella.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/crassula_capitella.pdf',
          instructions: [
            `Unique, branching succulent with green leaves that are tipped in bright red`,
            `Grows clusters of white flowers in summer`,
            `Thrives in partial to full sunlight`,
            `Water once every 7-10 days`,
            `Propagates best by offshoots`,
            `Not pet safe`,
          ]
        }
      ]
    },
    {
      name: 'September',
      plants: [
        {
          name: 'Desert Rose',
          scientisticName: `Echeveria 'Grey Red'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/desert_rose.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/desert_rose.pdf',
          instructions: [
            `Beautiful, light gray succulent with red-tipped leaves in bright sun`,
            `Blooms red-orange flowers in spring to mid-summer`,
            `Appreciates bright, indirect sun throughout the year`,
            `Water when soil is completely dry, about once every 10-14 days, by drenching thoroughly and pouring off excess water`,
            `Propagates easily by leaves or cuttings`,
          ]
        },
        {
          name: 'Hens + Chicks',
          scientisticName: `Sempervivum Hybrid`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/hens_chicks.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/hens_chicks.pdf',
          instructions: [
            `Bright green, low-maintenance succulent with flat, pointed leaves`,
            `Once mature, blooms light pink flowers in the summer`,
            `Can tolerate full to partial sunlight`,
            `Water when soil is completely dry, about once every 10-14 days, by drenching thoroughly and pouring off excess water`,
            `Propagates best by cuttings - will grow small offshoots from the stem`,
          ]
        }
      ]
    },
    {
      name: 'August',
      plants: [
        {
          name: 'Gollum Jade',
          scientisticName: `Crassula ovata`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/gollum_jade.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/gollum_jade.pdf',
          instructions: [
            `Unique, bright green shrub succulent with cylindrical, waxy leaves tipped in red`,
            `Blooms small, pink-white flowers in late fall and early winter`,
            `Appreciates filtered water once every 2 weeks, or whenever the soil is completely dry. Very sensitive to over-watering, and shriveled leaves are a sign it needs more water`,
            'Thrives in full, indirect sunlight',
            'Propagates best by cuttings',
            'Not pet safe'
          ]
        },
        {
          name: 'Copper Sedum',
          scientisticName: `Sedum adolphi`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/copper_sedum.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/copper_sedum.pdf',
          instructions: [
            `Low-growing rosette succulent with slender, pointed leaves that will turn from green to fiery red-orange in bright light or cool temperatures`,
            `Blooms bunches of tiny white flowers in late winter to early spring`,
            `Needs a good drench once every 2-3 weeks`,
            `Thrives in partial to full sun`,
            `Propagates easily by leaves or cuttings`,
            `Pet safe`
          ]
        }
      ]
    },
    {
      name: 'July',
      plants: [
        {
          name: 'Moon Silver',
          scientisticName: `Pachyphytum`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/pachyphytum.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/pachyphytum.pdf',
          instructions: [
            `Silver-blue succulent with fleshy leaves that grow in rosettes`,
            `Flowers in spring to summer`,
            `Loves bright, full sunlight`,
            'Water when soil is completely dry, about once every 10-14 days, by drenching thoroughly and pouring off excess water',
            'Propagates best by cuttings or mature leaves'
          ]
        },
        {
          name: 'Mexican Snowball',
          scientisticName: `Echeveria elegans`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/echeveria_elegans.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/echeveria_elegans.pdf',
          instructions: [
            `Powdery blue succulent with pink-tipped leaves, named from the Latin word for 'elegant'`,
            `Blooms pink-red flowers in spring to mid-summer`,
            `Appreciates bright, indirect sun throughout the year`,
            `Water when soil is completely dry, about once every 10-14 days, by drenching thoroughly and pouring off excess water`,
            `Propagates easily by leaves or cuttings`,
          ]
        }
      ]
    },
    {
      name: 'June',
      plants: [
        {
          name: 'Graptosedum Bronze',
          scientisticName: `Graptosedum 'Bronze'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/graptosedum_bronze.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/graptosedum_bronze.pdf',
          instructions: [
            `Beautiful, bronze-colored succulent with short, pointed leaves that form rosettes`,
            `Blooms little clusters of yellow flowers in spring`,
            `Water every 10-14 days in warm seasons and once a month in winter`,
            'Bright, indirect to direct sun',
            'Propagates easily by leaves or cuttings'
          ]
        },
        {
          name: 'Aloe Vera',
          scientisticName: `Aloe barbadensis`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/aloe_vera.jpg',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/aloe_vera.pdf',
          instructions: [
            `Ancient healing plant with long, waxy green leaves speckled in white`,
            `Leaves hold a gummy sap that can be used for moisturizing skin and healing sunburn`,
            `Water infrequently, about every 3-4 weeks`,
            `Full, direct sun to light shade`,
            `Propagates by cuttings`,
          ]
        }
      ]
    },
    {
      name: 'May',
      plants: [
        {
          name: 'Echeveria Rosy',
          scientisticName: `Echeveria 'Rosea'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/rosea.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/echeveria_rosea.pdf',
          instructions: [
            `I'm a stunning succulent with chalky, blue-gray leaves. The tips of my leaves are wavy and edged in a rosy pink.`,
            `I'm a slow grower and a perfect 'set-it-and-forget-it' houseplant. Sit me on a windowsill that gets partial to full sun and I'll be happy!`,
            `Water me just enough to keep me from wilting during the colder seasons, and only once a month in the summer.`,
          ]
        },
        {
          name: 'Echeveria Pearl',
          scientisticName: `Echeveria 'Perle von nurnberg'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/pearl.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/echeveria_pearl.pdf',
          instructions: [
            `My purple rosettes have made me one of the most popular succulents around! In intense sunlight, my leaves will deepen in color from a gray-purple to a bright pink-purple.`,
            `Like my boxmate, I'm a very low-maintenance succulent to care for, and I propagate very easily.`,
            `Keep me where I'll get partial to full sunlight and water me once a month, or whenever my soil is completely dry.`,
          ]
        },
        {
          name: 'Morning Beauty',
          scientisticName: `Echeveria subsessilis`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/morning-beauty.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/morning_beauty.pdf',
          instructions: [
            `I'm a powdery, gray-blue succulent with leaves that  are edged in bright pink.`,
            `In spring, I'll grow long steams with yellow-orange flowers.`,
            `As I grow, remove any dead leaves from the bottom of my rosettes.`,
            `I love full sun and a good soak once every 2-3 weeks.`
          ]
        }
      ]
    },
    {
      name: 'April',
      plants: [
        {
          name: 'Octopus Agave',
          scientisticName: `Agave vilmoriniana`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/octopus_agave.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/octopus_agave.pdf',
          instructions: [
            `I'm a beautiful, slow-growing succulent that grows long, gray-green leaves. When they start curving, they're said to resemble octopus tentacles.`,
            `I'm the perfect 'set it and forget it' plant; set me in a spot where I'll get a day's worth of partial to full sun and I'll be happy!`,
            `I only need water once a month.`,
            `Keep me out of reach of curious kids or pets, as my sap is dangerous and should not be touched or ingested.`,
          ]
        },
        {
          name: 'String Of Buttons',
          scientisticName: `Crassula perforata`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/string_of_buttons_2020.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/string_of_buttons_2020.pdf',
          instructions: [
            `Native to South Africa, I get my name from the flat bunches of leaves on my stems that look like little buttons.`,
            `While I'm young, my stems will stand up straight, but as I grow they'll become pendant, making me a great addition to a hanging basket.`,
            `I grow relatively quickly, crave well-draining soil and full sun, and I appreciate partial midday shade in hot summer climates.`,
          ]
        }
      ]
    },
    {
      name: 'March',
      plants: [
        {
          name: 'Kiwi Aeonium',
          scientisticName: `Aeonium haworthii 'Dream Color'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/kiwi.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/kiwi.pdf',
          instructions: [
            `I'm a brightly colored shrub succulent with pointed, yellow-green leaves edged in hot pink.`,
            `In the summer, I'll bloom clusters of little yellow flowers.`,
            `Like other Aeoniums, I'm sensitive to extreme heat and sun. I thrive in partial sun, and I like regular watering whenever my soil is dry.`,
          ]
        },
        {
          name: 'Little Gem',
          scientisticName: `Cremnosedum 'Little Gem'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/little-gem.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/little-gem.pdf',
          instructions: [
            `I'm a small, tightly clustered succulent with leaves that turn a beautiful shade of copper in full sun.`,
            `I'll bloom yellow flowers in late winter to early spring.`,
            `Like other Sedums, I need very little care to thrive. Give me a good watering every 3-4 weeks (or whenever you remember) and keep me anywhere I'll get a full day of sun.`,
          ]
        }
      ]
    },
    {
      name: 'February',
      plants: [
        {
          name: 'Moonstones',
          scientisticName: 'Pachyphytum oviferum',
          instructions: [
            `I'm a wonderfully plump succulent with round leaves that are smooth as river stones. I range in color from opalescent blue, green, or purple.`,
            `I do best indoors on a south-facing windowsill in wintertime, and in the summer I appreciate a sunny spot where I can soak up the day's sun and heat.`,
            `I'm a winter grower, so I appreciate a good soak whenever my soil is totally dry. In the summer, you can water me once or twice a month.`
          ],
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/moon.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/Moonstones.pdf'
        },
        {
          name: 'String of Pearls',
          scientisticName: 'Curio rowleyanus',
          instructions: [
            `I'm a crowd favorite among houseplants! I grow small, bright green 'pearls' along cascading stems, and I'll even bloom small white flowers in the summer.`,
            `I don't need much care at all, just a sunny windowsill and water once or twice a month.`,
            `To propagate me, prune off a 4" stem and place it in moist cactus soil. I'll root and start growing new pearls in no time!`
          ],
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/string.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/String_of_pearls.pdf'
        }
      ]
    },
    {
      name: 'January',
      plants: [
        {
          name: `Blue Chalksticks`,
          scientisticName: 'Curio Repens',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/blue-chalkstick.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/blue-chalkstick.pdf',
          instructions: [
            `I'm a powdery blue succulent with beautiful, cylindrical leaves. In mid-summer to early fall, I'll bloom little white flowers.`,
            `I love full, direct sunlight, so a south-facing window is the best place for me.`,
            `In winter, I only need enough water to keep my leaves from wilting. In warmer months, soak my soil every couple weeks.`,
          ]
        },
        {
          name: 'Blue Burrito',
          scientisticName: `x Sedeveria 'Blue Burrito'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/blue-burrito.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/blue-burrito.pdf',
          instructions: [
            `I'm a pale blue succulent with short, pointed leaves arranged in lovely rosettes.`,
            `With lots of sunlight, the tips of my leaves will turn pink!`,
            `I crave direct sunlight but can tolerate partial sun. In winter, I only need water once a month. In warmer months, soak my soil every couple weeks.`,
          ]
        },
      ]
    },
    {
      name: 'December',
      plants: [
        {
          name: `Blue Spruce`,
          scientisticName: 'Sedum rupestre',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/blue-spruce.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/blue-spruce.pdf',
          instructions: [
            `I'm a ground-covering succulent that gets my name from my beautiful, needle-like leaves`,
            `In late spring / early summer, I'll sprout long stalks with yellow flowers!`,
            `As a sedum, I am especially easy to grow. Just put me in a sunny window and water me thoroughly whenever my soil is completely dry (usually every 2-3 weeks).`,
          ]
        },
        {
          name: 'Pachyveria Empress',
          scientisticName: `x Pachyveria 'Myrtilla'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/pachyveria-empress.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/pachyveria-empress.pdf',
          instructions: [
            `I'm a pretty perennial succulent with gorgeous round leaves that vary in color from green to red to pink.`,
            `I'll do well in gardens and containers alike; just don't leave me outside when it frosts. I'd rather a sunny windowsill during the colder months.`,
            `I love full sun but can easily tolerate partial sun, and I appreciate water whenever my lower leaves start looking a little wilted.`,
          ]
        },
      ]
    },
    {
      name: 'November',
      plants: [
        {
          name: `Chocolate Drop`,
          scientisticName: 'Adromischus maculatus',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/chocolate-drop.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/Chocolate_drop.pdf',
          instructions: [
            `I'm a speckled succulent with beautiful, flat leaves marked in a chocolate brown color.`,
            `I love sunlight, so set me on a west or south facing windowsill and I'll thrive!`,
            `In the colder months I like a good soak once a month, and in the warmer months please water me every two weeks.`,
            `I can grow small, tubular yellow flowers in the spring and summer, especially if I get enough sunlight.`,
          ]
        },
        {
          name: 'Black Rose',
          scientisticName: `Aeonium arboreum ‘Zwartkop’`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/black-rose.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/Black_rose.pdf',
          instructions: [
            `I'm an especially unique succulent with glossy, dark purple leaves that can range from green in the center to black on the edges.`,
            `My stems can grow up to 3 feet long, and each of my rosettes can be up to 8 inches wide!`,
            `Put me in the brightest window you have and I'll keep my dark purple color.`,
            `During the winter, I need a good soak once a month. In the warmer months I like to be watered once a week.`,
          ]
        },
      ]
    },
    {
      name: 'October',
      plants: [
        {
          name: `Dragon's Blood`,
          scientisticName: 'Phedimus spurius',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/dragons-blood.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/dragons-blood.pdf',
          instructions: [
            `I'm a ground-covering succulent that ranges in color from a dark red to rich green.`,
            `I don't need much care to thrive, but I do appreciate being fertilized every few months.`,
            `I love full sun but can easily survive cloudy winters.`,
            `Water me thoroughly every few weeks and I'll be happy! If I’m in prolonged hot, full sunshine, water me every few days.`
          ]
        },
        {
          name: 'Lemon & Lime',
          scientisticName: `Crassula ovata`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/lemon-lime.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/lemon-lime.pdf',
          instructions: [
            `I'm a shrub succulent that can grow up to 4 feet tall!`,
            `My leaves are striped in ivory and green, and I'll bloom beautiful clusters of flowers from late winter to early spring.`,
            `I crave full sunlight and water whenever my soil is completely dry. In the winter, make sure to only water me once a month, or enough to keep my leaves from shriveling.`,
          ]
        },
      ]
    },
    {
      name: 'September',
      plants: [
        {
          name: 'String of Bananas',
          scientisticName: 'Senecio radicans',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/string-bananas.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/string-of-bananas.pdf',
          instructions: [
            `I'm a brightly colored, fast-growing succulent with vines that can grow up to 3 feet long!`,
            `My green, banana-shaped leaves are slightly translucent and will propagate easily if they fall off the vine.`,
            `I appreciate very dry soil and lots of sunlight, and once a year (springtime is best) I could use a light fertilizing.`,
            `I can live outside in the summer, and when it gets cold enough to frost in the fall, move me back inside.`
          ]
        },
        {
          name: 'Topsy Turvy',
          scientisticName: 'Echeveria runyonii',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/topsy-turvy.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/topsy-turvy.pdf',
          instructions: [
            `I'm a silvery-green succulent with rosettes that can grow up to 10 inches in diameter.`,
            `My leaves are wide and folded length-wise, and I can grow beautiful orange flowers on tall stems in late summer and fall.`,
            `Like other Echeveria, I love bright, indirect light and infrequent drenches of water.`,
            `To avoid mealybugs, don't let me sit in water and remember to remove any dead leaves from the bottom of my stem as needed.`
          ]
        },
      ]
    },
    {
      name: 'August',
      plants: [
        {
          name: 'Flapjacks',
          scientisticName: 'Kalanchoe thyrsiflora',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/flapjacks.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/flapjacks.pdf',
          instructions: [
            `Named for my round, flat leaves, I'm a hardy succulent whose leaves will flush with red when given lots of sunlight.`,
            `I love bright, day-long sun exposure and temperatures above 55 ºF.`,
            `I need regular watering in the summer (just let the top of my soil dry), and in the winter I only need water when my soil is completely dry.`
          ]
        },
        {
          name: 'Dedos',
          scientisticName: 'Sedum pachyphyllum',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/dedos.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/dedos.pdf',
          instructions: [
            `I grow short, finger-like leaves that look like jelly beans! My leaves are range from icy green to pale blue, and often they'll have pink tips.`,
            `As a sedum, I require very little care. I'll thrive in both direct and indirect sunlight, and I appreciate some water whenever my soil is completely dry.`,
            `I'll bloom small yellow flowers in the summer.`
          ]
        },
      ]
    },
    {
      name: 'July',
      plants: [
        {
          name: 'Firecracker',
          scientisticName: 'Crassula capitella subsp. thyrsiflora',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/firecracker.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/firecracker.pdf',
          instructions: [
            `Words don't do me justice! I'm named after my pink-red, pointed leaves that look like fireworks.`,
            `I'll light up the sky when I bloom tiny white flowers at the tops of my rosette chains, which can grow up to 10 inches long.`,
            `I love soaking up bright sunlight and I need water every 7-10 days in the summertime (only when my soil is completely dry).`
          ]
        },
        {
          name: 'Roger',
          scientisticName: 'Crassula rogersii',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/roger.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/roger.pdf',
          instructions: [
            `I'm a small succulent bush with unusual, velvety green leaves. In intense and prolonged sunlight, the tips of my leaves will turn red.`,
            `I'll grow pale yellow flowers that cluster at the ends of long stems.`,
            `Like other crassula, I am super hardy and appreciate lots of sunlight. Err on the side of less water, as I appreciate super dry soil. A good soak every 10 or so days is usually enough for me.`
          ]
        },
      ]
    },
    {
      name: 'June',
      plants: [
        {
          name: 'Rosary Vine',
          scientisticName: 'Crassula Rupestri',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/rosary-vine.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/rosary-vine.pdf',
          instructions: [
            `I'm a shrubby succulent with beatiful, bright green leaves that get pink tips in the summer.`,
            `I will grow little start-shaped pink flowers, and my stems will get woody near the base as I grow taller!`,
            `I appreciate a good soak every 7-10 days (make sure my soil is bone dry first), and please don't leave me sitting in water.`
          ]
        },
        {
          name: 'Coppertone Stonecrop',
          scientisticName: 'Sedum nussbaumerianum',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/coppertone.png',
          pdf: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/docs/coppertone-stonecrop.pdf',
          instructions: [
            `Gardeners joke that I'm easier to care for than a stone!`,
            `I'm the ideal plant for those areas in your home or garden that get intense sunlight.`,
            `I will bloom beautiful little flowers that are white, star-shaped, and fragrant.`,
            `I grow more in cooler months, and I appreciate water whenever my soil is completely dry.`
          ]
        },
      ]
    },
    {
      name: 'May',
      plants: [
        {
          name: 'Lavender Scallops',
          scientisticName: 'Kalenchoe fedtschenkoi',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/lavander-scallops.png',
          pdf: '/assets/docs/lavander_scallops.pdf',
          instructions: [
            'Native to Madagascar, I\'m a flowering succulent with flat, pale green leaves that have scalloped pink edges.',
            'I grow long stems with beautiful, bell-shaped flowers that vary in color from pink to brown.',
            'I love lots of sunlight and regular watering. Be sure to let the top of my soil dry between waterings.'
          ]
        },
        {
          name: 'Tiscalatengo Gorge Sedum',
          scientisticName: 'Sedum clavatum',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/tiscalatengo-gorge-sedum.png',
          pdf: '/assets/docs/sedum_clavatum.pdf',
          instructions: [
            'My beautiful blue-green rosettes can grow up to 2 inches wide, and I\'ll bloom white, star-shaped flowers in late spring / early summer.',
            'As my stems grow, I\'ll lose some leaves until new, younger stems grow.',
            'I enjoy lots of sunlight and dry soil, and I\'ll root very easily wherever you decide to plant me.'
          ]
        },
      ]
    },
    {
      name: 'April',
      plants: [
        {
          name: 'Pink Iceplant',
          scientisticName: 'Oscularia deltoides',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/pink-iceplant.png',
          pdf: '/assets/docs/pink_iceplant.pdf',
          instructions: [
            `I'm a low-growing, versatile succulent with gorgeous dark pink stems and angled green leaves that have little teeth on them.
            In spring, I'll bloom large purple flowers.`,
            'My long stems make me a perfect addition to any hanging basket.',
            'In the warmer months, I need water whenever the top inch of my soil dries out. In cool temps, I only need water once a month.'
          ]
        },
        {
          name: 'Burgundy',
          scientisticName: 'Crassula platyphylla',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/burgundy.png',
          pdf: '/assets/docs/burgundy.pdf',
          instructions: [
            'I\'m an elegant succulent with long green leaves that have burgundy tips. My leaves will become more red in cooler temps.',
            'My stems and leaves propagate easily, and if you remove my center leaves, new rosettes will grow in their place.',
            'I don\'t need watering very often, so err on the side of under-watering me.'
          ]
        },
      ]
    },
    {
      name: 'March',
      plants: [
        {
          name: `Rainbow Bush`,
          scientisticName: `Portulacaria afra f. variegata`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/rainbow-bush.png',
          pdf: '/assets/docs/rainbow_bush.pdf',
          instructions: [
            ` I am a slow-growing succulent shrub with many small, round variegated leaves, which are a pretty pale green, white, and pink in color. My stems propagate easily as cuttings.`,
            `I crave bright, indirect sunlight, as prolonged direct sunlight can burn my leaves.`,
            `In winter, I don't need much water. In warmer months, water me thoroughly every two weeks and make sure my pot has drainage holes.`
          ]
        },
        {
          name: `Lily Pad`,
          scientisticName: 'Aeonium Hybrid',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/lily-pad.png',
          pdf: '/assets/docs/lily_pad.pdf',
          instructions: [
            `I'm a beautiful succulent that grows bunches of bright green, flower-like rosettes. My stems can easily be propagated.`,
            `I'll grow up to a foot tall, and I'll sprout stalks with yellow flowers!`,
            `Water me thoroughly every two weeks, just make sure my soil is completely dry first.`
          ]
        },
      ]
    },
    {
      name: 'February',
      plants: [
        {
          name: `Love Plant`,
          scientisticName: `Anacampseros rufescens`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/love-plant.png',
          pdf: '/assets/docs/love-plant.pdf',
          instructions: [
            `I'm a small succulent that spreads outwards and will only get up to about 4" tall.`,
            `My leaves are colored an olive green, and they'll turn a deep shade of red-purple in bright sunlight.`,
            `I prefer partial sun, and I only need to be watered a couple times a month.`,
          ]
        },
        {
          name: `Baby Donkey's Tail`,
          scientisticName: 'Sedum Burrito',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/baby-donkey.png',
          pdf: '/assets/docs/baby-donkey.pdf',
          instructions: [
            `I'm a pendant sedum with beautiful leaves that can vary from gray-green to blue-green in color.`,
            `A couple weeks after I arrive, I need light, even watering regularly to keep slightly moist. After that, I only need water every 10-14 days.`,
            `If any of my little leaves fall, they can be used to make more of me! Partially nestle any fallen leaves in a soilless medium like sand or perlite and spritz with water daily to propagate.`
          ]
        },
        {
          name: 'Ripple Jade',
          scientisticName: 'Crassula arborescens subsp. undulatifolia Toelken',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/ripple-jade.png',
          pdf: '/assets/docs/ripple-jade.pdf',
          instructions: [
            `I'm a succulent shrub that can grow up to 3 feet tall.`,
            `Be extra careful not to over-water me, and never let me sit in water or my roots will rot.`
          ]
        },
        {
          name: 'Dwarf Jade Plant',
          scientisticName: `Crassula ovata 'Crosbys Compact'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/dwarf-jade.png',
          pdf: '/assets/docs/dwarf-jade.pdf',
          instructions: [
            `I'm a slow-growing, shrubby succulent with beautiful waxy leaves and thick stems.`,
            `My leaves start green when I'm young and will eventually turn a gorgeous red-orange as I grow in bright sunlight.`,
            `Along with bright sun, I like regular watering from spring to autumn (as always, let my soil dry out between watering), but during the winter I only need enough water to keep my leaves from shriveling.`
          ]
        },
        {
          name: 'Baby Necklace',
          scientisticName: `Crassula marnieriana`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/baby-necklace.png',
          pdf: '/assets/docs/baby-necklace.pdf',
          instructions: [
            `Did I arrive with one of my stems beheaded? Lucky you! In just a few weeks, I'll grow two or more rosette heads in its place!`,
            `I'm a fun little Crassula with thick green leaves lined in pink, and I'm easy to grow!`,
            `I do great indoors in the winter, just water me once every 3 weeks. In the summer I'll need a good soak once a week.`
          ]
        },
        {
          name: 'Aeonium Suncup',
          scientisticName: `Aeonium castello-paivae 'Variegata'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/aeonium.png',
          pdf: '/assets/docs/aeonium-suncup.pdf',
          instructions: [
            `I'm a gorgeous succulent whose leaves vary from yellow to green. I'll produce many offsets and bloom white flowers in the summer.`,
            `Unlike other succulents, I enjoy shadier, slightly cooler locations (65-75° F) and will do best in regular potting soil vs. cactus soil.`,
            `Water me whenever my soil is dry from winter to spring, and in the summer months water me less frequently and keep me away from hot, bright sunlight.`,
          ]
        },
        {
          name: 'Red Echeveria',
          scientisticName: `Echeveria ‘Pulv-Oliver’`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/red-echeveria.png',
          pdf: '/assets/docs/red-echeveria.pdf',
          instructions: [
            `I need plenty of natural light and water when my soil completely is dry.`,
            `Please remove any dead leaves as I grow.`,
            `I do best when repotted during a warm season.`
          ]
        },
        {
          name: `Tiger's Jaw`,
          scientisticName: `Faucaria tigrina`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/tiger-jaw.png',
          pdf: '/assets/docs/tigers-jaw.pdf',
          instructions: [
            `I get my name from the many sharp 'teeth' along my leaves.`,
            `In the fall, I'll bloom silky, bright yellow flowers.`,
            `I crave lots of bright sunlight and very little water - only water me when my leaves start feeling rubbery. In extreme heat, I need partial shade and water every few days.`
          ]
        },
        {
          name: `Baby Toes`,
          scientisticName: `Fenestraria`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/baby-toes.png',
          pdf: '/assets/docs/baby-toes.pdf',
          instructions: [
            `Originally from Namibia and South Africa, I am a unique little succulent with window-like, translucent tops on each of my leaves.`,
            `I enjoy bright, indirect sunlight and a thorough watering when my soil is completely dry.`,
            `I need good drainage when potted, and I appreciate a little sand or perlite mixed in with my potting soil.`,
          ]
        },
        {
          name: `Kalanchoe 'Behartii'`,
          scientisticName: `Kalanchoe 'Behartii'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/kalanchoe.png',
          pdf: '/assets/docs/kalanchoe.pdf',
          instructions: [
            `I'm a cute succulent with flat, pointy-edged, pale green leaves that are soft to the touch.`,
            `I need regular watering in hotter months, but otherwise appreciate dry soil and limited water.`,
            `I grow best in partial to full sun, and I can get up to a foot tall.`,
          ]
        },
        {
          name: `Gollum Jade`,
          scientisticName: `Crassula ovata ‘Gollum’`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/gollum-jade.png',
          pdf: '/assets/docs/gollum-jade.pdf',
          instructions: [
            `I am a shrubby succulent that can grow up to 3 feet tall.`,
            `I have tubular, waxy, bright green leaves with red tips. I'll grow little white flowers in the spring.`,
            `I appreciate full sun, and in the winter, I only need enough water to keep my leaves from shriveling.`,
          ]
        },
      ]
    },
    {
      name: 'January',
      plants: [
        {
          name: `Ice Princess`,
          scientisticName: `Crassula x justi-corderoyi`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/ice-princess.png',
          pdf: '/assets/docs/ice-princess.pdf',
          instructions: [
            `I'm a gorgeous hybrid succulent with narrow, textured leaves that range in color from green to gold, then become reddish in intense sunlight.`,
            `With proper care, I'll grow beautiful stems with clusters of light pink flowers mid-summer!`,
            `Like other crassula, I can withstand intense sun but am susceptible to over-watering. Water me once a month in wintertime, and every 10 days in summertime.`,
          ]
        },
        {
          name: `Star Cactus`,
          scientisticName: `Haworthia retusa`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/star-cactus.png',
          pdf: '/assets/docs/star-cactus.pdf',
          instructions: [
            `I'm a cute succulent with thick, pointed leaves, and I grow small white flowers in late spring and early summer.`,
            `I'm slow-growing and stay pretty small - usually just a few inches tall - so I'm perfect for little containers or as part of a succulent arrangement.`,
            `I don't need much water in the wintertime, and I like bright, indirect sun year-round, as I can sunburn in direct sunlight.`
          ]
        }
      ]
    },
    {
      name: 'December',
      plants: [
        {
          name: `Jelly Bean Plant`,
          scientisticName: `Sedum rubrotinctum`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/jelly-bean.png',
          pdf: '/assets/docs/jelly-bean.pdf',
          instructions: [
            `Also called "Christmas Cheer," I get my name from the cute jelly bean-shaped leaves along my stems that range in color from a beautiful green to shades of red and pink.`,
            `I can grow up to 6 inches tall and spread up to 36 inches wide, and I even grow small yellow flowers in the summertime!`,
            `I thrive best in dry soil and areas with intense sunlight. Any fallen leaves will easily propagate, so just nestle them halfway into my soil. They'll eventually sprout roots and grow new plants!`
          ]
        },
        {
          name: `Zebra Wart`,
          scientisticName: `Haworthia reinwardtii`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/zebra-wart.png',
          pdf: '/assets/docs/zebra-wart.pdf',
          instructions: [
            `I am a cute, shrubby succulent that varies in color from red-brown to green. I get my name from the little white markings ("warts") along my leaves.`,
            `Although my growth season is winter, I crave at least 6 hours of bright, indirect sunlight a day. During the hottest months of summer, I'll go dormant and pause growing.`,
            `Perfect for a beginner plant parent, I don't need much care - just set me on a sunny windowsill or in a pot with other succulents and I'll thrive!`
          ]
        }
      ]
    },
    {
      name: 'November',
      plants: [
        {
          name: `Watch Chain`,
          scientisticName: `Crassula muscosa`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/watch-chain.png',
          pdf: '/assets/docs/watch-chain.pdf',
          instructions: [
            `I'm a super unique succulent with tiny green leaves that are tightly stacked around my long stems,
            giving me the nickname "Watch Chain".`,
            `As part of the Crassula family, I'm especially hardy and can withstand cooler temps.`,
            `Plant me in well-draining soil, water me a couple times a month, and give me partial shade... I'll be happy!`
          ]
        },
        {
          name: `Baby Necklace`,
          scientisticName: `Crassula marnieriana`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/baby-necklace.png',
          pdf: '/assets/docs/baby-necklace.pdf',
          instructions: [
            `Did I arrive with one of my stems beheaded? Lucky you! In just a few weeks,
            I'll grow two or more rosette heads in its place! `,
            `I'm a fun little Crassula with thick green leaves lined in pink, and I'm easy to grow!`,
            `I do great indoors in the winter, just water me once every 3 weeks. In the summer I'll need a good soak once a week.`
          ]
        }
      ]
    },
    {
      name: 'October',
      plants: [
        {
          name: `Aeonium Suncup`,
          scientisticName: `Aeonium castello-paivae 'Variegata'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/aeonium.png',
          pdf: '/assets/docs/aeonium-suncup.pdf',
          instructions: [
            `I'm a gorgeous succulent whose leaves vary from yellow to green.
            I'll produce many offsets and bloom white flowers in the summer.`,
            `Unlike other succulents, I enjoy shadier, slightly cooler locations (65-75° F)
            and will do best in regular potting soil vs. cactus soil.`,
            `Water me whenever my soil is dry from winter to spring,
            and in the summer months water me less frequently and keep me away from hot, bright sunlight.`
          ]
        },
        {
          name: `Ghost Plant`,
          scientisticName: `Graptopetalum paraguayense`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/ghost-plant.png',
          pdf: '/assets/docs/ghost-plant.pdf',
          instructions: [
            `I'm a hardy succulent with thick, pointed leaves that vary in color from a light, pastel purple to pale blue.`,
            `My pot needs to have a well-draining soil and drainage holes, and I'll also do great with a mixture of soil, sand, and rock.`,
            `I love a good soak when watered, but these soaks should be infrequent (10-14 days) and only when my soil is dry. `
          ]
        }
      ]
    },
    {
      name: 'September',
      plants: [
        {
          name: `Dwarf Jade Plant`,
          scientisticName: `Crassula ovata 'Crosbys Compact'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/dwarf-jade.png',
          pdf: '/assets/docs/dwarf-jade.pdf',
          instructions: [
            `I'm a slow-growing, shrubby succulent with beautiful waxy leaves and thick stems.`,
            `My leaves start green when I'm young and will eventually turn a gorgeous red-orange as I grow in bright sunlight.`,
            `Along with bright sun, I like regular watering from spring to autumn (as always, let my soil dry out between watering), but during the winter I only need enough water to keep my leaves from shriveling.`
          ]
        },
        {
          name: `Tiger Tooth Aloe`,
          scientisticName: `Aloe juvenna`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/tiger-tooth-aloe.png',
          pdf: '/assets/docs/tiger-tooth-aloe.pdf',
          instructions: [
            `I have beautiful bright green leaves that are flecked with white and turn red-brown in full sun.`,
            `I rarely need repotting, but if I'm outgrowing my pot or I've stopped growing, it's best to repot me during the spring. Use a mixture of 2/3 cactus soil and 1/3 sand or pebbles.`,
            `I should never be left to sit in stagnant water and love intense sunlight. Ease me from indirect sun into direct sun slowly, adding about 1 additional hour in direct sun a day until I can tolerate full, direct sunlight.`
          ]
        },
      ]
    },
    {
      name: 'August',
      plants: [
        {
          name: `Baby Donkey's Tail`,
          scientisticName: `Sedum burrito`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/baby-donkey.png',
          pdf: '/assets/docs/baby-donkey.pdf',
          instructions: [
            `I'm a pendant sedum with beautiful leaves that can vary from gray-green to blue-green in color.`,
            `A couple weeks after I arrive, I need light, even watering regularly to keep slightly moist. After that, I only need water every 10-14 days.`,
            `If any of my little leaves fall, they can be used to make more of me! Partially nestle any fallen leaves in a soilless medium like sand or perlite and spritz with water daily to propagate.`
          ]
        },
        {
          name: `Drunkard's Dream`,
          scientisticName: `Hatiora salicornioides`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/drunkards-dream.png',
          pdf: '/assets/docs/drunkards-dream.pdf',
          instructions: [
            `I'm a bushy cactus with long, segmented stalks that can grow up to two feet tall!`,
            `I will flower beautiful yellow flowers at the tops of my stalks.`,
            `I need about a month’s rest after I flower, so water sparingly during this period. Also - try not to move me after I flower or my fragile flowers might fall off!`
          ]
        },
      ]
    },
    {
      name: 'July',
      plants: [
        {
          name: `Happy Young Lady`,
          scientisticName: `Cotyledon 'Happy Young Lady'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/happy-young-lady.png',
          pdf: '/assets/docs/happy-young-lady.pdf',
          instructions: [
            `I have pretty, purple-tipped leaves and love lots of sunlight!`,
            `I appreciate fertilizer a couple times during my growing season (the summer). Just be sure to pick one that is specifically formulated for cactus and succulents.`,
            `In the summer, I'll grow long stalks that will bloom orange bell-shaped flowers.`
          ]
        },
        {
          name: `Pink Moonstones`,
          scientisticName: `Pachyphytum oviferum Purpus`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/pink-moonstones.png',
          pdf: '/assets/docs/pink-moonstones.pdf',
          instructions: [
            `I get my name from my gorgeous, pink-gray leaves.`,
            `I love full sun and well-draining soil, but can also tolerate partial sun. I cannot tolerate frost.`,
            `My growing season is in the winter, and during this time I will grow stalks with red-orange flowers, and I'll require more regular watering. You'll be able to tell I need water because my leaves will appear a bit wilted and my soil will be completely dry.`
          ]
        }
      ]
    },
    {
      name: 'June',
      plants: [
        {
          name: `Variegated Bear's Paws`,
          scientisticName: `Cotyledon tomentosa 'Variegata'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/bears-paw.png',
          pdf: '/assets/docs/bears-paw.pdf',
          instructions: [
            `I get my name from the little brown-red "claws" that will grow on the tips of my leaves as I mature.`,
            `My growing season is in the summer, which is when I need more regular watering.`,
            `Be sure to keep me in well-draining soil and plenty of sunlight.`
          ]
        },
        {
          name: `Graptosedum 'Bronze'`,
          scientisticName: `Faucx Graptosedum 'Bronze'`,
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/bronze.png',
          pdf: '/assets/docs/bronze.pdf',
          instructions: [
            `I'm a small reddish-brown succulent with leaves that whorl around my stem.`,
            `I can grow up to 6 inches tall, and I'll eventually spread or drape over the edge of my pot.`,
            `I grow very easily and need standard care: lots of sunlight, more water in the summer and less in the winter. Be sure not to over-water me.`
          ]
        }
      ]
    },
    {
      name: 'May',
      plants: [
        {
          name: 'String of Buttons',
          scientisticName: 'Crassula perforata',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/string-buttons.png',
          pdf: '/assets/docs/string-of-buttons.pdf',
          instructions: [
            'Native to South Africa, I get my name from the flat bunches of leaves on my stems that look like little buttons.',
            'While I\'m young, my stems will stand up straight, and as I grow they\'ll become pendant, making me a great addition to a hanging basket.',
            'I grow relatively quickly, crave well-draining soil and full sun, and I appreciate partial midday shade in hot summer climates.'
          ]
        },
        {
          name: 'Belle Blue',
          scientisticName: 'Pachyveria \'Belle Blue\'',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/belle-blue.png',
          pdf: '/assets/docs/belle-blue.pdf',
          instructions: [
            'I have gorgeus leaves that vary in color from a muted powdery blue to pale lavander.',
            'My leaves grow together in compact bunches, and once a year, I will grow a long stem with pretty pink-red flowers on it.',
            'I am a sibiling of the Powder Puff succulent, and my care is similar: I crave dry soil and intense sunlight.'
          ]
        }
      ]
    },
    {
      name: 'April',
      plants: [
        {
          name: 'Cobweb Houseleek',
          scientisticName: 'Sempervivum arachnoideum L.',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/cobweb-houseleek.png',
          pdf: '/assets/docs/cobweb-houseleek.pdf',
          instructions: [
            'I\'m less spooky than I look! I am a low-growing plant with fine, cobweb-like hairs at the tips of my leaves.',
            'I will grow pink flowers once a year.',
            'During the winter months, I don\'t need much water at all. During the summer, I need more regular watering (just make sure my soil is dry in between waterings).'
          ]
        },
        {
          name: 'Chocolate Soldier / Panda Bear Plant',
          scientisticName: 'Kalanchoe tomentosa',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/chocolate-soldier.png',
          pdf: '/assets/docs/chocolate-soldier.pdf',
          instructions: [
            'No matter which name you call me, I\'m a very low-maintenance succulent that will grow adorable, bell-shaped golden flowers.',
            'I grow slowly, so please be patient with me!',
            'I like medium to full light and very little water.'
          ]
        },
        {
          name: 'Blue Bird',
          scientisticName: 'Crassula arborescens subsp. undulatifolia',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/blue-bird.png',
          pdf: '/assets/docs/blue-bird.pdf',
          instructions: [
            'I\'m a close relative of the Ripple Jade, and like my cousin, I cannot tolerate over-watering.',
            'When repotting me, make sure to gently crumble away old soil from my roots before putting me in fresh, dry cactus soil and do not water me for a week or so. This will help keep me healthy.'
          ]
        },
        {
          name: 'Coppertone Stonecrop',
          scientisticName: 'Sedum nussbaumerianum',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/coppertone.png',
          pdf: '/assets/docs/coppertone-stonecrop.pdf',
          instructions: [
            'I come in many different tones of copper and yellow, and gardeners joke that I\'m easier to care for than a stone - hence my name!',
            'I\'m the ideal plant for those areas in your home or garden that get intense sunlight.',
            'I will bloom beautiful little flowers that are white, star-shaped, and fragrant.'
          ]
        }
      ]
    },
    {
      name: 'March',
      plants: [
        {
          name: 'Pink Jelly Bean',
          scientisticName: 'Sedum rubrotinctum \'Aurora\'',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/pink-jelly-bean.png',
          pdf: '/assets/docs/pink-jelly-bean.pdf',
          instructions: [
            'I get my name from the jelly bean-shaped, red-brown leaves along my stems.',
            'I can grow up up to 6 inches tall and spread up to 36 inches wide, and I even grow small flowers in the summertime.',
            'I will thrive best in dry soil and areas with intense sunlight.'
          ]
        },
        {
          name: 'Powder Puff',
          scientisticName: 'x Pachyveria',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/powder-puff.png',
          pdf: '/assets/docs/powder-puff.pdf',
          instructions: [
            'I\'m a hybrid between Pachyphytum oviferum and Echeveria cante, and I crave dry soil and intense sunlight.',
            'My leaves will turn a pastel shade of violet in strong sunlight.',
            'I\'ll grow beautiful, bell-shaped cascading flowers in the summer.'
          ]
        },
        {
          name: 'Tiger\'s Jaw',
          scientisticName: 'Faucaria tigrina',
          image: 'https://s3-us-west-1.amazonaws.com/assets.succulent.studio/images/plants-new/tiger-jaw.png',
          pdf: '/assets/docs/tigers-jaw.pdf',
          instructions: [
            'I got my name from the many sharp \'teeth\' along my leaves.',
            'I\'m a perennial that will bloom silky, bright yellow flowers in the fall.',
            'In extreme heat, I need some shade and water every few days. Otherwise, I will be just fine with occasional water and full-time sun.'
          ]
        },
      ]
    }
  ];

  /* tslint:enable */

  constructor(
    private metaService: Meta,
    private titleService: Title
  ) {

  }

  ngOnInit(): void {
    this.metaService.updateTag({
      name: 'description',
      content: 'Get the names, care instructions, and descriptions of all your favorite succulents!'
    });

    this.titleService.setTitle('Succulent Identification & Care Tips | Succulent Studios');
  }
}
