import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LeadFormBaseComponent } from '@app/shared/components/lead-forms/lead-form-base/lead-form-base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LeadsService } from '@app/core/services/leads.service';
import { ErrorService } from '@app/error';
import { LocalStorageService } from 'ngx-webstorage';
import { AppConfig } from '@app/app.config';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { MarketingService } from '@app/core/services/marketing.service';
import { GmailModalService } from '@app/core/services/gmail-modal.service';
var RaceFansLeadFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RaceFansLeadFormComponent, _super);
    function RaceFansLeadFormComponent(fb, route, leadService, errorService, localStorage, platformId, app_config, marketingService, gmailModalService) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.route = route;
        _this.leadService = leadService;
        _this.errorService = errorService;
        _this.localStorage = localStorage;
        _this.platformId = platformId;
        _this.app_config = app_config;
        _this.marketingService = marketingService;
        _this.gmailModalService = gmailModalService;
        _this.savingLead = false;
        _this.form = new FormGroup({
            email: new FormControl('', Validators.email),
        });
        return _this;
    }
    RaceFansLeadFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.emailFormField = 'email';
        this.form.get('email').valueChanges
            .pipe(filter(function (data) { return typeof data === 'string' && data.indexOf('@') !== -1; }), debounceTime(1000), distinctUntilChanged())
            .pipe(takeUntil(this.destroyComponent$))
            .subscribe(function (email) {
            _this.testSuggestedEmails(email);
        });
    };
    RaceFansLeadFormComponent.prototype.submitLeadForm = function () {
        var _this = this;
        var _a = this.form.value, email = _a.email, phone = _a.phone;
        if (this.form.invalid || email === '' && phone === '') {
            return;
        }
        // This is a quick fix to avoid people clicking multiple times
        this.savingLead = true;
        if (email && email.includes('@gmail.co')) {
            this.gmailModalService.showGmailModal$.next(true);
        }
        this.createLead(email, phone)
            .then(function () {
            _this.submit.emit({ email: email });
            _this.closeLeadForm();
        })
            .catch(function (error) {
            _this.closeLeadForm();
            var message = "Error trying to create/update Lead.";
            _this.errorService.client.notify(error, {
                beforeSend: function (report) {
                    report.severity = 'warning';
                    report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message: message,
                        error: error,
                    });
                }
            });
        });
    };
    RaceFansLeadFormComponent.prototype.createLead = function (email, phone) {
        var _this = this;
        if (email === void 0) { email = ''; }
        if (phone === void 0) { phone = ''; }
        var uuid = this.localStorage.retrieve('uuid');
        var utmData = this.route.snapshot.queryParams;
        this.localStorage.store('customerEmail', email);
        return this.leadService
            .create({ uuid: uuid, email: email, phone: phone, utmData: utmData, leadForm: 'six-off' })
            .toPromise()
            .then(function (data) {
            if (isPlatformBrowser(_this.platformId) && _this.app_config.production && _this.app_config.name === 'production') {
                _this.marketingService
                    .reportLead(email)
                    .subscribe();
            }
            return data;
        });
    };
    return RaceFansLeadFormComponent;
}(LeadFormBaseComponent));
export { RaceFansLeadFormComponent };
