import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'sucstu-faq',
  styleUrls: ['./faq.component.scss'],
  templateUrl: 'faq.component.html'
})
export class FaqComponent implements OnInit {

  constructor(
    private metaService: Meta,
    private titleService: Title
  ) {

  }

  ngOnInit(): void {
    this.metaService.updateTag({
      name: 'description',
      content: 'Questions about our succulent subscription box? We\'re here to help!'
    });

    this.titleService.setTitle('Succulent Studios Frequently Asked Questions');
  }
}
