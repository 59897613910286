import * as tslib_1 from "tslib";
var _a;
import { createSelector } from '@ngrx/store';
import * as fromRoot from '@app/store/selectors';
import * as fromSubscriptions from '@app/store/reducers/subscription.reducer';
import * as fromPlans from '@app/store/selectors/plans.selector';
export var getSubscriptionsIds = (_a = fromSubscriptions.adapter.getSelectors(fromRoot.getSubscriptionsState), _a.selectIds), getSubscriptionEntities = _a.selectEntities, getTotalSubscriptions = _a.selectTotal;
export var getAllSubscriptions = createSelector(fromSubscriptions.adapter.getSelectors(fromRoot.getSubscriptionsState).selectAll, fromPlans.getPlansEntities, function (subscriptions, plans) {
    return subscriptions.map(function (subscription) {
        var plan = plans[subscription.plan];
        return tslib_1.__assign({}, subscription, { plan: plan });
    });
});
export var getSubscriptionsLoaded = createSelector(fromRoot.getSubscriptionsState, function (state) { return state.loaded; });
export var getSubscriptionsLoading = createSelector(fromRoot.getSubscriptionsState, function (state) { return state.loading; });
export var getSubscriptionSelected = createSelector(fromRoot.getSubscriptionsState, function (state) { return state.selected; });
export var getSubscriptionById = function (id) { return createSelector(getSubscriptionEntities, (function (entities) { return entities[id]; })); };
export var getSubscriptionError = createSelector(fromRoot.getSubscriptionsState, function (state) { return state.error; });
export var getSelectedSubscriptionEntity = createSelector(getSubscriptionEntities, getSubscriptionSelected, fromPlans.getPlansEntities, function (subscriptions, selectedId, plans) {
    var subscription = subscriptions[selectedId];
    if (subscription) {
        var plan = plans[subscription.plan];
        return tslib_1.__assign({}, subscription, { plan: plan });
    }
    return;
});
