import { Component } from '@angular/core';
import * as fromServices from './../../../core/services';


@Component({
  selector: 'sucstu-pages-churn',
  styleUrls: ['./churn.component.scss'],
  templateUrl: './churn.template.html'
})

export class ChurnComponent {
  
  churnNumbers = {
    turnedOffByDP : "000",
    turnedOffByCustomer: "000",
    delta: "000",
    gift_completed: "000"
  }
  
  constructor ( private StatsService: fromServices.StatsService) {  
  }
  
  ngOnInit() {
   /* this.fetchChurn();
    setInterval(()=>{
      this.fetchChurn();
    }, 60000)*/
  }
  
  fetchChurn () {
   /* this.StatsService.fetchChurn().subscribe(response => {
      this.churnNumbers.turnedOffByDP = response.turnedOff.byDP < 100 ? response.turnedOff.byDP < 10 ? '00' + String(response.turnedOff.byDP): '0' + String(response.turnedOff.byDP) : String(response.turnedOff.byDP);
      this.churnNumbers.turnedOffByCustomer = response.turnedOff.byUser < 100 ? response.turnedOff.byUser < 10 ? '00' + String(response.turnedOff.byUser): '0' + String(response.turnedOff.byUser) : String(response.turnedOff.byUser);
      this.churnNumbers.delta = response.offSubsAndDelta.delta < 100 ? response.offSubsAndDelta.delta < 10 ? '00' + String(response.offSubsAndDelta.delta): '0' + String(response.offSubsAndDelta.delta) : String(response.offSubsAndDelta.delta);
      this.churnNumbers.gift_completed = response.giftComplete < 100 ? response.giftComplete < 10 ? '00' + String(response.giftComplete): '0' + String(response.giftComplete) : String(response.giftComplete);
    });*/
  }

}