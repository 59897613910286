import * as tslib_1 from "tslib";
import * as user from '@app/store/actions/user.actions';
export var initialState = {
    data: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        role: '',
        referralCode: null,
        referrals: [],
        createdAt: null,
        updatedAt: null,
        selectedMonthBoxes: []
    },
    loaded: true,
    loading: false,
    isAuthenticated: false,
    error: null
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case user.LOGIN_COMPLETE:
        case user.CREATE_COMPLETE:
        case user.UPDATE_COMPLETE:
        case user.LOAD_COMPLETE: {
            var data = tslib_1.__assign({}, state.data, action.payload);
            return tslib_1.__assign({}, state, { isAuthenticated: true, data: data, loaded: true, loading: false, error: null });
        }
        case user.LOGOUT_COMPLETE: {
            return tslib_1.__assign({}, initialState, { error: null });
        }
        case user.LOGIN_FAIL:
        case user.CREATE_FAIL:
        case user.LOAD_FAIL: {
            return tslib_1.__assign({}, state, { error: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getUserData = function (state) { return state.data; };
export var getUserLoaded = function (state) { return state.loaded; };
export var getUserLoading = function (state) { return state.loading; };
export var getUserIsAuthenticated = function (state) { return state.isAuthenticated; };
export var getUserError = function (state) { return state.error; };
