import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

@Injectable()
export class ContentSubmissionService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  // submitContent(data) {
  //   return this.httpClient
  //     .post(`${this.app_config.api_uri}/content-submission`, data, {
  //       reportProgress: true,
  //       observe: 'events',
  //     })
  //     .pipe(
  //       map(event => {
  //         switch (event.type) {
  //           case HttpEventType.UploadProgress:
  //             const progress = Math.round(100 * event.loaded / event.total);
  //             return { status: 'progress', message: progress };
  //           case HttpEventType.Response:
  //             return event.body;
  //           default:
  //             return `Unhandled event: ${event.type}`;
  //         }
  //       })
  //     );
  // }

  submitContent(data) {
    return this.httpClient
      .post(`${this.app_config.api_uri}/content-submission`, data)
  }
}
