import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

// actions
import * as coupon from '@app/store/actions/coupon.actions';

// services
import { CouponsService } from '@app/core/services/coupons.service';

// models
import { Coupon } from '@app/core/models/coupon.model';
import * as fromPlans from '@app/store/selectors/plans.selector';

@Injectable()
export class CouponsEffects {

  @Effect({dispatch: false}) verifyCoupon$ = this.actions$
    .pipe(
      ofType(coupon.VERIFY_COUPON),
      map((action: coupon.VerifyCouponAction) => action.payload),
      withLatestFrom(this.store.pipe(select(fromPlans.getSelectedPlanId))),
      switchMap(([couponCode, planId]) => {
          return this.couponsService.verifyCoupon(couponCode, planId)
            .pipe(
              map((couponObj: Coupon) => {
                this.store.dispatch(new coupon.VerifyCouponCompleteAction(couponObj));
              }),
              catchError(reason => {
                this.store.dispatch(new coupon.VerifyCouponFailAction(reason.errors));
                return of(null);
              })
            )
        }
      )
    );

  @Effect({dispatch: false}) verifyClaimCoupon$ = this.actions$
    .pipe(
      ofType(coupon.VERIFY_CLAIM_COUPON),
      map((action: coupon.VerifyClaimCouponAction) => action.payload),
      withLatestFrom(this.store.pipe(select(fromPlans.getSelectedPlanId))),
      switchMap(([payload, planId]) => {
          return this.couponsService.verifyCoupon(payload.coupon, planId, payload.claimCode)
            .pipe(
              map((couponObj: Coupon) => {
                if (couponObj) {
                  this.store.dispatch(new coupon.VerifyCouponCompleteAction(couponObj));
                }
                this.store.dispatch(new coupon.VerifyClaimCouponCompleteAction(payload.claimCode));
              }),
              catchError(reason => {
                this.store.dispatch(new coupon.VerifyCouponFailAction(reason.errors));
                return of(null);
              })
            )
        }
      )
    );

  @Effect({dispatch: false}) verifyCouponComplete$ = this.actions$
    .pipe(
      ofType(coupon.VERIFY_COUPON_COMPLETE),
      map((action: coupon.VerifyCouponCompleteAction) => action.payload),
      tap((coupon: Coupon) => this.sessionStorage.store('coupon', JSON.stringify(coupon)))
    );

  @Effect({dispatch: false}) verifyClaimCouponComplete$ = this.actions$
    .pipe(
      ofType(coupon.VERIFY_CLAIM_COUPON_COMPLETE),
      map((action: coupon.VerifyClaimCouponCompleteAction) => action.payload),
      tap((claimCode: string) => this.sessionStorage.store('claimCode', claimCode))
    );

  @Effect({dispatch: false}) clearCoupon$ = this.actions$
    .pipe(ofType(coupon.CLEAR_COUPON))
    .pipe(
      tap(() => {
        this.localStorageService.clear('showCouponNotification');
        this.sessionStorage.clear('coupon')
      })
    );


  constructor(
    private couponsService: CouponsService,
    private actions$: Actions,
    private store: Store<any>,
    private sessionStorage: SessionStorageService,
    private localStorageService: LocalStorageService,
  ) {
  }

}

