import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as upperFirst from 'lodash/upperFirst';

@Component({
  selector: 'sucstu-gift-form',
  styleUrls: ['./gift-form.component.scss'],
  templateUrl: 'gift-form.component.html'
})
export class GiftFormComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() showErrors = false;

  constructor() { }

  ngOnInit() {
    const shipmentFirstNameControl = this.form.get('recipient.firstName');
    const shipmentLastNameControl = this.form.get('recipient.lastName');
    shipmentFirstNameControl.valueChanges.subscribe(value => {
      const newValue = shipmentFirstNameControl.value.split(' ').map(upperFirst).join(' ');
      shipmentFirstNameControl.setValue(newValue, { emitEvent: false });
    });
    shipmentLastNameControl.valueChanges.subscribe(value => {
      const newValue = shipmentLastNameControl.value.split(' ').map(upperFirst).join(' ');
      shipmentLastNameControl.setValue(newValue, { emitEvent: false });
    });
  }

  isFieldValid(field: string, validation: string) {
    const control = this.form.get(field);
    return this.showErrors && control.hasError(validation) || control.hasError(validation) && control.touched;
  }

}
