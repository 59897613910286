import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {filter, pluck, skip, take} from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-console-viewer',
  templateUrl: './console-viewer.component.html',
  styleUrls: ['./console-viewer.component.scss']
})
export class ConsoleViewerComponent implements OnInit {
  showConsole$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  logs$: BehaviorSubject<Array<{ name: string; text: string }>> = new BehaviorSubject<Array<{ name: string, text: string }>>([]);
  expand$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(PLATFORM_ID) private platform: any,
    private readonly route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.queryParams
      .pipe(skip(1))
      .pipe(take(1))
      .pipe(pluck(('stcos')))
      .pipe(filter(value => value === 'yes'))
      .pipe(filter(() => isPlatformBrowser(this.platform)))
      .subscribe(() => {
        this.updateConsoleMethods();
        this.showConsole$.next(true);
      });
  }

  updateConsoleMethods() {
    try {
      this.consoleMethod('log');
      this.consoleMethod('error');
      const that = this;
      window.onerror = function(event: Event | string, source?: string, fileno?: number, columnNumber?: number, error?: Error) {
        that.logs$.next([
          ...that.logs$.getValue(),
          { name, text: typeof error === 'object' ? JSON.stringify(error) : error }
        ]);
      }
    } catch (e) {

    }
  }

  consoleMethod(name: 'log' | 'debug' | 'warn' | 'error' | 'info') {
    const that = this;
    console[name] = function(...args: Array<string | object>) {
      that.logs$.next([
        ...that.logs$.getValue(),
        ...args.map(arg => ({ name, text: typeof arg === 'object' ? JSON.stringify(arg) : arg })),
      ]);
    }
  }

  toggleExpand() {
    this.expand$.next(!this.expand$.getValue());
  }

/*  fixLoggingFunc(name) {
    console['old' + name] = console[name];
    console[name] = function(...args) {
      const output = produceOutput(name, args);
      const eleLog = eleLocator();

      if (autoScroll) {
        const eleContainerLog = eleOverflowLocator();
        const isScrolledToBottom = eleContainerLog.scrollHeight - eleContainerLog.clientHeight <= eleContainerLog.scrollTop + 1;
        eleLog.innerHTML += output + "<br>";
        if (isScrolledToBottom) {
          eleContainerLog.scrollTop = eleContainerLog.scrollHeight - eleContainerLog.clientHeight;
        }
      } else {
        eleLog.innerHTML += output + "<br>";
      }

      console['old' + name].apply(undefined, args);
    };
  }

  produceOutput(name, args) {
    return args.reduce((output, arg) => {
      return output +
        "<span class=\"log-" + (typeof arg) + " log-" + name + "\">" +
        (typeof arg === "object" && (JSON || {}).stringify ? JSON.stringify(arg) : arg) +
        "</span>&nbsp;";
    }, '');
  }*/

}
