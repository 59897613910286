import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var ClaimGiftGuard = /** @class */ (function () {
    function ClaimGiftGuard(router) {
        this.router = router;
    }
    ClaimGiftGuard.prototype.canActivate = function (route, state) {
        if (!!route.queryParams.subscriptionId) {
            return true;
        }
        else {
            return this.router.navigate(['/'])
                .then(function () { return false; });
        }
    };
    ClaimGiftGuard.ngInjectableDef = i0.defineInjectable({ factory: function ClaimGiftGuard_Factory() { return new ClaimGiftGuard(i0.inject(i1.Router)); }, token: ClaimGiftGuard, providedIn: "root" });
    return ClaimGiftGuard;
}());
export { ClaimGiftGuard };
