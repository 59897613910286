import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import * as uniq from 'lodash/uniq';

// RxJS
import { BehaviorSubject, interval } from 'rxjs';
import { distinctUntilChanged, switchMap, filter, tap, map } from 'rxjs/operators';

// services
import { ContentSubmissionService, NotificationBarService } from '@app/core/services';
import { Router } from '@angular/router';

@Component({
  selector: 'sucstu-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  displayErrors = false;
  form = this.fb.group({
    firstName: ['', Validators.required],
    socialMediaUser: ['', Validators.required],
    files: this.fb.array([], [Validators.required]),
  });

  private _filesErrors$ = new BehaviorSubject([]);
  filesErrors$ = this._filesErrors$.asObservable().pipe(
    distinctUntilChanged(),
    map(data => uniq(data)),
  );

  submitting = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private contentSubmission: ContentSubmissionService,
    private notificationService: NotificationBarService,
  ) { }

  ngOnInit() {
    this.filesErrors$.pipe(
      filter(data => data.length > 0),
      switchMap(() => interval(8000)),
      tap(() => this._filesErrors$.next([])),
    ).subscribe();
  }

  onFileChange(event) {
    this.clearFileList();
    this._filesErrors$.next([]);
    const filesList = this.form.get('files') as FormArray;
    let totalSize = 0;
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const fileSize = file.size / 1024 / 1024;
      totalSize += fileSize;

      if (totalSize < 200 && fileSize < 200 && this.checkExtension(file.name)) {
        filesList.push(new FormControl(file));
      } else if (totalSize > 200) {
        this.addNewError(`Can't upload "${file.name}" because you have upload too many files.`);
      } else if (fileSize > 200) {
        this.addNewError(`Can't upload "${file.name}" because is too large.`);
      } else if (!this.checkExtension(file.name)) {
        this.addNewError(`Can't upload "${file.name}" because it is an unsupported file format`);
      }

    }
  }

  submitForm() {
    if (this.form.invalid) {
      this.displayErrors = true;
      if (this.form.value.files.length < 1) {
        this.addNewError('You have to select at least one file.');
      }
      return;
    }

    const value = this.form.value;
    const formData = new FormData();
    for (let i = 0; i < value.files.length; i++) {
        formData.append('files[]', value.files[i], value.files[i]['name']);
    }
    formData.append('firstName', value.firstName);
    formData.append('socialMediaUser', value.socialMediaUser);

    this.submitting = true;
    this.contentSubmission.submitContent(formData).subscribe(
      res => {
        this.submitting = false;
        this.router.navigate(['/']).then(() => {
          this.notificationService.open({
            title: 'Content Upload Success',
            message: 'Your files have been uploaded',
            type: 'success'
          });
        });
      },
      error => {
        this.submitting = false;
        this.addNewError(error.message);
      }
    );
  }

  onClearFiles() {
    this.fileInput.nativeElement.value = '';
    this.clearFileList();
  }

  clearFileList() {
    const filesList = this.form.get('files') as FormArray;
    while (filesList.value.length !== 0) {
      filesList.removeAt(0);
    }
  }

  checkExtension(fileName: string) {
    const valToLower = fileName.toLowerCase();
    const regex = new RegExp('(.*?)\.(jpg|png|jpeg|gif|wmv|mp4|mov|avi)$');
    return regex.test(valToLower);
  }

  isFieldValid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && this.displayErrors;
  }

  addNewError(error) {
    const errors = uniq([...this._filesErrors$.value, error]);
    this._filesErrors$.next([...errors]);

  }
}
