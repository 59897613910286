export const environment = {
  name: 'production',
  slug: 'succulent-studio',
  production: true,
  api_uri: 'https://succulent.studio/api',
  api_uri_v2: '/api-v2',
  api_customers_v2: 'https://a2pnfgckpj.us-east-1.awsapprunner.com',
  app_version: '2.0.4',
  btClientToken: 'production_5r4mvgrg_fw4bbg4skkshkwwh',
  payment_processor: 'edgepay',
  edgepay_pivot: 'https://static.edgepayportal.com/public/js/edgepayPivot.min.js',
  googlePayMerchantId: 'BCR2DN6TZPJZT6TK',
  gtmContainerId: 'GTM-NLPCRNZ',
  propsToClearOnLS: {
    selectedPlanId: true,
    checkoutUser: true
  },
  pca_predict: {
    key: 'ZW39-HX97-ZC55-NM99',
    api_endpoint: 'https://services.postcodeanywhere.co.uk/Capture/Interactive/Find/v1.00/json.ws'
  },
  bugSnagKey: '96ce495f6077e8c211d161dbbe1a6f33',

  // Angler AI
  anglerAiWorkspaceId: 'ws-succulent-studios-5_btj_62r',
  anglerAiToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTkzODU1MDQ5MTAsImV4cCI6MTc2MjQ1NzUwNDkxMCwic3ViIjoid3Mtc3VjY3VsZW50LXN0dWRpb3MtNV9idGpfNjJyIiwic2NvcGVzIjoiQ0xJRU5UX0VWRU5UUyIsImlzcyI6ImYwZWRhM2UzLWQwZDEtNDkxZC04OWQ5LWY4MWNlOTkyN2EzZiIsImp0aSI6IkVnZE5tUDE3ZUFHWDRRaVJEb2lQcSJ9.GMoLUAX--SNUltsgeaiSfiQfCbgUfSZA02IY1biUf7dpjoLuzdbhY-5xR34MpdMERS9ZeMUOPnuRYCBY27Grj3cJVV_LttN-emb8AcFV39fa2myeGKjF5-VekHha9vrBvImH4jlxwFCfbcvsyAwqbnMn-y3Pf76nb6bZ_P-T4KoKxzZdSblfXBM76Rg2TqXqC9aLtqkbn-sdVfoh2b5tQt5OOItUEXX9feF7SNEi4HzGvQWbk4bwYb-Rh5g6VDrt_-M2ceJa6pVDhLvpog-WIKbsPHrgtvHQ8bzqyWAcx5CiVSsYKtu_7AF1lOy1-jm5FeCtm4fOokM241XO1Di8FQ',
};
