import { Action } from '@ngrx/store';
import { Box } from '@app/core/models/box.model';

export const LOAD_BOXES = '[COUPON] Load Boxes';
export const LOAD_BOXES_COMPLETE = '[Coupon] Load Boxes Complete';
export const LOAD_BOXES_FAIL = '[Coupon] Load Boxes Fail';

export const LOAD_BOX = '[COUPON] Load Box';
export const LOAD_BOX_COMPLETE = '[Coupon] Load Box Complete';
export const LOAD_BOX_FAIL = '[Coupon] Load Box Fail';

export class LoadBoxesAction implements Action {
  readonly type = LOAD_BOXES;
  constructor() {}
}

export class LoadBoxesCompleteAction implements Action {
  readonly type = LOAD_BOXES_COMPLETE;
  constructor(public payload: Box[]) {}
}

export class LoadBoxesFailAction implements Action {
  readonly type = LOAD_BOXES_FAIL;
  constructor(public payload: any) {}
}

export class LoadBoxAction implements Action {
  readonly type = LOAD_BOX;
  constructor(public payload: { id: string }) {}
}

export class LoadBoxCompleteAction implements Action {
  readonly type = LOAD_BOX_COMPLETE;
  constructor(public payload: Box) {}
}

export class LoadBoxFailAction implements Action {
  readonly type = LOAD_BOX_FAIL;
  constructor(public payload: any) {}
}

export type Actions =
  | LoadBoxesAction
  | LoadBoxesCompleteAction
  | LoadBoxesFailAction
  | LoadBoxAction
  | LoadBoxCompleteAction
  | LoadBoxFailAction;
