import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HttpEvent, HttpEventType } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
// actions
import * as fromUserActions from './../actions/user.actions';
import * as searchEmail from './../actions/search-email.actions';
import * as paymentMethod from './../actions/payment-methods.actions';
import * as subscriptionActions from './../actions/subscription.action';
// models
// services
import { AuthService } from '@app/auth/services/auth.service';
import { UserService } from '@app/core/services/user.service';
import { ErrorService } from '@app/error';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

declare var FS: any;
declare var __insp: any;

@Injectable()
export class AuthEffects {

  @Effect({ dispatch: false }) load$ = this.actions$
    .pipe(
      ofType(fromUserActions.LOAD),
      switchMap(() => {

        if (!this.authService.tokenPayload) {
          return of(new fromUserActions.LoadFailAction([{ message: 'User not authenticated!' }]));
        }

        return this.authService.profile()
          .pipe(
            map((data: any) => {
              this.store.dispatch(new fromUserActions.LoadCompleteAction(data.user));

              this.errorService.setClientReporterUser(
                data.user._id,
                data.user.fullName,
                data.user.email
              );
              // this.store.dispatch(new paymentMethod.LoadPaymentMethodsCompleteAction(data.user.paymentMethods));
            }),
            catchError(reason => {
              this.store.dispatch(new fromUserActions.LoadFailAction(reason.errors));
              this.authService.removeJWTToken();
              return of(new fromUserActions.LoadFailAction(reason.errors));
            }),
          );
      })
    );

  @Effect({ dispatch: false }) login$ = this.actions$
    .pipe(
      ofType(fromUserActions.LOGIN),
      map((action: fromUserActions.LoginAction) => action.payload),
      switchMap(
        auth => this.authService.loginUser(auth)
          .pipe(
            map((event: HttpEvent<any>) => {
              if (event.type === HttpEventType.Response) {
                const user = event.body.user;
                // Set FullStory user
                if (isPlatformBrowser(this.platformId) && typeof FS !== 'undefined' && 'FS' in window) {
                  console.log('Set FullStory user');
                  FS.identify(user._id, {
                    displayName: user.fullName,
                    email: user.email
                  });
                }

                if (isPlatformBrowser(this.platformId) && typeof __insp !== 'undefined' && '__insp' in window) {
                  __insp.push(['identify', user.email]);
                }

                this.errorService.setClientReporterUser(
                  user._id,
                  user.fullName,
                  user.email
                );

                this.store.dispatch(new fromUserActions.LoginCompleteAction(user));
              }
            }),
            catchError(reason => {
              this.store.dispatch(new fromUserActions.LoginFailAction(reason.error));
              return of(new fromUserActions.LoginFailAction(reason.error));
            }),
          )
      ),
    );

  @Effect({ dispatch: false }) update$ = this.actions$
    .pipe(
      ofType(fromUserActions.UPDATE),
      map((action: fromUserActions.UpdateAction) => action.payload),
      switchMap((userData: any) =>
        this.userService.updateAccount(userData)
          .pipe(
            map((event: HttpEvent<any>) => {

              if (event.type === HttpEventType.Response) {
                this.store.dispatch(new fromUserActions.UpdateCompleteAction(event.body.user));
              }

            }),
            catchError(reason => {
              this.store.dispatch(new fromUserActions.UpdateFailAction(reason.error));
              return of(null);
            }),
          )
      ),
    );

  @Effect({ dispatch: false }) updateName$ = this.actions$
    .pipe(
      ofType(fromUserActions.UPDATE_NAME),
      map((action: fromUserActions.UpdateAction) => action.payload),
      switchMap(({ firstName, lastName, _id }) =>
        this.userService.updateName({ firstName, lastName, _id })
          .pipe(
            map((data) => {
              const mapToUpdate = subscription => {
                subscription.plan = subscription.plan._id;
                return {
                  id: subscription._id,
                  changes: subscription
                };
              };

              this.store.dispatch(new fromUserActions.UpdateCompleteAction(data.user));
              this.store.dispatch(new subscriptionActions.UpdateSubscriptionsCompleteAction(data.updatedSubscriptions.map(mapToUpdate)));
            }),
            catchError(reason => {
              this.store.dispatch(new fromUserActions.UpdateFailAction(reason.error));
              return of(null);
            }),
          )
      ),
    );

  @Effect({ dispatch: false }) register$ = this.actions$
    .pipe(
      ofType(fromUserActions.CREATE),
      map((action: fromUserActions.CreateAction) => action.payload),
      switchMap(
        auth => {
          const { firstName, lastName, email, password, phone } = auth;
          return this.authService.createUser({ firstName, lastName, email, password, phone })
            .pipe(
              map((event: HttpEvent<any>) => {

                // if (event.type === HttpEventType.Sent) {
                //   this.store.dispatch(new ui.ShowUserCreateLoaderAction());
                // }

                if (event.type === HttpEventType.Response) {
                  this.store.dispatch(new fromUserActions.CreateCompleteAction(event.body.user));
                }

              }),
              catchError(reason => {
                this.store.dispatch(new fromUserActions.CreateFailAction(reason.error));
                return of(null);
              }),
            );
        }
      ),
    );

  @Effect({ dispatch: false }) logout$ = this.actions$
    .pipe(
      ofType(fromUserActions.LOGOUT),
      map(() => {
        // Clear FullStory user
        if (isPlatformBrowser(this.platformId) && typeof FS !== 'undefined' && 'FS' in window) {
          console.log('Clear FullStory user.');
          FS.identify(false);
        }

        if (isPlatformBrowser(this.platformId) && typeof __insp !== 'undefined' && '__insp' in window) {
          __insp.push(['identify', null]);
        }

        this.errorService.unsetClientReporterUser();
        this.authService.logout();
        // this.store.dispatch(new user.LogoutCompleteAction());
        this.store.dispatch(new paymentMethod.LoadPaymentMethodsCompleteAction([]));
      })
    );

  @Effect({ dispatch: false }) logoutComplete$ = this.actions$
    .pipe(
      ofType(fromUserActions.LOGOUT_COMPLETE),
      tap(() => {
        this.router.navigate(['/']);
      })
    );

  @Effect({ dispatch: false }) searchEmail$ = this.actions$
    .pipe(
      ofType(searchEmail.SEARCH_EMAIL),
      map((action: searchEmail.SearchEmailAction) => action.payload),
      switchMap(
        email => this.authService.checkUserExist(email)
          .pipe(
            map((event: HttpEvent<any>) => {
              // if (event.type === HttpEventType.Sent) {
              //  this.store.dispatch(new ui.ShowSearchEmailLoaderAction());
              // }

              if (event.type === HttpEventType.Response) {
                this.store.dispatch(new searchEmail.SearchEmailCompleteAction());
              }
            }),
            catchError(reason => {
              this.store.dispatch(new searchEmail.SearchEmailFailAction(reason.errors));
              return of(new searchEmail.SearchEmailFailAction(reason.errors));
            }),
          )
      )
    );

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private actions$: Actions,
    private store: Store<any>,
    private errorService: ErrorService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

}

