import * as tslib_1 from "tslib";
import { ComponentRef, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ModalInfoComponent } from '../../components/modal-info.component';
import { ModalInfoRef } from './modal-info-ref';
import { MODAL_INFO_DATA } from './modal-info.tokens';
var DEFAULT_CONFIG = {
    hasBackdrop: true,
    backdropClass: 'dark-backdrop',
    panelClass: 'modal-dialog-panel',
    data: null
};
var ModalInfoService = /** @class */ (function () {
    function ModalInfoService(injector, overlay) {
        this.injector = injector;
        this.overlay = overlay;
    }
    ModalInfoService.prototype.open = function (config) {
        if (config === void 0) { config = {}; }
        // Override default configuration
        var dialogConfig = tslib_1.__assign({}, DEFAULT_CONFIG, config);
        // Returns an OverlayRef which is a PortalHost
        var overlayRef = this.createOverlay(dialogConfig);
        // Instantiate remote control
        var dialogRef = new ModalInfoRef(overlayRef);
        var overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);
        overlayRef.backdropClick().subscribe(function (_) { return dialogRef.close(); });
        return dialogRef;
    };
    ModalInfoService.prototype.createOverlay = function (config) {
        var overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    };
    ModalInfoService.prototype.attachDialogContainer = function (overlayRef, config, dialogRef) {
        var injector = this.createInjector(config, dialogRef);
        var containerPortal = new ComponentPortal(ModalInfoComponent, null, injector);
        var containerRef = overlayRef.attach(containerPortal);
        return containerRef.instance;
    };
    ModalInfoService.prototype.createInjector = function (config, dialogRef) {
        var injectionTokens = new WeakMap();
        injectionTokens.set(ModalInfoRef, dialogRef);
        injectionTokens.set(MODAL_INFO_DATA, config.data);
        return new PortalInjector(this.injector, injectionTokens);
    };
    ModalInfoService.prototype.getOverlayConfig = function (config) {
        var positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();
        var overlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: positionStrategy
        });
        return overlayConfig;
    };
    return ModalInfoService;
}());
export { ModalInfoService };
