<div class="thanks-container">

  <div class="thanks-title-container">
    <h1 class="thanks-title">Thank you for your one-time order!</h1>

    <p class="thanks-title-message">
      We've processed your June box, and we'll have it in the mail to you in just a few business days.
    </p>

    <div class="thanks-title-divider">
      <img src="/assets/images/icon-transparent@3x.png" alt="Succulent Studio Logo"/>
      <span class="gray-line"></span>
    </div>

    <p class="title-links">
      <a class="text-right" routerLink="/profile">REVIEW YOUR ACCOUNT</a>
      <span class="vertical-divider">|</span>
      <a routerLink="/gift">SEND A SUCCULENT GIFT</a>
    </p>

  </div>
  <video class="video-container" controls preload="auto"
         poster="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/placeholder/unboxing.jpg">
    <source src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/unboxing-vid.mp4" type="video/mp4">
    <source src="https://s3-us-west-1.amazonaws.com/assets.succulent.studio/videos/unboxing-vid.webm" type="video/webm">
    Your browser does not support the video tag.
  </video>
</div>
