import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {pluck, switchMap, tap, delay} from 'rxjs/operators';
import {SubscriptionService} from '@app/core/services';

interface ISingUpForm {
  email: string;
  password?: string;
  fName: string;
  lName: string;
  phone: string;
}

@Component({
  selector: 'sucstu-signup',
  styleUrls: ['./signup.component.scss'],
  templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit, OnDestroy {
  sigupForm: FormGroup;
  subscriptionId: Observable<string> = this.route.queryParams.pipe(pluck('subscriptionId'));
  responseError$: Subject<string> = new Subject<string>();
  componentDestroyed$: Subject<true> = new Subject<true>();

  getEmailSubscription$: Subscription;



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private subscriptionsService: SubscriptionService,
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.getEmailSubscription$ = this.subscriptionId
      .pipe(switchMap(id => {
        return this.subscriptionsService.getReceiverEmail(parseInt(id, 10));
      }))
      .subscribe(response => {
        this.sigupForm.get('email').setValue(response.email);
        this.sigupForm.get('firstName').setValue(response.firstName);
        this.sigupForm.get('lastName').setValue(response.lastName);
      }, error => {
        console.error(error);
        this.router.navigate(['/']);
      });

  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
  }

  initForm(data?: ISingUpForm) {
    this.sigupForm = this.fb.group({
      email: [data && data.email || '', Validators.required],
      password: [data && data.password || '', Validators.required],
      firstName: [data && data.fName || '', Validators.required],
      lastName: [data && data.lName || '', Validators.required],
      phone: [data && data.phone || '', Validators.required],
    });
  }

  onClaimSubscription() {
    if (!this.sigupForm.valid) {
      return this.fadeError('All fields are required.');
    }

    this.subscriptionId
      .pipe(switchMap(id => {
        return this.subscriptionsService.claimGiftSubscription({
          userData: this.sigupForm.value,
          subscriptionId: parseInt(id, 10),
        })
      }))
      .subscribe(results => {
        this.router.navigate(['/', 'profile'])
      }, response => {
        const errorText = response.error && response.error.message
          ? response.error.message
          : `We could't process you request, please contact support.`;

        this.fadeError(errorText);
      })
  }

  fadeError(message: string) {
    of(message)
      .pipe(tap(data => this.responseError$.next(data)))
      .pipe(delay(5000))
      .subscribe(() => {
        this.responseError$.next('');
      });
  }

}
