import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as plansActions from './../actions/plans.actions';

// models
import { Plan } from '@app/core/models/plan.model';

export interface State extends EntityState<Plan> {
  loading: boolean,
  loaded: boolean,
  error: any,
  selectedPlanId: string | null
}

export const adapter: EntityAdapter<Plan> = createEntityAdapter<Plan>({
  selectId: (plan: Plan) => plan._id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null,
  selectedPlanId: null
});

export function reducer(
  state: State = initialState,
  action: plansActions.Actions
): State {
  switch (action.type) {

    case plansActions.LOAD_PLANS: {
      return { ...state, loading: true, loaded: false, error: null };
    }

    case plansActions.LOAD_PLANS_COMPLETE: {
      return {
        ...adapter.addMany(action.payload, state),
        loaded: true,
        loading: false,
        error: null
      };
    }

    case plansActions.LOAD_PLANS_FAIL: {
      return adapter.removeAll({
        ...state,
        loaded: false,
        loading: false,
        selectedPlanId: null,
        error: action.payload.error
      });
    }

    case plansActions.LOAD_PLAN: {
      return { ...state, loading: true, loaded: false, error: null };
    }

    case plansActions.LOAD_PLAN_COMPLETE: {
      return {
        ...adapter.addOne(action.payload, state),
        loaded: true,
        loading: false,
        error: null
      };
    }

    case plansActions.LOAD_PLAN_FAIL: {
      return { ...state, loaded: true, loading: false, error: action.payload };
    }

    case plansActions.SELECT_PLAN: {
      return { ...state, selectedPlanId: action.payload };
    }

    case plansActions.DESELECT_PLAN: {
      return { ...state, selectedPlanId: null };
    }

    case plansActions.CLEAR_PLAN_ERROR: {
      return { ...state, error: null };
    }

    default: {
      return state;
    }
  }
}

export const getPlansEntities = (state: State) => state.entities;
export const getPlansIds = (state: State) => state.ids;
export const getSelectedPlanId = (state: State) => state.selectedPlanId;
export const getPlansLoaded = (state: State) => state.loaded;
export const getPlansError = (state: State) => state.error;
export const getPlansLoading = (state: State) => state.loading;
