import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';

// services
import { AuthService } from '@app/auth/services/auth.service';

@Injectable()
export class RecoveryPasswordGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const recoveryToken = route.queryParams.recoveryToken;
    const authToken = this.authService.token;

    if (!recoveryToken) {
      this.router.navigate(['/']);
      return false;
    }

    if (authToken) {
      this.router.navigate(['/']);
      return false;
    }

    return true;
  }
}
