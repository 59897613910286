<section *ngIf="showGmailModal$ | async" style="background-color: rgba(0,0,0,.6)"
         class="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
  <div class="modal-container w-full h-full flex flex-wrap">
    <div class="order-2 md:order-1 w-full md:w-5/12 h-full image-wrapper mt-auto md:mt-0">

    </div>

    <div class="order-1 md:order-2 md:w-7/12 text-wrapper py-16">
      <div class="flex items-center mb-6">
        <h2 class="title flex mr-1">
          Hey GMAIL users!
        </h2>

        <img src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/gmail-modal/hugging-face_1f917+1.png" alt="">
      </div>

      <p class="general mb-8 md:mb-10">
        We know awesome bonus emails can get lost in your Gmail Promotions folder, BOO! Go to your Gmail now and <b>add Succulent Studios as a contact</b> (hello@succulent.studio).
      </p>

      <p class="dont-miss-out mb-8">
        <b>Don’t miss out</b> on plants like these.
      </p>

      <button class="cta" (click)="okClose()">
        <b>OK, GOT IT!</b>
      </button>
    </div>
  </div>
</section>
