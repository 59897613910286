import { ComponentRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ModalInfoComponent } from '../../components/modal-info.component';

import { ModalInfoRef } from './modal-info-ref';
import { MODAL_INFO_DATA } from './modal-info.tokens';

export interface ModalInfoData {
  title: string,
  message: string,
}

interface ModalConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: ModalInfoData;
}

const DEFAULT_CONFIG: ModalConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'modal-dialog-panel',
  data: null
}

@Injectable()
export class ModalInfoService {

  constructor(
    private injector: Injector,
    private overlay: Overlay
  ) { }

  open(config: ModalConfig = {}) {

    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new ModalInfoRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: ModalConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: ModalConfig, dialogRef: ModalInfoRef) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(ModalInfoComponent, null, injector);
    const containerRef: ComponentRef<ModalInfoComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: ModalConfig, dialogRef: ModalInfoRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ModalInfoRef, dialogRef);
    injectionTokens.set(MODAL_INFO_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: ModalConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}
