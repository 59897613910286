
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


import { AppErrorHandler } from './error-handlers';
import { ErrorService } from './services';
import { HTTPErrorInterceptor } from './error-interceptors/error.interceptor';


export function initErrorService(errorService: ErrorService) {
  return () => errorService.initialize();
}

@NgModule({
  imports: [
    // BrowserModule,
    CommonModule,
    RouterModule,
  ],

  providers: [
    ErrorService,
    {
      provide: APP_INITIALIZER,
      useFactory: initErrorService,
      deps: [ErrorService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPErrorInterceptor,
      multi: true,
    }
  ]
})
export class ErrorsModule { }
