import { Component, OnInit, Input, Output, AfterViewInit, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'sucstu-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent
  implements OnInit, AfterViewInit, OnChanges {
  @Input('size') size: number;
  @Input('checked') checked: boolean;
  @Output('change') change: EventEmitter<boolean> = new EventEmitter();

  loading = false;

  constructor(private el: ElementRef) { }

  ngOnInit() { }

  ngAfterViewInit() {
    if (typeof this.size !== 'number') {
      this.size = 16;
      console.error(`Size property must be a number, it will be 16 as default.`)
    }

    this.el.nativeElement.style.fontSize = this.size
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.loading = false;
    if (changes.checked) {
      if (changes.checked.currentValue !== this.checked) {
        this.change = changes.checked.currentValue;
      }
    }
  }

  onClick() {
    // this.checked = !this.checked;
    this.change.emit(!this.checked);
  }
}
