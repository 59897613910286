import { Directive, HostListener, ElementRef, Input} from '@angular/core';
import { timer } from 'rxjs';

@Directive({
  selector: '[appVideoTimedLoop]'
})
export class VideoTimedLoopDirective{

  @Input('appVideoTimedLoop') secs = 2;
  started = false;

  constructor(private el: ElementRef) {}

  @HostListener('ended', ['$event']) loopVideoAfter2secs() {
    // console.time('appVideoTimedLoop[ended]');
    const loopInterval = timer(this.secs * 1000);
    loopInterval.subscribe(async () => {
      this.el.nativeElement.currentTime = 0;
      await this.el.nativeElement.play();
      // console.log('appVideoTimedLoop[ended]: Looping!');
      // console.timeEnd('appVideoTimedLoop[ended]');
    });
  }
}
