var _a;
import { createSelector } from '@ngrx/store';
import * as fromRoot from '@app/store/selectors';
import * as fromPlans from '@app/store/reducers/plans.reducer';
export var getPlansIds = (_a = fromPlans.adapter.getSelectors(fromRoot.getPlansState), _a.selectIds), getPlansEntities = _a.selectEntities, getAllPlans = _a.selectAll, getTotalPlans = _a.selectTotal;
export var getPlansLoaded = createSelector(fromRoot.getPlansState, fromPlans.getPlansLoaded);
export var getPlansLoading = createSelector(fromRoot.getPlansState, fromPlans.getPlansLoading);
export var getPlansError = createSelector(fromRoot.getPlansState, fromPlans.getPlansError);
export var getSelectedPlanId = createSelector(fromRoot.getPlansState, fromPlans.getSelectedPlanId);
export var getPlanById = function (id) { return createSelector(fromRoot.getPlansState, function (_a) {
    var entities = _a.entities;
    return entities[id];
}); };
export var getSelectedPlan = createSelector(getSelectedPlanId, getPlansEntities, function (id, entities) { return entities[id]; });
