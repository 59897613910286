import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { delay, map } from 'rxjs/operators';
// config
import { AppConfig } from './../../app.config';
// services
import { AuthService } from './../../auth/services/auth.service';
import { MarketingService } from '@app/core/services/marketing.service';
var SubscriptionService = /** @class */ (function () {
    function SubscriptionService(marketingService, httpClient, authService, app_config) {
        this.marketingService = marketingService;
        this.httpClient = httpClient;
        this.authService = authService;
        this.app_config = app_config;
    }
    SubscriptionService.prototype.getSubscriptions = function () {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .get(this.app_config.api_uri + "/users/" + userId + "/subscriptions")
            .pipe(map(function (result) { return result.subscriptions; }));
    };
    SubscriptionService.prototype.getSubscription = function (subscriptionId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .get(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId)
            .pipe(map(function (result) { return result.subscription; }));
    };
    SubscriptionService.prototype.updateSubscription = function (data) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + data._id, data)
            .pipe(map(function (result) { return result.subscription; }));
    };
    SubscriptionService.prototype.cancelSubscription = function (subscriptionId, reasons) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .request('delete', this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId, { body: { reasons: reasons } })
            .pipe(map(function (result) { return result.subscription; }));
    };
    SubscriptionService.prototype.skipMonth = function (subscriptionId, skipValue) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/skipMonth", { subscriptionId: subscriptionId, skipValue: skipValue })
            .pipe(map(function (result) { return result.subscription; }));
    };
    SubscriptionService.prototype.unSkipMonth = function (subscriptionId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/unSkipMonth", { subscriptionId: subscriptionId })
            .pipe(map(function (results) { return results.subscription; }));
    };
    SubscriptionService.prototype.reActivate = function (nextBillingDate, subscriptionId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/re-activate", { nextBillingDate: nextBillingDate })
            .pipe(map(function (results) { return results.subscription; }));
    };
    SubscriptionService.prototype.turnOffAutoRenew = function (data) {
        var userId = this.authService.tokenPayload._id;
        this.marketingService.reportTurnOff({ userId: userId })
            .subscribe({ error: console.error });
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + data.subscriptionId + "/turn-off", {
            turnedOffReasons: data.turnedOffReasons,
            refundShipment: data.refundShipment
        })
            .pipe(delay(1000), map(function (results) { return ({ subscription: results.subscription, shipment: results.shipment }); }));
    };
    SubscriptionService.prototype.turnOnAutoRenew = function (data) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + data.subscriptionId + "/turn-on", {
            nextBillingDate: data.nextBillingDate,
        })
            .pipe(delay(1000), map(function (results) { return results.subscription; }));
    };
    SubscriptionService.prototype.giftShipment = function (_a) {
        var subscriptionId = _a.subscriptionId, giftIt = _a.giftIt, useLastShipmentWithoutShip = _a.useLastShipmentWithoutShip;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/giftShipment", {
            subscriptionId: subscriptionId,
            gift: giftIt,
            useLastShipmentWithoutShip: useLastShipmentWithoutShip,
        })
            .pipe(delay(1000), map(function (result) { return result; }));
    };
    SubscriptionService.prototype.cancelGiftShipment = function (subscriptionId, giftId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .delete(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/giftShipment/" + giftId)
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.cancelGiftExtraBoxShipment = function (subscriptionId, giftId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .delete(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/giftShipment/" + giftId + "/cancel-extra-box")
            .pipe(map(function (result) { return result.subscription; }));
    };
    SubscriptionService.prototype.updateSubscriptionAddress = function (_a) {
        var subscriptionId = _a.subscriptionId, address = _a.address;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/address", { address: address })
            .pipe(map(function (data) { return data; }));
    };
    SubscriptionService.prototype.updateSubscriptionNBD = function (_a) {
        var subscriptionId = _a.subscriptionId, newBillingDate = _a.newBillingDate;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .put(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/nbd", { newBillingDate: newBillingDate })
            .pipe(map(function (data) { return data; }));
    };
    SubscriptionService.prototype.buyShipment = function (data) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + data.subscriptionId + "/shipment", tslib_1.__assign({}, data))
            .pipe(delay(1000), map(function (result) { return result; }));
    };
    SubscriptionService.prototype.getLastShipmentWithoutShip = function (id) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .get(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + id + "/last-pending-shipment")
            .pipe(delay(1000), map(function (result) { return result; }));
    };
    SubscriptionService.prototype.cancelLastShipmentWithoutShip = function (id) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .delete(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + id + "/last-pending-shipment")
            .pipe(delay(1000), map(function (result) { return result; }));
    };
    SubscriptionService.prototype.reactivateSubscriptionWinBack = function (id) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + id + "/reactivate-with-free-shipping", null)
            .pipe(delay(1000), map(function (result) { return result; }));
    };
    SubscriptionService.prototype.unselectSubscriptionBox = function (subscriptionId, boxId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .delete(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/unselect-box", {
            params: {
                boxId: boxId
            }
        })
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.selectSubscriptionBox = function (subscriptionId, boxId) {
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/select-box", { boxId: boxId })
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.processSubscriptionFromAhoySkipper = function (subscriptionId, data) {
        if (data === void 0) { data = {}; }
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/process-subscription-billing-ahoy-skipper", data)
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.updatePaymentMethodSkipAndRemoveDeclineStatus = function (_a) {
        var nonce = _a.nonce, skipValue = _a.skipValue, subscriptionId = _a.subscriptionId, paymentMethodId = _a.paymentMethodId;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/update-skip-and-remove-decline", {
            nonce: nonce,
            skipValue: skipValue,
            subscriptionId: subscriptionId,
            paymentMethodId: paymentMethodId,
        })
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.updatePaymentMethodChargeAndChangeFrequency = function (_a) {
        var nonce = _a.nonce, billingHz = _a.billingHz, subscriptionId = _a.subscriptionId, paymentMethodId = _a.paymentMethodId;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/update-charge-change-frequency", {
            nonce: nonce,
            billingHz: billingHz,
            subscriptionId: subscriptionId,
            paymentMethodId: paymentMethodId,
        })
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.skipAndRemoveDeclineStatus = function (_a) {
        var skipValue = _a.skipValue, subscriptionId = _a.subscriptionId;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/skip-and-remove-decline", {
            skipValue: skipValue,
            subscriptionId: subscriptionId,
        })
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.removeDeclineStatus = function (_a) {
        var subscriptionId = _a.subscriptionId;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/remove-decline-status", {
            subscriptionId: subscriptionId,
        })
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.processBilling = function (_a) {
        var subscriptionId = _a.subscriptionId, options = _a.options;
        var userId = this.authService.tokenPayload._id;
        return this.httpClient
            .post(this.app_config.api_uri + "/users/" + userId + "/subscriptions/" + subscriptionId + "/process-subscription-billing", {
            subscriptionId: subscriptionId,
            options: options,
            origin: 'account'
        })
            .pipe(map(function (result) { return result; }));
    };
    SubscriptionService.prototype.claimGiftSubscription = function (data) {
        var _this = this;
        return this.httpClient
            .post(this.app_config.api_uri + "/subscriptions/gifts/" + data.subscriptionId + "/create-receiver", {
            userData: data.userData
        }, { observe: 'response' })
            .pipe(map(function (response) {
            _this.authService.interceptAuthorizationHeader(response);
            return response.body;
        }));
    };
    SubscriptionService.prototype.getReceiverEmail = function (subscriptionId) {
        return this.httpClient.get(this.app_config.api_uri + "/subscriptions/gifts/" + subscriptionId + "/get-receiver-email");
    };
    return SubscriptionService;
}());
export { SubscriptionService };
