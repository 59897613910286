// import * as Sentry from '@sentry/browser';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorService } from './../services';
import { environment } from '@env/environment';
// import { environment } from './../../../environments/environment';

// Juan https://f297d035654448a5856027d365ed513a@sentry.io/1353888
// Succs https://fbeb0ef97eb84492809c6fb8ad27b8be@sentry.io/272191

// Sentry.init({
//   environment: environment.name,
//   dsn: 'https://f297d035654448a5856027d365ed513a@sentry.io/1353888',
//   attachStacktrace: true,

//   beforeBreadcrumb: crumb => {
//     const shrinkedCrumb = { ...crumb };
//     // if (crumb.data && crumb.data.extra && crumb.data.extra.arguments) {
//     //   shrinkedCrumb.data.extra = {
//     //     ...crumb.data.extra,
//     //     arguments: crumb.data.extra.arguments.map((arg: any) => {
//     //       const shrinkedArg = { ...arg };
//     //       delete shrinkedArg.ngDebugContext;
//     //       delete shrinkedArg.ngErrorLogger;
//     //       return shrinkedArg;
//     //     })
//     //   };
//     // }
//     return shrinkedCrumb;
//   }
// });


// @Injectable()
// export class AppErrorHandler implements ErrorHandler {
//   constructor(
//     private injector: Injector
//   ) {}
//   handleError(error) {
//     console.log('handleError');
//     if (error instanceof HttpErrorResponse) {
//       // Http request errors
//       if (navigator && !navigator.onLine) {
//         console.log('User is offline');
//       } else {
//         console.log('HttpErrorResponse error');
//       //   Sentry.captureEvent({
//       //     level: Sentry.Severity.Error,
//       //     message: 'HTTP Error',
//       //     extra: error,
//       //   });
//       }
//     } else {
//       console.log('Client error');
//       // Sentry.captureException(error.originalError || error);
//       // if (!error.__logged) {
//       //   error.__logged = true;
//       // }
//     }
//     throw error;
//   }
// }

@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(
    private injector: Injector
  ) { }

  handleError(error) {
    console.log('handleError', error);
    if (environment.name !== 'local') {
      const errorService = this.injector.get(ErrorService);
      const state = {
        severity: 'error',
        severityReason: { type: 'unhandledException' },
        unhandled: true
      };

      const report = new errorService.client.BugsnagReport(
        error.name,
        error.message,
        errorService.client.BugsnagReport.getStacktrace(error),
        state
      );

      if (error.ngDebugContext) {
        report.updateMetaData('angular', {
          component: error.ngDebugContext.component,
          context: error.ngDebugContext.context
        });
      }

      if (error instanceof HttpErrorResponse) {
        // Http request errors
        if (navigator && !navigator.onLine) {
          console.log('User is offline');
        } else {
          report.updateMetaData('extras', {
            client: false,
            http: true,
            error
          });
          console.log('HttpErrorResponse error');
          errorService.client.notify(report);
        }
      } else {
        report.updateMetaData('extras', {
          client: true,
          http: false,
          error
        });
        console.error('Client Error', error);
        errorService.client.notify(report);
      }
    }
    throw error;
  }
}


