import { Action } from '@ngrx/store';
import { Coupon } from '@app/core/models/coupon.model';

export const VERIFY_COUPON = '[COUPON] Verify coupon';
export const VERIFY_CLAIM_COUPON = '[COUPON] Verify claim coupon';
export const VERIFY_CLAIM_COUPON_COMPLETE = '[COUPON] Verify claim coupon Complete';
export const VERIFY_COUPON_COMPLETE = '[Coupon] Verify coupon Complete';
export const VERIFY_COUPON_FAIL = '[Coupon] Verify coupon Fail';
export const CLEAR_COUPON = '[Coupon] Clear coupon';

export class VerifyCouponAction implements Action {
  readonly type = VERIFY_COUPON;

  constructor(public payload: string) {
  }
};

export class VerifyClaimCouponAction implements Action {
  readonly type = VERIFY_CLAIM_COUPON;

  constructor(public payload: { claimCode: string, coupon: string }) {
  }
};

export class VerifyClaimCouponCompleteAction implements Action {
  readonly type = VERIFY_CLAIM_COUPON_COMPLETE;

  constructor(public payload: string) {
  }
};

export class VerifyCouponCompleteAction implements Action {
  readonly type = VERIFY_COUPON_COMPLETE;

  constructor(public payload: Coupon) {
  }
};

export class VerifyCouponFailAction implements Action {
  readonly type = VERIFY_COUPON_FAIL;

  constructor(public payload: any[]) {
  }
};

export class ClearCoupon implements Action {
  readonly type = CLEAR_COUPON;

  constructor() {
  }
};

export type Actions =
  | VerifyCouponAction
  | VerifyClaimCouponAction
  | VerifyCouponCompleteAction
  | VerifyCouponFailAction
  | ClearCoupon;
