import { Component } from '@angular/core';
import * as fromServices from './../../../core/services';


@Component({
  selector: 'sucstu-pages-sales',
  styleUrls: ['./sales.component.scss'],
  templateUrl: './sales.template.html'
})

export class SalesComponent {
  
  numberOfSales = "000";
  sales = [];
  i=0;
  orderShown;
  
  //Order information
  plan = "";
  
  
  constructor ( private StatsService: fromServices.StatsService) {  
  }
  ngOnInit() {
    this.fetchSales(true);
    setInterval(()=>{
      this.fetchSales(false);
    }, 60000)
    setInterval(()=>{
      this.changeOrderShown();
      console.log(this.orderShown);
    }, 30000)
  }
  
  fetchSales(firstTime) {
      this.StatsService.fetchSales().subscribe(response => {
        this.numberOfSales = response.numberOfSales < 100 ? response.numberOfSales < 10 ? '00' + String(response.numberOfSales) : '0' + String(response.numberOfSales) : String(response.numberOfSales);
        this.sales = response.salesToday;
        if (firstTime) {
          this.changeOrderShown();
        }
        console.log(this.sales);
      });
  }
  
  changeOrderShown () {
    if (this.sales.length===0) {
      return;
    }
    let newOrderIndex = Math.floor((Math.random() * (this.sales.length-1)));
    this.i = newOrderIndex;
    this.orderShown = this.sales[newOrderIndex];
    if (this.orderShown.plan.name === "2x") {
      this.plan = "Monthly subscription"
    } else if (this.orderShown.plan.name === "2x_3gift") {
      this.plan = "3 month gift subscription"
    } else if (this.orderShown.plan.name === "2x_6gift") {
      this.plan = "6 month gift subscription"
    } else if (this.orderShown.plan.name === "2x_12gift") {
      this.plan = "12 month gift subscription"
    }
  }
}