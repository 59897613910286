/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 67, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 54, "div", [["class", "footer-links-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "footer-links__group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "footer-links__title text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["more Info"])), (_l()(), i1.ɵeld(5, 0, null, null, 23, "ul", [["class", "footer-links__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["routerLink", "/reviews"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Reviews"])), (_l()(), i1.ɵeld(10, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["routerLink", "/inspiration"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Inspiration"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "li", [["class", "footer-link__item"]], [[1, "test-id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["routerLink", "/gift"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Send a Gift"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [["routerLink", "/carecards"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Succulent Care"])), (_l()(), i1.ɵeld(22, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 2, "a", [["routerLink", "/blog"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Blog"])), (_l()(), i1.ɵeld(26, 0, null, null, 2, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "a", [["href", "https://gifting.succulent.studio"], ["target", "a_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Corporate Gifting"])), (_l()(), i1.ɵeld(29, 0, null, null, 18, "div", [["class", "footer-links__group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "h5", [["class", "footer-links__title text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["questions?"])), (_l()(), i1.ɵeld(32, 0, null, null, 15, "ul", [["class", "footer-links__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 2, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 1, "a", [["href", "mailto:hello@succulent.studio"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["hello@succulent.studio"])), (_l()(), i1.ɵeld(36, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(37, 0, null, null, 2, "a", [["routerLink", "/faq"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 38).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(38, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["FAQ"])), (_l()(), i1.ɵeld(40, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 2, "a", [["routerLink", "/privacy-policy"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 42).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(42, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Privacy Policy"])), (_l()(), i1.ɵeld(44, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 2, "a", [["routerLink", "/terms"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 46).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(46, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Terms of Service"])), (_l()(), i1.ɵeld(48, 0, null, null, 7, "div", [["class", "footer-links__group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(49, 0, null, null, 1, "h5", [["class", "footer-links__title text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["company"])), (_l()(), i1.ɵeld(51, 0, null, null, 4, "ul", [["class", "footer-links__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(52, 0, null, null, 3, "li", [["class", "footer-link__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(53, 0, null, null, 2, "a", [["routerLink", "/about"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 54).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(54, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["About"])), (_l()(), i1.ɵeld(56, 0, null, null, 11, "div", [["class", "footer-social-links"]], null, null, null, null, null)), (_l()(), i1.ɵeld(57, 0, null, null, 8, "div", [["class", "footer-social-links__group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(58, 0, null, null, 1, "a", [["class", "social-link__item"], ["href", "https://www.facebook.com/sucstu"], ["target", "a_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(59, 0, null, null, 0, "img", [["alt", "Facebook Icon"], ["src", "/assets/images/facebook@2x.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(60, 0, null, null, 1, "a", [["class", "social-link__item"], ["href", "https://www.twitter.com/sucstu"], ["target", "a_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(61, 0, null, null, 0, "img", [["alt", "Twitter Icon"], ["src", "/assets/images/twitter.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(62, 0, null, null, 1, "a", [["class", "social-link__item"], ["href", "https://www.pinterest.com/SUCSTU/"], ["target", "a_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(63, 0, null, null, 0, "img", [["alt", "Pinterest Icon"], ["src", "/assets/images/pinterest-black@2x.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(64, 0, null, null, 1, "a", [["class", "social-link__item"], ["href", "https://www.instagram.com/sucstu"], ["target", "a_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(65, 0, null, null, 0, "img", [["alt", "Instagram Icon"], ["src", "/assets/images/instagram@2x.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(66, 0, null, null, 1, "a", [["class", "go-top-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.moveToTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(67, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/images/go-top.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/reviews"; _ck(_v, 8, 0, currVal_2); var currVal_5 = "/inspiration"; _ck(_v, 12, 0, currVal_5); var currVal_9 = "/gift"; _ck(_v, 16, 0, currVal_9); var currVal_12 = "/carecards"; _ck(_v, 20, 0, currVal_12); var currVal_15 = "/blog"; _ck(_v, 24, 0, currVal_15); var currVal_18 = "/faq"; _ck(_v, 38, 0, currVal_18); var currVal_21 = "/privacy-policy"; _ck(_v, 42, 0, currVal_21); var currVal_24 = "/terms"; _ck(_v, 46, 0, currVal_24); var currVal_27 = "/about"; _ck(_v, 54, 0, currVal_27); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).target; var currVal_1 = i1.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 12).target; var currVal_4 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = "footer__gift-button"; _ck(_v, 14, 0, currVal_6); var currVal_7 = i1.ɵnov(_v, 16).target; var currVal_8 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 20).target; var currVal_11 = i1.ɵnov(_v, 20).href; _ck(_v, 19, 0, currVal_10, currVal_11); var currVal_13 = i1.ɵnov(_v, 24).target; var currVal_14 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_13, currVal_14); var currVal_16 = i1.ɵnov(_v, 38).target; var currVal_17 = i1.ɵnov(_v, 38).href; _ck(_v, 37, 0, currVal_16, currVal_17); var currVal_19 = i1.ɵnov(_v, 42).target; var currVal_20 = i1.ɵnov(_v, 42).href; _ck(_v, 41, 0, currVal_19, currVal_20); var currVal_22 = i1.ɵnov(_v, 46).target; var currVal_23 = i1.ɵnov(_v, 46).href; _ck(_v, 45, 0, currVal_22, currVal_23); var currVal_25 = i1.ɵnov(_v, 54).target; var currVal_26 = i1.ɵnov(_v, 54).href; _ck(_v, 53, 0, currVal_25, currVal_26); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isSales; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i4.FooterComponent, [i3.Location, i1.PLATFORM_ID], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("sucstu-footer", i4.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
