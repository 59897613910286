import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import { APP_CONFIG, AppConfig } from '@app/app.config';

import { ErrorService } from '@app/error';
import { ModalInfoService } from '@app/core/services';
import { environment } from '@env/environment';

interface APIResponse {
  client_token: string;
}

@Injectable()
export class ClientAuthorizationResolver implements Resolve<string> {

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorService: ErrorService,
    private modalInfoService: ModalInfoService,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    return environment.btClientToken;
  }

}
