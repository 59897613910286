
    <div class="login-container container">

      <h2 class="login-container__title">Log In</h2>

      <sucstu-auth-form
        (submitted)="onSubmit($event)">

        <p class="error-text" *ngIf="(error$ | async) as error;">
          {{ error.message }}
        </p>
      </sucstu-auth-form>

    </div>
  