import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// service
import { AuthService } from '@app/auth/services/auth.service';
import { NotificationBarService } from '@app/core/services/notification-bar.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'sucstu-auth-recovery-password',
  styleUrls: ['./recovery-password.component.scss'],
  template: `
    <div class="login-container container">

      <h2 class="login-container__title">Password Recovery</h2>

      <form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <div class="form-group form-group__flex-start">
          <input
            [ngClass]="{ 'input-error': isFieldValid('password', 'required') }" class="form-group__input" type="password"
            placeholder="New Password" formControlName="password" name="password" autocomplete="off">
          <span class="error-text" *ngIf="isFieldValid('password', 'required')">Please write your new password</span>
        </div>

        <div class="form-group form-group__flex-start">
          <input
            [ngClass]="{ 'input-error': isFieldValid('confirmPassword', 'required') }" class="form-group__input" type="password"
            placeholder="Confirm New Password" formControlName="confirmPassword" name="confirm-password" autocomplete="off">
          <span class="error-text" *ngIf="isFieldValid('confirmPassword', 'required')">Please confirm your new password</span>
          <span class="error-text error-text--center" *ngIf="!arePasswordsEquals && displayErrors">
          Passwords don't match
          </span>
          <span class="error-text" *ngIf="error">{{ error }}</span>
        </div>


        <div class="form-button-container">
          <button
            class="btn btn-primary btn-primary--with-hover"
            type="submit">
            Save
          </button>
        </div>

      </form>

    </div>
  `
})
export class RecoveryPasswordComponent implements OnInit {

  displayErrors = false;
  token: string;
  error: string;

  form = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private notificationBarService: NotificationBarService
  ) { }

  get arePasswordsEquals() {
    const password = this.form.get('password').value;
    const confirmPassword = this.form.get('confirmPassword').value;
    return password === confirmPassword;
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.recoveryToken;
  }

  onSubmit() {
    const password = this.form.get('password').value;
    const confirmPassword = this.form.get('confirmPassword').value;

    if (this.form.invalid && !this.arePasswordsEquals) {
      this.displayErrors = true;
      return;
    }

    this.authService
      .recoveryPassword(this.token, password, confirmPassword)
      .subscribe(
        ({ message }) => {
          this.notificationBarService.open({ title: '', message, type: 'notification', mode: 'success' });
          this.router.navigate(['/login']);
        },
        error => this.error = error.error.message
      );
  }

  isFieldValid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && this.displayErrors;
  }

}
