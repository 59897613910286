import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
var GoogleTagManagerService = /** @class */ (function () {
    function GoogleTagManagerService(platformId) {
        this.platformId = platformId;
    }
    GoogleTagManagerService.prototype.dataLayerPush = function (data) {
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            window.dataLayer.push(data);
        }
    };
    GoogleTagManagerService.ngInjectableDef = i0.defineInjectable({ factory: function GoogleTagManagerService_Factory() { return new GoogleTagManagerService(i0.inject(i0.PLATFORM_ID)); }, token: GoogleTagManagerService, providedIn: "root" });
    return GoogleTagManagerService;
}());
export { GoogleTagManagerService };
