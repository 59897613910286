import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_CONFIG } from './../../app.config';
import { AppConfig } from './../../app.config';

// services
import { AuthService } from './../../auth/services/auth.service';
import {Box} from '@app/core/models/box.model';
import {Address} from '@app/core/models/address.model';
import {Shipment} from '@app/core/models/shipment.model';
import {environment} from '@env/environment';

@Injectable()
export class UserService {

  updateAccount(data) {
    const userId = this.authService.tokenPayload._id;
    const request = new HttpRequest(
      'PUT', `${this.app_config.api_uri}/users/${userId}/account`, data, { reportProgress: true });

    return this.http
      .request(request);
  }

  updatePassword(data) {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .put(`${this.app_config.api_uri}/users/${userId}/change-password`, { ...data });
  }

  updateName({ firstName, lastName, _id }) {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .put(`${this.app_config.api_uri}/users/${userId}/update-name`, { firstName, lastName, _id })
      .pipe(map((result: any) => result as { user, updatedSubscriptions }));
  }

  updatePaymentMethod({ paymentMethodId, nonce, cardholderName, paymentProcessor = 'edgepay', zip }): Observable<any> {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .post(`${this.app_config.api_customers_v2}/customer/${userId}/payment-method`, { paymentMethodId, nonce, cardholderName, paymentProcessor, zip });
  }

  buyShipment({ boxId, address, gift, paymentProcessor }): Observable<any> {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .post(`${this.app_config.api_uri}/users/${userId}/buy-shipment`, { boxId, address, gift, paymentProcessor });
  }

  buyShipments(data: { boxes: { boxId: Box['_id'], qty: number }, gift: boolean, address: Address, paymentProcessor: string }) {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .post<{ shipments: Shipment[] }>(`${this.app_config.api_uri}/users/${userId}/buy-shipments`, {
        boxes: data.boxes,
        address: data.address,
        gift: data.gift,
        paymentProcessor: data.paymentProcessor
      });
  }

  getUserSubscription(id): Observable<any> {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .get(`${this.app_config.api_uri}/users/${userId}/subscriptions/${id}`);
  }

  cancelSubscription(): Observable<any> {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .delete(`${this.app_config.api_uri}/users${userId}/subscription`);
  }

  setUserMonthBox(id: string) {
    const userId = this.authService.tokenPayload._id;
    return this.http
      .put<{ success: boolean }>(`${this.app_config.api_uri}/users/${userId}/set-month-box`, {
        box: id,
      });
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject(APP_CONFIG) private app_config: typeof environment
  ) { }
}
