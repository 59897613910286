import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { isPlatformServer } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "ngx-webstorage";
import * as i2 from "@angular/router";
var LandingPagesGuard = /** @class */ (function () {
    function LandingPagesGuard(sessionStorageService, platformId, router) {
        this.sessionStorageService = sessionStorageService;
        this.platformId = platformId;
        this.router = router;
    }
    LandingPagesGuard.prototype.canActivate = function (route, state) {
        if (isPlatformServer(this.platformId)) {
            return true;
        }
        console.log(this.sessionStorageService.retrieve('testlp'));
        switch (this.sessionStorageService.retrieve('testlp')) {
            case 'farm-house':
                return this.router.navigate(['/', 'lp', 'farm'])
                    .then(function () { return false; });
            case 'farm-house-2':
                return this.router.navigate(['/', 'lp', 'farm2'])
                    .then(function () { return false; });
            case 'glow-up':
                return this.router.navigate(['/', 'lp', 'glow-up'])
                    .then(function () { return false; });
            case 'glow2':
                return this.router.navigate(['/', 'lp', 'glow2'])
                    .then(function () { return false; });
            default:
                return true;
        }
    };
    LandingPagesGuard.ngInjectableDef = i0.defineInjectable({ factory: function LandingPagesGuard_Factory() { return new LandingPagesGuard(i0.inject(i1.SessionStorageService), i0.inject(i0.PLATFORM_ID), i0.inject(i2.Router)); }, token: LandingPagesGuard, providedIn: "root" });
    return LandingPagesGuard;
}());
export { LandingPagesGuard };
