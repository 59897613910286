import { Action } from '@ngrx/store';

export const SEARCH_EMAIL = '[User] Search email';
export const SEARCH_EMAIL_COMPLETE = '[User] Search email Complete';
export const SEARCH_EMAIL_FAIL = '[User] Search email Fail';

export class SearchEmailAction implements Action {
  readonly type = SEARCH_EMAIL;
  constructor(public payload: string) { }
};

export class SearchEmailCompleteAction implements Action {
  readonly type = SEARCH_EMAIL_COMPLETE;
  constructor() { }
};

export class SearchEmailFailAction implements Action {
  readonly type = SEARCH_EMAIL_FAIL;
  constructor(public payload: any[]) { }
};

export type Actions =
  | SearchEmailAction
  | SearchEmailCompleteAction
  | SearchEmailFailAction;
