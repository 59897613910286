import * as tslib_1 from "tslib";
import { OnInit, AfterViewInit, ChangeDetectorRef, } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GooglePlacesService } from '@app/core/services';
var AddressFormComponent = /** @class */ (function () {
    function AddressFormComponent(cd, googlePlacesService) {
        this.cd = cd;
        this.googlePlacesService = googlePlacesService;
        this.showLabelPreview = false;
        this.addressId = this.googlePlacesService.generateId();
        this.showAllFields = false;
        this.states = [];
    }
    AddressFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        var toCapitalize = function (text) { return text.split(' ').map(function (w) { return w.slice(0, 1).toUpperCase() + w.slice(1); }).join(' '); };
        this.states = this.googlePlacesService
            .getAvailableStates()
            .map(function (item) { return (tslib_1.__assign({}, item, { state: toCapitalize(item.state) })); });
        var labelControl = this.form.get('label');
        this.form.valueChanges.subscribe(function (value) {
            labelControl.setValue(_this.createLabel(value), { emitEvent: false });
        });
    };
    AddressFormComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.googlePlacesService.listenGooglePlaces("address-" + this.addressId, function (_a) {
            var error = _a.error, data = _a.data;
            // 1. The user is trying to manually set their address.
            if (_this.showAllFields) {
                return null;
            }
            // 2. There was an error trying to find the user address.
            if (error && !_this.showAllFields) {
                _this.addressError = error;
                if (data && data.streetNumber && data.route) {
                    _this.form.get('shippingAddress').setValue(data.streetNumber + " " + data.route);
                }
                _this.form.get('label').setValue('');
                _this.cd.detectChanges();
                return;
            }
            // 3. The address was found!
            _this.addressError = undefined;
            console.log('FORM VALUE', _this.form.value);
            console.log('DATA', data);
            _this.form.setValue(tslib_1.__assign({}, _this.form.value, data, { shippingAddressExtra: _this.form.value.shippingAddressExtra !== ''
                    ? _this.form.value.shippingAddressExtra
                    : '' }));
            _this.form.get('label').setValue(_this.createLabel(_this.form.value));
            _this.showLabelPreview = true;
            _this.cd.detectChanges();
        });
    };
    AddressFormComponent.prototype.toggleShowAllFields = function () {
        this.showAllFields = !this.showAllFields;
        this.addressError = undefined;
    };
    AddressFormComponent.prototype.isFieldValid = function (field, validation) {
        var control = this.form.get(field);
        return control.hasError(validation) && control.touched;
    };
    AddressFormComponent.prototype.createLabel = function (address) {
        var label = "" + address.shippingAddress;
        if (address.shippingAddressExtra.length) {
            label += "\n" + address.shippingAddressExtra + "\n";
        }
        label += address.city + " " + address.stateCode + " " + address.zip;
        return label;
    };
    AddressFormComponent.prototype.onChangeState = function () {
        var stateText = this.form.get('state').value;
        var state = this.states.find(function (item) { return item.state === stateText; });
        if (!state) {
            this.form.get('state').setValue('');
            this.form.get('stateCode').setValue('');
            return this.statesError = false;
        }
        this.form.get('stateCode').setValue(state.stateCode.toUpperCase());
        this.statesError = false;
    };
    return AddressFormComponent;
}());
export { AddressFormComponent };
