import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// modules
import { SharedModule } from './../shared/shared.module';
import { AuthRoutingModule } from './auth.routing';

// services
import { AuthService } from './services/auth.service';

// guards
import { AuthGuard } from './guards/auth.guard';
import { RecoveryPasswordGuard } from './guards/recovery-password.guard';

// interceptors
import { AuthInterceptor } from './interceptors/auth.interceptor';

// containers
import { AuthComponent } from './auth.component';
import { LoginComponent } from './containers/login/login.component';
import { SignupComponent } from './containers/signup/signup.component';
import { RememberPasswordComponent } from './containers/remember-password/remember-password.component';
import { RecoveryPasswordComponent } from './containers/recovery-password/recovery-password.component';

// components
import { AuthFormComponent } from './components/auth-form/auth-form.component';

@NgModule({
  declarations: [
    // containers
    AuthComponent,
    LoginComponent,
    SignupComponent,
    RememberPasswordComponent,
    RecoveryPasswordComponent,

    // components
    AuthFormComponent
  ],
  imports: [
    HttpClientModule,
    SharedModule,
    AuthRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    AuthGuard,
    RecoveryPasswordGuard
  ],
  exports: [
    AuthComponent
  ]
})
export class AuthModule { }
