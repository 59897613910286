import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

// actions
import * as paymentMethodsActions from '@app/store/actions/payment-methods.actions';
import * as userActions from '@app/store/actions/user.actions';

// models
import { PaymentMethod } from '@app/core/models/payment-method';

export interface PaymentMethodEntities {
  [id: string]: PaymentMethod
}

export interface State extends EntityState<PaymentMethod> {
  loading: boolean,
  loaded: boolean,
  error: any
}

export const adapter: EntityAdapter<PaymentMethod> = createEntityAdapter<PaymentMethod>({
  selectId: (paymentMethod: PaymentMethod) => paymentMethod._id,
  sortComparer: false
});

export const initialState: EntityState<PaymentMethod> & { loaded: boolean; loading: boolean; error: null } = adapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});


export function reducer(
  state: State = initialState,
  action: paymentMethodsActions.Actions | userActions.Actions): State {
    switch (action.type) {

      case paymentMethodsActions.LOAD_PAYMENT_METHODS:
      case paymentMethodsActions.UPDATE_PAYMENT_METHOD: {
        return {...state, loading: true, loaded: false };
      }

      case paymentMethodsActions.LOAD_PAYMENT_METHODS_COMPLETE: {
        return {
          ...adapter.addAll(action.payload, state),
          loaded: true,
          loading: false,
          error: null
        };
      }

      case paymentMethodsActions.UPDATE_PAYMENT_METHOD_COMPLETE: {
        return adapter.updateOne(action.payload, {
          ...state,
          loaded: true,
          loading: false
        });
      }

      case userActions.LOAD_COMPLETE:
      case userActions.LOGIN_COMPLETE: {
        const paymentMethodsFromUser = (action.payload as any).paymentMethods;
        return adapter.addAll(paymentMethodsFromUser, {
          ...state,
          loaded: true,
          loading: false,
          error: null
        });
      }

      case paymentMethodsActions.LOAD_PAYMENT_METHODS_FAIL:
      case paymentMethodsActions.UPDATE_PAYMENT_METHOD_FAIL: {
        return {...state, error: action.payload};
      }

      default: {
        return state;
      }
    }
  }

  export const getPaymentMethods = (state: State) => state.entities;
  export const getPaymentIds = (state: State) => state.ids;
  export const getPaymentMethodLoaded = (state: State) => state.loaded;
  export const getPaymentMethodLoading = (state: State) => state.loading;
  export const getPaymentMethodErrors = (state: State) => state.error;
