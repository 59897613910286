import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from './../../app.config';

@Injectable()
export class StatsService {
   constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }


  public fetchChurn() {
    return this.http.get(`${this.app_config.api_uri}/public/churn/`)
      .pipe(
        map((response: any) => {
          return response
        })
      );
  }

  public fetchSales() {
    return this.http.get(`${this.app_config.api_uri}/public/sales/`)
      .pipe(
        map((response: any) => {
          return response
        })
      );
  }
}
