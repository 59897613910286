import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import * as boxActions from '@app/store/actions/box.action';
// services
import { BoxService } from '@app/core/services/box.service';
var BoxEffects = /** @class */ (function () {
    function BoxEffects(boxService, actions$, store) {
        var _this = this;
        this.boxService = boxService;
        this.actions$ = actions$;
        this.store = store;
        this.loadAll$ = this.actions$.pipe(ofType(boxActions.LOAD_BOXES), switchMap(function () {
            return _this.boxService.getBoxes().pipe(map(function (boxes) {
                return _this.store.dispatch(new boxActions.LoadBoxesCompleteAction(boxes));
            }), catchError(function (reason) {
                _this.store.dispatch(new boxActions.LoadBoxesFailAction(reason.errors));
                return of(new boxActions.LoadBoxesFailAction(reason.errors));
            }));
        }));
        this.load$ = this.actions$.pipe(ofType(boxActions.LOAD_BOX), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var id = _a.id;
            return _this.boxService.getBox(id).pipe(map(function (box) {
                return _this.store.dispatch(new boxActions.LoadBoxCompleteAction(box));
            }), catchError(function (reason) {
                _this.store.dispatch(new boxActions.LoadBoxFailAction(reason.error));
                return of(new boxActions.LoadBoxFailAction(reason.error));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], BoxEffects.prototype, "loadAll$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], BoxEffects.prototype, "load$", void 0);
    return BoxEffects;
}());
export { BoxEffects };
