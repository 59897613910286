import { AfterViewInit, ApplicationRef, Component, ElementRef, Renderer2, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'sucstu-mothers-banner',
  templateUrl: './mothers-banner.component.html',
  styleUrls: ['./mothers-banner.component.scss'],
})
export class MothersBannerComponent implements AfterViewInit {

  header: HTMLElement;
  headerContainer: HTMLElement;
  navRowElement: HTMLElement;
  parentElement: HTMLElement;

  constructor(private appRef: ApplicationRef,
              private viewContainerRef: ViewContainerRef,
              private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.parentElement = this.elementRef.nativeElement.parentElement;
    //   if (this.parentElement) {
    //     this.header = this.parentElement.querySelector('sucstu-header') as HTMLElement;
    //     this.headerContainer = this.header.querySelector('.header-container') as HTMLElement;
    //     this.navRowElement = this.headerContainer.querySelector('.nav-row') as HTMLElement;
    //
    //     this.renderer.setStyle(this.navRowElement, 'position', 'relative');
    //     this.renderer.setStyle(this.headerContainer, 'padding-top', 0);
    //   }
    // }, 0);

  }

  // @HostListener('window:scroll', ['$event'])
  // checkScroll() {
  //   setTimeout(() => {
  //     const position = this.header.getBoundingClientRect();
  //     if (position.top <= 0) {
  //       this.renderer.setStyle(this.navRowElement, 'position', 'fixed');
  //       this.renderer.setStyle(this.headerContainer, 'padding-top', '');
  //     } else {
  //       this.renderer.setStyle(this.navRowElement, 'position', 'relative');
  //       this.renderer.setStyle(this.headerContainer, 'padding-top', 0);
  //     }
  //
  //   }, 0);
  // }
}
