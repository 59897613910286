import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, PLATFORM_ID, Inject } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { EmailValidator } from '@app/shared/validators/email.validator';

import { AppConfig, APP_CONFIG } from '@app/app.config';
import { LeadsService } from '@app/core/services/leads.service';
import { LeadFormBaseComponent } from '../lead-form-base/lead-form-base.component';
import { ErrorService } from '@app/error';
import { NotificationBarService } from '@app/core/services/notification-bar.service';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {MarketingService} from '@app/core/services/marketing.service';
import {ModalService} from '@app/core/services';
import {GmailModalService} from '@app/core/services/gmail-modal.service';

@Component({
  selector: 'sucstu-win-it-wednesday-lead-form',
  styleUrls: ['./win-it-wednesday-lead-form.component.scss'],
  templateUrl: './win-it-wednesday-lead-form.component.html'
})
export class WinItWednesdayLeadFormComponent extends LeadFormBaseComponent {

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private leadService: LeadsService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private notificationService: NotificationBarService,
    private readonly marketingService: MarketingService,
    private readonly gmailModalService: GmailModalService
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', EmailValidator.checkEmail),
      username: new FormControl('', [Validators.required]),
    });
    this.emailFormField = 'email';

    this.form.get('email').valueChanges
      .pipe(
        filter(data => typeof data === 'string' && data.indexOf('@') !== -1),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .pipe(takeUntil(this.destroyComponent$))
      .subscribe(email => {
        this.testSuggestedEmails(email);
      });
  }

  submitLeadForm() {
    const email = this.form.get('email').value;
    const username = this.form.get('username').value;

    if (this.form.invalid) {
      return;
    }

    if (email && email.includes('@gmail.co')) {
      this.gmailModalService.showGmailModal$.next(true);
    }

    this.createLead(email, username)
      .then(() => {
        this.submit.emit({ email });
        this.closeLeadForm();
      })
      .catch(error => {
        this.closeLeadForm();
        const message = `Error trying to create/update Lead.`;
        this.errorService.client.notify(error, {
          beforeSend: report => {
            report.severity = 'warning';
            report.updateMetaData('extras', {
              http: true,
              client: true,
              message,
              error,
            });
          }
        });
      });
  }

  createLead(email: string = '', username: string = ''): Promise<any> {
    const uuid = this.localStorage.retrieve('uuid');
    const utmData = this.route.snapshot.queryParams;
    this.localStorage.store('customerEmail', email);

    return this.leadService
      .createNewWinItWednesdayEntry({ uuid, email, utmData, leadForm: 'five-off', social: { instagram: username } })
      .toPromise()
      .then(data => {
        this.notificationService.open({ title: 'Entry Submitted!', message: '' });
        if (isPlatformBrowser(this.platformId) && this.app_config.production && this.app_config.name === 'production') {
          this.marketingService
            .reportLead(email)
            .subscribe();
        }
        return data;
      });
  }

  isFieldInvalid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }
}
