
    <form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">

      <div class="form-group form-group--flex-start">
        <input
          [attr.test-id]="'login__email'"
          class="form-group__input"
          type="email"
          placeholder="Email"
          formControlName="email">
        <span class="error-text" *ngIf="isFieldValid('email', 'invalidEmail')">Please use a valid email</span>
      </div>

      <div class="form-group form-group--flex-start">
        <div class="form-group__password">
          <input
            [attr.test-id]="'login__password'"
            class="form-group__input form-group__input--password"
            type="password"
            placeholder="Password"
            formControlName="password"
            (focus)="onFocusPassword()"
            *ngIf="!showPassword" />

          <input
            class="form-group__input form-group__input--password"
            type="text"
            placeholder="Password"
            formControlName="password"
            (focus)="onFocusPassword()"
            *ngIf="showPassword" />

          <button type="button" class="btn-show" (click)="toggleShowPassword()">Show</button>
        </div>
        <span class="error-text" *ngIf="isFieldValid('password', 'minlength')">
          The password requires 6 characters or more
        </span>
        <span class="error-text" *ngIf="isFieldValid('password', 'required') || formInvalid">
          Please enter your password
        </span>
      </div>
      <a class="forgot-password" routerLink="/forgot_password">Forgot password?</a>
      <div class="form-group form-group--submit">
        <button
          [attr.test-id]="'login__login-button'"
          class="btn btn-primary btn-primary--with-hover"
          type="submit">
          <!-- [ngClass]="{ 'btn-primary--invalid': form.invalid }" -->
          Log In
        </button>
      </div>
      <div class="error-text-container">
        <ng-content select=".error-text"></ng-content>
        <p class="error-text" *ngIf="formInvalid"></p>
      </div>
      <a class="subscribe-link" routerLink="/checkout">Don't have an account? Subscribe now.</a>


    </form>
  