/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./churn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./churn.component";
import * as i3 from "../../../core/services/stats.service";
var styles_ChurnComponent = [i0.styles];
var RenderType_ChurnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChurnComponent, data: {} });
export { RenderType_ChurnComponent as RenderType_ChurnComponent };
export function View_ChurnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "numbers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "firstNumber"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "otherNumbers"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.churnNumbers.delta; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.churnNumbers.turnedOffByCustomer; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.churnNumbers.turnedOffByDP; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.churnNumbers.gift_completed; _ck(_v, 8, 0, currVal_3); }); }
export function View_ChurnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-pages-churn", [], null, null, null, View_ChurnComponent_0, RenderType_ChurnComponent)), i1.ɵdid(1, 114688, null, 0, i2.ChurnComponent, [i3.StatsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChurnComponentNgFactory = i1.ɵccf("sucstu-pages-churn", i2.ChurnComponent, View_ChurnComponent_Host_0, {}, {}, []);
export { ChurnComponentNgFactory as ChurnComponentNgFactory };
