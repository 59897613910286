import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Store } from '@ngrx/store';
import { Meta, Title } from '@angular/platform-browser';
import { APP_CONFIG, AppConfig } from '@app/app.config';

import * as couponsActions from '@app/store/actions/coupon.actions';
// models
import { Coupon } from '@app/core/models/coupon.model';

@Component({
  selector: 'sucstu-pages-home',
  styleUrls: ['./every-plate.component.scss'],
  templateUrl: './every-plate.template.html'
})
export class EveryPlateComponent implements OnInit {

  coupon: Coupon;

  constructor(
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private router: Router,
    private store: Store<any>,
    private metaService: Meta,
    private titleService: Title,
    @Inject(APP_CONFIG) private app_config: AppConfig,
  ) { }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: 'Simple signup. Stunning succulents. We ship two organically-grown succulents from our SoCal nursery straight to your door. $5 off your first month!'
    });

    this.titleService.setTitle('Succulent Studios | Monthly Succulent Subscription Box');

    this.store.dispatch(new couponsActions.VerifyCouponAction('EVERYPLATE'));
  }


  selectPlan() {
    this.router.navigate(['/checkout'], { queryParamsHandling: 'preserve' });
  }

}
