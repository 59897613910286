import { Directive, ElementRef, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appBgDinamic]'
})
export class BackgroundDinamicDirective implements OnInit {
  @Input() bgDinamicXS: string;
  @Input() bgDinamicSM: string;
  @Input() bgDinamicMD: string;
  @Input() bgDinamicLG: string;

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  ngOnInit() {
    this.changeElementBg();
  }

  @HostListener('window:resize', ['$event'])
  changeElementBg() {
    if (isPlatformBrowser(this.platformId)) {
      const windowW = window.innerWidth;
      if (this.bgDinamicXS) {
        this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicXS})`;
      }

      if (windowW >= 768 && this.bgDinamicSM) {
        this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicSM})`;
      }

      if (windowW >= 1024 && this.bgDinamicMD) {
        this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicMD})`;
      }

      if (windowW >= 1600 && this.bgDinamicLG) {
        this.el.nativeElement.style.backgroundImage = `url(${this.bgDinamicLG})`;
      }
    }
  }
}
