<section class="w-full hero flex justify-center pt-20 mb-20">
  <div class="container px-4">
    <h1 class="hero-title uppercase m-0">
      Want <br> <span>more?</span>
    </h1>

    <h2 class="hero-subtitle">
      Claim your new succulent subscription now so you don't miss out on any rare + unique bbs!
    </h2>
  </div>
</section>

<section class="w-full flex flex-col mb-16">
  <div class="container w-full mb-16">
    <h2 class="why-claim-title m-0">
      WHY CLAIM and EXTEND YOUR SUBSCRIPTION? It's simple:
    </h2>
  </div>

  <div class="container">
    <div class="w-full grid grid-cols-1 lg:grid-cols-5 grid-rows-5 lg:grid-rows-1 gap-10">
      <div class="col-span-1 row-span-1 reasons-item-container">
        <div class="flex justify-center items-center h-full py-8 px-2 relative">
          <div class="reasons-number">1</div>
          <p class="reasons-text m-0">
            Because you love succulents (DUH)
          </p>
        </div>
      </div>
      <div class="col-span-1 row-span-1 reasons-item-container">
        <div class="flex justify-center items-center h-full py-8 px-2 relative">
          <div class="reasons-number">2</div>
          <p class="reasons-text m-0">
            Shipment / delivery email notifications for each box
          </p>
        </div>
      </div>
      <div class="col-span-1 row-span-1 reasons-item-container">
        <div class="flex justify-center items-center h-full py-8 px-2 relative">
          <div class="reasons-number">3</div>
          <p class="reasons-text m-0">
            Access to bonus boxes of special plants that only subscribers get
          </p>
        </div>
      </div>
      <div class="col-span-1 row-span-1 reasons-item-container">
        <div class="flex justify-center items-center h-full py-8 px-2 relative">
          <div class="reasons-number">5</div>
          <p class="reasons-text m-0">
            Be part of the SUCSTU fam with special access to our customer service team
          </p>
        </div>
      </div>
      <div class="col-span-1 row-span-1 reasons-item-container">
        <div class="flex justify-center items-center h-full py-8 px-2 relative">
          <div class="reasons-number">4</div>
          <p class="reasons-text m-0">
            Promos and discounts on pots and SUCSTU merch
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sm:container mx-auto w-full px-4 md:px-20 flex flex-col xl:flex-row items-center justify-end mb-12 xl:mb-16">
  <div class="w-auto mb-8 xl:mb-0 xl:pr-12">
    <h3 class="what-you-get__text m-0">
      What you get.
    </h3>
  </div>

  <div class="w-auto what-you-get__content-container flex flex-col items-center lg:flex-row justify-around">
    <div class="px-8 py-16 what-you-get__img">
      <img class="w-full" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/farm/img1.png" alt="">
    </div>

    <div class="px-8 flex flex-col justify-center">
      <div class="w-full mb-5">
        <p class="what-you-get__text-title m-0">
          <b>3 Months For Just $30</b>
        </p>
      </div>

      <div class="w-full">
        <p class="what-you-get__text-details m-0 pb-4 lg:p-0">
          Continue enjoying 2 rare bb succulents a month, potted in our 2 inch biodegradable pots with care instructions included, and get FREE SHIPPING on all 3 months with this special offer!
        </p>
      </div>
    </div>
  </div>

</section>

<section style="background-color: #FAFAFA;" class="w-full px-4 flex mb-12 xl:mb-16 overflow-x-hidden">
  <section class="mx-auto as-seen-container py-6 relative flex flex-wrap md:flex-no-wrap justify-start items-center md:justify-end">
    <div class="md:w-auto mb-8 md:mr-16 md:mb-0 text-center">
      <p class="as-seen-text m-0">
        AS SEEN IN
      </p>
    </div>

    <swiper [config]="{ spaceBetween: 66, slidesPerView: 'auto', autoplay: { delay: 1000 } }" class="w-full md:w-9/12 md:block items-center">

      <img class="inline-block self-center" style="max-width: 130px;" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/teen.png" alt="">


      <img class="inline-block self-center" style="max-width: 81px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/knot.png" alt="">


      <img class="inline-block self-center" style="max-width: 83px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/spruce.png" alt="">


      <img class="inline-block self-center" style="max-width: 105px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/dwell.png" alt="">


      <img class="inline-block self-center" style="max-width: 114px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/marthe.png" alt="">


      <img class="inline-block self-center" style="max-width: 90px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/refinery.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/essence.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/Buzzfeed.png" alt="">

      <img class="inline-block self-center" style="max-width: 130px;" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/teen.png" alt="">


      <img class="inline-block self-center" style="max-width: 81px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/knot.png" alt="">


      <img class="inline-block self-center" style="max-width: 83px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/spruce.png" alt="">


      <img class="inline-block self-center" style="max-width: 105px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/dwell.png" alt="">


      <img class="inline-block self-center" style="max-width: 114px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/marthe.png" alt="">


      <img class="inline-block self-center" style="max-width: 90px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/refinery.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/essence.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/Buzzfeed.png" alt="">

      <img class="inline-block self-center" style="max-width: 130px;" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/teen.png" alt="">


      <img class="inline-block self-center" style="max-width: 81px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/knot.png" alt="">


      <img class="inline-block self-center" style="max-width: 83px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/spruce.png" alt="">


      <img class="inline-block self-center" style="max-width: 105px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/dwell.png" alt="">


      <img class="inline-block self-center" style="max-width: 114px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/marthe.png" alt="">


      <img class="inline-block self-center" style="max-width: 90px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/refinery.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/essence.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/Buzzfeed.png" alt="">

      <img class="inline-block self-center" style="max-width: 130px;" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/teen.png" alt="">


      <img class="inline-block self-center" style="max-width: 81px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/knot.png" alt="">


      <img class="inline-block self-center" style="max-width: 83px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/spruce.png" alt="">


      <img class="inline-block self-center" style="max-width: 105px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/dwell.png" alt="">


      <img class="inline-block self-center" style="max-width: 114px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/marthe.png" alt="">


      <img class="inline-block self-center" style="max-width: 90px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/refinery.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/essence.png" alt="">


      <img class="inline-block self-center" style="max-width: 92px" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/landing-pages/glow-up/Buzzfeed.png" alt="">

    </swiper>
  </section>
</section>

<section class="w-full mb-20">
  <section class="w-full md:w-8/12 lg:w-6/12 mx-auto px-4">
    <div class="w-full px-6 mb-8">
      <h2 class="find-claim-code__title">
        Find your claim code on your box label and enter it here<span *ngIf="!isAuthenticated">, along with your email address.</span>
      </h2>
    </div>

    <section class="w-full">
      <div class="w-full grid grid-rows-2 md:grid-rows-1 grid-cols-1 md:grid-cols-2 gap-8">
        <div [formGroup]="form" class="md:order-2 col-span-1 col-row-1 flex flex-col">
          <input type="text" class="find-claim-code__input mb-6" formControlName="claimCode" placeholder="CLAIM CODE">

          <ng-container *ngIf="!isAuthenticated">
            <input type="text" class="find-claim-code__input mb-4" formControlName="email" placeholder="Email">
            <p *ngIf="userExist" class="login-text">Email address already exist -
              <span (click)="goToLogin()">Log in here.</span>
            </p>
          </ng-container>

          <div class="w-full text-center mb-4">
            <span class="find-claim-code__help-text">
              *Code located on shipping label.
            </span>
          </div>

          <div class="w-full">
            <button [disabled]="requesting" (click)="claimSubscription()" class="w-full uppercase find-claim-code__button p-4">
              <ng-container *ngIf="!requesting">
                Order now
              </ng-container>

              <ng-container *ngIf="!!requesting">
                Loading...
              </ng-container>
            </button>
          </div>
        </div>

        <div class="md:order-1 col-span-1 col-row-1">
          <img src="/assets/images/claim-code.png" alt="Label picture with Claim code">
        </div>
      </div>
    </section>
  </section>
</section>

<section class="w-full pb-20">
  <section class="container">
    <div class="w-full grid grid-cols-1 lg:grid-cols-4 grid-rows-4 lg:grid-rows-1 gap-5">
      <div class="col-span-1 row-span-1 flex flex-col items-center final-squares">
        <div class="final-squares__img-container flex items-center justify-center mb-6">
          <img src="/assets/images/claim/wave.png" alt="">
        </div>
        
        <h3 class="text-center final-squares__title mb-0 mb-5">
          Drought-tolerant
        </h3>

        <h4 class="text-center final-squares__subtitle m-0">
          Conserve water! Your succulents only need a drink every 7-10 days.
        </h4>
      </div>

      <div class="col-span-1 row-span-1 flex flex-col items-center final-squares">
        <div class="final-squares__img-container flex items-center justify-center mb-6">
          <img src="/assets/images/claim/heart.png" alt="">
        </div>

        <h3 class="text-center final-squares__title mb-0 mb-5">
          Easy Care
        </h3>

        <h4 class="text-center final-squares__subtitle m-0">
          Set in a spot that gets about 6+ hours of natural light a day.
        </h4>
      </div>

      <div class="col-span-1 row-span-1 flex flex-col items-center final-squares">
        <div class="final-squares__img-container flex items-center justify-center mb-6">
          <img src="/assets/images/claim/oxygen.png" alt="">
        </div>

        <h3 class="text-center final-squares__title mb-0 mb-5">
          Oxygen-boosters
        </h3>

        <h4 class="text-center final-squares__subtitle m-0">
          Succulents release oxygen all night long while your other plants release carbon dioxide.
        </h4>
      </div>

      <div class="col-span-1 row-span-1 flex flex-col items-center final-squares">
        <div class="final-squares__img-container flex items-center justify-center mb-6">
          <img src="/assets/images/claim/low-pollen.png" alt="">
        </div>

        <h3 class="text-center final-squares__title mb-0 mb-5">
          Low Pollen Production
        </h3>

        <h4 class="text-center final-squares__subtitle m-0">
          Most succulents and all cacti are a 1 on the Ogren Plant Allergy Scale (OPALS), so they make perfect houseplants for those who suffer from allergies.
        </h4>
      </div>
    </div>
  </section>
</section>
