import { of } from 'rxjs';
import * as i0 from "@angular/core";
var PreloadByRoutingService = /** @class */ (function () {
    function PreloadByRoutingService() {
    }
    PreloadByRoutingService.prototype.preload = function (route, preload) {
        return route.data && route.data.preload ? preload() : of(null);
    };
    PreloadByRoutingService.ngInjectableDef = i0.defineInjectable({ factory: function PreloadByRoutingService_Factory() { return new PreloadByRoutingService(); }, token: PreloadByRoutingService, providedIn: "root" });
    return PreloadByRoutingService;
}());
export { PreloadByRoutingService };
