import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigService {
  constructor(
    private httpClient: HttpClient, @Inject(APP_CONFIG) private app_config: AppConfig) {
  }

  getConfig(): Observable<any> {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.httpClient
      .get(`${this.app_config.api_uri}/config`, { headers });
  }
}
