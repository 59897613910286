/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/auth-form/auth-form.component.ngfactory";
import * as i3 from "../../components/auth-form/auth-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./login.component";
import * as i7 from "@angular/router";
import * as i8 from "@ngrx/store";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "error-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "login-container container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "login-container__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log In"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "sucstu-auth-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AuthFormComponent_0, i2.RenderType_AuthFormComponent)), i1.ɵdid(4, 49152, null, 0, i3.AuthFormComponent, [i4.FormBuilder], null, { submitted: "submitted" }), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_LoginComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.error$)); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 245760, null, 0, i6.LoginComponent, [i7.Router, i7.ActivatedRoute, i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("sucstu-login", i6.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
