import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Box } from '@app/core/models/box.model';
import * as boxActions from '@app/store/actions/box.action';

export interface Entities {
  [id: string]: Box
}

export interface State extends EntityState<Box> {
  entities: Entities,
  loaded: boolean,
  loading: boolean,
  errors: any
};

export const adapter: EntityAdapter<Box> = createEntityAdapter<Box>({
  selectId: (box: Box) => box._id,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  loaded: false,
  errors: null
});

export function reducer(
  state: State = initialState,
  action: boxActions.Actions): State {
  switch (action.type) {

    case boxActions.LOAD_BOX:
    case boxActions.LOAD_BOXES: {
      return { ...state, loading: true, loaded: false };
    }

    case boxActions.LOAD_BOXES_COMPLETE: {
      return adapter.addAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        errors: null
      });
    }

    case boxActions.LOAD_BOX_COMPLETE: {
      return adapter.addOne(action.payload, {
        ...state,
        loaded: true,
        loading: false,
        errors: null
      });
    }

    case boxActions.LOAD_BOX_FAIL:
    case boxActions.LOAD_BOXES_FAIL: {
      return { ...state, errors: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getBoxes = (state: State) => state.entities;
export const getBoxesLoaded = (state: State) => state.loaded;
export const getBoxesLoading = (state: State) => state.loading;
export const getBoxesError = (state: State) => state.errors;
