import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import * as fromCouponsActions from './../../../store/actions/coupon.actions';

// Angulartics

@Component({
  selector: 'sucstu-thanks',
  styleUrls: ['./thanks.component.scss'],
  templateUrl: './thanks.component.html',
})
export class ThanksComponent implements OnInit {

  constructor(
    private store: Store<any>,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService) {
  }

  ngOnInit() {
    this.localStorage.clear('customerEmail');
    this.localStorage.clear('customerAddress');
    this.localStorage.clear('customerRecipient');
    this.localStorage.clear('checkoutSubscriptionStartDate');
    this.localStorage.clear('claimCode');
    this.sessionStorage.clear('coupon');
    this.store.dispatch(new fromCouponsActions.ClearCoupon());
  }
}
