import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer
} from '@angular/core';

// models
import { PaymentMethod } from '@app/core/models/payment-method';

@Component({
  selector: 'sucstu-editable-payment-select',
  styleUrls: ['./editable-payment-select.component.scss'],
  templateUrl: './editable-payment-select.component.html'
})
export class EditablePaymentSelectComponent implements OnInit {

  @ViewChild('paymentMethodSelect') paymentMethodSelect: ElementRef;

  @Input() paymentMethodList: PaymentMethod[];
  @Input() selectedPaymentMethod: PaymentMethod;
  @Input() editing = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();


  constructor(
    private _renderer: Renderer
  ) { }

  ngOnInit() { }

  onSave() {
    const element = this.paymentMethodSelect.nativeElement as HTMLSelectElement;
    this.save.emit(element.value);
  }

  onCancel() {
    this.cancel.emit();
  }

  onEdit() {
    this.edit.emit();
    setTimeout(() =>
      this._renderer.invokeElementMethod(this.paymentMethodSelect.nativeElement, 'focus', [])
    );
  }
}
