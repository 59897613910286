import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { Observable } from 'rxjs';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

@Injectable()
export class ClaimService {
  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  checkClaimCode({ email, claimCode }: { email: string, claimCode: string }): Observable<any> {
    return this.httpClient.post(
      `${this.app_config.api_uri}/users/subscriptions/check-claim-code`,
      { email, claimCode: claimCode ? claimCode.toUpperCase() : '' }
    );
  }
}
