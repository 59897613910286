import * as tslib_1 from "tslib";
import * as mapKeys from 'lodash/mapKeys';
import * as shipmentActions from '@app/store/actions/shipment.action';
export var initialState = {
    entities: {},
    loaded: true,
    loading: false,
    errors: []
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a, _b;
    switch (action.type) {
        case shipmentActions.LOAD_SHIPMENTS:
        case shipmentActions.UPDATE_SHIPMENT:
        case shipmentActions.LOAD_SHIPMENT: {
            return tslib_1.__assign({}, state, { loading: true, loaded: false });
        }
        case shipmentActions.LOAD_SHIPMENTS_COMPLETE: {
            var shipments = mapKeys(action.payload, '_id');
            var entities = tslib_1.__assign({}, state.entities, shipments);
            return tslib_1.__assign({}, state, { entities: entities, loaded: true, loading: false });
        }
        case shipmentActions.UPDATE_SHIPMENT_COMPLETE: {
            var entities = tslib_1.__assign({}, state.entities, (_a = {}, _a[action.payload._id] = action.payload, _a));
            return tslib_1.__assign({}, state, { entities: entities, loaded: true, loading: false });
        }
        case shipmentActions.LOAD_SHIPMENT_COMPLETE: {
            var entities = tslib_1.__assign({}, state.entities, (_b = {}, _b[action.payload._id] = action.payload, _b));
            return tslib_1.__assign({}, state, { entities: entities, loaded: true, loading: false });
        }
        case shipmentActions.LOAD_SHIPMENTS_FAIL:
        case shipmentActions.LOAD_SHIPMENT_FAIL:
        case shipmentActions.UPDATE_SHIPMENT_FAIL: {
            return tslib_1.__assign({}, state, { errors: action.payload });
        }
        default: {
            return state;
        }
    }
}
export var getShipments = function (state) { return state.entities; };
export var getShipmentLoaded = function (state) { return state.loaded; };
export var getShipmentLoading = function (state) { return state.loading; };
export var getShipmentErrors = function (state) { return state.errors; };
