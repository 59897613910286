import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

// services
import { ModalInfoService, SubscriptionService } from '@app/core/services';

@Injectable()
export class SelectBoxGuard implements CanActivate {
  constructor(
    private router: Router,
    private modalInfoService: ModalInfoService,
    private subscriptionService: SubscriptionService
  ) {}

  selectBox(subscriptionId, boxId) {
    return this.subscriptionService
      .selectSubscriptionBox(subscriptionId, boxId)
      .toPromise();
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { subscriptionId, boxId } = route.queryParams;
    return this.selectBox(subscriptionId, boxId)
      .then(() => {
        this.router.navigate(['/boxes/box-confirmation'], {
          queryParams: { boxId, subscriptionId }
        });
        return true;
      })
      .catch(({ error }) => {

        if (error.code && error.code === 1) {
          this.router.navigate(
            ['/profile'],
            {
              queryParams: {
                reason: 'box-selection-payment-failed',
                subscriptionId,
                boxId,
              }
            }
          );
        } else {
          this.router.navigate(['/profile']).then(() => {
            this.modalInfoService.open({
              data: {
                title: 'Something went wrong',
                message: error.message
              }
            });
          });
        }

        return false;
      });
  }
}
