import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import * as addressActions from '@app/store/actions/address.actions';
// services
import { AddressService } from '@app/core/services/address.service';
var AddressEffects = /** @class */ (function () {
    function AddressEffects(addressService, actions$, store) {
        var _this = this;
        this.addressService = addressService;
        this.actions$ = actions$;
        this.store = store;
        this.load$ = this.actions$
            .pipe(ofType(addressActions.LOAD_ADDRESSES), switchMap(function () {
            return _this.addressService
                .getAddresses()
                .pipe(map(function (addresses) { return _this.store.dispatch(new addressActions.LoadAddressesCompleteAction(addresses)); }), catchError(function (reason) {
                _this.store.dispatch(new addressActions.LoadAddressesFailAction(reason.errors));
                return of(new addressActions.LoadAddressesFailAction(reason.errors));
            }));
        }));
        this.loadById$ = this.actions$
            .pipe(ofType(addressActions.LOAD_ADDRESS), switchMap(function (action) {
            return _this.addressService
                .getAddress(action.payload)
                .pipe(map(function (address) { return _this.store.dispatch(new addressActions.LoadAddressCompleteAction(address)); }), catchError(function (reason) {
                _this.store.dispatch(new addressActions.LoadAddressFailAction(reason.errors));
                return of(new addressActions.LoadAddressFailAction(reason.errors));
            }));
        }));
        this.update$ = this.actions$
            .pipe(ofType(addressActions.UPDATE_ADDRESS), map(function (action) { return action.payload; }), switchMap(function (data) {
            return _this.addressService.updateAddress(data)
                .pipe(map(function (address) { return _this.store.dispatch(new addressActions.UpdateAddressesCompleteAction({
                id: address._id,
                changes: address
            })); }), catchError(function (reason) {
                _this.store.dispatch(new addressActions.UpdateAddressesFailAction(reason.errors));
                return of(new addressActions.UpdateAddressesFailAction(reason.errors));
            }));
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AddressEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AddressEffects.prototype, "loadById$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AddressEffects.prototype, "update$", void 0);
    return AddressEffects;
}());
export { AddressEffects };
