export class DeviceDetectorService {

  constructor() { }

  isiOS(): boolean {
    return [
      `iPad`,
      `iPod`,
      `iPhone`,
      `iPad Simulator`,
      `iPod Simulator`,
      `iPhone Simulator`,
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes(`Mac`) && `ontouchend` in document);
  }

  isAndroid(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf(`android`) > -1;
    return isAndroid;
  }

}
