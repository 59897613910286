import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'sucstu-pages-about',
  styleUrls: ['./about.component.scss'],
  templateUrl: './about.template.html'
})

export class AboutComponent implements OnInit {
  constructor(
    private metaService: Meta,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: 'Discover more about our second-generation nursery in Fallbrook, CA and take a peek at our stunning succulents.'
    });

    this.titleService.setTitle('About Succulent Studios | Monthly Subscription Box');
  }
}
