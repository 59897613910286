import { createSelector } from '@ngrx/store';

import * as fromRoot from '@app/store/selectors';
import * as fromBoxes from '@app/store/reducers/box.reducer';

export { State, Entities } from '@app/store/reducers/box.reducer';

export const getBoxesEntities = createSelector(
  fromRoot.getBoxesState,
  (state: fromBoxes.State) => state.entities
);

export const getAllBoxes = createSelector(
  getBoxesEntities,
  entities => {
    return Object.keys(entities).map(id => entities[id]);
  }
);

export const getBoxesErrors = createSelector(
  fromRoot.getBoxesState,
  (state: fromBoxes.State) => state.errors
);

export const getBoxesLoaded = createSelector(
  fromRoot.getBoxesState,
  (state: fromBoxes.State) => state.loaded
);

export const getBoxesLoading = createSelector(
  fromRoot.getBoxesState,
  (state: fromBoxes.State) => state.loading
);

export const getBoxById = (id: string) => createSelector(
  fromRoot.getBoxesState,
  ({ entities }) => entities[id]
);
