import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import {Observable, of, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// config
import { APP_CONFIG, AppConfig } from '@app/app.config';
// models
import { Address } from './../models/address.model';
// services
import { AuthService } from '@app/auth/services/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AddressService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  getAddresses(): Observable<Address[]> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .get(`${this.app_config.api_uri}/users/${userId}/address`)
      .pipe(map((result: any) => result.addresses as Address[]));
  }

  getAddress(addressId: string): Observable<Address> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .get(`${this.app_config.api_uri}/users/${userId}/address/${addressId}`)
      .pipe(map((result: any) => result.address as Address));
  }

  updateAddress({ _id, newData }): Observable<Address> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${this.app_config.api_uri}/users/${userId}/address/${_id}`, newData)
      .pipe(map((result: any) => result.address as Address));
  }

  verifyAddress({ address }): Observable<Address> {
    // We are disabling the validation because some users hasn't been able to checkout
    return of(address);
    // return this.httpClient
    //   .post(`${this.app_config.api_uri}/public/address/validation`, { address })
    //   .pipe(catchError(e => {
    //     if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
    //       (window as any).dataLayer.push({
    //         'event': 'manuallyAddressFail',
    //         'manuallyAddressFail': true,
    //       });
    //     }
    //     return throwError(e);
    //   }))
    //   .pipe(map((result: any) => result.address as Address));
  }
}
