import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

// models
import { Box } from '@app/core/models/box.model';

@Injectable()
export class BoxService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  getBoxes(): Observable<Box[]> {
    return this.httpClient
      .get(`${this.app_config.api_uri}/boxes/`)
      .pipe(map((result: any) => result.boxes as Box[]));
  }

  getBox(id: string): Observable<Box> {
    return this.httpClient
      .get(`${this.app_config.api_uri}/boxes/${id}`)
      .pipe(map((result: any) => result.box as Box));
  }

  getCurrentBox(): Observable<Box> {
    return this.httpClient
      .get(`${this.app_config.api_uri}/boxes/current`)
      .pipe(map((result: any) => result.box as Box));
  }
}
