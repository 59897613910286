<div class="page-container container">
  <div class="backgrounds-page">
    <div class="backgrounds-title">
      <h1 class="text-medium">Desert Dream Digital Wallpapers</h1>
      <p>Download Succulent Studios' exclusive collection of Desert Dream Digital Wallpapers featuring succulents and cacti, oh my! Perfect for smartphones, Apple watches, and tablets. Show off your dreamy new background with a screenshot, and tag us @sucstu.<p>
    </div>

    <ul class="backgrounds-container">
      <li class="background-item" *ngFor="let background of backgroundsList">
        <img [src]="background" alt="background image" />
        <a class="download-link" [href]="background" target="a_blank">Download</a>
      </li>
    </ul>

  </div>
</div>
