/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./box-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./box-item.component";
var styles_SummerBoxItemComponent = [i0.styles];
var RenderType_SummerBoxItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SummerBoxItemComponent, data: {} });
export { RenderType_SummerBoxItemComponent as RenderType_SummerBoxItemComponent };
export function View_SummerBoxItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), i1.ɵpid(0, i4.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "item-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "succulent"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "item-name mb-4"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "item-cost mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " per box\n"])), i1.ɵppd(8, 4), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "item-name flex items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "item-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDecrease() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "item-button-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "item-qty"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "item-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onIncrease() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "item-button-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.box.heroImage; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.box.name)); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 1), (_co.box.price + _co.box.shippingCosts), "USD", "symbol", "1.2-2")); _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.quantityCount; _ck(_v, 14, 0, currVal_3); }); }
export function View_SummerBoxItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-summer-box-item", [], null, null, null, View_SummerBoxItemComponent_0, RenderType_SummerBoxItemComponent)), i1.ɵdid(1, 49152, null, 0, i5.SummerBoxItemComponent, [], null, null)], null, null); }
var SummerBoxItemComponentNgFactory = i1.ɵccf("sucstu-summer-box-item", i5.SummerBoxItemComponent, View_SummerBoxItemComponent_Host_0, { box: "box" }, { quantity: "quantity" }, []);
export { SummerBoxItemComponentNgFactory as SummerBoxItemComponentNgFactory };
