import { Routes } from '@angular/router';
// guards
import { RecoveryPasswordGuard } from '@app/auth/guards/recovery-password.guard';
import { LoginComponent } from './containers/login/login.component';
import { SignupComponent } from './containers/signup/signup.component';
import { RememberPasswordComponent } from './containers/remember-password/remember-password.component';
import { RecoveryPasswordComponent } from './containers/recovery-password/recovery-password.component';
import { ClaimGiftGuard } from '@app/auth/guards/claim-gift.guard';
var ɵ0 = {
    page: 'claim-gift'
};
var routes = [
    { path: 'login', component: LoginComponent, },
    { path: 'signup', component: SignupComponent },
    {
        path: 'claim-gift',
        component: SignupComponent,
        data: ɵ0,
        canActivate: [ClaimGiftGuard],
    },
    {
        path: 'recovery-password',
        component: RecoveryPasswordComponent,
        canActivate: [RecoveryPasswordGuard]
    },
    { path: 'forgot_password', component: RememberPasswordComponent }
];
var AuthRoutingModule = /** @class */ (function () {
    function AuthRoutingModule() {
    }
    return AuthRoutingModule;
}());
export { AuthRoutingModule };
export { ɵ0 };
