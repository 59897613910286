import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Meta, Title } from '@angular/platform-browser';

// actions
import * as fromCouponsActions from './../../../store/actions/coupon.actions';

@Component({
  selector: 'sucstu-apartment-therapy',
  styleUrls: ['./apartment-therapy.component.scss'],
  templateUrl: './apartment-therapy.component.html'
})
export class ApartmentTherapyComponent implements OnInit {

  constructor(
    private router: Router,
    private store: Store<any>,
    private metaService: Meta,
    private titleService: Title,
  ) {
  }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: 'Succulent Studios welcomes Apartment Therapy shoppers.' +
        ' Get two unique succulents a month, and free shipping the first month!'
    });

    this.titleService.setTitle('Succulent Studios | Apartment Therapy Free Shipping');
  }

  applyCoupon() {
    this.router.navigate(['/checkout'], { queryParamsHandling: 'preserve' })
      .then(
        () => this.store.dispatch(new fromCouponsActions.VerifyCouponAction('ATFREE'))
      );
  }

}
