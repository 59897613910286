import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

// RxJS
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

// actions
import * as planActions from '@app/store/actions/plans.actions';

// selectos
import * as fromPlans from '@app/store/selectors/plans.selector';

// Services
import { ErrorService } from '@app/error';
import { ModalInfoService } from './../../../core/services';

@Injectable()
export class PlansGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<any>,
    private errorService: ErrorService,
    private modalInfoService: ModalInfoService
  ) { }

  checkStore(): Observable<any> {
    return Observable.create(observer => {
      this.store.select(fromPlans.getPlansError)
        .pipe(
          filter((error: any) => !!error),
          take(1),
        )
        .subscribe((error) => {
          observer.error(error);
          this.store.dispatch(new planActions.ClearPlanErrorsAction());
        });

      this.store
        .select(fromPlans.getPlansLoaded)
        .pipe(
          tap(loaded => {
            if (!loaded) {
              this.store.dispatch(new planActions.LoadPlansAction());
            }
          }),
          filter((loaded: boolean) => loaded),
          take(1),
        )
        .subscribe(() => {
          observer.next(true);
        });
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError((error) => {
        const message = `Error trying to fetch plans in the PlansGuard.`;
        console.error(message);
        this.errorService.client.notify(error, {
          beforeSend: report => {
            report.severity = 'warning';
            report.updateMetaData('extras', {
              http: true,
              client: false,
              message,
              error
            });
          }
        })
        this.router.navigate(['/']);
        this.modalInfoService.open({
          data: {
            title: 'Ooops!',
            message: 'There was an error trying to load the checkout page. Please try again.',
          }
        });
        return of(false);
      })
    );
  }

}
