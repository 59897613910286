<div class="lead-banner flex flex-col items-center justify-center md:flex-row md:flex-wrap">
  <div class="w-full h-full absolute top-0 left-0 lead-darker"></div>

  <div class="w-full md:w-4/12 join-the-club md:flex md:items-center relative">
    <h1 class="join-the-club__text">
      JOIN <br> THE CLUB
    </h1>
  </div>

  <section class="w-full md:w-7/12 md:ml-auto md:flex md:flex-col md:justify-center lg:w-6/12 relative">
    <div class="w-full weve-got">
      <p class="m-0 text-white text-center weve-got__text">
      <span class="font-semibold">
        We've got some plan(t)s in the making ... <br> wanna come?
      </span>
        <br>
        <span>
        You should come. Drop your info below to get insider tips,
      </span>
      </p>
    </div>

    <div class="w-full off-number text-center">
      <h2 class="off-number__6-off-title">FREE SHIPPING</h2>
      <p class="off-number__6-off-text text-white">on your first box, and be on the road to plant happiness.</p>
    </div>

    <div class="w-full dont-worry text-center">
      <p class="m-0 p-0 text-white dont-worry__text">
        Don't worry, we won't ping you in the middle of the night when your plants are sleeping.
        <br class="md:hidden">
        Use <b>HELP</b> for help or <b>STOP</b> to cancel.
      </p>
    </div>

    <span *ngIf="(showSuggestedEmail$ | async)" class="user-suggested-email text-center text-white mb-2">
    Do you mean <strong (click)="saveSuggestedEmail()">{{ suggestedEmail$ | async }}</strong>?
  </span>

    <form class="form flex flex-col md:flex-row md:flex-wrap md:justify-center" [formGroup]="form">
      <div class="flex w-full md:w-auto md:flex-1 md:mr-2 bg-white form__input">
        <input formControlName="email" class="w-full" id="six-off-email" type="text" placeholder="Your email"/>
      </div>

      <div class="flex items-center w-full md:w-auto md:flex-1 md:ml-2 bg-white form__input">
        <img class="form__input__flag mr-2" src="/assets/images/lead-form/united-states.png" alt="">
        <input mask="(000) 000-0000" formControlName="phone" class="w-full" id="six-off-phone" type="text" placeholder="Your mobile" />
      </div>

      <button (click)="submitLeadForm()"
              [disabled]="(form.value.email === '' && form.value.phone === '') || savingLead"
              class="w-full love-succulents">
        {{ savingLead ? 'Loading...' : 'I Love Succulents' }}
      </button>
    </form>

    <div class="cursor-pointer w-full no-thanks text-center text-white">
      <p (click)="closeLeadForm()" class="m-0 p-0 no-thanks__text">No thanks, I don't want to save free shipping.</p>
    </div>
  </section>
</div>
