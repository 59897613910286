import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import * as shipmentsAction from '@app/store/actions/shipment.action';

// services
import { ShipmentService } from '@app/core/services/shipment.service';

@Injectable()
export class ShipmentEffects {

  @Effect({ dispatch: false }) load$ = this.actions$
    .pipe(
      ofType(shipmentsAction.LOAD_SHIPMENTS),
      switchMap(() => {
        return this.shipmentService.getShipments()
          .pipe(
            map(shipments =>  this.store.dispatch(new shipmentsAction.LoadShipmentsCompleteAction(shipments))),
            catchError(reason => {
              this.store.dispatch(new shipmentsAction.LoadShipmentsFailAction(reason.errors));
              return of(new shipmentsAction.LoadShipmentsFailAction(reason.errors));
            }),
          )
      }),
    );

  @Effect({ dispatch: false }) loadById$ = this.actions$
    .pipe(
      ofType(shipmentsAction.LOAD_SHIPMENT),
      switchMap((action: shipmentsAction.LoadShipmentAction) => {
        return this.shipmentService.getShipment(action.payload)
          .pipe(
            map(shipment =>  this.store.dispatch(new shipmentsAction.LoadShipmentCompleteAction(shipment))),
            catchError(reason => {
              const error = { message: `Couldn't load the shipment` };
              this.store.dispatch(new shipmentsAction.LoadShipmentFailAction([error]));
              return of(new shipmentsAction.LoadShipmentFailAction([error]));
            }),
          );
      }),
    );

  constructor(
    private shipmentService: ShipmentService,
    private actions$: Actions,
    private store: Store<any>
  ) { }
}
