import { OnDestroy } from '@angular/core';
import { ShipmentTrackingService } from '@app/pages/services/shipment-tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, pluck, switchMap, takeUntil } from 'rxjs/operators';
var ShipmentTrackingComponent = /** @class */ (function () {
    function ShipmentTrackingComponent(shipmentTrackingService, route, router) {
        var _this = this;
        this.shipmentTrackingService = shipmentTrackingService;
        this.route = route;
        this.router = router;
        this.componentDestroyed$ = new Subject();
        this.trackingCode$ = this.route.queryParams
            .pipe(pluck('trackingCode'));
        this.trackingEvents$ = new Subject();
        this.trackerObject$ = new Subject();
        this.getTrackingEventsSubscription = this.trackingCode$
            .pipe(map(function (trackingCode) {
            if (!trackingCode) {
                throw new Error('Tracking code is not defined');
            }
            return trackingCode;
        }))
            .pipe(switchMap(function (trackingCode) {
            return _this.shipmentTrackingService.getShipmentTrackingEvents(trackingCode);
        }))
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(function (_a) {
            var events = _a.events, tracker = _a.tracker;
            _this.trackingEvents$.next(events);
            _this.trackerObject$.next(tracker);
        }, function (error) {
            console.error(error);
            _this.router.navigate(['/']);
        });
    }
    ShipmentTrackingComponent.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    };
    return ShipmentTrackingComponent;
}());
export { ShipmentTrackingComponent };
