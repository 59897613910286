import {
  Component,
  OnInit,
  Output,
  ElementRef,
  ViewChild,
  Renderer,
  Input,
  EventEmitter
} from '@angular/core';

// validators
import { isValidNumber } from 'libphonenumber-js';

@Component({
  selector: 'sucstu-editable-input',
  styleUrls: ['./editable-input.component.scss'],
  template: `
    <div>
      <div *ngIf="editing">
        <div class="form-group form-group__flex-start">
          <input
            #inlineEditControl
            [required]="required"
            [type]="type"
            [name]="value"
            [value]="value"
            class="form-group__input"/>
        </div>

        <div class="actions-container">
          <div [attr.test-id]="'editable-input__save-button'" class="action-btn" (click)="onSave()">Save</div>
          <div class="action-btn" (click)="onCancel()">Cancel</div>
        </div>

      </div>
      <div *ngIf="!editing" class="value-container">
        <div (click)="onEdit()" class="value-field">{{ value }}</div>
        <div (click)="onEdit()" class="edit-button">
          <img src="/assets/images/pencil-small-icon.png" alt="" />
        </div>
      </div>
    </div>
  `
})
export class EditableInputComponent implements OnInit {

  @ViewChild('inlineEditControl') inlineEditControl: ElementRef; // input DOM element

  @Input() value = '';
  @Input() type = 'text';
  @Input() required = false;
  @Input() disabled = false;
  @Input() editing = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();

  onSave() {
    const element = this.inlineEditControl.nativeElement as HTMLInputElement;
    const value = element.value;
    if (this.type === 'phone') {
      if (isValidNumber(value, 'US')) {
        this.save.emit(value);
        return;
      }
    } else {
      this.save.emit(value);
    }
  }

  onCancel() {
    this.cancel.emit();
  }

  // Start the editting process for the input element
  onEdit() {
    this.edit.emit();

    // Focus on the input element just as the editing begins
    setTimeout(() =>
      this._renderer.invokeElementMethod(this.inlineEditControl.nativeElement, 'focus', [])
    );
  }

  constructor(
    private _renderer: Renderer
  ) { }

  ngOnInit() { }
}
