<div class="container">
  <div class="summer-titleContent">
    <div class="summer-line"></div>
<!--    <img class="summer-sun" src="/assets/images/sun-copy.svg" alt="summer sun" width="76px">-->
    <div class="summer-line"></div>
  </div>

  <h1 class="summer-title">
    SHOP THE
  </h1>
  <div class="banner mobile">
    <img class="mx-auto" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/winter/WINTER+BLOWOUT.png" alt="">
  </div>
  <div class="banner desktop">
    <!--      <img src="/assets/images/summer-desktop.png" alt="">-->
    <img class="mx-auto" src="https://s3.us-west-1.amazonaws.com/assets.succulent.studio/images/otb/winter/WINTER+BLOWOUT.png" alt="">
  </div>
  <!-- <div class="summertime-collectio-1">
      SUMMERTIME COLLECTION
  </div> -->
  <div class="summer-line"></div>

  <div class="w-full" style="padding-top: 16px">
    <div class="mx-auto text-center" style="max-width: 820px">
      We’re celebrating 2023 by highlighting our most popular succulents. Take advantage of this limited time offer to snag as many of these $10 boxes as you can!
      <br>
      <b>*FREE SHIPPING ON ALL ORDERS</b>
    </div>
  </div>

  <div class="row item-row mt-0">
    <sucstu-summer-box-item
            class="col-md-5"
            *ngFor="let box of (boxes$ | async); let i = index"
            [box]="box"
            (quantity)="quantityChange($event, box)">
    </sucstu-summer-box-item>

  </div>

  <div class="row item-row mb-8">
    <div *ngIf="preventRedirect()" class="checkout-button">CHECKOUT</div>
    <a *ngIf="!preventRedirect()" routerLink="/boxes/boxes-purchase" [queryParams]="queryParams$ | async" class="checkout-button enabled">CHECKOUT</a>
  </div>

  <div class="w-full text-center mb-8">
    <p style="font-size: 18px;" class="description-terms mb-16"><b>LIMITED STOCK!</b> Order before your faves are sold out!</p>

    <p class="description-terms">
      *While supplies last. Boxes can not be split up or exchanged. Only one address can be used per order. Succulents may be larger or smaller / different coloring than images shown, as each plant grows at its own pace. Printed care cards will be added in each box IF we have inventory for both plants in that box. Otherwise they will be available for digital download on our site. Please allow up to 2 weeks for shipping.
    </p>
  </div>
</div>
