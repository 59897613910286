// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';
import * as tslib_1 from "tslib";
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AfterViewInit, ElementRef, NgZone, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
// Angulartics
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
// RxJS
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
// services
import { AuthService } from '@app/auth/services/auth.service';
import { AppService, ConfigService, GoogleOptimizeService, LeadsService } from '@app/core/services';
import { ErrorService } from './error/services';
// actions
import * as userActions from '@app/store/actions/user.actions';
import * as notificationBarActions from '@app/store/actions/notification-bar.actions';
// selectors
import * as fromRoot from '@app/store/selectors';
import { paramsMap } from '@app/core/mappers/params-map.mapper';
import { isPlatformBrowser, Location } from '@angular/common';
import { MarketingService } from '@app/core/services/marketing.service';
import { GoogleTagManagerService } from './core/services/google-tag-manager.service';
import { AnglerAiService } from '@app/core/services/angler-ai/angler-ai.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, route, appService, authService, angulartics, leadService, errorService, store, configService, localStorage, sessionStorage, googleOptimizeService, marketingService, zone, platformId, location, googleTagManagerService, anglerAiService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.appService = appService;
        this.authService = authService;
        this.angulartics = angulartics;
        this.leadService = leadService;
        this.errorService = errorService;
        this.store = store;
        this.configService = configService;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.googleOptimizeService = googleOptimizeService;
        this.marketingService = marketingService;
        this.zone = zone;
        this.platformId = platformId;
        this.location = location;
        this.googleTagManagerService = googleTagManagerService;
        this.anglerAiService = anglerAiService;
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.appLoading$ = this.appService.appLoading$.asObservable();
        this.state = 'inactive';
        this.notificationBarHeight = 0;
        this.displayMotherBanner = false;
        this.angulartics.startTracking();
        router.events
            .pipe(withLatestFrom(this.store))
            .subscribe(function (_a) {
            var event = _a[0], state = _a[1];
            _this.navigationInterceptor(event);
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user$ = this.store.pipe(select(fromRoot.getUserState));
        this.notificationBar$ = this.store.pipe(select(fromRoot.getNotificationBarState));
        this.configService.getConfig().subscribe(function (data) {
            _this.sessionStorage.store('config', data.config);
        });
        this.appService.init();
        // Clear LS props when needed.
        var currentAppVersion = this.localStorage.retrieve('appVersion');
        if (!currentAppVersion || currentAppVersion !== environment.app_version) {
            this.localStorage.store('appVersion', environment.app_version);
            Object.keys(environment.propsToClearOnLS)
                .filter(function (key) { return environment.propsToClearOnLS[key]; })
                .forEach(function (key) { return _this.localStorage.clear(key); });
        }
        if (isPlatformBrowser(this.platformId)) {
            window['setGOExperiments'] = {
                zone: this.zone,
                componentFn: function (experiment) {
                    _this.googleOptimizeService.setGoogleOptimizeAsInitialzed();
                    _this.googleOptimizeService.setExperiment(experiment);
                    // RUN THIS FUNCTION ON OPTIMIZE OR IN YOUR CONSOLE TO ACTIVE the METHOD ABOVE
                    /*
                        window['setGOExperiments'].zone.run(() => {
                            window['setGOExperiments'].componentFn({ name: 'ccr_version', value: true, version: 1, reload: true })
                        });
                    */
                },
                component: this,
            };
        }
        // Set UUID in order to be capable
        // of recognize the client when the lead
        // data is saved/updated
        var availableAffiliateRefs = ['clickref', 'clickRef'];
        var availableLeadsProps = [
            'utm_medium',
            'utm_term',
            'utm_campaign',
            'utm_source',
            'utm_content',
            'http_referrer_url',
            'source'
        ];
        var queryParams$ = this.route.queryParams
            .pipe(filter(function (data) { return !!Object.keys(data).length; }), map(paramsMap));
        var utmData$ = queryParams$.pipe(filter(function (data) {
            var value = Object.keys(data)
                .findIndex(function (key) { return availableLeadsProps.includes(key); });
            return value !== -1;
        }));
        var affiliateRef$ = queryParams$.pipe(map(function (data) {
            var key = Object.keys(data).find(function (item) { return availableAffiliateRefs.includes(item); });
            return data[key] || null;
        }), filter(function (data) { return !!data; })).subscribe(function (data) {
            _this.sessionStorage.store('affiliateRef', data);
        });
        var storedUUID = this.localStorage.retrieve('uuid');
        var getUUID$ = storedUUID ? of(storedUUID) :
            this.appService.getUUID().pipe(tap(function (uuid) { return _this.localStorage.store('uuid', uuid); }));
        getUUID$
            .pipe(take(1), switchMap(function (uuid) {
            return utmData$.pipe(map(function (utmData) { return ({ uuid: uuid, params: utmData }); }));
        }), switchMap(function (_a) {
            var uuid = _a.uuid, params = _a.params;
            console.log('Here is', uuid, params);
            _this.sessionStorage.store('utmData', tslib_1.__assign({}, params, { utm_source: params.utm_source || params.source }));
            return _this.leadService.create({ uuid: uuid, utmData: params });
        })).subscribe(function (data) { return console.log(data); }, function (error) {
            var message = "Error trying to create UUID/Lead.";
            _this.errorService.client.notify(error, {
                beforeSend: function (report) {
                    report.severity = 'warning';
                    report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message: message,
                        error: error,
                    });
                }
            });
        });
        combineLatest([
            this.user$,
            this.route.queryParams.pipe(map(paramsMap), filter(function (queryParams) { return Object.keys(queryParams).length > 0 === _this.location.path().includes('?'); }), map(function (queryParams) { return queryParams.token; }))
        ])
            .pipe(switchMap(function (_a) {
            var user = _a[0], token = _a[1];
            if (token && token.length && _this.authService.token !== token) {
                _this.authService.setJWTToken(token);
                return _this.authService.checkToken(token).pipe(switchMap(function () { return of([user, token]); }), catchError(function (error) {
                    _this.authService.unsetJWTToken();
                    _this.router.navigate(['/login']);
                    return of([user, null]);
                }));
            }
            return of([user, token]);
        }))
            .subscribe(function (_a) {
            var user = _a[0], token = _a[1];
            if (token && token.length && _this.authService.token !== token
                || !user.isAuthenticated && _this.authService.token) {
                _this.store.dispatch(new userActions.LoadAction());
            }
        });
        this.localStorage
            .observe('authToken')
            .subscribe(function (token) {
            if (!token) {
                _this.store.dispatch(new userActions.LogoutCompleteAction());
            }
        });
        this.displayMotherBanner = this.router.url === '/';
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {
                if (window.dataLayer && typeof window.dataLayer.push === 'function') {
                    window.dataLayer.push({
                        event: 'optimize.activate',
                        eventCallback: function (containerId) {
                            var args = [];
                            for (var _i = 1; _i < arguments.length; _i++) {
                                args[_i - 1] = arguments[_i];
                            }
                            console.log('Container ID: ' + containerId, args);
                        }
                    });
                }
            }, 0);
        }
    };
    AppComponent.prototype.onCloseNotificationBar = function () {
        this.store.dispatch(new notificationBarActions.CloseNotificationBarAction());
    };
    // Shows and hides the loading spinner during RouterEvent changes
    AppComponent.prototype.navigationInterceptor = function (event) {
        var _this = this;
        if (event instanceof NavigationStart) {
            if (isPlatformBrowser(this.platformId)) {
                window.scroll(0, 0);
            }
            // this.state = 'inactive';
            this.appService.setAppLoading(true);
        }
        if (event instanceof NavigationEnd) {
            // this.state = 'active';
            // this.appService.setAppLoading(false)
            if (isPlatformBrowser(this.platformId)) {
                this.marketingService.reportPageView().subscribe(function (response) {
                    _this.googleTagManagerService.dataLayerPush({
                        event: 'pageView',
                        eventID: response.eventId,
                    });
                });
                this.user$
                    .pipe(take(1))
                    .subscribe(function (user) {
                    _this.anglerAiService.notifyPageView({
                        customer: user.data
                    })
                        .then()
                        .catch(function (e) {
                        console.error(e);
                    });
                });
            }
            if (isPlatformBrowser(this.platformId) && window.Intercom) {
                window.Intercom('update');
            }
        }
        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError) {
            this.appService.setAppLoading(false);
        }
    };
    AppComponent.prototype.onDeactivate = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }
        // this.showCouponBanner = false;
    };
    return AppComponent;
}());
export { AppComponent };
