import { Pipe, PipeTransform } from '@angular/core';
import { format, parseNumber } from 'libphonenumber-js';

@Pipe({ name: 'formatPhoneNumber' })
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value, extension: boolean = false) {
    const parsePhone = parseNumber(value, 'US');
    return format(parsePhone, 'International');
  }
}
