<section class="container care-cards-container">
  <div class="row">
    <h1 class="section__title care-cards__title text-medium">Succulent Care Cards</h1>
    <div class="lead-text">
      <p>
        Get the names, care instructions, and descriptions of your succulents!
      </p>
      <a href="/assets/docs/care-guide.pdf">
        <b>Download our Care Guide for instructions on unboxing, repotting, and healing broken succulents</b>
      </a>
    </div>
  </div>

  <div class="row">
    <div class="care-cards-list">
      <div *ngFor="let month of careCardsList">
        <div class="month-header">
          <h2 class="month-name">{{ month.name }}</h2>
          <span class="month-header-line"></span>
        </div>
        <div class="care-cards">
          <div class="care-card" *ngFor="let plant of month.plants">
            <div class="plant-image-container">
              <img [src]="plant.image" [alt]="plant.name" class="plant-image">
            </div>
            <div class="care-card-content">
              <h4 class="plant-name">{{ plant.name }}</h4>
              <h5 class="plant-scientistic-name">{{ plant.scientisticName }}</h5>
              <a class="month-donwload" target="_blank" [href]="plant.pdf">Download care card</a>
              <ul class="instruction-list">
                <li class="instruction-item" *ngFor="let instruction of plant.instructions">{{ instruction }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
