// configs
import { app_config } from '@app/app.config';
var ɵ0 = app_config;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
