import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as plansActions from '@app/store/actions/plans.actions';
// services
import * as fromServices from './../../core/services';
import { SessionStorageService } from 'ngx-webstorage';
var PlansEffects = /** @class */ (function () {
    function PlansEffects(plansService, sessionStorage, actions$, store) {
        var _this = this;
        this.plansService = plansService;
        this.sessionStorage = sessionStorage;
        this.actions$ = actions$;
        this.store = store;
        this.load$ = this.actions$
            .pipe(ofType(plansActions.LOAD_PLANS), switchMap(function (data) {
            return _this.plansService.getAvailablePlans()
                .pipe(map(function (plans) {
                _this.store.dispatch(new plansActions.LoadPlansCompleteAction(plans));
            }), catchError(function (reason) {
                _this.store.dispatch(new plansActions.LoadPlansFailAction({ error: reason }));
                return of(null);
            }));
        }));
        this.loadById$ = this.actions$
            .pipe(ofType(plansActions.LOAD_PLAN), switchMap(function (action) {
            return _this.plansService.getPlan(action.payload)
                .pipe(map(function (_a) {
                var plan = _a.plan;
                _this.store.dispatch(new plansActions.LoadPlanCompleteAction(plan));
            }), catchError(function (reason) {
                _this.store.dispatch(new plansActions.LoadPlanFailAction({ error: reason }));
                return of(null);
            }));
        }));
        this.select$ = this.actions$
            .pipe(ofType(plansActions.SELECT_PLAN), map(function (action) { return action.payload; }), tap(function (planId) { return _this.sessionStorage.store('selectedPlanId', planId); }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PlansEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PlansEffects.prototype, "loadById$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], PlansEffects.prototype, "select$", void 0);
    return PlansEffects;
}());
export { PlansEffects };
