import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
import { Meta, Title } from '@angular/platform-browser';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { APP_CONFIG, AppConfig } from '@app/app.config';

// RxJS
import { Observable, timer } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

// services
import * as fromServices from './../../../core/services';
import { LEAD_FORM_TYPE } from '@app/core/services/lead-form-component.service';
import { ErrorService } from '@app/error';

// models
import { Coupon } from '@app/core/models/coupon.model';

// actions
import * as fromPlansActions from './../../../store/actions/plans.actions';
import * as coupons from '@app/store/actions/coupon.actions';

// selectors
import * as fromCoupons from '@app/store/selectors/coupons.selector';
import { isPlatformBrowser } from '@angular/common';
import { MarketingService } from '@app/core/services/marketing.service';
import * as isEmail from 'validator/lib/isEmail';
import {ModalService} from './../../../core/services';
import {GmailModalService} from '@app/core/services/gmail-modal.service';

@Component({
  selector: 'sucstu-pages-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.template.html'
})
export class HomeComponent implements OnInit, AfterViewInit {
  isBrowser: boolean = isPlatformBrowser(this.platformId);

  sliderConf: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    pagination: true,
    autoplay: false
  };

  @ViewChild('newsLetterEmail') newsLetterEmail: ElementRef;
  @ViewChild(SwiperComponent) swiperSlider?: SwiperComponent;

  coupon$: Observable<Coupon>;
  coupon: Coupon;

  constructor(
    private router: Router,
    private store: Store<any>,
    private metaService: Meta,
    private titleService: Title,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private leadService: fromServices.LeadsService,
    private plansService: fromServices.PlansService,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private leadFormService: fromServices.LeadFormService,
    private notificationBarService: fromServices.NotificationBarService,
    private readonly marketingService: MarketingService,
    private readonly gmailModalService: GmailModalService,
  ) {
  }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: 'Simple signup. Stunning succulents. We ship two organically-grown succulents from our SoCal nursery straight to your door. $5 off your first month!'
    });

    this.titleService.setTitle('Succulent Studios | Monthly Succulent Subscription Box');

    this.coupon$ = this.store.pipe(select(fromCoupons.getCouponData));

    this.coupon$
      .pipe(filter(coupon => !!coupon))
      .pipe(take(1))
      .subscribe((coupon) => {
        this.coupon = coupon;
      });
  }

  ngAfterViewInit() {
    timer(0).pipe(
      switchMap(() => this.route.queryParams),
      switchMap(queryParams =>
        queryParams.lf === 'win-it-wednesday'
          ? this.leadFormService.canShowLeadFormWithoutLS$
          : this.leadFormService.canShowLeadForm$
      ),
      filter(Boolean),
      switchMap(() => this.route.queryParams),
      map(queryParams => {
        console.log({queryParams})
        if (queryParams.lf === 'free-shipping') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.FREE_SHIPPING),
            coupon: 'SHIPPINGSUCCS',
          };
        } else if (queryParams.lf === 'win-it-wednesday') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.WIN_IT_WEDNESDAY),
            coupon: '',
          };
        } else if (queryParams.lf === 'six-off') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.SIX_OFF),
            coupon: 'SIXDOLLARS',
          };
        } else if (queryParams.lf === 'fifty-off') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.FIFTY_OFF),
            coupon: 'NEWSSUCCS50',
          };
        } else if (queryParams.lf === 'only-shipping') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.ONLY_SHIPPING),
            coupon: 'ONLYSHIPPING',
          };
        } else if (queryParams.lf === 'rare-collective') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.RARE_COLLECTIVE),
            coupon: 'FIVEDOLLARS',
          };
        } else if (queryParams.lf === 'colorful-classic') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.COLORFUL_CLASSIC),
            coupon: 'FIVEDOLLARS',
          };
        } else if (queryParams.lf === 'cactus-gwp') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.FREE_SHIPPING),
            coupon: 'CACTUSGIFT',
          };
        } else if (queryParams.lf === 'race-fans') {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.RACE_FANS),
            coupon: 'RACEFANS',
          };
        } else {
          return {
            leadFormInstance: this.leadFormService.open(LEAD_FORM_TYPE.FIVE_OFF),
            coupon: 'FIVEDOLLARS',
          };
        }
      }),
      filter(data => !!data.leadFormInstance),
      take(1),
    )
      .subscribe(({leadFormInstance, coupon}) => {
        leadFormInstance.submit.subscribe(() => {
          this.store.dispatch(new coupons.VerifyCouponAction(coupon));
        });

        leadFormInstance.close.subscribe(() => {
          this.localStorage.store('isBannerActive', false);
        });
      });
  }

  moveSliderToLeft() {
    this.swiperSlider.directiveRef.prevSlide();
  }

  moveSliderToRight() {
    this.swiperSlider.directiveRef.nextSlide();
  }

  onCreateLead() {
    const emailInput = this.newsLetterEmail.nativeElement as HTMLInputElement;
    const email = emailInput.value;
    if (!email.length || !isEmail(email)) {

      this.notificationBarService.open({
        mode: 'error',
        type: 'coupon',
        title: 'Validation',
        message: `Please use a valid email`,
      });
      return;
    }
    this.createLead(email)
      .pipe(take(1))
      .subscribe(
        () => {
        },
        error => {
          const message = `Error trying to create/update Lead.`;
          this.errorService.client.notify(error, {
            beforeSend: report => {
              report.severity = 'warning';
              report.updateMetaData('extras', {
                http: true,
                client: true,
                message,
                error,
              });
            }
          });
        }
      );
  }

  createLead(email: string = ''): Observable<any> {
    const uuid = this.localStorage.retrieve('uuid');
    const utmData = this.route.snapshot.queryParams;
    const input = this.newsLetterEmail.nativeElement as HTMLInputElement;
    email = email.length > 0 ? email : input.value;
    this.localStorage.store('customerEmail', email);
    return this.leadService
      .create({uuid, email, utmData, leadForm: 'five-off'})
      .pipe(
        tap((data: any) => {
          if (isPlatformBrowser(this.platformId) && this.app_config.production && this.app_config.name === 'production') {
            this.marketingService
              .reportLead(email)
              .subscribe();

            if (email && email.includes('@gmail.co')) {
              this.gmailModalService.showGmailModal$.next(true);
            }
          }

          if (!this.coupon) {
            this.store.dispatch(new coupons.VerifyCouponAction('fivedollars'));
          }
        })
      );
  }

  selectPlan() {
    const planId = this.plansService.REGULAR_PLAN;
    this.store.dispatch(new fromPlansActions.SelectPlanAction(planId));
    this.router.navigate(['/checkout'], {queryParamsHandling: 'preserve'});
  }

}
