import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ShipmentTrackingService = /** @class */ (function () {
    function ShipmentTrackingService(http) {
        this.http = http;
    }
    ShipmentTrackingService.prototype.getShipmentTrackingEvents = function (trackingCode) {
        return this.http.get(environment.api_uri_v2 + "/shipments/tracking-events?trackingCode=" + trackingCode);
    };
    ShipmentTrackingService.ngInjectableDef = i0.defineInjectable({ factory: function ShipmentTrackingService_Factory() { return new ShipmentTrackingService(i0.inject(i1.HttpClient)); }, token: ShipmentTrackingService, providedIn: "root" });
    return ShipmentTrackingService;
}());
export { ShipmentTrackingService };
