import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {LeadFormBaseComponent} from '@app/shared/components/lead-forms/lead-form-base/lead-form-base.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { LeadsService } from '@app/core/services/leads.service';
import {ErrorService} from '@app/error';
import {LocalStorageService} from 'ngx-webstorage';
import {APP_CONFIG, AppConfig} from '@app/app.config';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {MarketingService} from '@app/core/services/marketing.service';
import {ModalService} from '@app/core/services';
import {GmailModalService} from '@app/core/services/gmail-modal.service';

@Component({
  selector: 'app-leadform-colorful-classic',
  templateUrl: './leadform-colorful-classic.component.html',
  styleUrls: ['./leadform-colorful-classic.component.scss']
})
export class LeadformColorfulClassicComponent extends LeadFormBaseComponent implements OnInit {
  savingLead = false;
  form = new FormGroup({
    email: new FormControl('', Validators.email),
    phone: new FormControl('', [Validators.minLength(10)]),
  });

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private leadService: LeadsService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private readonly marketingService: MarketingService,
    private readonly gmailModalService: GmailModalService,
  ) {
    super();
  }

  ngOnInit() {
    this.emailFormField = 'email';
    this.form.get('email').valueChanges
      .pipe(
        filter(data => typeof data === 'string' && data.indexOf('@') !== -1),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .pipe(takeUntil(this.destroyComponent$))
      .subscribe(email => {
        this.testSuggestedEmails(email);
      });
  }

  submitLeadForm() {
    const { email, phone } = this.form.value;
    if (this.form.invalid || email === '' && phone === '') {
      return;
    }

    // This is a quick fix to avoid people clicking multiple times
    this.savingLead = true;

    if (email && email.includes('@gmail.co')) {
      this.gmailModalService.showGmailModal$.next(true);
    }

    this.createLead(email, phone)
      .then(() => {
        this.submit.emit({ email: email });
        this.closeLeadForm();
      })
      .catch(error => {
        this.closeLeadForm();
        const message = `Error trying to create/update Lead.`;
        this.errorService.client.notify(error, {
          beforeSend: report => {
            report.severity = 'warning';
            report.updateMetaData('extras', {
              http: true,
              client: true,
              message,
              error,
            });
          }
        });
      });
  }

  createLead(email: string = '', phone: string = ''): Promise<any> {
    const uuid = this.localStorage.retrieve('uuid');
    const utmData = this.route.snapshot.queryParams;
    this.localStorage.store('customerEmail', email);

    return this.leadService
      .create({ uuid, email, phone, utmData, leadForm: 'six-off' })
      .toPromise()
      .then(data => {
        if (isPlatformBrowser(this.platformId) && this.app_config.production && this.app_config.name === 'production') {
          this.marketingService
            .reportLead(email)
            .subscribe();
        }
        return data;
      });
  }

}
