import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from '@app/auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authService = this.injector.get(AuthService);

    if (req.url.includes('wordpress')) {
      return next.handle(req.clone());
    }

    // Get the auth header from the service.
    if (authService.token) {
      const authHeader = `Bearer ${authService.token}`;
      // Clone the request to add the new header.
      const authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
      // Pass on the cloned request instead of the original request.
      return next.handle(authReq);
    }

    return next.handle(req.clone());
  }
}
