import { Location, isPlatformBrowser } from '@angular/common';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(locationService, platformId) {
        this.locationService = locationService;
        this.platformId = platformId;
    }
    FooterComponent.prototype.moveToTop = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }
    };
    Object.defineProperty(FooterComponent.prototype, "isSales", {
        get: function () {
            return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
        },
        enumerable: true,
        configurable: true
    });
    return FooterComponent;
}());
export { FooterComponent };
