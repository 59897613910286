import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { isPlatformBrowser } from '@angular/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MarketingService = /** @class */ (function () {
    function MarketingService(platformId, http) {
        this.platformId = platformId;
        this.http = http;
    }
    MarketingService.prototype.reportPageView = function () {
        if (!isPlatformBrowser(this.platformId)) {
            return of();
        }
        return this.http.post(environment.api_uri_v2 + "/marketing/tracking/event/PageView", {
            userAgent: navigator.userAgent,
            sourceUrl: window.location.href,
            eventTime: new Date().getTime()
        });
    };
    MarketingService.prototype.reportLead = function (userEmail) {
        if (!isPlatformBrowser(this.platformId)) {
            return of();
        }
        return this.http.post(environment.api_uri_v2 + "/marketing/tracking/event/Lead", {
            userAgent: navigator.userAgent,
            sourceUrl: window.location.href,
            eventTime: new Date().getTime(),
            userEmail: userEmail,
        })
            .pipe(tap(function (response) {
            window['dataLayer'].push({
                'event': 'leadFormSubmited',
                'lead': {
                    'email': userEmail,
                },
                eventID: response.eventId,
            });
        }));
    };
    MarketingService.prototype.reportPurchase = function (data) {
        if (!isPlatformBrowser(this.platformId)) {
            return of();
        }
        return this.http.post(environment.api_uri_v2 + "/marketing/tracking/event/Purchase", {
            userAgent: navigator.userAgent,
            sourceUrl: window.location.href,
            eventTime: new Date().getTime(),
            cart: {
                value: data.value,
                items: [{
                        id: data.id,
                        quantity: 1,
                        title: data.planName,
                        price: data.value,
                    }]
            }
        });
    };
    MarketingService.prototype.reportOTBPurchase = function (data) {
        if (!isPlatformBrowser(this.platformId)) {
            return of();
        }
        return this.http.post(environment.api_uri_v2 + "/marketing/tracking/event/OtbPurchase", {
            userAgent: navigator.userAgent,
            sourceUrl: window.location.href,
            eventTime: new Date().getTime(),
            shipmentId: data.shipmentsId,
        });
    };
    MarketingService.prototype.reportPaymentInfoAdded = function () {
        if (!isPlatformBrowser(this.platformId)) {
            return of();
        }
        return this.http.post(environment.api_uri_v2 + "/marketing/tracking/event/AddPaymentInfo", {
            userAgent: navigator.userAgent,
            sourceUrl: window.location.href,
            eventTime: new Date().getTime(),
        });
    };
    MarketingService.prototype.reportInitialCheckout = function () {
        if (!isPlatformBrowser(this.platformId)) {
            return of();
        }
        return this.http.post(environment.api_uri_v2 + "/marketing/tracking/event/InitiateCheckout", {
            userAgent: navigator.userAgent,
            sourceUrl: window.location.href,
            eventTime: new Date().getTime(),
        });
    };
    MarketingService.prototype.reportTurnOff = function (data) {
        if (!isPlatformBrowser(this.platformId)) {
            return of();
        }
        return this.http.post(environment.api_uri_v2 + "/marketing/tracking/event/TurnOFF", tslib_1.__assign({ userAgent: navigator.userAgent, sourceUrl: window.location.href, eventTime: new Date().getTime() }, data));
    };
    MarketingService.ngInjectableDef = i0.defineInjectable({ factory: function MarketingService_Factory() { return new MarketingService(i0.inject(i0.PLATFORM_ID), i0.inject(i1.HttpClient)); }, token: MarketingService, providedIn: "root" });
    return MarketingService;
}());
export { MarketingService };
