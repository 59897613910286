<div class="page-container">
  <section class="privacy-container container">
    <h1 class="section__title privacy__title center-text">Succulent Studios, INC. Terms and Conditions</h1>
    <div class="privacy__content">
      <div class="privacy__section">
        <p class="privacy__section__text">These terms of service (“agreement”) are a legal agreement between you (“you”, “your”, or “customer”) and
          Succulent Studios, INC. (“we”, “Succulent Studios” or “SUCSTU”), the owner and operator of the succulent.studio website
          (the “site”). This agreement states the terms and conditions that govern your use of the site and the products
          sold on it and memberships sold to it. By accessing and using the site, you are indicating that you accept, and
          agree to comply with, this agreement. If you do not accept this agreement, you are not permitted to, and you must
          not, access or use the site or purchase products from, or memberships to, the site. By signing up for a Membership
          (as defined below), you represent, acknowledge and agree that you are at least the age of majority in your jurisdiction
          of residence, or if you are under the age of majority in your jurisdiction of residence, that you are at least
          13 years old, and that you are using the Site with the consent of your parent or legal guardian and that you have
          received your parent’s or legal guardian’s permission to enter into this Agreement. If you are a parent or legal
          guardian who is registering on behalf of a minor, you hereby agree to bind said minor to this Agreement and to
          fully indemnify and hold harmless Succulent Studios if said minor breaches or disaffirms any term or condition
          of this Agreement.</p>

      </div>
      <div class="privacy__section">
        <h4 class="privacy__section__title">1 – Changes to Terms, Personal Information/Privacy</h4>
        <p class="privacy__section__text">1.1 – Changes to the Site Succulent Studios may add to, change or remove any part of the Site, including, without
          limitation, any Content (as defined below) therein, at any time without prior notice to you.</p>
        <p class="privacy__section__text">1.2 – Personal Information/Privacy Customers agree to provide accurate, current, and complete information as required
          for the purchase of the Products and Memberships (both as defined below in Section 3.1) and to update that information
          as soon as possible after any information provided changes. Succulent Studios reserves the right to block further
          sales to Customers who provide false, inaccurate or incomplete data. Customer acknowledges that Succulent Studios
          uses a third party payment processing service to processing orders and bill fees to your credit card. Succulent
          Studios’ Privacy Policy, located at the URL:
          <a routerLink="/privacy-policy">http://www.succulent.studio/privacy-policy</a> (the “Privacy Policy”), explains how Customers’ personally identifiable
          information is collected, used and disclosed. By signing up for Membership you hereby agree that we may use your
          personal information in accordance with the terms of the Privacy Policy.
        </p>
      </div>
      <div class="privacy__section">
        <h4 class="privacy__section__title">2 – Passwords; Use of Site</h4>
        <p class="privacy__section__text">2.1 – Passwords You will be required to register and create an account by providing your email address and a password.
          You are responsible for maintaining the confidentiality of your password, and you are responsible for all activities
          that occur using your email and password. You agree not to share your password, let anyone else access your account
          or do anything else that might jeopardize the security of your passwords or account. You agree to notify Succulent
          Studios if there is any unauthorized use of your password or account on this Site or if you know of any other breach
          of security in relation to this Site.</p>
        <p class="privacy__section__text">2.2 – Use of Site; Prohibitions Subject to your compliance with this Agreement, Succulent Studios hereby grants you
          a limited, personal, revocable, non-transferable, non-sublicensable, and non-exclusive license to access the Site
          and use the content, information, text, images, graphics, interfaces, audio and video clips and any other materials
          displayed on the Site (collectively, the “Content”), solely for your personal, non-commercial use. You may not
          copy, modify, reproduce, publicly display or perform, distribute, create derivate works from or otherwise use the
          Content except as expressly set forth in this Agreement. You may not remove any copyright or other proprietary
          notices from any Content. If you breach any term of this Agreement, your authorization to use the Site and Content
          automatically terminates without notice to you. You hereby represent and warrant that you will not, and will not
          induce any third party to: (a) attempt to disable or circumvent any security mechanisms used by the Site or Content
          or otherwise attempt to gain unauthorized access to any portion of the Site or Content or any other systems or
          networks connected to the Site, or to any server of Succulent Studios or its third party service providers, by
          hacking, password “mining”, or any other illegal means; (b) use any “deep-link”, “page-scrape”, “robot”, “spider”,
          or other automatic device, program, algorithm or methodology, or any comparable manual process, to access, acquire,
          copy, or monitor any portion of the Site or Content; (c) use any device, software or routine to interrupt or interfere
          with, or attempt to interrupt or interfere with, the proper operation and working of the Site or with any other
          person’s use of the Site; (d) track or seek to trace, harvest or compile any information on any other person who
          visits the Site; (e) use the Site or Content for, or in connection with, any illegal purpose, to solicit, facilitate,
          encourage, condone, or induce any illegal activity, or as otherwise prohibited by this Agreement or applicable
          laws, rules or regulations; or (f) copy, modify, create a derivative work of, reverse engineer, decompile, or otherwise
          attempt to extract the source code of any proprietary software used to provide, maintain, or otherwise applicable
          to the Site or Content.
        </p>
      </div>
      <div class="privacy__section">
        <h4 class="privacy__section__title">3 – Terms of Sale</h4>
        <p class="privacy__section__text">3.1 – Sales of Products and Memberships to End Users Only If you are interested in purchasing wholesale quantities
          or in the private branding of our Products, please email us directly at hello@succulent.studio for more
          information.
          We reserve the right to limit the quantity of memberships purchased per person, per household or per order. The
          Membership and all rights and privileges conferred are personal and non-transferable.</p>
        <p class="privacy__section__text">3.2 - Pricing Memberships are offered by Succulent Studios through the Website are only an offer to conclude a sales
          contract. The sales contract is only concluded upon the confirmation of the order by e-mail by Succulent Studios
          to You. The price of a product as shown on the Website does not include any applicable taxes or shipping. The total
          price of your order will be reflected on our final checkout page. An order receipt shall be sent to the email address
          you provided during sign up showing the final total price as well. The net price for Products and Memberships can
          be found on Succulent Studios then-current pricing page located on the Site. Succulent Studios reserves the right
          to change the prices and fees for Products and Memberships at any time, and does not provide price protection or
          refunds in the event of promotions or price decreases.
        </p>
        <p class="privacy__section__text">3.3 – Refund Policy If you are dissatisfied with the Product for any reason, Succulent Studios will refund the amount
          paid for your most recent month of service. Refund requests must be made directly to Succulent Studios at
          hello@succulent.studio.
          All refund requests must be made within thirty (30) days of the date of delivery by Succulent Studios. In response
          to your refund request, Succulent Studios will credit the amount paid for the returned Product (less any shipping
          and handling costs and fees related to the original purchase, which are nonrefundable) to the credit card you used
          to make the original purchase. Notwithstanding the foregoing, Succulent Studios does not control when a specific
          credit card company processes a chargeback transaction. You are responsible for contacting your credit card company
          if you have questions about the status of the chargeback. Succulent Studios shall in its sole discretion provide
          a refund for a request that is received by Succulent Studios more than thirty (30) days after the date of original
          delivery. Succulent Studios also does not provide a refund for returned Products that are damaged due to misuse,
          lack of care, mishandling, accident, abuse or other abnormal use. If Products are damaged as a result of shipping
          complications or you believe there was a mistake in fulfillment. Please contact us at hello@succulent.studio
          immediately
          and we will work to resolve the issue. In the case of damage, please provide pictures if possible for our records.
          Apparel Refund Policy Succulent Studios does not accept Returns or Refunds on our Apparel line which includes the
          following products: T-shirts, Hat’s. If you receive what you think to believe is a defective product, please contact.</p>
        <p class="privacy__section__text">
          3.4 – Payment Methods: Membership Terms and Membership Cancellation Policy Succulent Studios accepts the following forms
          of payment: (i) Credit Card (Visa, MasterCard, and American Express) and (ii) debit card.. You agree to pay all
          fees charged to your account based on Succulent Studios' fees, charges, and billing terms in effect as shown on
          the payment page when you first sign-up for a Membership to the Site. As stated previously and for purposes of
          clarification, the price of a product as shown on the Website is the NET PRICE of the product not including any
          applicable taxes. The total price of your order will be reflected on Our final checkout page. An order receipt
          shall be sent to the email address you provided during sign-up showing the final total price of the Membership
          as well. You are responsible for paying the amount reflected on Our final checkout page. If you do not wish to
          pay the final amount reflected on Our final checkout page you are welcome to not sign up for Membership. Any applicable
          taxes shall be based on the address that you provide as the shipping address when you register for a Membership.
          You authorize Succulent Studios or the third party payment processing service provider that we engage to charge
          your credit card for any such taxes and fees. All payments shall be made by credit card in advance prior to shipping
          the Products. You represent and warrant that you are the cardholder of the credit card used. If your credit card
          is rejected by the card issuer, if you do not pay on time or if your credit card cannot be charged for any reason,
          Succulent Studios reserves the right to either suspend or terminate your account and Membership. All sales and
          payments will be in United States Dollars. IMPORTANT NOTICE TO CONSUMER: Succulent Studios Membership is a month-to-month,
          cancel-at-any-time, biennial membership. At least thirty (30) days prior to your biennial anniversary date Succulent
          Studios will send You an email requesting whether You would like to we renew your Membership. In the event We do
          not get a response within thirty (30) days We will automatically renew your biennial membership. Your Membership
          is billed month-to-month and may be cancelled at any time by logging on to your account within succulent.studio. To cancel
          a Membership, please login to your account on the Site and select the “Membership Options” link at the bottom of
          the page, then follow instructions towards cancellation. All cancellation requests must be received 3 (three) days
          prior to your monthly account renewal. If you have any problems, please email hello@succulent.studio.
          Succulent Studios
          requires
        </p>
        <p class="privacy__section__text">3.5 – Shipping and Product Acceptance The shipment date will be determined according to whichever date is closer
          to the date of the Customer’s first purchase date. Shipping dates are estimates only. The risk of loss and title
          for all Products purchased via the Site pass to the Customer upon delivery of the item to the carrier.
        </p>
        <p class="privacy__section__text">3.6 – Correspondence With Members Any and all correspondences sent, issued, expressed, or transmitted by Succulent
          Studios or any of its affiliates to You shall strictly be in the English language. Correspondences shall include
          but not be limited to Succulent Studios mailing envelopes and/or boxes, postcards, business cards, and any and
          all other mailings including but not limited to electronic mail.</p>
      </div>
      <div class="privacy__section">
        <h4 class="privacy__section__title">4 – Proprietary Rights</h4>
        <p class="privacy__section__text">You hereby acknowledge and agree that Succulent Studios or its licensors own all legal right, title and interest
          in and to the Site and Content, including, without limitation, any and all intellectual property and other proprietary
          rights which subsist in the Site and Content, whether such rights are registered or unregistered, and wherever
          in the world those rights may exist. Succulent Studios is a pending trademark of Succulent Studios, Inc. Other
          trademarks, names and logos on this Site are the property of their respective owners. Unless otherwise specified
          in this Agreement, all information and screens appearing on this Site, including Content, site design, text, graphics,
          logos, images and icons, as well as the arrangement thereof, are the sole property of Succulent Studios.. All rights
          not expressly granted herein are reserved. Except as otherwise required or limited by applicable law, any reproduction,
          distribution, modification, retransmission, or publication of any copyrighted material is strictly prohibited without
          the express written consent of the copyright owner or license.
        </p>
      </div>

      <div class="privacy__section">
        <h4 class="privacy__section__title">5 – Third Party Sites; Indemnification</h4>
        <p class="privacy__section__text">5.1 – Third Party Websites The Site may provide links to third party Sites that are not owned or controlled by Succulent
          Studios, including, without limitation, Facebook, Twitter and LinkedIn (“Third Party Sites”). We provide such links
          solely as a convenience to you. Succulent Studios does not review, approve, endorse, or make any representations
          about such Third Party Sites, the companies or persons who own and/or operate them, or any information, software
          or other products and services made available through such Third Party Sites, or any results that may be obtained
          from using them. You should exercise common sense and your own judgment, and if you decide to access any Third
          Party Sites linked to the Site, you do so entirely at your own risk, and you are solely responsible for your activities
          conducted in connection with such Third Party Sites. Your use of Third Party Sites is subject to the terms of use
          and privacy policies located on the linked to Third Party Sites which may be different from this Agreement or our
          Privacy Policy, including, without limitation, such Third Party Sites’ password and account security policies and
          user-generated content posting and acceptable use policies.
        </p>
        <p class="privacy__section__text">5.2 – Indemnification You hereby agree to defend, indemnify and hold each of the Succulent Studios Parties harmless
          from and against, any and all losses, liabilities, damages, and/or claims (including, without limitation, attorneys’
          fees and costs) arising from your breach of this Agreement, or otherwise arising from your (or anyone acting under
          your password) use or misuse of the Site, Content or Products. The laws of certain jurisdictions do not allow the
          exclusion or limitation of legal warranties and the exclusion or limitation of representations made concerning
          goods or services. If these laws apply to you, some or all of the below exclusions or limitations may not apply
          to you. The site, content, products and memberships are furnished to you without warranties, representations or
          conditions, statutory or otherwise, of any kind. Succulent Studios, on behalf of itself and its affiliates, licensors,
          suppliers and third party service providers, and each of their respective directors, officers, employees, contractors,
          agents, successors, and assigns (collectively, the “Succulent Studios parties”): (a) expressly disclaims all representations,
          warranties and conditions, whether express, implied or statutory, including, without limitation, the implied warranties
          of non-infringement, title, merchantability, and fitness for a particular purpose; (b) does not represent or warrant
          that the site, content, products or memberships will meet your requirements, or that the operation of the site
          or content will be timely, uninterrupted, stable, or secure; (c) does not represent or warrant that the site, content,
          products or memberships will be error-free or that any defects will be corrected; (d) does not represent and warrant
          that the site will be free of viruses or other harmful components; and (e) does not make any representations, warranties,
          or conditions regarding the use of the use of the site, content or products in terms of their accuracy, reliability,
          timeliness, completeness, or otherwise. Your use of the site, content, products or memberships is entirely at your
          own discretion and risk and you assume total responsibility for your use of the site, content, products and memberships.
          This limitation of remedies is a part of the bargain between you and Succulent Studios. No oral or written information
          or advice given by Succulent Studios or any person on behalf of Succulent Studios shall create a warranty or condition,
          or in any way change this exclusion of warranty.
        </p>
      </div>

      <div class="privacy__section">
        <h4 class="privacy__section__title">6 – Limitation of Liability</h4>
        <p class="privacy__section__text">The laws of certain jurisdictions do not allow the exclusion or limitation of liability or of certain damages. If
          these laws apply to you, some or all of the below exclusions or limitations may not apply to you, and you might
          have additional rights.
        </p>
        <p class="privacy__section__text">6.1 – in no event shall any Succulent Studios party be liable to you, or any third party, for any special, indirect,
          incidental, punitive, exemplary, reliance, or consequential damages of any kind, including, but not limited to,
          property damage, loss of value of the products or loss of use of the products, whether based on breach of any express
          or implied warranty or condition, breach of contract or tort (including, without limitation, negligence or strict
          liability), even if a Succulent Studios party has been advised of the possibility of such damages. 7.2 – if, notwithstanding
          the foregoing, a Succulent Studios party is found to be liable to you or any third party for any damage or loss
          which arises under or in connection with your use of the site, content, products or memberships, the relevant Succulent
          Studios party’s total cumulative liability shall in no event exceed the greater of: (a) the amount you paid Succulent
          Studios for the products or membership, as applicable; and (b) the sum of one hundred us dollars (us$110).</p>
      </div>

      <div class="privacy__section">
        <h4 class="privacy__section__title">7 – Dispute and Arbitration; Class Action Waiver; Choice of Law 7.1 - Dispute and Arbitration; Class Action Waiver</h4>
        <p class="privacy__section__text">7.1(a) – United States United States Please read this carefully. It affects your rights. Summary: Most customer concerns
          can be resolved quickly and to a customer’s satisfaction by writing to our customer service department at
          hello@succulent.studio
          or Succulent Studios, Attn: Succulent Studios 120 Newport Center, Drive. Newport Beach, CA. In the unlikely event
          that our customer service department is unable to resolve a complaint you may have to your satisfaction (or if
          we have not been able to resolve a dispute we have with you after attempting to do so informally), we each agree
          to resolve those disputes arising out of this Agreement (other than those related to Succulent Studios' enforcement
          and protection of its name and intellectual property rights) through binding arbitration or small claims court
          instead of in courts of general jurisdiction. This includes any claims against other parties relating to services
          or products provided or billed to you (such as our licensors, suppliers, dealers or third party vendors) whenever
          you also assert claims against us in the same proceeding. Arbitration is more informal than a lawsuit in court.
          Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court,
          and is subject to very limited review by courts. The arbitrator must follow this Agreement and can award the same
          damages and relief that a court can award. We each also agree that this Agreement affects interstate commerce so
          that the Federal Arbitration Act and federal arbitration law apply (despite the choice of law provision in Section
        </p>
        <p class="privacy__section__text">7.2 below). Any arbitration under this agreement will take place on an individual basis; class arbitrations and class
          actions are not permitted. Neither you nor Succulent Studios has the right to act as a class representative or
          class representative or participate as a member of a class of claimants with respect to any claim. All disputes
          relating in any way, directly or indirectly, to Succulent Studios for breach of contract, breach of fiduciary duty,
          negligence, personal injury, intentional torts or other tort will be arbitrated according to the rules of the American
          Arbitration Association (AAA) in Los Angeles, California, including any dispute about the scope of this arbitration
          agreement, and including all questions about the types of disputes that are subject to this arbitration agreement,
          all of which you agree will be decided by the arbitrators, whose decision will be final and binding on you. Any
          issue concerning the extent to which a dispute is subject to arbitration, or concerning the applicability, interpretation
          or enforceability of this Agreement, including any contention that all or part of this agreement is invalid or
          unenforceable, shall be governed by the Federal Arbitration Act and resolved by the arbitrators. You acknowledge
          and agree that, in any arbitration proceeding, no depositions will be taken, and all other forms of discovery of
          facts will be limited to those things that the arbitrators determine, in their sole discretion, to be necessary.
          Further, in any arbitration proceeding, (i) there shall be no award of punitive, exemplary, incidental or consequential
          or other special damages, (ii) all damages claims and awards will be governed by the provisions of the California
          Civil Code, and (iii) the parties will conduct the arbitration confidentially and expeditiously and will pay their
          own costs and expenses of arbitration, including their own attorneys’ fees. If you are unable to afford the AAA
          fee, you agree to notify all persons against whom you have an arbitrable claim and give such persons the opportunity
          individually and as a group to pay such fee. The proceeding and the decision shall be kept confidential by the
          parties.
        </p>
        <p class="privacy__section__text">7.1B – Canada After any dispute regarding the Program arises, the parties involved in the dispute may agree to resolve
          the dispute using arbitration. If the parties elect to use arbitration, claims shall be referred to the ADR Institute
          of Canada. For a copy of the procedure to file a claim, or for other information about this organization, contact
          them as follows: www.adrcanada.ca.</p>
        <p class="privacy__section__text">7.2 – Choice of Law Unless the applicable laws of your, require that the laws of that jurisdiction govern, in which
          case, the laws of such jurisdiction are to govern, this Agreement is governed by the Federal Arbitration Act, applicable
          federal law, and the laws of the state of California, without regard to its conflicts of laws rules. Arbitration
          or court proceedings must be in Los Angeles County, California. If any provision of the Agreement is invalid under
          the law of a particular jurisdiction, that provision will not apply in that jurisdiction. The United Nations Convention
          on Contracts for the International Sale of Goods is expressly excluded from this Agreement.</p>
      </div>

      <div class="privacy__section">
        <h4 class="privacy__section__title">8 – Electronic Communications</h4>
        <p class="privacy__section__text">8.1 – Electronic Communications Whenever you visit our Site or send emails to us, you are communicating with us electronically.
          For that reason, you also consent to receive communications from us electronically regarding administrative or
          account information. Since these administrative or account electronic communications are essential to the successful
          and effective functioning of your Membership the sole remedy to opt-out is to cancel your account. You may cancel
          your account either by logging into your account or by emailing a request to hello@succulent.studio. We will
          communicate
          with you by email (if you have provided your email address to us), by posting notices on our Site or by such other
          means as we may determine from time-to-time. You agree that all agreements, notices, disclosures, and other communications
          that we provide to you electronically satisfy any legal requirement that such communications be in writing, to
          the extent permitted by applicable law. General Terms
        </p>
      </div>

      <div class="privacy__section">
        <h4 class="privacy__section__title">General Terms</h4>
        <p class="privacy__section__text">
          Succulent Studios may issue a warning, temporarily suspend, indefinitely suspend or terminate any Customer’s right to use
          or access all or any part of the Site including any account thereon or Membership thereto, without notice, for
          any reason in Succulent Studios' sole discretion, including without limitation breach of this agreement and/or
          violation of the Terms of Service, Succulent Studios' belief that such access would violate any applicable law,
          rule or regulation or would be harmful to the interests of, or potentially cause financial loss or legal liability
          to Succulent Studios or another user of the Site. This Agreement and the Privacy Policy constitute the entire agreement
          between you and Succulent Studios regarding its subject matter. Succulent Studios will not be responsible for failures
          to fulfill any of its obligations due to causes beyond its control. The failure of Succulent Studios to exercise
          or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If
          any part of this Agreement is held invalid, illegal or unenforceable, that provision shall be enforced to the maximum
          extent permissible so as to maintain the intent of this Agreement, and the other parts will remain in full force
          and effect. The parties shall at all times and for all purposes be deemed to be independent contractors and neither
          party nor its agents may bind the other party. The parties acknowledge and agree that this Agreement and all related
          documents shall be drawn up in the English language. If you require this Agreement and all related documents to
          be translated to French please email hello@succulent.studio. Any translation of this Agreement and all related
          documents
          shall be for reference only and the English version of this Agreement controls. This Agreement shall not be interpreted
          or construed to confer any rights or remedies on any third parties, except that each indemnified Succulent Studios
          Party shall be a third party beneficiary hereunder. Succulent Studios may assign or transfer its rights, or delegate
          any performance, under this Agreement to a third party in its sole discretion. Customer may not assign or otherwise
          transfer its rights, or delegate its performance, under this Agreement to any third party without in each and every
          case, Succulent Studios' express prior written consent. All terms which by their nature are intended to survive
          any termination of this Agreement, or any termination of your use of the Site, Content, Products or Memberships
          shall survive such termination.
        </p>
      </div>
    </div>
  </section>
</div>
