import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sucstu-attributes-bar',
  templateUrl: './attributes-bar.component.html',
  styleUrls: ['./attributes-bar.component.scss'],
})
export class AttributesBarComponent implements OnInit {
  constructor() { }
  ngOnInit() { }
}
