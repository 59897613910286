
    <div class="login-container container">

      <h2 class="login-container__title">Password Recovery</h2>

      <form class="login-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <div class="form-group form-group__flex-start">
          <input
            [ngClass]="{ 'input-error': isFieldValid('password', 'required') }" class="form-group__input" type="password"
            placeholder="New Password" formControlName="password" name="password" autocomplete="off">
          <span class="error-text" *ngIf="isFieldValid('password', 'required')">Please write your new password</span>
        </div>

        <div class="form-group form-group__flex-start">
          <input
            [ngClass]="{ 'input-error': isFieldValid('confirmPassword', 'required') }" class="form-group__input" type="password"
            placeholder="Confirm New Password" formControlName="confirmPassword" name="confirm-password" autocomplete="off">
          <span class="error-text" *ngIf="isFieldValid('confirmPassword', 'required')">Please confirm your new password</span>
          <span class="error-text error-text--center" *ngIf="!arePasswordsEquals && displayErrors">
          Passwords don't match
          </span>
          <span class="error-text" *ngIf="error">{{ error }}</span>
        </div>


        <div class="form-button-container">
          <button
            class="btn btn-primary btn-primary--with-hover"
            type="submit">
            Save
          </button>
        </div>

      </form>

    </div>
  