import {Injectable, PLATFORM_ID, Inject, ComponentRef, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LocalStorageService } from 'ngx-webstorage';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import {
  FiveOffLeadFormComponent,
  FreeShippingLeadFormComponent, LeadformColorfulClassicComponent, RaceFansLeadFormComponent, SixOffLeadFormComponent,
  WinItWednesdayLeadFormComponent,
} from '@app/shared/components/lead-forms';

import { paramsMap } from '@app/core/mappers/params-map.mapper';
import * as fromRoot from '@app/store/reducers';
import * as fromStore from '@app/blog/store';
import {FiftyOffLeadFormComponent} from '@app/shared/components/lead-forms/fifty-off-lead-form/fifty-off-lead-form.component';
import {OnlyShippingComponent} from '@app/shared/components/lead-forms/only-shipping/only-shipping.component';
import {LeadformRareCollectibleComponent} from '@app/shared/components/lead-forms/leadform-rare-collectible/leadform-rare-collectible.component';
import {LeadformThreeforthirtyComponent} from '@app/shared/components/lead-forms/leadform-threeforthirty/leadform-threeforthirty.component';

export type LEAD_FORMS = SixOffLeadFormComponent
  | OnlyShippingComponent
  | FiveOffLeadFormComponent
  | FreeShippingLeadFormComponent
  | WinItWednesdayLeadFormComponent
  | LeadformThreeforthirtyComponent
  | RaceFansLeadFormComponent;
export enum LEAD_FORM_TYPE {
  FIVE_OFF = 'FIVE_OFF',
  SIX_OFF = 'SIX_OFF',
  FIFTY_OFF = 'FIFTY_OFF',
  FREE_SHIPPING = 'FREE_SHIPPING',
  WIN_IT_WEDNESDAY = 'WIN_IT_WEDNESDAY',
  ONLY_SHIPPING = 'ONLY_SHIPPING',
  RARE_COLLECTIVE = 'RARE_COLLECTIVE',
  COLORFUL_CLASSIC = 'COLORFUL_CLASSIC',
  THREE_FOR_THIRTY = 'THREE_FOR_THIRTY',
  RACE_FANS = 'RACE_FANS',
}

@Injectable()
export class LeadFormComponentService implements OnDestroy {
  private componentDestroyed$: Subject<boolean> = new Subject();

  private routerState$ = this.store.pipe(
    takeUntil(this.componentDestroyed$),
    select(fromRoot.getRouterState),
  );

  canShowLeadForm$: Observable<boolean> = forkJoin([
    this.routerState$.pipe(take(1)),
    this.route.queryParams.pipe(map(paramsMap), take(1)),
  ])
    .pipe(
      map(([routerState, queryParams]) => {
        // We decided to show it everytime we can again
        // if (!queryParams || JSON.stringify(queryParams) === '{}') {
        //   return false;
        // }

        return (queryParams as any).vn !== 'np'
          && this.localStorage.retrieve('isBannerActive') !== false
          && routerState.navigationId === 1;
      })
    );

  canShowLeadFormWithoutLS$: Observable<boolean> = forkJoin([
    this.routerState$.pipe(take(1)),
    this.route.queryParams.pipe(map(paramsMap), take(1)),
  ])
    .pipe(
      map(([routerState, queryParams]) =>
        queryParams.vn !== 'np' && routerState.navigationId === 1
      )
    );

  constructor(
    private overlay: Overlay,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private store: Store<fromStore.BlogState>,
  ) { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  open(leadFormType: LEAD_FORM_TYPE): LEAD_FORMS | null {

    if (!isPlatformBrowser(this.platformId)) {
      console.error('Not in the browser.');
      return null;
    }

    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      panelClass: 'modal-dialog-panel',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy: this.overlay.position()
        .global()
        .centerHorizontally()
        .centerVertically(),
    });

    let leadFormPortal;
    if (leadFormType === LEAD_FORM_TYPE.FIVE_OFF) {
      leadFormPortal = new ComponentPortal(FiveOffLeadFormComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.FREE_SHIPPING) {
      leadFormPortal = new ComponentPortal(FreeShippingLeadFormComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.WIN_IT_WEDNESDAY) {
      leadFormPortal = new ComponentPortal(WinItWednesdayLeadFormComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.SIX_OFF) {
      leadFormPortal = new ComponentPortal(SixOffLeadFormComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.FIFTY_OFF) {
      leadFormPortal = new ComponentPortal(FiftyOffLeadFormComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.ONLY_SHIPPING) {
      leadFormPortal = new ComponentPortal(OnlyShippingComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.RARE_COLLECTIVE) {
      leadFormPortal = new ComponentPortal(LeadformRareCollectibleComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.COLORFUL_CLASSIC) {
      leadFormPortal = new ComponentPortal(LeadformColorfulClassicComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.THREE_FOR_THIRTY) {
      leadFormPortal = new ComponentPortal(LeadformThreeforthirtyComponent);
    } else if (leadFormType === LEAD_FORM_TYPE.RACE_FANS) {
      leadFormPortal = new ComponentPortal(RaceFansLeadFormComponent);
    }

    const containerRef: ComponentRef<FiveOffLeadFormComponent> = overlayRef.attach(leadFormPortal);

    containerRef.instance.close.subscribe(() => overlayRef.dispose());

    overlayRef.backdropClick().subscribe(() => overlayRef.dispose());

    return containerRef.instance;
  }

}
