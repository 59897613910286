import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Params,
} from '@angular/router';
import { combineReducers, ActionReducer, createFeatureSelector, ActionReducerMap } from '@ngrx/store';

// reducers
import * as fromRouter from '@ngrx/router-store';
import * as fromBoxes from '@app/store/reducers/box.reducer';
import * as fromUser from '@app/store/reducers/user.reducer';
import * as fromPlans from '@app/store/reducers/plans.reducer'
import * as fromCoupon from '@app/store/reducers/coupon.reducer';
import * as fromAddresses from '@app/store/reducers/address.reducer';
import * as fromShipments from '@app/store/reducers/shipment.reducer';
import * as fromSearchEmail from '@app/store/reducers/search-email.reducer';
import * as fromSubscriptions from '@app/store/reducers/subscription.reducer';
import * as fromPaymentMethods from '@app/store/reducers/payment-method.reducer';
import * as fromNotificationBar from '@app/store/reducers/notification-bar.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface State {
  user: fromUser.State,
  boxes: fromBoxes.State,
  plans: fromPlans.State,
  coupon: fromCoupon.State,
  addresses: fromAddresses.State,
  shipments: fromShipments.State,
  searchEmail: fromSearchEmail.State,
  subscriptions: fromSubscriptions.State,
  paymentMethods: fromPaymentMethods.State
  notificationBar: fromNotificationBar.State,
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  user: fromUser.reducer,
  boxes: fromBoxes.reducer,
  plans: fromPlans.reducer,
  searchEmail: fromSearchEmail.reducer,
  coupon: fromCoupon.reducer,
  addresses: fromAddresses.reducer,
  subscriptions: fromSubscriptions.reducer,
  shipments: fromShipments.reducer,
  paymentMethods: fromPaymentMethods.reducer,
  notificationBar: fromNotificationBar.reducer,
  routerReducer: fromRouter.routerReducer,
};

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
  >('routerReducer');

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action: any) => {
    console.log('\n--------------------');
    console.log('Action: ', action.type);
    console.log('Previous State: ', state);
    if (action.payload) {
      console.log('Payload: ', action.payload);
    }
    console.log('--------------------\n');
    return reducer(state, action);
  };
}

export const mainReducer = combineReducers(reducers);

export const metaReducers = [debug];

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}
