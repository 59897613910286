/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shared/components/notification-bar/notification-bar.component.ngfactory";
import * as i4 from "./shared/components/notification-bar/notification-bar.component";
import * as i5 from "./core/services/notification-bar.service";
import * as i6 from "./shared/components/header/header.component.ngfactory";
import * as i7 from "./shared/components/header/header.component";
import * as i8 from "@ngrx/store";
import * as i9 from "@angular/router";
import * as i10 from "./shared/components/footer/footer.component.ngfactory";
import * as i11 from "./shared/components/footer/footer.component";
import * as i12 from "./core/components/console-viewer/console-viewer.component.ngfactory";
import * as i13 from "./core/components/console-viewer/console-viewer.component";
import * as i14 from "./core/components/gmail-modal/gmail-modal.component.ngfactory";
import * as i15 from "./core/components/gmail-modal/gmail-modal.component";
import * as i16 from "./core/services/gmail-modal.service";
import * as i17 from "./app.component";
import * as i18 from "./core/services/app.service";
import * as i19 from "./auth/services/auth.service";
import * as i20 from "angulartics2/gtm";
import * as i21 from "./core/services/leads.service";
import * as i22 from "./error/services/error.service";
import * as i23 from "./core/services/config.service";
import * as i24 from "ngx-webstorage";
import * as i25 from "./core/services/google-optimize.service";
import * as i26 from "./core/services/marketing.service";
import * as i27 from "./core/services/google-tag-manager.service";
import * as i28 from "./core/services/angler-ai/angler-ai.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "app-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, ":svg:svg", [["class", "spinner"], ["viewBox", "25 25 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "50"], ["cy", "50"], ["fill", "none"], ["r", "20"], ["stroke-miterlimit", "10"], ["stroke-width", "2"]], null, null, null, null, null))], null, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { mothersBannerComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AppComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "sucstu-notification-bar", [], null, null, null, i3.View_NotificationBarComponent_0, i3.RenderType_NotificationBarComponent)), i1.ɵdid(6, 12828672, null, 0, i4.NotificationBarComponent, [i1.Renderer2, i5.NotificationBarService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "sucstu-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i1.ɵdid(9, 4440064, null, 0, i7.HeaderComponent, [i8.Store, i9.Router, i1.Renderer2, i2.Location, i5.NotificationBarService], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "app-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 1, "router-outlet", [], null, [[null, "deactivate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("deactivate" === en)) {
        var pd_0 = (_co.onDeactivate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { deactivateEvents: "deactivate" }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "sucstu-footer", [["class", "footer-container"]], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i1.ɵdid(14, 49152, null, 0, i11.FooterComponent, [i2.Location, i1.PLATFORM_ID], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-console-viewer", [], null, null, null, i12.View_ConsoleViewerComponent_0, i12.RenderType_ConsoleViewerComponent)), i1.ɵdid(16, 114688, null, 0, i13.ConsoleViewerComponent, [i1.PLATFORM_ID, i9.ActivatedRoute], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-gmail-modal", [], null, null, null, i14.View_GmailModalComponent_0, i14.RenderType_GmailModalComponent)), i1.ɵdid(18, 114688, null, 0, i15.GmailModalComponent, [i16.GmailModalService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.appLoading$)) || !_co.isBrowser); _ck(_v, 2, 0, currVal_0); _ck(_v, 6, 0); _ck(_v, 9, 0); _ck(_v, 12, 0); _ck(_v, 16, 0); _ck(_v, 18, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i17.AppComponent, [i9.Router, i9.ActivatedRoute, i18.AppService, i19.AuthService, i20.Angulartics2GoogleTagManager, i21.LeadsService, i22.ErrorService, i8.Store, i23.ConfigService, i24.LocalStorageService, i24.SessionStorageService, i25.GoogleOptimizeService, i26.MarketingService, i1.NgZone, i1.PLATFORM_ID, i2.Location, i27.GoogleTagManagerService, i28.AnglerAiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("sucstu-root", i17.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
